const styles = theme => ({
  profileInfoPaper: {
    textAlign: "center",
    paddingBottom: "18px",
    paddingTop: "30px"
  },
  ownerName: {
    fontSize: "20px",
    fontWeight: "500",
    margin: "0",
    color: "#EB5C00"
  },
  ownerDesignation: {
    color: "#363636",
    marginTop: "0px",
    fontSize: "13px",
    textTransform: "uppercase",
    fontWeight: "500"
  },
  pageLink: {
    textDecoration: "none",
    color: "#222",
    "&:hover>div": {
      color: "#EB5C00",
      borderLeft: "6px solid #EB5C00 !important",
      background: "rgb(255, 244, 237) !important"
    }
  },
  activeLink: {
    "&>div": {
      color: "#EB5C00",
      borderLeft: "6px solid #EB5C00 !important",
      background: "rgb(255, 244, 237) !important"
    }
  },
  textField: {
    marginBottom: theme.spacing.unit,
    borderRadius: "10px"
  },
  rightPaper: {
    padding: "20px"
  },
  title: {
    fontWeight: "500",
    fontSize: "22px",
    marginTop: "10px",
    marginBottom: "10px"
  },
  description: {
    fontWeight: 400
  },
  leftMenu: {
    borderLeft: "6px solid #fff",
    padding: "16px 10px 16px 10px",
    lineHeight: "20px",
    "&>img": {
      verticalAlign: "-webkit-baseline-middle"
    },
    "&>span": {
      paddingLeft: "12px",
      fontSize: "14px"
    }
  },
  uploadProfilePhotoBtn: {
    marginLeft: "6px"
  },
  actionContainer: {
    padding: "45px 0 25px 0",
    "&>Button": {
      marginRight: "12px"
    }
  },
  about: {
    marginBottom: "20px"
  },
  changePasswordButton: {
    backgroundColor: "#EB5C00",
    color: "#fff",
    border: 0,
    marginRight: "10px",
    textTransform: "capitalize"
  },
  cancelButton: {
    color: "#aaa",
    textTransform: "capitalize"
  },
  socialLink: {
    cursor: "pointer",
    marginRight: "5px"
  },
  profileImage: {
    position: "relative"
  },
  profileImageUploaderContainer: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    margin: "auto",
    width: "200px",
    height: "200px"
  },
  profileImagePlaceholder: {
    display: "block",
    margin: "0px auto 15px",
    width: "200px",
    height: "200px",
    background: "#eaeaea",
    boxShadow: "inset 0 0px 1px 2px rgb(206, 206, 206)",
    borderRadius: "50%",
    "&>img": {
      borderRadius: "50%"
    }
  },
  uploadImageButtonFile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    position: "absolute",
    bottom: "0",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    borderRadius: "100%",
    padding: "8px",
    textAlign: "center",
    transition: "all 0.2s ease-in",
    "&>img": {
      verticalAlign: "-webkit-baseline-middle"
    },
    "&>span": {
      paddingLeft: "8px",
      fontSize: "12px",
      verticalAlign: "sub"
    },
    "& svg": {
      display: "none"
    },
    "&:hover": {
      background: "rgba(0,0,0,0.3)"
    },
    "&:hover svg": {
      display: "block"
    }
  },
  profileImageSmall: {
    position: "relative"
  },
  profileImageUploaderContainerSmall: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    width: "150px",
    height: "150px"
  },
  profileImagePlaceholderSmall: {
    width: "150px",
    height: "150px",
    borderRadius: "100%",
    overflow: "hidden",
    background: "#dadada",
    marginTop: "20px",
    boxShadow: "inset 0 0px 1px 2px rgb(206, 206, 206)",
    border: "1px solid #e6e6e6"
  },
  uploadImageButtonFileSmall: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    position: "absolute",
    bottom: "0",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    borderRadius: "100%",
    padding: "8px",
    textAlign: "center",
    transition: "all 0.2s ease-in",
    "&>img": {
      verticalAlign: "-webkit-baseline-middle"
    },
    "&>span": {
      paddingLeft: "8px",
      fontSize: "12px",
      verticalAlign: "sub"
    },
    "& svg": {
      display: "none"
    },
    "&:hover": {
      background: "rgba(0,0,0,0.3)"
    },
    "&:hover svg": {
      display: "block"
    }
  }
});

export default styles;
