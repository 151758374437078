import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from 'prop-types';
import ScrollArea from "react-scrollbar";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import globalstyles from "../../../../globalstyles";
import StarRatings from "react-star-ratings";
import DemoUserImage from "../../../../assets/images/default-profile-pic.png";
import contentStyles from "../../../auth-layout/content.styles";
import styles from "./styles";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { FormHelperText, Grid, IconButton, Tooltip } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faStar, faUpload
} from "@fortawesome/free-solid-svg-icons";
import EditIcon from '@material-ui/icons/Edit';
import { fetchTrip, resetDetails, cancelRequest, requestCanceledDone, adjustScheduleTime, adjustScheduleTimeDone } from "../../../../store/actions/bookingRequest";
import CountryCodesConfig from "../../../../config/country-codes";
import {
  BookingRequestStatusObject,
  TripTypeObject,
  ActivityList,
  RiderActivityList,
  DriverActivityList,
  AdminActivityList
} from "../../../../config/trip";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getOperationalParameter } from "../../../../store/actions/operationalParameter";
import { DateTimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
library.add(faStar, faUpload);

function TabContainer({ children }) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  };
}
const modalStyles = theme => ({
  modalPaper: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    "& $profileImagePlaceholder": {
      margin: "0 auto 20px"
    },
    "& $profileImageUploaderContainer": {
      left: "0",
      right: "0",
      margin: "auto"
    }
  }
});

class RiderDetails extends Component {
  state = {
    single: null,
    labelWidth: 0,
    confirmationModalOpen: false,
    editableMode: false,
    adjustTimeModal: false,
    tripDetails: {
      riderName: "",
      riderAvgRating: 0,
      riderEmail: "",
      riderPhoneNumber: "",
      riderId: "",
      driverName: "",
      driverAvgRating: 0,
      driverEmail: "",
      driverPhoneNumber: "",
      driverId: "",
      driverVehicleType: "",
      driverVehicleMake: "",
      driverVehicleModel: "",
      driverVehicleColor: "",
      tripReqBookingId: "",
      tripType: "Fixed",
      noOfPassenger: 0,
      tripReqTime: "",
      pickupTimeRequested: "",
      pickupTimeActual: "",
      pickupLocationRequested: "",
      pickupLocationActual: "",
      dropAddressRequested: "",
      dropAddressActual: "",
      destinationAddressActual: "",
      dropOffTime: "",
      tripStatus: 0,
      travelDistance: 0,
      waitingTime: 0,
      travelTime: 0,
      suvFare: 0,
      sedanFare: 0,
      paymentStatus: "",
      activityList: [],
      currentAlert: "",
      notificationContacts: [],
      isBusinessTrip: false,
      additionalComment: null,
      airline: null,
      flightNumber: null
    },
    errors: {},
    haveImage: false,
    tabValue: 0,
    scheduledTime: "",
    scheduleTripInterval: null,
    scheduleTripIntervalFetched: false
  };

  tripTypeList = TripTypeObject;

  statusList = BookingRequestStatusObject;

  activityLogMessageList = ActivityList;

  vehicleTypes = {
    1: "Sedan",
    2: "SUV"
  };

  riderActivities = RiderActivityList;
  driverActivities = DriverActivityList;
  adminActivities = AdminActivityList;

  componentDidMount() {
    let localStorageData = window.localStorage.upcAuthData;
    if (localStorageData && JSON.parse(localStorageData)) {
      if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
        JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
          each => {
            if (each.moduleName === "bookingRequest") {
              if (each.role === 2) {
                this.setState({
                  ...this.state,
                  editableMode: true,
                  loading: true
                });
              } else {
                this.setState({
                  ...this.state,
                  loading: true
                });
              }
            }
          }
        );
      } else {
        this.setState({
          ...this.state,
          editableMode: true,
          loading: true
        });
      }
    }
    this.props.fetchTrip(this.props.match.params.tripId);
    this.props.getOperationalParameter();
  }

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }

  timeDifference = (date1, date2) => {
    var difference = date1 - date2;

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60

    // var secondsDifference = Math.floor(difference/1000);
    // console.log(minutesDifference,"minutesDifference")
    let strTimeDiff = "";
    if (daysDifference !== 0) {
      strTimeDiff += daysDifference + ' day '
    }
    if (hoursDifference !== 0) {
      strTimeDiff += hoursDifference + ' hour '
    }
    if (minutesDifference !== 0) {
      strTimeDiff += minutesDifference + ' minute '
    }

    return strTimeDiff
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.scheduledTime !== this.state.scheduledTime) {
      const { errors } = this.state;
      if (new Date(this.state.scheduledTime).getTime() - new Date().getTime() < this.state.scheduleTripInterval * 60 * 1000) {
        errors.unacceptableScheduleTime = true;
        this.setState({ errors })
      } else {
        errors.unacceptableScheduleTime = false;
        this.setState({ errors })
      }
    }

    if (
      Object.keys(
        this.props.data.operationalParameterData.operationalParameterDetails
      ).length &&
      !this.state.scheduleTripIntervalFetched
    ) {
      let localParameter = { ...this.state.scheduleTripInterval };
      localParameter = this.props.data.operationalParameterData.operationalParameterDetails
        .minimumScheduleThreshold;

      this.setState({
        ...this.state,
        scheduleTripInterval: localParameter,
        scheduleTripIntervalFetched: true
      });
    }

    // console.log('detailsFetched--> ',this.props.data.bookingRequestReducer.tripDetails)
    if (this.props.data.notificationsReducer.mqttComing) {
      // console.log(this.props.data.notificationsReducer.mqttNotification)
      let tmpMqttdata = this.props.data.notificationsReducer.mqttNotification
      let tmpMqttdataType = tmpMqttdata.type
      // console.log(tmpMqttdata.data,"this.props.match.params.tripId",this.props.match.params.tripId)
      if (tmpMqttdataType === 1 || tmpMqttdataType === 2 || tmpMqttdataType === 3 || tmpMqttdataType === 4) {
        if (tmpMqttdata.data._id === this.props.match.params.tripId) {
          this.setState({ ...this.state, detailsFetched: false })
          // console.log(tmpMqttdata.data,"this.props.match.params.tripId",this.props.match.params.tripId)
          this.props.fetchTrip(this.props.match.params.tripId);
        }

      }
    }
    //  console.log(this.props.data.bookingRequestReducer.tripDetails,"this.props.data.bookingRequestReducer.tripDetails")
    if (this.props.data.bookingRequestReducer.tripDetails &&
      Object.keys(this.props.data.bookingRequestReducer.tripDetails).length) {
      if (this.props.data.bookingRequestReducer.tripDetails.tripStatus !== 1 &&
        this.props.data.bookingRequestReducer.tripDetails.tripStatus !== 2 &&
        this.props.data.bookingRequestReducer.tripDetails.tripStatus !== 3 &&
        this.props.data.bookingRequestReducer.tripDetails.tripStatus !== 4 &&
        this.props.data.bookingRequestReducer.tripDetails.tripStatus !== 8 &&
        this.props.data.bookingRequestReducer.tripDetails.tripStatus !== 9 &&
        this.props.data.bookingRequestReducer.tripDetails.tripStatus !== 14 &&
        this.props.data.bookingRequestReducer.tripDetails.tripStatus !== 15 &&
        this.props.data.bookingRequestReducer.tripDetails.tripStatus !== 10) {
        this.props.history.push("/web/auth/booking-request");
      }
    }

    if (this.props.data.bookingRequestReducer.tripDetails &&
      Object.keys(this.props.data.bookingRequestReducer.tripDetails).length) {
      if (this.props.data.bookingRequestReducer.tripDetails.tripStatus === 15 ||
        this.props.data.bookingRequestReducer.tripDetails.tripStatus === 14) {
        this.props.history.push("/web/auth/past-trips/details/" + this.props.match.params.tripId);
      }
    }

    if (
      (!this.state.detailsFetched &&
        Object.keys(this.props.data.bookingRequestReducer.tripDetails).length) ||
      (this.props.data.bookingRequestReducer.isFetching &&
        this.props.data.notificationsReducer.mqttComing &&
        (this.props.data.notificationsReducer.mqttNotification.type === 1
          || this.props.data.notificationsReducer.mqttNotification.type === 2
          || this.props.data.notificationsReducer.mqttNotification.type === 3
          || this.props.data.notificationsReducer.mqttNotification.type === 4) &&
        this.props.data.notificationsReducer.mqttNotification.data &&
        this.props.data.notificationsReducer.mqttNotification.data._id === this.props.match.params.tripId)
    ) {

      let localTripData = { ...this.state.tripDetails };
      localTripData.riderProfilePicture = this.props.data.bookingRequestReducer.tripDetails.rider.profilePicture;

      localTripData.riderName = this.props.data.bookingRequestReducer.tripDetails.rider.fullName || "N/A";
      localTripData.riderAvgRating = this.props.data.bookingRequestReducer.tripDetails.rider.ratingData && this.props.data.bookingRequestReducer.tripDetails.rider.ratingData.total ?
        (this.props.data.bookingRequestReducer.tripDetails.rider.ratingData.total / this.props.data.bookingRequestReducer.tripDetails.rider.ratingData.count).toFixed(1) : 0;
      localTripData.riderEmail = this.props.data.bookingRequestReducer.tripDetails.rider.email || "N/A";
      localTripData.riderPhoneNumber = this.props.data.bookingRequestReducer.tripDetails.rider.phone ?
        CountryCodesConfig.filter(item => {
          return item.code === this.props.data.bookingRequestReducer.tripDetails.rider.phone.countryCode;
        })[0].dial_code + this.formatNumber(this.props.data.bookingRequestReducer.tripDetails.rider.phone.number) : "N/A";
      localTripData.riderId = this.props.data.bookingRequestReducer.tripDetails.rider.displayId || "N/A";

      localTripData.driverProfilePicture = this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.profilePicture;
      localTripData.driverName = this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.fullName || "N/A";
      localTripData.driverAvgRating = this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.ratingData &&
        this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.ratingData.total ?
        (this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.ratingData.total / this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.ratingData.count).toFixed(1) : 0;
      localTripData.driverEmail = this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.email || "N/A";
      localTripData.driverPhoneNumber = this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.phone ?
        CountryCodesConfig.filter(item => {
          return item.code === this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.phone.countryCode;
        })[0].dial_code +
        this.formatNumber(this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.phone.number) : "N/A";
      localTripData.driverId = this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.displayId || "N/A";

      localTripData.driverVehicleType = this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.vehicleTypes[this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleType] : "N/A";
      localTripData.driverVehicleMake = this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleMake : "N/A";
      localTripData.driverVehicleModel = this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleModel : "N/A";
      localTripData.driverVehicleColor = this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.bookingRequestReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleColor : null;

      localTripData.tripReqBookingId = this.props.data.bookingRequestReducer.tripDetails.displayId || "N/A";

      localTripData.tripType = this.tripTypeList[this.props.data.bookingRequestReducer.tripDetails.tripType] || "N/A";
      localTripData.noOfPassenger = this.props.data.bookingRequestReducer.tripDetails.requestedNumberOfSeats || "0";
      if (this.props.data.bookingRequestReducer.tripDetails.bookingTime) {
        localTripData.tripReqTime = moment(this.props.data.bookingRequestReducer.tripDetails.bookingTime).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.tripReqTime = "";
      }
      if (this.props.data.bookingRequestReducer.tripDetails.pickupTime.requested) {
        localTripData.pickupTimeRequested = moment(this.props.data.bookingRequestReducer.tripDetails.pickupTime.requested).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.pickupTimeRequested = "";
      }
      if (this.props.data.bookingRequestReducer.tripDetails.pickupTime.actual) {
        localTripData.pickupTimeActual = moment(this.props.data.bookingRequestReducer.tripDetails.pickupTime.actual).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.pickupTimeActual = "";
      }

      localTripData.pickupLocationRequested = this.props.data.bookingRequestReducer.tripDetails.pickupAddress.requested || "N/A";
      localTripData.pickupLocationActual = this.props.data.bookingRequestReducer.tripDetails.pickupAddress.actual || "N/A";

      localTripData.dropAddressRequested = this.props.data.bookingRequestReducer.tripDetails.dropAddress.requested || "N/A";
      localTripData.dropAddressActual = this.props.data.bookingRequestReducer.tripDetails.dropAddress.actual || "N/A";

      localTripData.destinationAddressActual = this.props.data.bookingRequestReducer.tripDetails.dropAddress.actual || "N/A"; //it needs to be handled depending on the trip type

      if (this.props.data.bookingRequestReducer.tripDetails.dropTime.actual) {
        localTripData.dropOffTime = moment(this.props.data.bookingRequestReducer.tripDetails.dropTime.actual).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.dropOffTime = "";
      }

      localTripData.tripStatus = this.props.data.bookingRequestReducer.tripDetails.tripStatus || "N/A";

      localTripData.travelDistance = this.props.data.bookingRequestReducer.tripDetails.travelDistance.actual || "0";
      localTripData.waitingTime = this.props.data.bookingRequestReducer.tripDetails.waitingTime || "0";
      // if (this.props.data.bookingRequestReducer.tripDetails.dropTime.actual && this.props.data.bookingRequestReducer.tripDetails.pickupTime.actual) {
      //   localTripData.travelTime = Math.ceil((new Date(this.props.data.bookingRequestReducer.tripDetails.dropTime.actual) - new Date(this.props.data.bookingRequestReducer.tripDetails.pickupTime.actual)) / 3600000);
      // } else {
      //   localTripData.travelTime = "0";
      // }

      if (this.props.data.bookingRequestReducer.tripDetails.dropTime.requested && this.props.data.bookingRequestReducer.tripDetails.pickupTime.requested) {
        // console.log(new Date(this.props.data.bookingRequestReducer.tripDetails.dropTime.requested).getTime(),"pickupActual")
        // console.log(new Date(this.props.data.bookingRequestReducer.tripDetails.dropTime.requested) - new Date(this.props.data.bookingRequestReducer.tripDetails.pickupTime.requested))

        let localPickupRequested = new Date(this.props.data.bookingRequestReducer.tripDetails.pickupTime.requested).getTime();
        let localDropRequested = new Date(this.props.data.bookingRequestReducer.tripDetails.dropTime.requested).getTime();

        let differenceTime = this.timeDifference(localDropRequested, localPickupRequested)


        // localTripData.travelTime = Math.ceil((new Date(this.props.data.bookingRequestReducer.tripDetails.dropTime.requested) - new Date(this.props.data.billingReducer.billingDetails.pickupTime.actual)) / 3600000)
        localTripData.travelTime = differenceTime;
      } else {
        localTripData.travelTime = "0";
      }

      //calculating SUV & Sedan fare
      let fareChartInfo = null;
      if (this.props.data.bookingRequestReducer.tripDetails.tripType === 1) {
        fareChartInfo = this.props.data.bookingRequestReducer.tripDetails.emeraldFareChartInfo;
      } else {
        fareChartInfo = this.props.data.bookingRequestReducer.tripDetails.fareChartInfo;
      }
      let dropTime = this.props.data.bookingRequestReducer.tripDetails.dropTime;
      let pickupTime = this.props.data.bookingRequestReducer.tripDetails.pickupTime;
      let travelDistance = this.props.data.bookingRequestReducer.tripDetails.travelDistance;

      // if (this.props.data.bookingRequestReducer.tripDetails.tripType === 1 || this.props.data.bookingRequestReducer.tripDetails.tripType===2){
      //   localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + (new Date(dropTime.requested) -
      //     new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.suv
      //     + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
      //   localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + (new Date(dropTime.requested) -
      //     new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.sedan
      //     + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;
      // } else {F
      //   localTripData.suvFare = fareChartInfo.duration.baseFare.suv + this.props.data.bookingRequestReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.suv;
      //   localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + this.props.data.bookingRequestReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.sedan;
      // }
      console.log(this.props.data.bookingRequestReducer.tripDetails, "this.props.data.bookingRequestReducer.tripDetails")
      let distanceTravelled = this.props.data.bookingRequestReducer.tripDetails.travelDistance.requested

      switch (this.props.data.bookingRequestReducer.tripDetails.tripType) {
        case 1:
          localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.suv
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
          localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.sedan
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;

          if (distanceTravelled > 10) {
            localTripData.suvFare = localTripData.suvFare - ((localTripData.suvFare) * (fareChartInfo.fixed.discountPercentage / 100))
            localTripData.sedanFare = localTripData.sedanFare - ((localTripData.sedanFare) * (fareChartInfo.fixed.discountPercentage / 100))
          }
          break;
        case 2:
          localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.suv
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;

          localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.sedan
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;

          if (distanceTravelled > 10) {
            localTripData.suvFare = localTripData.suvFare - ((localTripData.suvFare) * (fareChartInfo.fixed.discountPercentage / 100))
            localTripData.sedanFare = localTripData.sedanFare - ((localTripData.sedanFare) * (fareChartInfo.fixed.discountPercentage / 100))
          }
          break;
        case 3:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + this.props.data.bookingRequestReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + this.props.data.bookingRequestReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.sedan;
          break;
        case 4:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + fareChartInfo.duration.halfDay.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + fareChartInfo.duration.halfDay.perHourCost.sedan;
          break;
        case 5:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + fareChartInfo.duration.fullDay.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + fareChartInfo.duration.fullDay.perHourCost.sedan;
          break;
      }
      localTripData.paymentStatus = this.props.data.bookingRequestReducer.tripDetails.transactionInfo &&
        this.props.data.bookingRequestReducer.tripDetails.transactionInfo.riderTransactionId ? "Payment done" : "Not paid yet";
      localTripData.activityList = this.props.data.bookingRequestReducer.tripDetails.activityLog;
      localTripData.currentAlert = this.statusList[this.props.data.bookingRequestReducer.tripDetails.tripStatus];

      if (this.props.data.bookingRequestReducer.tripDetails.travelDistance.requested) {
        // let distanceTravelled = this.props.data.bookingRequestReducer.tripDetails.travelDistance.requested
        // if(distanceTravelled > 10 ) {
        //   localTripData.suvFare = localTripData.suvFare - ((localTripData.suvFare)*(fareChartInfo.discountPercentage/100))
        //   localTripData.sedanFare = localTripData.sedanFare - ((localTripData.sedanFare)*(fareChartInfo.discountPercentage/100))
        // }
      }
      localTripData.notificationContacts = this.props.data.bookingRequestReducer.tripDetails.notificationContacts
      localTripData.isBusinessTrip = this.props.data.bookingRequestReducer.tripDetails.isBusinessTrip;
      localTripData.additionalComment = this.props.data.bookingRequestReducer.tripDetails.additionalComment;
      localTripData.airline = this.props.data.bookingRequestReducer.tripDetails.airline;
      localTripData.flightNumber = this.props.data.bookingRequestReducer.tripDetails.flightNumber;

      this.setState({
        ...this.state,
        tripDetails: localTripData,
        scheduledTime: new Date(localTripData.pickupTimeRequested),
        detailsFetched: true,
        loading: false
      });
    }
    if (this.props.data.bookingRequestReducer.bookingCanceled) {
      this.setState({ ...this.state, confirmationModalOpen: false });
      this.props.requestCanceledDone();
      this.props.history.push("/web/auth/booking-request");
    }

    if (this.props.data.bookingRequestReducer.scheduleTimeUpdated) {
      this.props.adjustScheduleTimeDone();
      this.closeAdjustTimeModal();
    }
  }

  componentWillUnmount() {
    this.props.resetDetails();
  }

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  cancelRequest = () => {
    this.props.cancelRequest(this.props.match.params.tripId);
  };

  openConfirmationModal = () => {
    this.setState({ confirmationModalOpen: true });
  };
  closeConfirmationModal = () => {
    this.setState({ confirmationModalOpen: false });
  };

  redirect = event => {
    this.props.history.push("/web/auth/assign-new-driver");
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  }

  openAdjustTimeModal = () => {
    this.setState({
      ...this.state,
      adjustTimeModal: true
    });
  };
  closeAdjustTimeModal = () => {
    this.setState({ ...this.state, adjustTimeModal: false });
  };

  canAdjustScheduleTime = () => {
    const { tripStatus } = this.state.tripDetails;
    return !(tripStatus === 6 ||
      tripStatus === 13 ||
      tripStatus === 12 ||
      tripStatus === 14 ||
      tripStatus === 15 ||
      tripStatus === 16)
  }

  handleDateChange = date => {
    this.setState({
      scheduledTime: date,
      errors: {
        ...this.state.errors, scheduleDate: !date
      }
    });
  };

  handleScheduleTimeSubmit = () => {
    this.props.adjustScheduleTime(this.props.match.params.tripId, {
      scheduleTime: this.state.scheduledTime.toISOString()
    });
  };

  render() {
    const { classes, theme } = this.props;
    const { tabValue } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        {!this.state.loading ? (
          <div>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                    <Typography component="p">
                      <span className={classes.pageHeader}>
                        {
                          this.props.data.literalsReducer.BookingReqDetails.PageHeader
                        }
                      </span>
                      <span className={classes.PageDescription}>

                        {
                          this.props.data.literalsReducer.BookingReqDetails
                            .PageDescription
                        }
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.headerRightButton}
                      onClick={this.backToPrevPage}
                    >

                      <ArrowBackIosIcon className={classes.leftIcon} /> Back
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <Grid container className={classes.rightPaper}>
                    <Grid item xs={12} sm={9}>
                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Tabs value={tabValue} onChange={this.handleTabChange} className={classes.tabPan}>
                              <Tab label="Chauffeur Details" />
                              <Tab label="Rider Details" />
                            </Tabs>
                            {tabValue === 0 && <TabContainer>
                              {(this.state.tripDetails.tripStatus === 2 || this.state.tripDetails.tripStatus === 4) ?
                                <div className={classes.tabDetailsCont}>
                                  <div className={classes.profileImageWrap}>
                                    <div className={classes.profileImagePlaceholder}>
                                      {this.state.tripDetails.driverProfilePicture ?
                                        <div style={{
                                          backgroundSize: "cover",
                                          backgroundRepeat: "no-repeat",
                                          width: "100%",
                                          height: "100%",
                                          backgroundImage: `url( ${this.state.tripDetails.driverProfilePicture} )`
                                        }}></div>
                                        : <img
                                          src={DemoUserImage}
                                          alt="no-menu-image"
                                          style={{ width: "100%" }}
                                        />}
                                    </div>
                                    <div style={{ marginLeft: "5px" }}>
                                      <span>{this.state.tripDetails.driverName || "N/A"}</span>

                                      <div
                                        className={classNames(
                                          classes.ratingShow,
                                          classes.description
                                        )}
                                      >
                                        <StarRatings
                                          rating={Number(this.state.tripDetails.driverAvgRating)}
                                          starRatedColor="#ffc000"
                                          numberOfStars={10}
                                          name="rating"
                                          starDimension="15px"
                                          starSpacing="2px"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.Email} -
                                    <span>{this.state.tripDetails.driverEmail || "N/A"}</span>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.PhoneNo} -
                                    <span>{this.state.tripDetails.driverPhoneNumber || "N/A"}</span>
                                  </div>
                                  {/* <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.DriverId} -
                            <span>{this.state.tripDetails.driverId || "N/A"}</span>
                            </div> */}
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.CarType} -
                                    <span>{this.state.tripDetails.driverVehicleType || "N/A"}</span>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.CarMaker} -
                                    <span>{this.state.tripDetails.driverVehicleMake || "N/A"}</span>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.CarModel} -
                                    <span>{this.state.tripDetails.driverVehicleModel || "N/A"}</span>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.CarColor} -
                                    {this.state.tripDetails.driverVehicleColor ?
                                      <span className={classes.colorView} style={{ background: this.state.tripDetails.driverVehicleColor }}></span>
                                      : <span>N/A</span>}
                                  </div>
                                </div>
                                : <div className={classes.tabDetailsCont} style={{ textAlign: "center" }}> Driver not assigned yet </div>}
                            </TabContainer>}
                            {tabValue === 1 && <TabContainer>
                              <div className={classes.tabDetailsCont}>
                                <div className={classes.profileImageWrap}>
                                  <div className={classes.profileImagePlaceholder}>
                                    {this.state.tripDetails.riderProfilePicture ?
                                      <div style={{
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        width: "100%",
                                        height: "100%",
                                        backgroundImage: `url( ${this.state.tripDetails.riderProfilePicture} )`
                                      }}></div>
                                      : <img
                                        src={DemoUserImage}
                                        alt="no-menu-image"
                                        style={{ width: "100%" }}
                                      />}
                                  </div>
                                  <div style={{ marginLeft: "5px" }}>
                                    <span>{this.state.tripDetails.riderName || "N/A"}</span>

                                    <div
                                      className={classNames(
                                        classes.ratingShow,
                                        classes.description
                                      )}
                                    >
                                      <StarRatings
                                        rating={Number(this.state.tripDetails.riderAvgRating)}
                                        starRatedColor="#ffc000"
                                        numberOfStars={10}
                                        name="rating"
                                        starDimension="15px"
                                        starSpacing="2px"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.RiderDetails.Email} -
                                  <span>{this.state.tripDetails.riderEmail || "N/A"}</span>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.RiderDetails.PhoneNo} -
                                  <span>{this.state.tripDetails.riderPhoneNumber || "N/A"}</span>
                                </div>
                                {/* <div className={classes.description}>
                              {this.props.data.literalsReducer.RiderDetails.RiderId} -
                            <span>{this.state.tripDetails.riderId || "N/A"}</span>
                            </div> */}
                              </div>
                            </TabContainer>}
                          </Grid>
                          <Grid item xs={12} sm={6} className={classes.breakDownPart}>
                            <h3>Fare Breakdown</h3>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.Estimation} -
                              <Chip label="SUV" className={classes.chip} /><span>&#36;{this.state.tripDetails.suvFare.toFixed(2) || "0"}</span>
                              {this.state.tripDetails.noOfPassenger < 5 ?
                                <span>
                                  <Chip label="SEDAN" className={classes.chip} />
                                  <span>&#36;{this.state.tripDetails.sedanFare.toFixed(2) || "0"}</span>
                                </span> : null}
                            </div>
                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                            <h3>Alert</h3>
                            <div className={classes.description}>
                              <span>{this.state.tripDetails.currentAlert}</span>
                            </div>
                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                            <div className={classes.breakDownPartAction}>
                              {window.localStorage.upcAuthData && ((JSON.parse(window.localStorage.upcAuthData).user.roleInfo.roleId &&
                                JSON.parse(window.localStorage.upcAuthData).user.roleInfo.roleId.canCancelBooking) ||
                                JSON.parse(window.localStorage.upcAuthData).user.roleInfo.isSuperAdmin) ?
                                <Button
                                  size="small"
                                  color="secondary"
                                  variant="contained"
                                  onClick={this.openConfirmationModal}
                                >
                                  Cancel
                                </Button>
                                : null}
                              {this.state.editableMode ?
                                <Link style={{ textDecoration: 'none' }} to={`/web/auth/assign-new-driver/${this.props.match.params.tripId}`} target="_blank">
                                  <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    className={classes.activeGoButton}
                                  >
                                    Assign Alternative Chauffeur
                                  </Button>
                                </Link> : null}
                            </div>
                          </Grid>

                        </Grid>
                      </Grid>
                      <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.confirmationModalOpen}
                        onClose={this.closeConfirmationModal}
                      >
                        <div style={getModalStyle()} className={classes.modalPaper}>
                          <Grid item xs={12} sm={12} className={classes.descSection}>
                            <Grid container spacing={24}>
                              <Grid item xs={12} sm={12}>
                                <h2>Cancel booking request</h2>
                                <div
                                  className={classes.description}
                                  style={{ marginBottom: "20px" }}
                                >
                                  Are you sure you want to cancel this booking request?
                                </div>
                                <div style={{ textAlign: "right" }}>
                                  <Button
                                    size="small"
                                    color="default"
                                    variant="contained"
                                    className={classes.customMargin}
                                    onClick={this.closeConfirmationModal}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    style={{ marginLeft: "10px" }}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    className={classNames(
                                      classes.customMargin,
                                      classes.activeGoButton
                                    )}
                                    onClick={this.cancelRequest}
                                  >
                                    Yes
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Modal>
                      <Divider variant="middle" style={{ "margin": "25px 0" }} />
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={24} alignItems="center" >
                          <Grid item>
                            <h3>Booking/Trip Details</h3>
                          </Grid>
                          {this.state.tripDetails.isBusinessTrip ? (
                            <Grid item>
                              <Chip icon={<BusinessCenterIcon fontSize="small" />} label="Business trip" className={classes.chip} />
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={6}>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.BookingId} -
                              <span>{this.state.tripDetails.tripReqBookingId || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.BookingType} -
                              <span>{this.state.tripDetails.tripType || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.NoOfPassenger} -
                              <span>{this.state.tripDetails.noOfPassenger || "0"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ReqAt} -
                              <span>{this.state.tripDetails.tripReqTime || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.PickupTime} -
                              <span>{this.state.tripDetails.pickupTimeRequested || "N/A"}</span>
                              {this.canAdjustScheduleTime() ? (
                                <IconButton
                                  color="inherit"
                                  className={classes.adjustTimeButton}
                                  onClick={this.openAdjustTimeModal}
                                >
                                  <Tooltip placement="right" title="Adjust pickup time">
                                    <EditIcon fontSize="small" />
                                  </Tooltip>
                                </IconButton>
                              ) : null}
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualPickupTime} -
                              <span>{this.state.tripDetails.pickupTimeActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ReqPickupLocation} -
                              <span>{this.state.tripDetails.pickupLocationRequested || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ReqDestination} -
                              <span>{this.state.tripDetails.dropAddressRequested || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualPickupLocation} -
                              <span>{this.state.tripDetails.pickupLocationActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.Airline} -
                              <span>{this.state.tripDetails.airline || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.FlightNumber} -
                              <span>{this.state.tripDetails.flightNumber || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.AdditionalComment} -
                              <span>{this.state.tripDetails.additionalComment || "N/A"}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualDestinationLocation} -
                              <span>{this.state.tripDetails.destinationAddressActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualDropOffLocation} -
                              <span>{this.state.tripDetails.dropAddressActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.TimeofDropoff} -
                              <span>{this.state.tripDetails.dropOffTime || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.Status} -
                              <span className={classes.activeStatus}>{this.statusList[this.state.tripDetails.tripStatus]}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.KmsTravelled} -
                              <span>{this.state.tripDetails.travelDistance ? this.state.tripDetails.travelDistance + " Mile" : "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.WaitTime} -
                              <span>{this.state.tripDetails.waitingTime ? this.state.tripDetails.waitingTime + " hour" : "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              Estimated Travel Time -
                              <span>{this.state.tripDetails.travelTime ? this.state.tripDetails.travelTime : "N/A"}</span>
                            </div>
                            {this.state.tripDetails.notificationContacts && this.state.tripDetails.notificationContacts.length ? (
                              <div className={classes.description}>
                                Notification contacts -
                                {this.state.tripDetails.notificationContacts.map(phoneNo => (
                                  <p>
                                    <span>{phoneNo}</span>
                                  </p>
                                ))}
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.activityLog}>
                      <h3>Activity Log</h3>
                      <ScrollArea
                        speed={0.8}
                        className={classes.scrollContentSmall}
                        contentClassName="content"
                        horizontal={false}
                      >
                        {this.state.tripDetails.activityList.length ?
                          <List className={classes.root}>
                            {this.state.tripDetails.activityList.map(activity => {
                              return <ListItem alignItems="flex-start" key={activity._id}>
                                <span>
                                  {this.riderActivities.indexOf(activity.actionType) > -1 ?
                                    activity.rider.fullName : this.driverActivities.indexOf(activity.actionType) > -1 ?
                                      (activity.driver && activity.driver.fullName || 'Chauffeur') : this.adminActivities.indexOf(activity.actionType) > -1 ?
                                        activity.admin.fullName : null}
                                </span>{this.activityLogMessageList[activity.actionType]}
                                <p>
                                  <span>{moment(activity.timeStamp).format("MM-DD-YYYY, h:mm a")}</span>
                                </p>
                              </ListItem>
                            })}

                          </List> : <div
                            style={{
                              textAlign: "center",
                              padding: "10px",
                              backgroundColor: "#fff",
                              borderRadius: "10px"
                            }}
                          >No activity found!</div>}
                      </ScrollArea>

                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Modal
              open={this.state.adjustTimeModal}
              onClose={this.closeAdjustTimeModal}
            >
              <div style={getModalStyle()} className={classes.modalPaper}>
                <Grid item xs={12} sm={12} className={classes.descSection}>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="h6" component="h6">Adjust pickup time</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {/* <Typography variant="body1" component="span">{this.state.scheduledTime}</Typography> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          className={classes.textField}
                          variant="outlined"
                          value={this.state.scheduledTime}
                          onChange={this.handleDateChange}
                        />
                      </MuiPickersUtilsProvider>
                      {this.state.errors.unacceptableScheduleTime ? (
                        <FormHelperText error={true} filled={true}>
                          {
                            this.props.data.literalsReducer
                              .ValidationErrors.UnacceptableScheduleTime
                          }
                        </FormHelperText>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div style={{ textAlign: "right" }}>
                        <Button
                          size="small"
                          color="defalut"
                          variant="contained"
                          className={classes.customMargin}
                          onClick={this.closeAdjustTimeModal}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          style={{ marginLeft: "10px" }}
                          color="primary"
                          variant="contained"
                          disabled={this.state.errors.unacceptableScheduleTime}
                          className={classNames(
                            classes.customMargin,
                            classes.activeGoButton
                          )}
                          onClick={this.handleScheduleTimeSubmit}
                        >
                          Update
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Modal>
          </div>) : (
          <div
            style={{
              textAlign: "center",
              padding: "10px",
              backgroundColor: "#fff",
              borderRadius: "10px"
            }}
          >
            {this.props.data.literalsReducer.Common.Loading}
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTrip: (id) => dispatch(fetchTrip(id)),
    cancelRequest: (id) => dispatch(cancelRequest(id)),
    resetDetails: () => dispatch(resetDetails()),
    requestCanceledDone: () => dispatch(requestCanceledDone()),
    getOperationalParameter: () => dispatch(getOperationalParameter()),
    adjustScheduleTime: (id, data) => dispatch(adjustScheduleTime(id, data)),
    adjustScheduleTimeDone: () => dispatch(adjustScheduleTimeDone())
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...modalStyles(theme),
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(RiderDetails)
);
