import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Logo from "../../assets/images/logo.png";
import LogInImage from "../../assets/images/login_front.jpg";
import { Link, Redirect } from "react-router-dom";

import globalstyles from "../../globalstyles";
import { styles } from "./styles";

import { connect } from "react-redux";
import { resetPassword } from "../../store/actions/auth";
import { AUTH_DATA } from "../../config/local-storage-keys";

import Grid from "@material-ui/core/Grid";

import classNames from "classnames";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";

import theme from "../../app/theme";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    // initially assuming that user is logged out
    let user = {
      isLoggedIn: false
    };

    // if user is logged in, his details can be found from local storage
    try {
      let userJsonString = localStorage.getItem(AUTH_DATA);
      if (userJsonString) {
        user.data = JSON.parse(userJsonString);
        if (user.data.token) {
          user.isLoggedIn = true;
        }
      }
    } catch (exception) {
      console.log("exception", exception);
    }

    this.state = {
      fields: {
        otp: "",
        password: "",
        confirmPassword: ""
      },
      errors: {},
      isLoggedIn: user.isLoggedIn,
      toastMessage: false
    };
  }
  
  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleChange = event => {
    let fields = {
      ...this.state.fields
    };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;
    if (event.target.name === "confirmPassword") {
      errors.passwordMismatch = false;
    }

    if (event.target.name === "password") {
      errors.passwordLength = false;
    }

    this.setState({ ...this.state, fields, errors });
  };

  handleValidation = () => {
    let fields = { ...this.state.fields };
    let errors = {
      ...this.state.errors
    };
    let isFormValid = true;

    for (let name in fields) {
      if (fields[name].trim() === "") {
        errors[name] = true;
        isFormValid = false;
      }
    }

    if (fields.password !== fields.confirmPassword) {
      errors["passwordMismatch"] = true;
      isFormValid = false;
    } else {
      errors["passwordMismatch"] = false;
    }

    if (fields.password.length < 6) {
      errors["passwordLength"] = true;
      isFormValid = false;
    } else {
      errors["passwordLength"] = false;
    }

    this.setState({ errors: errors });
    return isFormValid;
  };

  submitHandler = event => {
    event.preventDefault();
    let requestData = { ...this.state.fields };
   
    let payload = {
      email: localStorage.getItem("email"),
      otp: requestData.otp,
      password: requestData.password
    };
    if (this.handleValidation()) {
      console.log("payload", payload);
      this.props.dispatch(resetPassword(payload));
    } else {
      console.log("Admin Reset Form has errors", this.state);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (this.props.data.authReducer.forgotPasswordData.resetPasswordSuccess) {
        localStorage.removeItem("email");
        this.props.history.push("/login");
      }
    }
  }

  resendPass = () => {};

  render(props) {
    const { classes } = this.props;

    if (this.state.isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/web/auth/home",
            state: {
              from: this.props.location
            }
          }}
        />
      );
    } else {
      return (
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <Grid container spacing={24} className={classes.signInWrapper}>
              <Grid
                item
                xs={12}
                sm={7}
                className={classes.loginImage}
                style={{
                  backgroundImage: `url(${LogInImage})`
                }}
              />
              <Grid item xs={12} sm={5} className={classes.signinBox}>
                <Paper className={classes.signinBoxPaper}>
                  <Typography
                    component="h1"
                    variant="h5"
                    className={classes.formHeaderLogo}
                  >
                    <Link to="/login">
                      <img src={Logo} alt="logo" />
                    </Link>
                  </Typography>
                  <Typography
                    component="h1"
                    variant="h5"
                    className={classes.formHeader}
                  >
                    {this.props.data.literalsReducer.Home.ResetPasswordHeader}
                  </Typography>
                  <form className={classes.form}>
                    <TextField
                      className={classes.textField}
                      style={{ marginBottom: 0 }}
                      margin="normal"
                      autoComplete="otp"
                      variant="outlined"
                      label={this.props.data.literalsReducer.Home.OTP}
                      name="otp"
                      value={this.state.fields["otp"]}
                      onChange={this.handleChange}
                      error={this.state.errors.otp}
                    />
                    {this.state.errors.otp ? (
                      <FormHelperText id="validation-error">
                        {
                          this.props.data.literalsReducer.ValidationErrors
                            .Required
                        }
                      </FormHelperText>
                    ) : null}

                    <TextField
                      className={classNames(
                        classes.textField,
                        classes.passwordInput
                      )}
                      variant="outlined"
                      type={this.state.showPassword ? "text" : "password"}
                      label={this.props.data.literalsReducer.Home.Password}
                      name="password"
                      autoComplete="new-password"
                      value={this.state.fields["password"]}
                      onChange={this.handleChange}
                      error={this.state.errors.password}
                    />
                    {this.state.errors.password ? (
                      <FormHelperText id="validation-error">
                        {
                          this.props.data.literalsReducer.ValidationErrors
                            .Required
                        }
                      </FormHelperText>
                    ) : null}
                    <TextField
                      className={classNames(
                        classes.textField,
                        classes.passwordInput
                      )}
                      variant="outlined"
                      type={this.state.showPassword ? "text" : "password"}
                      label={
                        this.props.data.literalsReducer.Home.ConfirmPassword
                      }
                      name="confirmPassword"
                      autoComplete="new-password"
                      value={this.state.fields["confirmPassword"]}
                      onChange={this.handleChange}
                      error={
                        this.state.errors.confirmPassword ||
                        this.state.errors.passwordMismatch
                      }
                    />
                    {this.state.errors.confirmPassword ? (
                      <FormHelperText id="validation-error">
                        {
                          this.props.data.literalsReducer.ValidationErrors
                            .Required
                        }
                      </FormHelperText>
                    ) : null}
                    {!this.state.errors.confirmPassword &&
                    this.state.errors.passwordMismatch ? (
                      <FormHelperText id="validation-error">
                        {
                          this.props.data.literalsReducer.ValidationErrors
                            .PasswordMismatch
                        }
                      </FormHelperText>
                    ) : null}
                  </form>
                  <div className={classes.formFooter}>
                    <div className={classes.formFooterRight}>
                      <Button
                        type="Button"
                        variant="contained"
                        color="primary"
                        className={classNames(
                          classes.submit,
                          classes.buttonHalf,
                          classes.linkButton
                        )}
                      >
                        <Link
                          to="/forgot-password/request-otp"
                          className={classes.link}
                        >
                          Back
                        </Link>
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classNames(
                          classes.submit,
                          classes.buttonHalf,
                          classes.linkButton
                        )}
                        onClick={this.submitHandler}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </MuiThemeProvider>
      );
    }
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return { data: state };
};

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(ResetPassword)
);
