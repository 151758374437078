import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@material-ui/core"
import React, { Component } from "react";
import DemoUserImage from "../../../assets/images/avatar.png";
import SedanCarModel from '../../../assets/images/sedan-car-model.png';
import SuvCarModel from '../../../assets/images/car-suv.png';
import { VehicleTypes } from "../../../config/vehicle";

export const renderFavoriteChauffeurs = (data, sort) => {

    const getVehicleModel = (type) => {
        return VehicleTypes.find(_v => _v.value === type.toString()).label;
    };

    const convertToMiles = (value) => {
        return `${(value * 0.000621).toFixed(1)} miles`;
    };

    const getSortedDrivers = () => {
        switch (sort) {
            case 'NEAREST':
                return data.searchedDrivers.sort((a, b) => {
                    return a.distance.value - b.distance.value
                })
            case 'PRICE':
                return data.searchedDrivers.sort((a, b) => {
                    return a.fare - b.fare
                })
            default:
                return data.searchedDrivers
        }
    };

    console.log(data);



    return (
        <List style={{ width: '100%', bgcolor: 'background.paper' }}>
            {getSortedDrivers().map(_driver => (
                <>
                    <ListItem alignItems="center">
                        <ListItemAvatar>
                            <Avatar src={_driver.driverInfo.personalInfo.profilePicture || DemoUserImage} />
                        </ListItemAvatar>
                        <ListItemText
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                    >
                                        {_driver.driverInfo.personalInfo.fullName}
                                    </Typography>
                                    {convertToMiles(_driver.distance.value)} | {`$${_driver.fare.toFixed(2)}`}
                                </React.Fragment>
                            }
                        />
                        <Grid justify="flex-end">
                            <Grid item>
                                <img src={_driver.driverInfo.vehicleInfo.vehicleType == 1 ? SedanCarModel : SuvCarModel} width="60dp" alt="Sedan model" />
                            </Grid>
                            <Grid item>
                                <Typography align="right" component="span" variant="caption">{getVehicleModel(_driver.driverInfo.vehicleInfo.vehicleType)}</Typography>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Divider />
                </>
            ))}
        </List>
    )
}