const leftMenuConfig = [
  {
    to: "/web/auth/home",
    menuText: "Dashboard",
    menuIcon: "tachometer-alt",
    moduleName: "home"
  },
  {
    to: "/web/auth/riders",
    menuText: "Riders",
    menuIcon: "users",
    moduleName: "rider"
  },
  {
    to: "/web/auth/drivers",
    menuText: "Chauffeurs",
    menuIcon: "user-tie",
    moduleName: "driver"
  },
  {
    to: "/web/auth/schedule-trip",
    menuText: "Schedule a Trip",
    menuIcon: "route",
    moduleName: "scheduleTrip"
  },
  {
    to: "/web/auth/past-trips",
    menuText: "Past Trips",
    menuIcon: "history",
    moduleName: "pastTrip"
  },
  {
    to: "/web/auth/ongoing-trips",
    menuText: "Ongoing Trips",
    menuIcon: "road",
    moduleName: "ongoingTrip"
  },
  {
    to: "/web/auth/ongoing-trip-alerts",
    menuText: "Ongoing Trip Alerts",
    menuIcon: "bell",
    moduleName: "ongoingTripAlert"
  },
  {
    to: "/web/auth/operational-zone",
    menuText: "Operational Zone",
    menuIcon: "globe",
    moduleName: "zone"
  },
  {
    to: "/web/auth/booking-request",
    menuText: "Booking Request",
    menuIcon: "exclamation-triangle",
    moduleName: "bookingRequest"
  },
  {
    to: "/web/auth/booking-alerts",
    menuText: "Booking Alerts",
    menuIcon: "bell",
    moduleName: "bookingRequestAlert"
  },
  {
    to: "/web/auth/rate-charts",
    menuText: "Rate Charts",
    menuIcon: "money-check-alt",
    moduleName: "fareChart"
  },
  {
    to: "/web/auth/billing",
    menuText: "Billing",
    menuIcon: "file-invoice",
    moduleName: "billing"
  },
  {
    to: "/web/auth/roles",
    menuText: "Role",
    menuIcon: "users-cog",
    moduleName: "role"
  },
  {
    to: "/web/auth/admins",
    menuText: "Admins",
    menuIcon: "user-lock",
    moduleName: "admin"
  },
  {
    to: "/web/auth/global-settings",
    menuText: "Global Settings",
    menuIcon: "cogs",
    moduleName: "globalSettings"
  },
  {
    to: "/web/auth/operational-params",
    menuText: "Operational Params",
    menuIcon: "tools",
    moduleName: "operationalParameter"
  },
  {
    to: "/web/auth/revenue",
    menuText: "Revenue",
    menuIcon: "coins",
    moduleName: "revenue"
  },
  {
    to: "/web/auth/upc-riders",
    menuText: "Ur PC Riders",
    menuIcon: "users",
    moduleName: "UPCRider"
  },
  {
    to: "/web/auth/upc-drivers",
    menuText: "Ur PC Chauffeurs",
    menuIcon: "users",
    moduleName: "UPCDriver"
  }
];

export default leftMenuConfig;
