// API Config
import {apiURLs} from '../api-config/api.urls';
// Action Types Constant
import {
  GET_DASHBOARD_DETAILS
} from "../constants/action-types";

// //////////////////// Get Dashboard  Details // //////////////////
export const getDashboardDetail = (data) => {
  return {
    type: GET_DASHBOARD_DETAILS,
    payload: {
      request: {
        url: apiURLs.dashboardDetail,
        method: "GET"
      }
    },
    hideToast: true
  };
};


