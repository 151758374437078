import React, { Component } from 'react';
import DraggableListItem from './DraggableListItem';
import {
    DragDropContext,
    Droppable,
    OnDragEndResponder
} from 'react-beautiful-dnd';
import { List } from '@material-ui/core';

class DraggableList extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { onDragEnd, items } = this.props;

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-list">
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {items.map((item, index) => (
                                <DraggableListItem item={item} index={index} key={item._id} />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

export default DraggableList;