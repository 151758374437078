import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import authRoutesConfig from "./auth-routes-config";
import UserProfile from "../../auth/user-profile/index";

class AuthRoutes extends Component {
  render() {
    let localStorageData = {};
    if (window.localStorage.upcAuthData) {
      localStorageData = JSON.parse(window.localStorage.upcAuthData);
    }
    let routePaths = [];

    if (
      window.localStorage.upcAuthData &&
      !localStorageData.user.roleInfo.isSuperAdmin
    ) {
      authRoutesConfig.map(route => {
        localStorageData.user.roleInfo.roleId.permissions.map(module => {
          if (module.moduleName === route.moduleName) {
            routePaths.push(route);
          }
        });
      });
      routePaths = [
        ...routePaths,
        {
          path: "/web/auth/profile/account-settings",
          component: UserProfile
        },
        {
          path: "/web/auth/profile/change-password",
          component: UserProfile
        }
      ];
    } else {
      routePaths = authRoutesConfig;
    }

    return (
      <Switch>
        {routePaths.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            render={props => (
              <route.component {...props} routes={route.routes} />
            )}
            exact
          />
        ))}
        <Route render={() => <Redirect to="/web/auth/home" />} />
      </Switch>
    );
  }
}

export default AuthRoutes;
