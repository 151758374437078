import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import leftMenuConfig from "./left-menu-config";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTachometerAlt,
  faChartBar,
  faUsers,
  faUser,
  faUserTie,
  faCheckDouble,
  faRoad,
  faExclamationTriangle,
  faMoneyCheckAlt,
  faUsersCog,
  faUserLock,
  faCogs,
  faCoins,
  faHistory,
  faTools,
  faGlobe,
  faBell,
  faFileInvoice,
  faRoute
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(
  faTachometerAlt,
  faChartBar,
  faUsers,
  faUserTie,
  faCheckDouble,
  faRoad,
  faExclamationTriangle,
  faMoneyCheckAlt,
  faUsersCog,
  faUserLock,
  faCogs,
  faCoins,
  faHistory,
  faTools,
  faGlobe,
  faBell,
  faFileInvoice,
  faRoute
);

// const LeftMenu = props =>
class LeftMenu extends Component {
  componentDidMount() {}

  render() {
    let localStorageData = {};
    if (window.localStorage.upcAuthData) {
      localStorageData = JSON.parse(window.localStorage.upcAuthData);
    }
    return leftMenuConfig.map((eachMenu, index) => {
      return window.localStorage.upcAuthData &&
        localStorageData.user.roleInfo.isSuperAdmin ? (
        <ListItem className="left-menu-item-cont" key={index}>
          <NavLink
            activeClassName="is-active"
            to={eachMenu.to}
            key={index}
            style={{ textDecoration: "none" }}
          >
            {this.props.open ? (
              <ListItem button className="left-menu-item">
                <ListItemIcon
                  style={{
                    marginRight: 0,
                    marginLeft: "7px"
                  }}
                >
                  <FontAwesomeIcon
                    icon={eachMenu.menuIcon}
                    style={{ fontSize: "20px" }}
                  />
                </ListItemIcon>
                <ListItemText primary={eachMenu.menuText} />
              </ListItem>
            ) : (
              <ListItem button className="left-menu-item-closed">
                <ListItemIcon
                  style={{
                    marginRight: 0,
                    marginLeft: "7px"
                  }}
                >
                  <FontAwesomeIcon
                    icon={eachMenu.menuIcon}
                    style={{ fontSize: "20px" }}
                  />
                </ListItemIcon>
              </ListItem>
            )}
          </NavLink>
        </ListItem>
      ) : (
        localStorageData &&
          localStorageData.user &&
          localStorageData.user.roleInfo.roleId &&
          localStorageData.user.roleInfo.roleId.permissions.map(
            (module, ind) => {
              return (
                <ListItem className="left-menu-item-cont" key={ind}>
                  {module.moduleName === eachMenu.moduleName ? (
                    <NavLink
                      activeClassName="is-active"
                      to={eachMenu.to}
                      key={ind}
                      style={{ textDecoration: "none" }}
                    >
                      {this.props.open ? (
                        <ListItem button className="left-menu-item">
                          <ListItemIcon
                            style={{
                              marginRight: 0,
                              marginLeft: "7px"
                            }}
                          >
                            <FontAwesomeIcon
                              icon={eachMenu.menuIcon}
                              style={{ fontSize: "20px" }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={eachMenu.menuText} />
                        </ListItem>
                      ) : (
                        <ListItem button className="left-menu-item-closed">
                          <ListItemIcon
                            style={{
                              marginRight: 0,
                              marginLeft: "7px"
                            }}
                          >
                            <FontAwesomeIcon
                              icon={eachMenu.menuIcon}
                              style={{ fontSize: "20px" }}
                            />
                          </ListItemIcon>
                        </ListItem>
                      )}
                    </NavLink>
                  ) : null}
                </ListItem>
              );
            }
          )
      );
    });
  }
}

// export default LeftMenu;
const mapStateToProps = state => {
  return {
    data: state
  };
};

export default connect(mapStateToProps)(LeftMenu);
