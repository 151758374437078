import {
  FETCH_ROLES_SUCCESS,
  FETCH_UPC_SUCCESS,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE,
  FETCH_ROLE_FAIL,
  ADD_ROLE_SUCCESS,
  ROLE_CREATED_DONE,
  RESET_ROLE_DETAILS,
  EDIT_ROLE_SUCCESS,
  ROLE_UPDATED_DONE,
  DELETE_ROLE_SUCCESS,
  ROLE_DELETE_DONE
} from "../constants/action-types";

const initialState = {
  roleList: {},
  roleDetails: {},
  roleCreated: false,
  isFetching: false,
  roleUpdated: false,
  roleDeleted: false
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roleList: action.payload.data
      };
    case FETCH_UPC_SUCCESS:
      return {
        ...state,
        upcData: action.payload.data
      };
    case FETCH_ROLE:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_ROLE_SUCCESS:
      return {
        ...state,
        roleDetails: action.payload.data,
        isFetching: false
      };
    case FETCH_ROLE_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case RESET_ROLE_DETAILS:
      return {
        ...state,
        roleDetails: {}
      };
    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        roleCreated: true
      };
    
    case EDIT_ROLE_SUCCESS:
      let localDataEdit = {...state.roleDetails};
      localDataEdit=action.payload.data;
      return {
        ...state,
        roleDetails: {
            ...localDataEdit
        },
        roleUpdated: true
      };
    case ROLE_UPDATED_DONE:
      return {
        ...state,
        roleUpdated: false
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        roleDeleted: true
      };
    case ROLE_DELETE_DONE:
      return {
        ...state,
        roleDeleted: false
      };
    case ROLE_CREATED_DONE:
      return {
        ...state,
        roleCreated: false
      };
    default:
      return state;
  }
};

export default roleReducer;
