import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../../app/theme";
import Divider from "@material-ui/core/Divider";
import contentStyles from "../../../auth-layout/content.styles";
import classNames from "classnames";
import globalstyles from "../../../../globalstyles";

import styles from "./styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import RoleCodesConfig from "../../../../config/role-config";

import { addRole, roleCreatedDone } from "../../../../store/actions/role";
import { showSnackbar } from "../../../../store/actions/snackbar";
library.add(faUpload);

// const roles = RoleCodesConfig;
const accessModules = RoleCodesConfig.map(accessModules => ({
  value: accessModules.code,
  label: accessModules.label
}));

class AddRole extends Component {
  state = {
    single: null,
    labelWidth: 0,
    roleDetails: {
      roleName: ""
    },

    modules: [
      {
        value: "1",
        selected: true,
        text: "Dashboard",
        name: "home",
        access: accessModules[0].value
      },
      {
        value: "2",
        selected: false,
        text: "Rider",
        name: "rider",
        access: accessModules[0].value
      },
      {
        value: "3",
        selected: false,
        text: "Role",
        name: "role",
        access: accessModules[0].value
      },
      {
        value: "4",
        selected: false,
        text: "Fare Chart",
        name: "fareChart",
        access: accessModules[0].value
      },
      {
        value: "5",
        selected: false,
        text: "Past Trip",
        name: "pastTrip",
        access: accessModules[0].value
      },
      {
        value: "6",
        selected: false,
        text: "Booking Request",
        name: "bookingRequest",
        access: accessModules[0].value
      },
      {
        value: "7",
        selected: false,
        text: "Ongoing Trip",
        name: "ongoingTrip",
        access: accessModules[0].value
      },
      {
        value: "8",
        selected: false,
        text: "Ongoing Trip Alert",
        name: "ongoingTripAlert",
        access: accessModules[0].value
      },
      {
        value: "9",
        selected: false,
        text: "Booking Request Alert",
        name: "bookingRequestAlert",
        access: accessModules[0].value
      },
      {
        value: "10",
        selected: false,
        text: "Revenue",
        name: "revenue",
        access: accessModules[0].value
      },
      {
        value: "11",
        selected: false,
        text: "Global Settings",
        name: "globalSettings",
        access: accessModules[0].value
      },
      {
        value: "12",
        selected: false,
        text: "Operational Parameter",
        name: "operationalParameter",
        access: accessModules[0].value
      },
      {
        value: "13",
        selected: false,
        text: "Chauffeur",
        name: "driver",
        access: accessModules[0].value
      },
      {
        value: "14",
        selected: false,
        text: "Zone",
        name: "zone",
        access: accessModules[0].value
      },
      {
        value: "15",
        selected: false,
        text: "Admin",
        name: "admin",
        access: accessModules[0].value
      },
      {
        value: "16",
        selected: false,
        text: "Ur PC Chauffeur",
        name: "UPCDriver",
        access: accessModules[0].value
      },
      {
        value: "17",
        selected: false,
        text: "Ur PC Rider",
        name: "UPCRider",
        access: accessModules[0].value
      },
      {
        value: "18",
        selected: false,
        text: "Can cancel booking",
        name: "canCancelBooking",
        access: accessModules[0].value
      },
      {
        value: "19",
        selected: false,
        text: "Can End Trip",
        name: "canEndTrip",
        access: accessModules[0].value
      }
    ],
    errors: {},
    haveImage: false
  };

  handleChange = event => {
    let fields = { ...this.state.roleDetails };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    this.setState({ ...this.state, roleDetails: fields, errors });
  };

  roleInfoValidation = () => {
    let fields = { ...this.state.roleDetails };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    if (!fields.roleName.trim()) {
      errors.roleName = true;
      hasErrors = true;
    }

    console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  componentDidMount() {
    this.setState({
      ...this.state
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (ReactDOM.findDOMNode(this.InputLabelRef)) {
      this.setState({
        ...this.state,
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
      });
    }

    if (this.props.data.roleReducer.roleCreated) {
      this.props.roleCreatedDone();
      this.props.history.push("/web/auth/roles");
    }
  }

  handleModuleSelectionChange = index => {
    let localModules = [...this.state.modules];
    localModules[index].selected = !localModules[index].selected;

    this.setState({ ...this.state, modules: localModules });
  };
  handleModuleAccessChange = index => {
    let localModulesAccess = [...this.state.modules];
    if (localModulesAccess[index].access === 1) {
      localModulesAccess[index].access = 2;
    } else {
      localModulesAccess[index].access = 1;
    }

    this.setState({ ...this.state, modules: localModulesAccess });
  };

  componentWillUnmount() { }

  saveHandler = () => {
    if (!this.roleInfoValidation()) {
      let tmpModule = [...this.state.modules];
      let permissions = [];
      for (let i = 0; i < tmpModule.length; i++) {
        if (i !== 17 && i !== 18) {
          if (tmpModule[i].selected) {
            permissions.push({
              moduleName: tmpModule[i].name,
              role: tmpModule[i].access
            });
          }
        }
      }

      if (permissions.length === 1) {
        this.props.showSnackbar("Please select atleast one module", 'error');
        return;
      } else {
        let payload = {
          name: this.state.roleDetails.roleName,
          permissions: permissions,
          canCancelBooking: tmpModule[17].selected,
          canEndTrip: tmpModule[18].selected
        };
        this.props.addRole(payload);
      }
    }
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                  <Typography component="p">
                    <span className={classes.pageHeader}>
                      {this.props.data.literalsReducer.Roles.PageHeader}
                    </span>
                    <span className={classes.PageDescription}>
                      {this.props.data.literalsReducer.AddRole.PageDescription}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.headerRightButton}
                    onClick={this.backToPrevPage}
                  >
                    <ArrowBackIosIcon className={classes.leftIcon} /> Back
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "15px" }}>
            <Grid item xs={12} sm={12}>
              <Paper>
                <Grid container className={classes.rightPaper}>
                  <Grid item xs={12} sm={12}>
                    <h3>Role Name</h3>
                    <div
                      className={classes.description}
                      style={{ width: "50%" }}
                    >
                      <TextField
                        fullwidth
                        variant="outlined"
                        label="Role Name"
                        placeholder="Enter Role Name"
                        className={classes.textField}
                        name="roleName"
                        value={this.state.roleDetails.roleName}
                        onChange={this.handleChange}
                        error={this.state.errors.roleName}
                      />
                      {this.state.errors.roleName ? (
                        <FormHelperText id="validation-error2">
                          {
                            this.props.data.literalsReducer.ValidationErrors
                              .Required
                          }
                        </FormHelperText>
                      ) : null}
                    </div>
                    <Divider
                      variant="middle"
                      style={{ margin: "30px 0 25px" }}
                    />

                    <h3>Accessibility Modules</h3>
                    <FormGroup className={classes.selectList}>
                      <Grid container>
                        {this.state.modules && this.state.modules.length
                          ? this.state.modules.map((type, index) => {
                            return (
                              <Grid item xs={6}>
                                <div key={index}>
                                  {type.value !== "1" ? (
                                    <Checkbox
                                      checked={type.selected}
                                      onChange={() =>
                                        this.handleModuleSelectionChange(
                                          index
                                        )
                                      }
                                      value={type.value}
                                      color="primary"
                                    />
                                  ) : (
                                    <Checkbox
                                      checked={type.selected}
                                      disabled
                                      onChange={() =>
                                        this.handleModuleSelectionChange(
                                          index
                                        )
                                      }
                                      value={type.value}
                                      color="primary"
                                    />
                                  )}{" "}
                                  <span style={{ marginRight: "10px" }}>
                                    {type.text}
                                  </span>
                                  {type.value !== "1" &&
                                    type.value !== "18" &&
                                    type.value !== "19" ? (
                                    <TextField
                                      select
                                      variant="outlined"
                                      className={classes.textField}
                                      SelectProps={{
                                        native: true
                                      }}
                                      onChange={() =>
                                        this.handleModuleAccessChange(index)
                                      }
                                      value={type.access}
                                      disabled={!type.selected}
                                    >
                                      {accessModules.map(option => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </TextField>
                                  ) : null}
                                </div>
                              </Grid>
                            );
                          })
                          : null}
                      </Grid>
                    </FormGroup>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.actionContainer}>
                      <Button
                        size="large"
                        color="default"
                        variant="contained"
                        className={classes.customMargin}
                        onClick={this.backToPrevPage}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        className={classNames(
                          classes.customMargin,
                          classes.activeGoButton
                        )}
                        onClick={this.saveHandler}
                      >
                        Add Role
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    addRole: payload => dispatch(addRole(payload)),
    roleCreatedDone: () => dispatch(roleCreatedDone()),
    showSnackbar: (message, variant) => dispatch(showSnackbar(message, variant))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(AddRole)
);
