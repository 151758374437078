const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  pageHeader: {
    fontSize: "26px",
    marginRight: "10px",
    color: "#4b4b4c"
  },
  PageDescription: {
    color: "#bebdbf"
  },
  chartWrapper: {
    backgroundColor: "#fff",
    padding: "25px !important"
  },
  formControl: {
    minWidth: 120,
    width: "90%"
  },
  select: {
    padding: "15px"
  },
  chartHeading: {
    fontSize: "20px",
    marginBottom: "25px"
  },
  chartHeaderImage: {
    marginRight: "5px"
  },
  revenueAmount: {
    fontSize: "32px",
    color: "#EB5C00",
    float: "right"
  },
  revenueCurrency: {
    fontSize: "20px"
  },
  rightChartWrapper: {
    marginLeft: "2%"
  },
  counterWrapper: {
    maxWidth: "100%"
  },
  rightPaper: {
    padding: "32px 48px !important"
  },
  textField: {
    margin: "0 10px 0 5px",
    width: "40px",
    "& input": {
      padding: "1px 0 3px"
    }
  },
  description: {
    fontSize: "14px",
    margin: "20px 0 10px 0",
    lineHeight: "1.6",
    color: "#000",
    "& span": {
      margin: "0 10px 0",
      color: "rgba(0, 0, 0, 0.7)",
      fontSize: "15px",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif"
    }
  },
  actionContainer: {
    padding: "20px 48px",
    borderTop: "1px solid #ccc",
    textAlign: "right",
    "&>Button": {
      textTransform: "capitalize",
      "&:first-child": {
        marginRight: "12px"
      }
    }
  },
  filterDateLabel: {
    display: "inline-block",
    width: "50px",
    border: "1px solid #ccc",
    borderRight: "0 !important",
    padding: "7px 7px 8px",
    fontSize: "14px",
    color: "#ccc",
    textAlign: "left",
    backgroundColor: "#fff"
  },
  filterButton: {
    width: "80%",
    padding: "14px",
    margin: 0,
    textTransform: "capitalize"
  },
  dateTimeField: {
    backgroundColor: "#fff",
    display: "inline-block",
    margin: "0",
    width: "75%"
  },
  activeGoButton: {
    color: "#fff",
    background: "#eb5c00",
    borderColor: "#eb5c00",
    "&:hover": {
      background: "#cc5000 !important",
      borderColor: "#cc5000 !important"
    }
  }
});

export default styles;
