import React, { Component } from "react";
import { connect } from "react-redux";
import theme from "../../../app/theme";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import contentStyles from "../../auth-layout/content.styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { Link, Route, Switch } from "react-router-dom";
import ChangePassword from "./change-password";
import globalstyles from "../../../globalstyles";
import AccountSettings from "./account-settings";

import BlankProfileImage from "../../../assets/images/default-profile-pic.png";
import DemoUserImage from "../../../assets/images/super-admin.jpg";
import facebookIcon from "../../../assets/images/facebook.png";
import twitterIcon from "../../../assets/images/twitter.png";
import googlePlusIcon from "../../../assets/images/google-plus.png";

import settingsIcon from "../../../assets/images/settings.png";
import passwordIcon from "../../../assets/images/password.png";

import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCog, faKey, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faCog, faKey, faUpload);

// import {getProfile} from "../../../store/actions/profile";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    console.log(this.props, "props");
    switch (this.props.history.location.pathname) {
      case "/web/auth/profile/account-settings":
        this.state = {
          accountSettingsActiveLink: true,
          profile: {
            fullName: "",
            isSuperAdmin: false,
            profilePicture: "",
            roleName: ""
          }
        };
        break;
      case "/web/auth/profile/change-password":
        this.state = {
          accountSettingsActiveLink: false,
          profile: {
            fullName: "",
            isSuperAdmin: false,
            profilePicture: "",
            roleName: ""
          }
        };
        break;
      default:
        break;
    }
  }

  changeLinkHandler = target => {
    switch (target) {
      case "account-settings":
        this.setState({
          ...this.state,
          accountSettingsActiveLink: true
        });
        break;
      case "change-password":
        this.setState({
          ...this.state,
          accountSettingsActiveLink: false
        });
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    let localData = JSON.parse(localStorage.getItem("upcAuthData"));
    let localUsrData = { ...this.state.profile };
    let tmpUserData = this.props.data.authReducer.userData;
    if (Object.keys(tmpUserData).length !== 0) {
      localUsrData.fullName = tmpUserData.user.personalInfo.fullName;
      localUsrData.profilePicture =
        tmpUserData.user.personalInfo.profilePicture;
      localUsrData.isSuperAdmin = tmpUserData.user.roleInfo.isSuperAdmin;
      if (!localUsrData.isSuperAdmin) {
        localUsrData.roleName = tmpUserData.user.roleInfo.roleId.name;
      }
      this.setState({ ...this.state, profile: localUsrData });
    } else {
      if (localData) {
        let tmpProfileData = localData.user;
        localUsrData.fullName = tmpProfileData.personalInfo.fullName;
        localUsrData.profilePicture =
          tmpProfileData.personalInfo.profilePicture;
        localUsrData.isSuperAdmin = tmpProfileData.roleInfo.isSuperAdmin;
        if (!localUsrData.isSuperAdmin) {
          localUsrData.roleName = tmpProfileData.roleInfo.roleId.name;
        }
        this.setState({ ...this.state, profile: localUsrData });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let localData = JSON.parse(localStorage.getItem("upcAuthData"));
    if (prevProps.data !== this.props.data) {
      // if success
      let localUsrData = { ...this.state.profile };
      let tmpUserData = this.props.data.authReducer.userData;

      if (Object.keys(tmpUserData).length !== 0) {
        localUsrData.fullName = tmpUserData.user.personalInfo.fullName;
        localUsrData.profilePicture =
          tmpUserData.user.personalInfo.profilePicture;
        localUsrData.isSuperAdmin = tmpUserData.user.roleInfo.isSuperAdmin;
        if (!localUsrData.isSuperAdmin) {
          localUsrData.roleName = tmpUserData.user.roleInfo.roleId.name;
        }
        this.setState({ ...this.state, profile: localUsrData });
      } else {
        if (localData) {
          let tmpProfileData = localData.user;
          localUsrData.fullName = tmpProfileData.personalInfo.fullName;
          localUsrData.profilePicture =
            tmpProfileData.personalInfo.profilePicture;
          localUsrData.isSuperAdmin = tmpProfileData.roleInfo.isSuperAdmin;
          if (!localUsrData.isSuperAdmin) {
            localUsrData.roleName = tmpProfileData.roleInfo.roleId.name;
          }
          this.setState({ ...this.state, profile: localUsrData });
        }
      }
    }
  }

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;
    const userProfileData = { ...this.state.profile };

    return (
      <Router>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12}>
            <Typography component="p">
              <span className={classes.pageHeader}>
                {this.props.data.literalsReducer.UserProfile.PageHeader}
              </span>
              <span className={classes.pageDescription}>
                {this.props.data.literalsReducer.UserProfile.PageDescription}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={4}>
                <Paper className={classes.profileInfoPaper}>
                  <div className={classes.profileImage}>
                    <div className={classes.profileImagePlaceholder}>
                      {this.state.profile.profilePicture ? (
                        <img
                          src={this.state.profile.profilePicture}
                          alt="no-menu-image"
                          style={{ width: "100%", height: "100%" }}
                        />
                      ) : (
                        <img
                          src={BlankProfileImage}
                          alt="no-menu-image"
                          style={{ width: "100%", height: "100%" }}
                        />
                      )}
                    </div>
                  </div>
                  <p className={classes.ownerName}>
                    {userProfileData.fullName}
                  </p>
                  <p className={classes.ownerDesignation}>
                    {userProfileData.isSuperAdmin
                      ? "Super Admin"
                      : userProfileData.roleName}
                  </p>
                </Paper>
                <Paper>
                  <Link
                    to="/web/auth/profile/account-settings"
                    className={
                      classes.pageLink +
                      " " +
                      (this.state.accountSettingsActiveLink
                        ? classes.activeLink
                        : "")
                    }
                    onClick={() => this.changeLinkHandler("account-settings")}
                  >
                    <div className={classes.leftMenu}>
                      <FontAwesomeIcon icon={faCog} />
                      <span>
                        {
                          this.props.data.literalsReducer.UserProfile
                            .AccountSettings
                        }
                      </span>
                    </div>
                  </Link>
                  <Link
                    to="/web/auth/profile/change-password"
                    className={
                      classes.pageLink +
                      " " +
                      (this.state.accountSettingsActiveLink
                        ? ""
                        : classes.activeLink)
                    }
                    onClick={() => this.changeLinkHandler("change-password")}
                  >
                    <div className={classes.leftMenu}>
                      <FontAwesomeIcon icon={faKey} />
                      <span>
                        {
                          this.props.data.literalsReducer.UserProfile
                            .ChangePassword
                        }
                      </span>
                    </div>
                  </Link>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Switch>
                  <Route
                    path="/web/auth/profile/account-settings"
                    component={AccountSettings}
                    exact
                  />
                  <Route
                    path="/web/auth/profile/change-password"
                    component={ChangePassword}
                    exact
                  />
                </Switch>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    // getProfile: () => dispatch(getProfile())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(UserProfile)
);
