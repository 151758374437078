import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { NavLink } from "react-router-dom";
import DefaultProfilePic from "../assets/images/super-admin.jpg";

import BlankProfileImage from "../assets/images/default-profile-pic.png";
import Fab from "@material-ui/core/Fab";
import SignOut from "@material-ui/icons/PowerSettingsNew";
import { connect } from "react-redux";

const styles = theme => ({
  root: {
    display: "flex",
    borderRadius: "25px",
    backgroundColor: "#fff"
  },
  paper: {
    marginRight: theme.spacing.unit * 2
  },
  avatar: {
    marginRight: "10px",
    display: "inline-block",
    verticalAlign: "middle"
    // margin: 10
  },
  userWelcomeText: {
    display: "inline-block"
  },
  fab: {
    margin: theme.spacing.unit,
    width: "45%",
    height: "30px",
    fontSize: "10px",
    backgroundColor: "#EB5C00",
    color: "#fff",
    padding: "0",
    boxShadow: "none"
  },
  signOutIcon: {
    marginRight: theme.spacing.unit,
    width: "15px"
  },
  userName: {
    fontSize: "14px",
    color: "#8c8c8c",
    marginLeft: 5
  }
});

class MenuListComposition extends Component {
  state = {
    open: false,
    userData: {
      name: "",
      email: "",
      profilePicture: ""
    }
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleLogout = () => {
    this.props.logout();
  };

  componentDidMount() {
    let localData = JSON.parse(localStorage.getItem("upcAuthData"));

    let tmpInfo = { ...this.state.userData };
    let tmpUserData = this.props.data.authReducer.userData;

    if (Object.keys(tmpUserData).length !== 0) {
      tmpInfo.name = tmpUserData.user.personalInfo.firstName;
      tmpInfo.email = tmpUserData.user.personalInfo.email;
      tmpInfo.profilePicture = tmpUserData.user.personalInfo.profilePicture;
      this.setState({
        ...this.state,
        userData: tmpInfo
      });
    } else {
      if (localData) {
        tmpInfo.name = localData.user.personalInfo.firstName;
        tmpInfo.email = localData.user.personalInfo.email;
        tmpInfo.profilePicture = localData.user.personalInfo.profilePicture;
        this.setState({
          ...this.state,
          userData: tmpInfo
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let localData = JSON.parse(localStorage.getItem("upcAuthData"));
    if (prevProps.data !== this.props.data) {
      let tmpInfo = { ...this.state.userData };
      let tmpUserData = this.props.data.authReducer.userData;

      if (Object.keys(tmpUserData).length !== 0) {
        tmpInfo.name = tmpUserData.user.personalInfo.firstName;
        tmpInfo.email = tmpUserData.user.personalInfo.email;
        tmpInfo.profilePicture = tmpUserData.user.personalInfo.profilePicture;
        this.setState({
          ...this.state,
          userData: tmpInfo
        });
      } else {
        if (localData) {
          tmpInfo.name = localData.user.personalInfo.firstName;
          tmpInfo.email = localData.user.personalInfo.email;
          tmpInfo.profilePicture = localData.user.personalInfo.profilePicture;
          this.setState({
            ...this.state,
            userData: tmpInfo
          });
        }
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { open, userData } = this.state;

    return (
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          className={classes.userName}
        >
          <NavLink
            activeClassName="is-active"
            to="/web/auth/profile/account-settings"
            style={{ textDecoration: "none", color: "#8c8c8c" }}
          >
            {this.state.userData.profilePicture ? (
              <Avatar
                alt="User"
                src={this.state.userData.profilePicture}
                className={classes.avatar}
              />
            ) : (
              <Avatar
                alt="User"
                src={DefaultProfilePic}
                className={classes.avatar}
              />
            )}

            <div className={classes.userWelcomeText}>
              Welcome {this.state.userData.name}
            </div>
          </NavLink>
        </Grid>
        <Fab
          variant="extended"
          aria-label="Delete"
          className={classes.fab}
          onClick={this.handleLogout}
        >
          <SignOut className={classes.signOutIcon} />
          Logout
        </Fab>
      </div>
    );
  }
}

MenuListComposition.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    // getProfileDetail: () => dispatch(getProfileDetail()),
    // updateProfile: payload => dispatch(updateProfile(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MenuListComposition));
