import React, { Component } from "react";
import reactCSS from "reactcss";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import keycode from "keycode";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../app/theme";
import contentStyles from "../../auth-layout/content.styles";
import classNames from "classnames";
import globalstyles from "../../../globalstyles";
import styles from "./styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";

import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getGlobalConfig,
  updateGlobalConfig,
  configUpdatedDone,
  resetDetails
} from "../../../store/actions/globalConfig";
library.add(faUpload);

class GlobalSettings extends Component {
  state = {
    single: null,
    labelWidth: 0,
    editableMode: false,
    globalConfigDetails: {
      aboutUsUrl: "",
      privacyPolicyUrl: "",
      termsOfUseUrl: "",
      riderAndroidUrl: "",
      driverAndroidUrl: "",
      riderIosUrl: "",
      driverIosUrl: "",
      message: ""
    },
    errors: {},
    haveImage: false
  };

  handleChange = event => {
    let fields = { ...this.state.globalConfigDetails };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;
    errors[event.target.name + "Invalid"] = false;

    this.setState({ ...this.state, globalConfigDetails: fields, errors });
  };

  globalInfoValidation = () => {
    let fields = { ...this.state.globalConfigDetails };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    for (let name in fields) {
      if (fields[name].trim() === "") {
        errors[name] = true;
        hasErrors = true;
      }
    }

    for (let name in fields) {
      if (
        name !== "message" &&
        fields[name].trim().length &&
        !/^(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(
          fields[name]
        )
      ) {
        errors[name + "Invalid"] = true;
        hasErrors = true;
      }
    }

    console.log("hasErrors ", hasErrors);
    console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  componentDidMount() {
    let localStorageData = window.localStorage.upcAuthData;
    if (localStorageData && JSON.parse(localStorageData)) {
      if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
        JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
          each => {
            if (each.moduleName === "globalSettings") {
              if (each.role === 2) {
                this.setState({
                  ...this.state,
                  editableMode: true
                });
              }
            }
          }
        );
      } else {
        this.setState({
          ...this.state,
          editableMode: true
        });
      }
    }
    this.props.getGlobalConfig();
  }

  componentDidUpdate(prevProps, prevState) {
    // return;
    if (
      !this.state.fetchData &&
      Object.keys(this.props.data.globalConfigData.globalConfigDetails).length
    ) {
      let localGlobalConfigData = { ...this.state.globalConfigDetails };
      localGlobalConfigData.aboutUsUrl = this.props.data.globalConfigData.globalConfigDetails.aboutUs;
      localGlobalConfigData.privacyPolicyUrl = this.props.data.globalConfigData.globalConfigDetails.privacyPolicy;
      localGlobalConfigData.termsOfUseUrl = this.props.data.globalConfigData.globalConfigDetails.termsAndCondition;
      localGlobalConfigData.riderAndroidUrl = this.props.data.globalConfigData.globalConfigDetails.playStoreLink.rider;
      localGlobalConfigData.driverAndroidUrl = this.props.data.globalConfigData.globalConfigDetails.playStoreLink.driver;
      localGlobalConfigData.riderIosUrl = this.props.data.globalConfigData.globalConfigDetails.appleStoreLink.rider;
      localGlobalConfigData.driverIosUrl = this.props.data.globalConfigData.globalConfigDetails.appleStoreLink.driver;
      localGlobalConfigData.message = this.props.data.globalConfigData.globalConfigDetails.invitationMessageForRider;

      this.setState({
        ...this.state,
        globalConfigDetails: localGlobalConfigData,
        fetchData: true
      });
    }

    if (this.props.data.globalConfigData.configUpdated) {
      this.props.configUpdatedDone();
    }
  }

  componentWillUnmount() {
    this.props.resetDetails();
  }

  saveHandler = () => {
    if (!this.globalInfoValidation()) {
      let localGlobalConfigData = { ...this.state.globalConfigDetails };
      let requestData = {
        termsAndCondition: localGlobalConfigData.termsOfUseUrl,
        privacyPolicy: localGlobalConfigData.privacyPolicyUrl,
        aboutUs: localGlobalConfigData.aboutUsUrl,
        invitationMessageForRider: localGlobalConfigData.message,
        playStoreLink: {
          rider: localGlobalConfigData.riderAndroidUrl,
          driver: localGlobalConfigData.driverAndroidUrl
        },
        appleStoreLink: {
          rider: localGlobalConfigData.riderIosUrl,
          driver: localGlobalConfigData.driverIosUrl
        }
      };
      this.props.updateGlobalConfig(requestData);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                  <Typography component="p">
                    <span className={classes.pageHeader}>
                      {
                        this.props.data.literalsReducer.GlobalSettings
                          .PageHeader
                      }
                    </span>
                    <span className={classes.PageDescription}>
                      {
                        this.props.data.literalsReducer.GlobalSettings
                          .PageDescription
                      }
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Paper>
                <Grid container>
                  <Grid item xs={12} sm={12} className={classes.rightPaper}>
                    <Grid item xs={12} sm={12} className={classes.descSection}>
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={12}>
                          <h3 style={{ marginBottom: "0" }}>Links & Support</h3>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.description}>About Us Link</p>
                          <TextField
                            id="about-us-link"
                            label="About Us Link"
                            variant="outlined"
                            fullWidth
                            value={this.state.globalConfigDetails.aboutUsUrl}
                            className={classes.textField}
                            error={
                              this.state.errors.aboutUsUrl ||
                              this.state.errors.aboutUsUrlInvalid
                            }
                            name="aboutUsUrl"
                            onChange={this.handleChange}
                            disabled={!this.state.editableMode}
                          />
                          {this.state.errors.aboutUsUrl ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .Required
                              }
                            </FormHelperText>
                          ) : null}
                          {!this.state.errors.aboutUsUrl &&
                          this.state.errors.aboutUsUrlInvalid ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .InvalidUrl
                              }
                            </FormHelperText>
                          ) : null}
                          <p className={classes.description}>Terms Of Use</p>
                          <TextField
                            id="term-of-use"
                            label="Terms Of Use"
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            value={this.state.globalConfigDetails.termsOfUseUrl}
                            error={
                              this.state.errors.termsOfUseUrl ||
                              this.state.errors.termsOfUseUrlInvalid
                            }
                            name="termsOfUseUrl"
                            onChange={this.handleChange}
                            disabled={!this.state.editableMode}
                          />
                          {this.state.errors.termsOfUseUrl ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .Required
                              }
                            </FormHelperText>
                          ) : null}
                          {!this.state.errors.termsOfUseUrl &&
                          this.state.errors.termsOfUseUrlInvalid ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .InvalidUrl
                              }
                            </FormHelperText>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.description}>Privacy Policy</p>
                          <TextField
                            id="privacy-policy"
                            label="Privacy Policy"
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            value={
                              this.state.globalConfigDetails.privacyPolicyUrl
                            }
                            error={
                              this.state.errors.privacyPolicyUrl ||
                              this.state.errors.privacyPolicyUrlInvalid
                            }
                            name="privacyPolicyUrl"
                            onChange={this.handleChange}
                            disabled={!this.state.editableMode}
                          />
                          {this.state.errors.privacyPolicyUrl ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .Required
                              }
                            </FormHelperText>
                          ) : null}
                          {!this.state.errors.privacyPolicyUrl &&
                          this.state.errors.privacyPolicyUrlInvalid ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .InvalidUrl
                              }
                            </FormHelperText>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Divider
                        variant="middle"
                        style={{ margin: "40px 0 25px" }}
                      />
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={12}>
                          <h3 style={{ marginBottom: "0" }}>
                            App Store & Play Store
                          </h3>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.description}>
                            Rider Android URL
                          </p>
                          <TextField
                            id="rider-android-url"
                            label="Rider Android URL"
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            value={
                              this.state.globalConfigDetails.riderAndroidUrl
                            }
                            error={
                              this.state.errors.riderAndroidUrl ||
                              this.state.errors.riderAndroidUrlInvalid
                            }
                            name="riderAndroidUrl"
                            onChange={this.handleChange}
                            disabled={!this.state.editableMode}
                          />
                          {this.state.errors.riderAndroidUrl ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .Required
                              }
                            </FormHelperText>
                          ) : null}
                          {!this.state.errors.riderAndroidUrl &&
                          this.state.errors.riderAndroidUrlInvalid ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .InvalidUrl
                              }
                            </FormHelperText>
                          ) : null}
                          <p className={classes.description}>Rider iOS URL</p>
                          <TextField
                            id="rider-ios-url"
                            label="Rider iOS URL"
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            value={this.state.globalConfigDetails.riderIosUrl}
                            error={
                              this.state.errors.riderIosUrl ||
                              this.state.errors.riderIosUrlInvalid
                            }
                            name="riderIosUrl"
                            onChange={this.handleChange}
                            disabled={!this.state.editableMode}
                          />
                          {this.state.errors.riderIosUrl ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .Required
                              }
                            </FormHelperText>
                          ) : null}
                          {!this.state.errors.riderIosUrl &&
                          this.state.errors.riderIosUrlInvalid ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .InvalidUrl
                              }
                            </FormHelperText>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.description}>
                            Chauffeur Android URL
                          </p>
                          <TextField
                            id="driver-android-email"
                            label="Chauffeur Android URL"
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            value={
                              this.state.globalConfigDetails.driverAndroidUrl
                            }
                            error={
                              this.state.errors.driverAndroidUrl ||
                              this.state.errors.driverAndroidUrlInvalid
                            }
                            name="driverAndroidUrl"
                            onChange={this.handleChange}
                            disabled={!this.state.editableMode}
                          />
                          {this.state.errors.driverAndroidUrl ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .Required
                              }
                            </FormHelperText>
                          ) : null}
                          {!this.state.errors.driverAndroidUrl &&
                          this.state.errors.driverAndroidUrlInvalid ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .InvalidUrl
                              }
                            </FormHelperText>
                          ) : null}
                          <p className={classes.description}>
                            Chauffeur iOS URL
                          </p>
                          <TextField
                            id="driver-ios-url"
                            label="Chauffeur iOS URL"
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            value={this.state.globalConfigDetails.driverIosUrl}
                            error={
                              this.state.errors.driverIosUrl ||
                              this.state.errors.driverIosUrlInvalid
                            }
                            name="driverIosUrl"
                            onChange={this.handleChange}
                            disabled={!this.state.editableMode}
                          />
                          {this.state.errors.driverIosUrl ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .Required
                              }
                            </FormHelperText>
                          ) : null}
                          {!this.state.errors.driverIosUrl &&
                          this.state.errors.driverIosUrlInvalid ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .InvalidUrl
                              }
                            </FormHelperText>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Divider
                        variant="middle"
                        style={{ margin: "40px 0 25px" }}
                      />
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={12}>
                          <h3 style={{ marginBottom: "0" }}>Messages</h3>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <p className={classes.description}>
                            Message for inviting other riders
                          </p>
                          <TextField
                            id="inviting-others"
                            label="Messages"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows="3"
                            className={classes.textField}
                            value={this.state.globalConfigDetails.message}
                            error={this.state.errors.message}
                            name="message"
                            onChange={this.handleChange}
                            disabled={!this.state.editableMode}
                          />
                          {this.state.errors.message ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .Required
                              }
                            </FormHelperText>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.editableMode ? (
                    <Grid item xs={12} sm={12}>
                      <div className={classes.actionContainer}>
                        <Button
                          size="large"
                          color="primary"
                          variant="outlined"
                          className={classNames(
                            classes.customMargin,
                            classes.activeGoButton
                          )}
                          onClick={this.saveHandler}
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    getGlobalConfig: () => dispatch(getGlobalConfig()),
    resetDetails: () => dispatch(resetDetails()),
    configUpdatedDone: () => dispatch(configUpdatedDone()),
    updateGlobalConfig: payload => dispatch(updateGlobalConfig(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(GlobalSettings)
);
