// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  GET_PARAMETER,
  UPDATE_PARAMETER,
  PARAMETER_UPDATED_DONE,
  RESET_PARAMETER_DETAILS
} from "../constants/action-types";

// //////////////////// Get Dashboard  Details // //////////////////
export const getOperationalParameter = () => {
  return {
    type: GET_PARAMETER,
    payload: {
      hideToast: true,
      request: {
        url: apiURLs.operationalParameter,
        method: "GET"
      }
    },
    hideToast: true
  };
};
export const updateOperationalParameter = data => {
  return {
    type: UPDATE_PARAMETER,
    payload: {
      request: {
        url: apiURLs.operationalParameter,
        method: "PUT",
        data: data
      }
    }
  };
};

export const parameterUpdatedDone = () => {
  return {
    type: PARAMETER_UPDATED_DONE
  };
};

export const resetDetails = () => {
  return {
    type: RESET_PARAMETER_DETAILS
  };
};
