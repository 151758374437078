import Dashboard from "../../auth/home";
import RidersList from "../../auth/riders/riders-list";
import AddRider from "../../auth/riders/add-rider/index";
import RiderDetails from "../../auth/riders/rider-details/index";
import DriversList from "../../auth/drivers/drivers-list";
import AddDriver from "../../auth/drivers/add-driver/index";
import EditDriver from "../../auth/drivers/edit-driver/index";
import DriverDetails from "../../auth/drivers/driver-details/index";
import PastTripList from "../../auth/past-trips/past-trip-list";
import PastTripDetails from "../../auth/past-trips/past-trip-details/index";
import DownloadInvoice from "../../auth/past-trips/download-invoice/index";
import OngoingTripList from "../../auth/ongoing-trips/ongoing-trip-list";
import OngoingTripDetails from "../../auth/ongoing-trips/ongoing-trip-details/index";
import BookingRequestList from "../../auth/booking-request/booking-request-list";
import BookingRequestDetails from "../../auth/booking-request/booking-request-details/index";
import AssignNewDriver from "../../auth/booking-request/assign-new-driver/index";
import RateCharts from "../../auth/rate-charts/index";
import Roles from "../../auth/role/roles";
import AddRole from "../../auth/role/add-role/index";
import EditRole from "../../auth/role/edit-role/index";
import Admins from "../../auth/admins/admins";
import AddAdmin from "../../auth/admins/add-admin/index";
import EditAdmin from "../../auth/admins/edit-admin/index";
import GlobalSettings from "../../auth/global-settings/index";
import Revenue from "../../auth/revenue/index";
import UpcRiders from "../../auth/upc-riders/riders-list";
import UpcRiderDetails from "../../auth/upc-riders/rider-details/index";
import UpcDrivers from "../../auth/upc-drivers/driver-list";
import UpcDriverDetails from "../../auth/upc-drivers/driver-details";
import OperationalParams from "../../auth/operational-parameters/index";
import OperationalZone from "../../auth/operational-zone/index";
import BookingAlerts from "../../auth/booking-alerts/booking-alerts";
import OngoingTripAlerts from "../../auth/ongoing-trip-alerts/ongoing-trip-alerts";
import UserProfile from "../../auth/user-profile/index";
import Billing from "../../auth/billing/index";
import BillingDetailsSettled from "../../auth/billing/billing-details-settled/index";
import BillingDetailsUnSettled from "../../auth/billing/billing-details-unsettled/index";
import ScheduleTrip from "../../auth/schedule-trip/index";

const authRoutesConfig = [
  {
    path: "/web/auth/home",
    component: Dashboard,
    moduleName: "home"
  },
  {
    path: "/web/auth/riders",
    component: RidersList,
    moduleName: "rider"
  },
  {
    path: "/web/auth/riders/add",
    component: AddRider,
    moduleName: "rider"
  },
  {
    path: "/web/auth/riders/details/:riderId",
    component: RiderDetails,
    moduleName: "rider"
  },
  {
    path: "/web/auth/drivers",
    component: DriversList,
    moduleName: "driver"
  },
  {
    path: "/web/auth/drivers/add",
    component: AddDriver,
    moduleName: "driver"
  },
  {
    path: "/web/auth/drivers/edit/:driverId",
    component: EditDriver,
    moduleName: "driver"
  },
  {
    path: "/web/auth/drivers/details/:driverId",
    component: DriverDetails,
    moduleName: "driver"
  },
  {
    path: "/web/auth/schedule-trip",
    component: ScheduleTrip,
    moduleName: "scheduleTrip"
  },
  {
    path: "/web/auth/past-trips",
    component: PastTripList,
    moduleName: "pastTrip"
  },
  {
    path: "/web/auth/past-trips/details/:tripId",
    component: PastTripDetails,
    moduleName: "pastTrip"
  },
  {
    path: "/web/auth/past-trips/download-invoice/:tripId",
    component: DownloadInvoice,
    moduleName: "pastTrip"
  },
  {
    path: "/web/auth/ongoing-trips",
    component: OngoingTripList,
    moduleName: "ongoingTrip"
  },
  {
    path: "/web/auth/ongoing-trips/details/:tripId",
    component: OngoingTripDetails,
    moduleName: "ongoingTrip"
  },
  {
    path: "/web/auth/booking-request",
    component: BookingRequestList,
    moduleName: "bookingRequest"
  },
  {
    path: "/web/auth/booking-request/details/:tripId",
    component: BookingRequestDetails,
    moduleName: "bookingRequest"
  },
  {
    path: "/web/auth/assign-new-driver/:tripId",
    component: AssignNewDriver
  },
  {
    path: "/web/auth/rate-charts",
    component: RateCharts,
    moduleName: "fareChart"
  },
  {
    path: "/web/auth/roles",
    component: Roles,
    moduleName: "role"
  },
  {
    path: "/web/auth/roles/add",
    component: AddRole,
    moduleName: "role"
  },
  {
    path: "/web/auth/roles/edit/:roleId",
    component: EditRole,
    moduleName: "role"
  },
  {
    path: "/web/auth/admins",
    component: Admins,
    moduleName: "admin"
  },
  {
    path: "/web/auth/admins/add",
    component: AddAdmin,
    moduleName: "admin"
  },
  {
    path: "/web/auth/admins/edit/:adminId",
    component: EditAdmin,
    moduleName: "admin"
  },
  {
    path: "/web/auth/global-settings",
    component: GlobalSettings,
    moduleName: "globalSettings"
  },
  {
    path: "/web/auth/revenue",
    component: Revenue,
    moduleName: "revenue"
  },
  {
    path: "/web/auth/upc-riders",
    component: UpcRiders,
    moduleName: "UPCRider"
  },
  {
    path: "/web/auth/upc-riders/details/:riderId",
    component: UpcRiderDetails,
    moduleName: "UPCRider"
  },
  {
    path: "/web/auth/upc-drivers",
    component: UpcDrivers,
    moduleName: "UPCDriver"
  },
  {
    path: "/web/auth/upc-drivers/details/:driverId",
    component: UpcDriverDetails,
    moduleName: "UPCDriver"
  },
  {
    path: "/web/auth/operational-params",
    component: OperationalParams,
    moduleName: "operationalParameter"
  },
  {
    path: "/web/auth/operational-zone",
    component: OperationalZone,
    moduleName: "zone"
  },
  {
    path: "/web/auth/booking-alerts",
    component: BookingAlerts,
    moduleName: "bookingRequestAlert"
  },
  {
    path: "/web/auth/ongoing-trip-alerts",
    component: OngoingTripAlerts,
    moduleName: "ongoingTripAlert"
  },
  {
    path: "/web/auth/profile/account-settings",
    component: UserProfile
  },
  {
    path: "/web/auth/profile/change-password",
    component: UserProfile
  },
  {
    path: "/web/auth/billing",
    component: Billing,
    moduleName: "billing"
  },
  {
    path: "/web/auth/billing/settled-details/:tripId",
    component: BillingDetailsSettled,
    moduleName: "billing"
  },
  {
    path: "/web/auth/billing/unsettled-details/:tripId",
    component: BillingDetailsUnSettled,
    moduleName: "billing"
  }
];

export default authRoutesConfig;
