const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  pageHeader: {
    fontSize: "26px",
    marginRight: "10px",
    color: "#4b4b4c"
  },
  pageHeaderSmall: {
    display: "block",
    fontSize: "18px",
    marginTop: "20px",
    color: "#4b4b4c"
  },
  PageDescription: {
    color: "#bebdbf"
  },
  chartWrapper: {
    backgroundColor: "#fff",
    padding: "25px !important",
    maxWidth: "49%"
  },
  chartHeading: {
    fontSize: "20px",
    marginBottom: "25px"
  },
  chartHeaderImage: {
    marginRight: "5px"
  },
  revenueAmount: {
    fontSize: "32px",
    color: "#EB5C00",
    float: "right"
  },
  revenueCurrency: {
    fontSize: "20px"
  },
  rightChartWrapper: {
    marginLeft: "2%"
  },
  counterWrapper: {
    maxWidth: "100%",
    cursor: "pointer",
    "& a": {
      textDecoration: "none"
    }
  },
  testCard: {
    border: "red"
  }
});

export default styles;
