// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  FETCH_ONGOING_TRIPS,
  FETCH_ONGOING_TRIP,
  RESET_ONGOING_TRIP_DETAILS,
  APPROVE_ADDITIONAL_CHARGE,
  APPROVE_NO_SHOW,
  REJECT_ADDITIONAL_CHARGE,
  REJECT_NO_SHOW,
  NO_SHOW_REJECTED_DONE,
  NO_SHOW_APPROVED_DONE,
  ADDITIONAL_CHARGE_APPROVED_DONE,
  ADDITIONAL_CHARGE_REJECTED_DONE,
  END_TRIP,
  END_TRIP_DONE
} from "../constants/action-types";

export const getOngoingTripList = searchObj => {
  return {
    type: FETCH_ONGOING_TRIPS,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.ongoingTrip,
        method: "POST",
        params: {
          skip: searchObj.skip,
          limit: searchObj.limit
        },
        data: {
          sortConfig: searchObj.sortConfig,
          filters: searchObj.filters
        }
      }
    }
  };
};

export const fetchTrip = id => {
  return {
    type: FETCH_ONGOING_TRIP,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.ongoingTrip + "/" + id,
        method: "GET"
      }
    }
  };
};

export const noShowRejectedDone = () => {
  return {
    type: NO_SHOW_REJECTED_DONE
  };
};
export const noShowApprovedDone = () => {
  return {
    type: NO_SHOW_APPROVED_DONE
  };
};
export const additionalChargeApprovedDone = () => {
  return {
    type: ADDITIONAL_CHARGE_APPROVED_DONE
  };
};
export const additionalChargeRejectedDone = () => {
  return {
    type: ADDITIONAL_CHARGE_REJECTED_DONE
  };
};

export const approveAdditionalChargeRequest = (id,chargeData) => {
  return {
    type: APPROVE_ADDITIONAL_CHARGE,
    payload: {
      request: {
        url: apiURLs.additionalChargeAction + id + "/accept",
        method: "PUT",
        data: chargeData
      }
    }
  };
};
export const endTrip = (id, tripData) => {
 
  return {
    type: END_TRIP,
    payload: {
      request: {
        url: apiURLs.endTrip + id,
        method: "PUT",
        data: tripData
      },
     
    }
  };
};
export const endTripDone = () => {
  return {
    type: END_TRIP_DONE
  };
};
export const rejectAdditionalChargeRequest = id => {
  return {
    type: REJECT_ADDITIONAL_CHARGE,
    payload: {
      request: {
        url: apiURLs.additionalChargeAction + id + "/reject",
        method: "PUT"
      }
    }
  };
};
export const approveNoShowRequest = id => {
  return {
    type: APPROVE_NO_SHOW,
    payload: {
      request: {
        url: apiURLs.noShowAction + id + "/accept",
        method: "PUT"
      }
    }
  };
};
export const rejectNoShowRequest = id => {
  return {
    type: REJECT_NO_SHOW,
    payload: {
      request: {
        url: apiURLs.noShowAction + id + "/reject",
        method: "PUT"
      }
    }
  };
};

export const resetDetails = () => {
  return {
    type: RESET_ONGOING_TRIP_DETAILS
  };
};
