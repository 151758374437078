import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Logo from "../../assets/images/logo.png";
import LogInImage from "../../assets/images/login_front.jpg";
import { Link } from "react-router-dom";
import AndroidIcon from '@material-ui/icons/Android';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import globalstyles from "../../globalstyles";
import { styles } from "./styles";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import theme from "../../app/theme";
import { Menu, MenuItem } from "@material-ui/core";

var _interval;

const Platform = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
  UNKNOWN: 'UNKNOWN'
}

class ReferralSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playStoreUrl: '',
      appStoreUrl: '',
      counter: 5,
      platformMenu: {
        open: false,
        anchorEl: null
      },
      referralCode: null
    };
  }

  getDeviceOs = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let platform = Platform.UNKNOWN;
    if (/windows phone/i.test(userAgent)) {
      platform = Platform.UNKNOWN;
    }
    if (/android/i.test(userAgent)) {
      platform = Platform.ANDROID;
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      platform = Platform.IOS;
    }
    // platform = Platform.UNKNOWN;
    console.log(platform);
    this.setState({
      ...this.state,
      platform
    })
  };

  generateRedirectUrl = () => {
    let referralCode = new URLSearchParams(this.props.location.search).get('code');
    let playStoreUrl = `https://play.google.com/store/apps/details?id=com.urpc.customer&referrer=utm_source=${referralCode}&utm_medium=urpc&utm_term=urpc&utm_content=urpc&utm_campaign=urpc&anid=admob`;
    let appStoreUrl = `https://apps.apple.com/sc/app/urpc-chauffeur/id1487633816`;
    this.setState({
      ...this.state,
      playStoreUrl,
      appStoreUrl,
      referralCode
    })
  };

  autoRedirect = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let autoRedirectUrl;
    if (/windows phone/i.test(userAgent)) {
      autoRedirectUrl = this.state.playStoreUrl;
    }
    if (/android/i.test(userAgent)) {
      autoRedirectUrl = this.state.playStoreUrl;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      autoRedirectUrl = this.state.appStoreUrl;
    }
    if (autoRedirectUrl)
      window.location.href = autoRedirectUrl;
  };

  redirectToPlayStore = () => {
    window.open(this.state.playStoreUrl, '_blank');
    clearInterval(_interval);
  };

  redirectToAppStore = () => {
    window.open(this.state.appStoreUrl, '_blank');
    clearInterval(_interval);
  };

  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.playStoreUrl)
  };

  handleMenuOpen = (event) => {
    this.setState({
      ...this.state,
      platformMenu: {
        open: true,
        anchorEl: event.currentTarget
      }
    })
  };

  handleMenuClose = () => {
    this.setState({
      ...this.state,
      platformMenu: {
        open: false,
        anchorEl: null
      }
    })
  };

  renderPlatformSelectionMenu = () => {
    return (
      <div>
        <Button
          color="primary"
          style={{ textTransform: 'none' }}
          onClick={this.handleMenuOpen}
        >
          Select your platform
          <ArrowDropDownIcon className={this.props.classes.rightIcon} />
        </Button>
        <Menu
          fullWIdth
          anchorEl={this.state.platformMenu.anchorEl}
          open={this.state.platformMenu.open}
          onClose={this.handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => {
            this.redirectToPlayStore();
            this.handleMenuClose()
          }}>
            <AndroidIcon className={this.props.classes.leftIcon} />
            Android</MenuItem>
          <MenuItem onClick={() => {
            this.redirectToAppStore();
            this.handleMenuClose()
          }}>
            <PhoneIphoneIcon className={this.props.classes.leftIcon} />
            Ios</MenuItem>
        </Menu>
      </div>
    )
  };

  componentDidMount() {
    this.getDeviceOs();
    this.generateRedirectUrl();

    _interval = setInterval(() => {
      let counter = this.state.counter;
      if (counter === 0) {
        clearInterval(_interval);
        this.autoRedirect();
        return;
      }
      counter--;
      this.setState({
        ...this.state,
        counter
      })
    }, 1000)
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render(props) {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <Grid container spacing={24} className={classes.signInWrapper}>
            <Grid
              item
              xs={12}
              sm={7}
              md={7}
              lg={7}
              className={classes.loginImage}
              style={{
                backgroundImage: `url(${LogInImage})`
              }}
            />
            <Grid item xs={12} sm={5} md={5} lg={5} className={classes.signinBox}>
              <Paper className={classes.signinBoxPaper}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.formHeaderLogo}
                >
                  <Link to="/login">
                    <img src={Logo} alt="logo" />
                  </Link>
                </Typography>
                <Typography
                  component="h1"
                  variant="h5"
                  mb={5}
                  className={classes.formHeader}
                >
                  Get Ur PC Rider
                </Typography>
                <section className={classes.referralBlock}>
                  <Grid container direction="column" alignItems="center" spacing={8}>
                    {this.state.platform !== Platform.UNKNOWN ? (
                      <>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            component="p"
                            variant="subtitle1"

                          >You will be redirected to our app in: </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            component="p"
                            variant="subtitle1"
                            color="primary"
                          >{this.state.counter} sec</Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            component="p"
                            variant="body2"

                          >Use the link below if you are not redirected:</Typography>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12} md={12} lg={12} >
                        <Paper elevation={0} className={classes.disclaimer}>
                          <InfoOutlinedIcon className={classes.leftIcon} color="primary" />
                          <Typography
                            component="p"
                            variant="caption"
                            color="primary"
                          >Auto redirect is not possible since you have opened it in a web browser. Please select a platform below and proceed.</Typography>
                        </Paper>
                      </Grid>
                    )}

                    <Grid item xs={12} md={12} lg={12}>
                      {this.state.referralCode ? (
                        {
                          'ANDROID': <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={this.redirectToPlayStore}>
                            <AndroidIcon className={classes.leftIcon} />
                            Get it for Android</Button>,
                          'IOS': <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={this.redirectToPlayStore}>
                            <PhoneIphoneIcon className={classes.leftIcon} />
                            Get it for Ios</Button>,
                          'UNKNOWN': this.renderPlatformSelectionMenu()
                        }[this.state.platform]
                      ) : (
                        <Paper elevation={0} className={classes.warningAlert}>
                          <WarningOutlinedIcon className={classes.leftIcon} color="primary" />
                          <Typography
                            component="p"
                            variant="body2"
                            color="primary"
                          >No referral code found.</Typography>
                        </Paper>
                      )}
                    </Grid>
                  </Grid>
                </section>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider >
    );
  }
}

ReferralSignup.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return { data: state };
};

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(ReferralSignup)
);
