import { Divider, Grid, List, ListItem, ListItemText, Typography } from "@material-ui/core"
import React, { Component } from "react";
import { TripType } from "../../../config/trip";
import { getTimeIntervalInMinute, isInnerContentAvailable } from "../../../utils/SupportUtils";

export const renderFixedRateChart = (props) => {

    return (
        <List style={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem alignItems="flex-start">
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Base Fare
                            </Typography>
                        </React.Fragment>
                    }
                />
                <Grid style={{ marginRight: 10 }}  >
                    <Grid item>
                        <Typography component="span" variant="caption">Sedan</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="caption">SUV</Typography>
                    </Grid>
                </Grid>
                <Grid style={{ marginRight: 10 }}  >
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.baseFarePerKmSedan).toFixed(2)}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.baseFarePerKmSUV).toFixed(2)}`}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Waiting Charge
                            </Typography>
                            {"Per Minute"}
                        </React.Fragment>
                    }
                />
                <Grid style={{ marginRight: 10 }}  >
                    <Grid item>
                        <Typography component="span" variant="caption">Sedan</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="caption">SUV</Typography>
                    </Grid>
                </Grid>
                <Grid style={{ marginRight: 10 }}  >
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.waitingTimeChargeSedan).toFixed(2)}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.waitingTimeChargeSUV).toFixed(2)}`}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Ride Charge
                            </Typography>
                            {"Per Mile"}
                        </React.Fragment>
                    }
                />
                <Grid style={{ marginRight: 10 }}  >
                    <Grid item>
                        <Typography component="span" variant="caption">Sedan</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="caption">SUV</Typography>
                    </Grid>
                </Grid>
                <Grid style={{ marginRight: 10 }}  >
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.chargePerKmSedan).toFixed(2)}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.chargePerKmSUV).toFixed(2)}`}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Ride Charge
                            </Typography>
                            {"Per Minute"}
                        </React.Fragment>
                    }
                />
                <Grid style={{ marginRight: 10 }}  >
                    <Grid item>
                        <Typography component="span" variant="caption">Sedan</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="caption">SUV</Typography>
                    </Grid>
                </Grid>
                <Grid style={{ marginRight: 10 }}  >
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.chargePerMinTravelTimeSedan).toFixed(2)}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.chargePerMinTravelTimeSUV).toFixed(2)}`}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Discount to Estimate/Cost
                            </Typography>
                            {"If travelled more than 10 miles"}
                        </React.Fragment>
                    }
                />
                <Grid style={{ marginRight: 10 }}  >
                    <Grid item>
                        <Typography component="span" variant="caption">Sedan</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="caption">SUV</Typography>
                    </Grid>
                </Grid>
                <Grid style={{ marginRight: 10 }}  >
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`${props.discountPercentage || 0.00}%`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`${props.discountPercentage || 0.00}%`}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
        </List>
    )
}

export const renderDurationBasedRateChart = (props) => {
    console.log(props);

    return (
        <List style={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem alignItems="flex-start">
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Base Fare
                            </Typography>
                        </React.Fragment>
                    }
                />
                <Grid style={{ marginRight: 10 }} >
                    <Grid item>
                        <Typography component="span" variant="caption">Sedan</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="caption">SUV</Typography>
                    </Grid>
                </Grid>
                <Grid style={{ marginRight: 10 }} >
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.baseFare.sedan).toFixed(2)}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.baseFare.suv).toFixed(2)}`}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Wait Time Charge at Pickup
                            </Typography>
                            {"Per Minute"}
                        </React.Fragment>
                    }
                />
                <Grid style={{ marginRight: 10 }} >
                    <Grid item>
                        <Typography component="span" variant="caption">Sedan</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="caption">SUV</Typography>
                    </Grid>
                </Grid>
                <Grid style={{ marginRight: 10 }} >
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.waitingChargePerMinute.sedan).toFixed(2)}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.waitingChargePerMinute.suv).toFixed(2)}`}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Ride Cost
                            </Typography>
                            {"Per Hour"}
                        </React.Fragment>
                    }
                />
                <Grid style={{ marginRight: 10 }} >
                    <Grid item>
                        <Typography component="span" variant="caption">Sedan</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="caption">SUV</Typography>
                    </Grid>
                </Grid>
                <Grid style={{ marginRight: 10 }} >
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.perHourCost.sedan).toFixed(2)}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.perHourCost.suv).toFixed(2)}`}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Penalty Cost
                            </Typography>
                            {"Per Hour"}
                        </React.Fragment>
                    }
                />
                <Grid style={{ marginRight: 10 }} >
                    <Grid item>
                        <Typography component="span" variant="caption">Sedan</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="caption">SUV</Typography>
                    </Grid>
                </Grid>
                <Grid style={{ marginRight: 10 }} >
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.perHourPenaltyCost.sedan).toFixed(2)}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.perHourPenaltyCost.suv).toFixed(2)}`}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                For an Excess 0 mins
                            </Typography>
                            {"Round to nerest 30 mins with Charge"}
                        </React.Fragment>
                    }
                />
                <Grid style={{ marginRight: 10 }} >
                    <Grid item>
                        <Typography component="span" variant="caption">Sedan</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="caption">SUV</Typography>
                    </Grid>
                </Grid>
                <Grid style={{ marginRight: 10 }}  >
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.additionalExcessCost.penaltyCost.sedan).toFixed(2)}`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`$${Number(props.additionalExcessCost.penaltyCost.suv).toFixed(2)}`}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
            <Divider />
            <ListItem alignItems="flex-start">
                <ListItemText
                    secondary={
                        <React.Fragment>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                Discount to Estimate/Cost
                            </Typography>
                            {"If travelled more than 10 miles"}
                        </React.Fragment>
                    }
                />
                <Grid style={{ marginRight: 10 }} >
                    <Grid item>
                        <Typography component="span" variant="caption">Sedan</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="caption">SUV</Typography>
                    </Grid>
                </Grid>
                <Grid style={{ marginRight: 10 }} >
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`${Number(props.discountPercentage).toFixed(2)}%`}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="span" variant="subtitle2">{`${Number(props.discountPercentage).toFixed(2)}%`}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
        </List>
    )
}

export const renderRates = (type, rate, vehicleType) => {
    switch (type) {
        case TripType.FIXED_ONE_WAY:
        case TripType.FIXED_ROUND_TRIP:
            return (
                <>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary="Base fare:"
                        />
                        <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">${(rate.fixed.baseFare[vehicleType] || 0).toFixed(2)}</Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary="Rate per mile:"
                        />
                        <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">${(rate.fixed.chargePerDistanceUnit[vehicleType] || 0).toFixed(2)}</Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary="Rate per 1 minute:"
                        />
                        <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">${(rate.fixed.chargePerMinute[vehicleType] || 0).toFixed(2)}</Typography>
                    </ListItem>
                </>
            );
        case TripType.DURATION_HOURLY:
            return (
                <>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary="Base fare:"
                        />
                        <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">${(rate.duration.baseFare[vehicleType] || 0).toFixed(2)}</Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary="Charge per min waiting time:"
                        />
                        <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">${(rate.duration.waitingChargePerMinute[vehicleType] || 0).toFixed(2)}</Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary="Per hour booking charge:"
                        />
                        <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">${(rate.duration.hourly.perHourCost[vehicleType] || 0).toFixed(2)}</Typography>
                    </ListItem>
                </>
            )
        case TripType.DURATION_HALF_DAY:
            return (
                <>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary="Base fare:"
                        />
                        <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">${(rate.duration.baseFare[vehicleType] || 0).toFixed(2)}</Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary="Charge per min waiting time:"
                        />
                        <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">${(rate.duration.waitingChargePerMinute[vehicleType] || 0).toFixed(2)}</Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary="Half day booking charge:"
                        />
                        <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">${(rate.duration.halfDay.perHourCost[vehicleType] || 0).toFixed(2)}</Typography>
                    </ListItem>
                </>
            )
        case TripType.DURATION_FULL_DAY:
            return (
                <>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary="Base fare:"
                        />
                        <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">${(rate.duration.baseFare[vehicleType] || 0).toFixed(2)}</Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary="Charge per min waiting time:"
                        />
                        <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">${(rate.duration.waitingChargePerMinute[vehicleType] || 0).toFixed(2)}</Typography>
                    </ListItem>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary="Full day booking charge:"
                        />
                        <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">${(rate.duration.fullDay.perHourCost[vehicleType] || 0).toFixed(2)}</Typography>
                    </ListItem>
                </>
            )
    }
}

export const renderQuote = (props) => {
    return isInnerContentAvailable(props) ?
        <List style={{ marginLeft: -16, marginRight: -16, bgcolor: 'background.paper' }}>
            <ListItem alignItems="flex-start">
                <ListItemText
                    primary="Trip duration:"
                />
                <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">{(getRequestedTravelTime(props.direction.routes[0].legs[0].duration.value / 60, props.type).toFixed(0))} minutes</Typography>
            </ListItem>
            <ListItem alignItems="flex-start">
                <ListItemText
                    primary="Trip distance:"
                />
                <Typography style={{ color: '#a8a8a8' }} variant="body1" component="span">{(getRequestedTravelDistance(props.direction.routes[0].legs[0].distance.value * 0.000621, props.type).toFixed(1))} mi</Typography>
            </ListItem>
            <ListItem alignItems="flex-start" />
            {renderRates(props.type, props.rate, props.vehicleType === 1 ? 'sedan' : 'suv')}
            <ListItem alignItems="flex-start" />
            <ListItem alignItems="flex-start">
                <ListItemText
                    primary={<Typography variant="subtitle1" component="span">Estimated total:</Typography>}
                />
                <Typography style={{ color: '#a8a8a8' }} variant="subtitle1" component="span">{`$${(props.total || 0).toFixed(2)}`}</Typography>
            </ListItem>
        </List>
        : null;
}


export const getRequestedTravelDistance = (distance, tripType) => {
    return tripType === TripType.FIXED_ROUND_TRIP ? distance * 2 : distance;
}

export const getRequestedTravelTime = (duration, tripType) => {
    return tripType === TripType.FIXED_ROUND_TRIP ? duration * 2 : duration;
}

export const calculatePrice = (tripCreateReq, fareChartInfo, vehicleType) => {
    let tripPrice;
    let key = vehicleType == 1 ? 'sedan' : 'suv';

    switch (tripCreateReq.tripType) {
        case TripType.FIXED_ONE_WAY: {
            let triptime = getTimeIntervalInMinute(tripCreateReq.requestedPickupTime, tripCreateReq.requestedDropTime);
            let tripDistance = tripCreateReq.requestedTravelDistance * 0.621;
            let discount = ((100 - fareChartInfo.fixed.discountPercentage) / 100);

            console.log('ONE WAY---------------------------------')
            console.log('triptime', triptime);
            console.log('tripDistance', tripDistance);
            console.log('discount', discount);


            tripPrice = fareChartInfo.fixed.baseFare[key] +
                (fareChartInfo.fixed.chargePerDistanceUnit[key] * tripDistance) +
                (fareChartInfo.fixed.chargePerMinute[key] * triptime);

            console.log('tripPrice', tripPrice);


            if (tripDistance > 10) {
                tripPrice = fareChartInfo.fixed.baseFare[key] +
                    (fareChartInfo.fixed.chargePerDistanceUnit[key] * 10) +
                    ((fareChartInfo.fixed.chargePerDistanceUnit[key]
                        * discount) * (tripDistance - 10)) +
                    (fareChartInfo.fixed.chargePerMinute[key] * triptime);
            }
        }
            break;

        case TripType.FIXED_ROUND_TRIP: {
            let triptime = getTimeIntervalInMinute(tripCreateReq.requestedPickupTime, tripCreateReq.requestedDropTime);
            let tripDistance = tripCreateReq.requestedTravelDistance * 0.621;
            let discount = ((100 - fareChartInfo.fixed.discountPercentage) / 100);

            //for round trip double the distacne and time
            tripDistance *= 2;
            triptime *= 2;

            console.log('ROUND ---------------------------------')

            console.log('triptime', triptime);
            console.log('tripDistance', tripDistance);
            console.log('discount', discount);


            tripPrice = fareChartInfo.fixed.baseFare[key] +
                (fareChartInfo.fixed.chargePerDistanceUnit[key] * tripDistance) +
                (fareChartInfo.fixed.chargePerMinute[key] * triptime);

            console.log('tripPrice', tripPrice);

            if (tripDistance > 10) {

                tripPrice = fareChartInfo.fixed.baseFare[key] +
                    (fareChartInfo.fixed.chargePerDistanceUnit[key] * 10) +
                    ((fareChartInfo.fixed.chargePerDistanceUnit[key]
                        * discount) * (tripDistance - 10)) +
                    (fareChartInfo.fixed.chargePerMinute[key] * triptime);
            }
        }
            break;

        case TripType.DURATION_HOURLY: {
            tripPrice = fareChartInfo.duration.baseFare[key] +
                (fareChartInfo.duration.hourly.perHourCost[key] * tripCreateReq.requestedNumberOfHours);

        }
            break;

        case TripType.DURATION_HALF_DAY: {
            tripPrice = fareChartInfo.duration.baseFare[key] +
                (fareChartInfo.duration.halfDay.perHourCost[key]);
        }
            break;

        case TripType.DURATION_FULL_DAY: {
            tripPrice = fareChartInfo.duration.baseFare[key] +
                (fareChartInfo.duration.fullDay.perHourCost[key]);
        }
            break;

    }
    return tripPrice;
}