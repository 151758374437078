export const styles = theme => ({
    referralBlock: {
        marginTop: 40,
        marginBottom: 40
    },
    disclaimer: {
        backgroundColor: '#ffe1ce',
        padding: '8px 15px',
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'start'
    },
    leftIcon: {
        marginRight: theme.spacing.unit
    },
    rightIcon:{
        marginLeft: theme.spacing.unit
    },
    warningAlert: {
        padding: '8px 15px',
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
});
