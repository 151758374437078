import {
  FETCH_SETTLED_BILLS_SUCCESS,
  FETCH_SETTLED_BILLS,
  FETCH_UNSETTLED_BILLS,
  FETCH_UNSETTLED_BILLS_SUCCESS,
  SETTLE_BILL_SUCCESS,
  SETTLE_BILL,
  SETTLE_TRANSFER_BILL,
  SETTLE_TRANSFER_BILL_SUCCESS,
  FETCH_BILLING_SUCCESS,
  FETCH_BILLING,
  FETCH_BILLING_FAIL,
  RESET_BILLING_DETAILS,
  EDIT_PAYMENT_BILL_SUCCESS,
  EDIT_PAYMENT_BILL,
  BILL_UPDATED_DONE
} from "../constants/action-types";

const initialState = {
  billList: {},
  unsettledBillList: {},
  billingDetails: {},
  isFetching: false,
  isEditing: false,
};

const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTLED_BILLS:
        return {
          ...state,
          billList: {}
        };
    case FETCH_SETTLED_BILLS_SUCCESS:
      return {
        ...state,
      
        billList: action.payload.data
      };
    case FETCH_UNSETTLED_BILLS:
        return {
          ...state,
          billList: {}
        };
    case FETCH_UNSETTLED_BILLS_SUCCESS:
        return {
          ...state,
          billList: action.payload.data
        };
    case FETCH_BILLING:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_BILLING_SUCCESS:
      return {
        ...state,
        billingDetails: action.payload.data,
        isFetching: false
      };
    case SETTLE_BILL: 
      return {
        ...state,
        billingDetails: {}
      }
    case SETTLE_BILL_SUCCESS:
      let localBillDetails = {...state.billingDetails}
      localBillDetails = {}
      localBillDetails = action.payload.data
      return {
        ...state,
        billingDetails: localBillDetails
      }
    case SETTLE_TRANSFER_BILL_SUCCESS:
      // let localBillDetails = {...state.billingDetails}
      // localBillDetails = {}
      // localBillDetails = action.payload.data
      return {
        ...state,
        billingDetails: action.payload.data
        }
    case SETTLE_TRANSFER_BILL:
      return {
        ...state,
        billingDetails: {}
      }
    case EDIT_PAYMENT_BILL_SUCCESS:
      return {
        ...state,
        billingDetails: action.payload.data,
        isEditing: true
      } 
    case EDIT_PAYMENT_BILL: 
      return {
        ...state,
        billingDetails:{}
      }
    case BILL_UPDATED_DONE: 
      return {
        ...state,
        isEditing: false
      }
    case FETCH_BILLING_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case RESET_BILLING_DETAILS:
      return {
        ...state,
        billingDetails: {}
      };
    default:
      return state;
  }
};

export default billingReducer;
