import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import ScrollArea from "react-scrollbar";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import DemoUserImage from "../../../../assets/images/default-profile-pic.png";
import contentStyles from "../../../auth-layout/content.styles";
import globalstyles from "../../../../globalstyles";
import styles from "./styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import StarRatings from "react-star-ratings";
import classNames from "classnames";

import Modal from "@material-ui/core/Modal";
import {
  faStar, faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchTrip, resetDetails } from "../../../../store/actions/pastTrip";
import CountryCodesConfig from "../../../../config/country-codes";
import {
  PastTripStatusObject,
  TripTypeObject,
  ActivityList,
  RiderActivityList,
  DriverActivityList,
  AdminActivityList,
  PaymentStatus
} from "../../../../config/trip";
import moment from "moment";
library.add(faStar, faUpload);

function TabContainer({ children }) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  };
}

const modalStyles = theme => ({
  modalPaper: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",

  }
});
class BillingDetailsSettled extends Component {
  state = {
    single: null,
    labelWidth: 0,
    breakdownDetailModal: false,
    tripDetails: {
      riderName: "",
      riderAvgRating: 0,
      riderEmail: "",
      riderPhoneNumber: "",
      riderId: "",
      driverName: "",
      driverAvgRating: 0,
      driverEmail: "",
      driverPhoneNumber: "",
      driverId: "",
      driverVehicleType: "",
      driverVehicleMake: "",
      driverVehicleModel: "",
      driverVehicleColor: "",
      tripReqBookingId: "",
      tripType: "Fixed",
      noOfPassenger: 0,
      tripReqTime: "",
      pickupTimeRequested: "",
      pickupTimeActual: "",
      pickupLocationRequested: "",
      pickupLocationActual: "",
      dropAddressRequested: "",
      dropAddressActual: "",
      destinationAddressActual: "",
      dropOffTime: "",
      tripStatus: 0,
      travelDistance: 0,
      waitingTime: 0,
      travelTime: 0,
      suvFare: 0,
      sedanFare: 0,
      paymentStatus: "",
      transferStatus: "",
      activityList: [],
      upcCreditUsed: 0
    },
    errors: {},
    haveImage: false,
    tabValue: 0
  };

  tripTypeList = TripTypeObject;

  vehicleTypes = {
    1: "Sedan",
    2: "SUV"
  };

  statusList = PastTripStatusObject;

  activityLogMessageList = ActivityList;

  riderActivities = RiderActivityList;
  driverActivities = DriverActivityList;
  adminActivities = AdminActivityList;

  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true
    });
    this.props.fetchTrip(this.props.match.params.tripId);
  }

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }


  timeDifference = (date1, date2) => {
    var difference = date1 - date2;

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60

    // var secondsDifference = Math.floor(difference/1000);
    // console.log(minutesDifference,"minutesDifference")
    let strTimeDiff = "";
    if (daysDifference !== 0) {
      strTimeDiff += daysDifference + ' day '
    }
    if (hoursDifference !== 0) {
      strTimeDiff += hoursDifference + ' hour '
    }
    if (minutesDifference !== 0) {
      strTimeDiff += minutesDifference + ' minute '
    }

    return strTimeDiff
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.detailsFetched &&
      Object.keys(this.props.data.pastTripReducer.tripDetails).length
    ) {
      let localTripData = { ...this.state.tripDetails };
      localTripData.riderProfilePicture = this.props.data.pastTripReducer.tripDetails.rider.profilePicture;

      localTripData.riderName = this.props.data.pastTripReducer.tripDetails.rider.fullName || "N/A";
      localTripData.riderAvgRating = this.props.data.pastTripReducer.tripDetails.rider.ratingData && this.props.data.pastTripReducer.tripDetails.rider.ratingData.total ?
        (this.props.data.pastTripReducer.tripDetails.rider.ratingData.total / this.props.data.pastTripReducer.tripDetails.rider.ratingData.count).toFixed(1) : 0;
      localTripData.riderEmail = this.props.data.pastTripReducer.tripDetails.rider.email || "N/A";
      localTripData.riderPhoneNumber = this.props.data.pastTripReducer.tripDetails.rider.phone ?
        CountryCodesConfig.filter(item => {
          return item.code === this.props.data.pastTripReducer.tripDetails.rider.phone.countryCode;
        })[0].dial_code + this.formatNumber(this.props.data.pastTripReducer.tripDetails.rider.phone.number) : "N/A";
      localTripData.riderId = this.props.data.pastTripReducer.tripDetails.rider.displayId || "N/A";

      localTripData.driverProfilePicture = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.profilePicture;
      localTripData.driverName = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.fullName || "N/A";
      localTripData.driverAvgRating = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.ratingData &&
        this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.ratingData.total ?
        (this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.ratingData.total / this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.ratingData.count).toFixed(1) : 0;
      localTripData.driverEmail = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.email || "N/A";
      localTripData.driverPhoneNumber = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.phone ?
        CountryCodesConfig.filter(item => {
          return item.code === this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.phone.countryCode;
        })[0].dial_code +
        this.formatNumber(this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.phone.number) : "N/A";
      localTripData.driverId = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.displayId || "N/A";

      localTripData.driverVehicleType = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.vehicleTypes[this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleType] : "N/A";
      localTripData.driverVehicleMake = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleMake : "N/A";
      localTripData.driverVehicleModel = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleModel : "N/A";
      localTripData.driverVehicleColor = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleColor : null;

      localTripData.tripReqBookingId = this.props.data.pastTripReducer.tripDetails.displayId || "N/A";

      localTripData.tripType = this.tripTypeList[this.props.data.pastTripReducer.tripDetails.tripType] || "N/A";
      localTripData.noOfPassenger = this.props.data.pastTripReducer.tripDetails.requestedNumberOfSeats || "0";
      if (this.props.data.pastTripReducer.tripDetails.bookingTime) {
        localTripData.tripReqTime = moment(this.props.data.pastTripReducer.tripDetails.bookingTime).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.tripReqTime = "";
      }
      if (this.props.data.pastTripReducer.tripDetails.pickupTime.requested) {
        localTripData.pickupTimeRequested = moment(this.props.data.pastTripReducer.tripDetails.pickupTime.requested).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.pickupTimeRequested = "";
      }
      if (this.props.data.pastTripReducer.tripDetails.pickupTime.actual) {
        localTripData.pickupTimeActual = moment(this.props.data.pastTripReducer.tripDetails.pickupTime.actual).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.pickupTimeActual = "";
      }

      localTripData.paymentInfo = this.props.data.pastTripReducer.tripDetails.paymentInfo;

      localTripData.pickupLocationRequested = this.props.data.pastTripReducer.tripDetails.pickupAddress.requested || "N/A";
      localTripData.pickupLocationActual = this.props.data.pastTripReducer.tripDetails.pickupAddress.actual || "N/A";

      localTripData.dropAddressRequested = this.props.data.pastTripReducer.tripDetails.dropAddress.requested || "N/A";
      localTripData.dropAddressActual = this.props.data.pastTripReducer.tripDetails.dropAddress.actual || "N/A";

      localTripData.destinationAddressActual = this.props.data.pastTripReducer.tripDetails.dropAddress.actual || "N/A"; //it needs to be handled depending on the trip type

      if (this.props.data.pastTripReducer.tripDetails.dropTime.actual) {
        localTripData.dropOffTime = moment(this.props.data.pastTripReducer.tripDetails.dropTime.actual).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.dropOffTime = "";
      }

      localTripData.tripStatus = this.props.data.pastTripReducer.tripDetails.tripStatus || "N/A";

      localTripData.travelDistance = this.props.data.pastTripReducer.tripDetails.travelDistance.actual || "0";
      localTripData.waitingTime = this.props.data.pastTripReducer.tripDetails.waitingTime || "0";

      if (this.props.data.pastTripReducer.tripDetails.dropTime.actual && this.props.data.pastTripReducer.tripDetails.pickupTime.actual) {
        // console.log(new Date(this.props.data.pastTripReducer.tripDetails.dropTime.actual).getTime(),"pickupActual")
        // console.log(new Date(this.props.data.pastTripReducer.tripDetails.dropTime.actual) - new Date(this.props.data.pastTripReducer.tripDetails.pickupTime.actual))

        let localPickupActual = new Date(this.props.data.pastTripReducer.tripDetails.pickupTime.actual).getTime();
        let localDropActual = new Date(this.props.data.pastTripReducer.tripDetails.dropTime.actual).getTime();

        let differenceTime = this.timeDifference(localDropActual, localPickupActual)


        // localTripData.travelTime = Math.ceil((new Date(this.props.data.pastTripReducer.tripDetails.dropTime.actual) - new Date(this.props.data.billingReducer.billingDetails.pickupTime.actual)) / 3600000)
        localTripData.travelTime = differenceTime;
      } else {
        localTripData.travelTime = "0";
      }
      // console.log(this.props.data.pastTripReducer.tripDetails,"this.props.data.pastTripReducer.tripDetails")
      //calculating SUV & Sedan fare
      let fareChartInfo = this.props.data.pastTripReducer.tripDetails.fareChartInfo;
      let dropTime = this.props.data.pastTripReducer.tripDetails.dropTime;
      let pickupTime = this.props.data.pastTripReducer.tripDetails.pickupTime;
      let travelDistance = this.props.data.pastTripReducer.tripDetails.travelDistance;

      // if (this.props.data.bookingRequestReducer.tripDetails.tripType === 1 || this.props.data.bookingRequestReducer.tripDetails.tripType === 2) {
      //   localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + Math.ceil((new Date(dropTime.requested) -
      //     new Date(pickupTime.requested)) / 60000) * fareChartInfo.fixed.chargePerMinute.suv
      //     + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
      //   localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + Math.ceil((new Date(dropTime.requested) -
      //     new Date(pickupTime.requested)) / 60000) * fareChartInfo.fixed.chargePerMinute.sedan
      //     + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;
      // } else {
      //   localTripData.suvFare = fareChartInfo.duration.baseFare + this.props.data.bookingRequestReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.suv;
      //   localTripData.sedanFare = fareChartInfo.duration.baseFare + this.props.data.bookingRequestReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.sedan;
      // }

      switch (this.props.data.pastTripReducer.tripDetails.tripType) {
        case 1:
          localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.suv
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
          localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.sedan
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;
          if (travelDistance.requested > 10) {
            localTripData.suvFare -= (localTripData.suvFare * (fareChartInfo.fixed.discountPercentage / 100));
            localTripData.sedanFare -= (localTripData.sedanFare * (fareChartInfo.fixed.discountPercentage / 100));
          }
          break;
        case 2:
          localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.suv
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
          localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.sedan
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;
          if (travelDistance.requested > 10) {
            localTripData.suvFare -= (localTripData.suvFare * (fareChartInfo.fixed.discountPercentage / 100));
            localTripData.sedanFare -= (localTripData.sedanFare * (fareChartInfo.fixed.discountPercentage / 100));
          }
          break;
        case 3:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + this.props.data.pastTripReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + this.props.data.pastTripReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.sedan;
          break;
        case 4:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + fareChartInfo.duration.halfDay.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + fareChartInfo.duration.halfDay.perHourCost.sedan;
          break;
        case 5:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + fareChartInfo.duration.fullDay.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + fareChartInfo.duration.fullDay.perHourCost.sedan;
          break;
      }
      localTripData.upcCreditUsed = this.props.data.pastTripReducer.tripDetails.transactionInfo.upcCreditsUsed
      localTripData.paymentStatus = this.props.data.pastTripReducer.tripDetails.transactionInfo &&
        this.props.data.pastTripReducer.tripDetails.transactionInfo.paymentStatus;
      localTripData.transferStatus = this.props.data.pastTripReducer.tripDetails.transactionInfo &&
        this.props.data.pastTripReducer.tripDetails.transactionInfo.transferStatus;
      localTripData.activityList = this.props.data.pastTripReducer.tripDetails.activityLog;
      let distanceTravelled = this.props.data.pastTripReducer.tripDetails.travelDistance.actual
      if (distanceTravelled > 10) {
        localTripData.suvFare = localTripData.suvFare - ((localTripData.suvFare) * (fareChartInfo.discountPercentage / 100))
        localTripData.sedanFare = localTripData.sedanFare - ((localTripData.sedanFare) * (fareChartInfo.discountPercentage / 100))
      }

      this.setState({
        ...this.state,
        tripDetails: localTripData,
        detailsFetched: true,
        loading: false
      });
    }
  }
  openDetailsBreakdownModal = () => {
    this.setState({ breakdownDetailModal: true });
  };
  closeDetailsBreakdownModal = () => {
    this.setState({ ...this.state, breakdownDetailModal: false });
  };
  componentWillUnmount() {
    this.props.resetDetails();
  }

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  }

  render() {
    const { classes, theme } = this.props;
    const { tabValue } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        {!this.state.loading ? (
          <div>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                    <Typography component="p">
                      <span className={classes.pageHeader}>
                        {
                          this.props.data.literalsReducer.Billing.PageHeader
                        }
                      </span>
                      <span className={classes.PageDescription}>

                        {
                          this.props.data.literalsReducer.SettledBillDetails
                            .PageDescription
                        }
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.headerRightButton}
                      onClick={this.backToPrevPage}
                    >
                      <ArrowBackIosIcon className={classes.leftIcon} /> Back
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <Grid container className={classes.rightPaper}>
                    <Grid item xs={12} sm={9}>
                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Tabs value={tabValue} onChange={this.handleTabChange} className={classes.tabPan}>
                              <Tab label="Chauffeur Details" />
                              <Tab label="Rider Details" />
                            </Tabs>
                            {tabValue === 0 && <TabContainer>
                              {this.state.tripDetails.driverName.length ?
                                <div className={classes.tabDetailsCont}>
                                  <div className={classes.profileImageWrap}>
                                    <div className={classes.profileImagePlaceholder}>
                                      {this.state.tripDetails.driverProfilePicture ?

                                        <div style={{
                                          backgroundSize: "cover",
                                          backgroundRepeat: "no-repeat",
                                          width: "100%",
                                          height: "100%",
                                          backgroundImage: `url( ${this.state.tripDetails.driverProfilePicture} )`
                                        }}></div>
                                        : <img
                                          src={DemoUserImage}
                                          alt="no-menu-image"
                                          style={{ width: "100%" }}
                                        />}
                                    </div>
                                    <div style={{ marginLeft: "5px" }}>
                                      <span>{this.state.tripDetails.driverName || "N/A"}</span>

                                      <div
                                        className={classNames(
                                          classes.ratingShow,
                                          classes.description
                                        )}
                                      >
                                        <StarRatings
                                          rating={Number(this.state.tripDetails.driverAvgRating)}
                                          starRatedColor="#ffc000"
                                          numberOfStars={10}
                                          name="rating"
                                          starDimension="15px"
                                          starSpacing="2px"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.Email} -
                                    <span>{this.state.tripDetails.driverEmail || "N/A"}</span>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.PhoneNo} -
                                    <span>{this.state.tripDetails.driverPhoneNumber || "N/A"}</span>
                                  </div>
                                  {/* <div className={classes.description}>
                                {this.props.data.literalsReducer.DriverDetails.DriverId} - 
                                <span>{this.state.tripDetails.driverId || "N/A"}</span>
                              </div> */}
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.CarType} -
                                    <span>{this.state.tripDetails.driverVehicleType || "N/A"}</span>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.CarMaker} -
                                    <span>{this.state.tripDetails.driverVehicleMake || "N/A"}</span>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.CarModel} -
                                    <span>{this.state.tripDetails.driverVehicleModel || "N/A"}</span>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.CarColor} -
                                    {this.state.tripDetails.driverVehicleColor ?
                                      <span className={classes.colorView} style={{ background: this.state.tripDetails.driverVehicleColor }}></span>
                                      : <span>N/A</span>}
                                  </div>
                                </div>
                                : <div className={classes.tabDetailsCont} style={{ textAlign: "center" }}> Driver not assigned</div>}
                            </TabContainer>}
                            {tabValue === 1 && <TabContainer>
                              <div className={classes.tabDetailsCont}>
                                <div className={classes.profileImageWrap}>
                                  <div className={classes.profileImagePlaceholder}>
                                    {this.state.tripDetails.riderProfilePicture ?

                                      <div style={{
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        width: "100%",
                                        height: "100%",
                                        backgroundImage: `url( ${this.state.tripDetails.riderProfilePicture} )`
                                      }}></div>
                                      : <img
                                        src={DemoUserImage}
                                        alt="no-menu-image"
                                        style={{ width: "100%" }}
                                      />}
                                  </div>
                                  <div style={{ marginLeft: "5px" }}>
                                    <span>{this.state.tripDetails.riderName || "N/A"}</span>

                                    <div
                                      className={classNames(
                                        classes.ratingShow,
                                        classes.description
                                      )}
                                    >
                                      <StarRatings
                                        rating={Number(this.state.tripDetails.riderAvgRating)}
                                        starRatedColor="#ffc000"
                                        numberOfStars={10}
                                        name="rating"
                                        starDimension="15px"
                                        starSpacing="2px"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.RiderDetails.Email} -
                                  <span>{this.state.tripDetails.riderEmail || "N/A"}</span>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.RiderDetails.PhoneNo} -
                                  <span>{this.state.tripDetails.riderPhoneNumber || "N/A"}</span>
                                </div>
                                {/* <div className={classes.description}>
                                {this.props.data.literalsReducer.RiderDetails.RiderId} - 
                                <span>{this.state.tripDetails.riderId || "N/A"}</span>
                              </div> */}
                              </div>
                            </TabContainer>}
                          </Grid>
                          <Grid item xs={12} sm={6} className={classes.breakDownPart}>
                            <h3>Fare Breakdown</h3>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.Estimation} -
                              <Chip label="SUV" className={classes.chip} /><span>&#36;{this.state.tripDetails.suvFare.toFixed(2) || "0"}</span>
                              {this.state.tripDetails.noOfPassenger < 5 ?
                                <span>
                                  <Chip label="SEDAN" className={classes.chip} /><span>&#36;{this.state.tripDetails.sedanFare.toFixed(2) || "0"}</span>
                                </span> : null}
                            </div>
                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                            {this.state.tripDetails.tripStatus === 13 || this.state.tripDetails.tripStatus === 14 ? <span>
                              <div >
                                <div style={{ width: '75%', display: 'inline-block' }}><h3>Payment Breakdown</h3></div>
                                <div style={{ width: '25%', display: 'inline-block' }}>
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    className={classes.addButton}
                                    onClick={this.openDetailsBreakdownModal}
                                  >
                                    Shares
                                  </Button>
                                </div>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.PaymentStatus} -
                                <span className={classes.activeStatus} style={{ float: "right" }}>{PaymentStatus[this.state.tripDetails.paymentStatus]}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.TransferStatus} -
                                <span className={classes.activeStatus} style={{ float: "right" }}>{PaymentStatus[this.state.tripDetails.transferStatus]}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.BaseFare} -
                                <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.tripDetails.paymentInfo.paymentBreakdown.baseCharge).toFixed(2) : "N/A"}</span>

                              </div>

                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.RemainingTripCharge} -
                                <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.tripDetails.paymentInfo.paymentBreakdown.remainingTripCharge).toFixed(2) : "N/A"}</span>
                              </div>

                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.WaitingCharge} -
                                <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.tripDetails.paymentInfo.paymentBreakdown.waitingCharge).toFixed(2) : "N/A"}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.AdditionalChargeForDriver} -
                                <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.tripDetails.paymentInfo.paymentBreakdown.additionalChargeForDriver).toFixed(2) : "N/A"}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.CancelCharge} -
                                <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.tripDetails.paymentInfo.paymentBreakdown.cancellationCharge).toFixed(2) : "N/A"}</span>
                              </div>
                              <hr />
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.SubTotalFare} -
                                <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.tripDetails.paymentInfo.paymentBreakdown.totalFare).toFixed(2) : "N/A"}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.Tip} -
                                <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.tripDetails.paymentInfo.paymentBreakdown.tip).toFixed(2) : "N/A"}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.Discount} -
                                <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.tripDetails.paymentInfo.paymentBreakdown.discount).toFixed(2) : "N/A"}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.RiderCredit} -
                                <span style={{ float: "right" }}>&#36;{this.state.tripDetails.upcCreditUsed && this.state.tripDetails.upcCreditUsed ?
                                  (this.state.tripDetails.upcCreditUsed).toFixed(2) : "N/A"}</span>
                              </div>
                              <hr />
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.TotalFare} -
                                <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.tripDetails.paymentInfo.paymentBreakdown.payableAmount).toFixed(2) : "N/A"}</span>
                              </div>
                              <div>
                                <a href={`/web/past-trips/download-invoice/${this.props.match.params.tripId}`} className={classes.linkElem} target="_blank">Download Invoice</a>
                              </div>
                            </span> : null}
                          </Grid>

                        </Grid>
                      </Grid>
                      <Divider variant="middle" style={{ "margin": "25px 0" }} />
                      <Grid item xs={12} sm={12}>
                        <h3>Req. Details</h3>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={6}>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.BookingId} -
                              <span>{this.state.tripDetails.tripReqBookingId || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.BookingType} -
                              <span>{this.state.tripDetails.tripType || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.NoOfPassenger} -
                              <span>{this.state.tripDetails.noOfPassenger || "0"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ReqAt} -
                              <span>{this.state.tripDetails.tripReqTime || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.PickupTime} -
                              <span>{this.state.tripDetails.pickupTimeRequested || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualPickupTime} -
                              <span>{this.state.tripDetails.pickupTimeActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ReqPickupLocation} -
                              <span>{this.state.tripDetails.pickupLocationRequested || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ReqDestination} -
                              <span>{this.state.tripDetails.dropAddressRequested || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualPickupLocation} -
                              <span>{this.state.tripDetails.pickupLocationActual || "N/A"}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualDestinationLocation} -
                              <span>{this.state.tripDetails.destinationAddressActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualDropOffLocation} -
                              <span>{this.state.tripDetails.dropAddressActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.TimeofDropoff} -
                              <span>{this.state.tripDetails.dropOffTime || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.Status} -
                              <span className={classes.activeStatus}>{this.statusList[this.state.tripDetails.tripStatus]}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.KmsTravelled} -
                              <span>{this.state.tripDetails.travelDistance ? this.state.tripDetails.travelDistance + " Mile" : "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.WaitTime} -
                              <span>{this.state.tripDetails.waitingTime ? this.state.tripDetails.waitingTime + " hour" : "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.TimeOfTravel} -
                              <span>{this.state.tripDetails.travelTime ? this.state.tripDetails.travelTime : "N/A"}</span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.activityLog}>
                      <h3>Activity Log</h3>
                      <ScrollArea
                        speed={0.8}
                        className={classes.scrollContent}
                        contentClassName="content"
                        horizontal={false}
                      >
                        {this.state.tripDetails.activityList.length ?
                          <List className={classes.root}>
                            {this.state.tripDetails.activityList.map(activity => {
                              return <ListItem alignItems="flex-start" key={activity._id}>
                                <span>
                                  {this.riderActivities.indexOf(activity.actionType) > -1 ?
                                    activity.rider.fullName : this.driverActivities.indexOf(activity.actionType) > -1 ?
                                    (activity.driver && activity.driver.fullName || 'Chauffeur') : this.adminActivities.indexOf(activity.actionType) > -1 ?
                                        activity.admin.fullName : null}
                                </span>{this.activityLogMessageList[activity.actionType]}
                                <p>
                                  <span>{moment(activity.timeStamp).format("MM-DD-YYYY, h:mm a")}</span>
                                </p>
                              </ListItem>
                            })}

                          </List> : <div
                            style={{
                              textAlign: "center",
                              padding: "10px",
                              backgroundColor: "#fff",
                              borderRadius: "10px"
                            }}
                          >No activity found!</div>}
                      </ScrollArea>

                    </Grid>
                  </Grid>
                  <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.breakdownDetailModal}
                    onClose={this.closeDetailsBreakdownModal}
                  >
                    <div style={getModalStyle()} className={classes.modalPaper}>
                      <Grid item xs={12} sm={12} className={classes.descSection}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12}>
                            <h2 style={{ textAlign: 'center' }}>Share Breakdown</h2>
                            <hr />
                            <div className={classes.description}>
                              Admin Share -
                              <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.shareBreakDown ?
                                (this.state.tripDetails.paymentInfo.shareBreakDown.adminShare).toFixed(2) : "N/A"}</span>

                            </div>
                            <div className={classes.description}>
                              Chauffeur Share -
                              <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.shareBreakDown ?
                                (this.state.tripDetails.paymentInfo.shareBreakDown.driverShare).toFixed(2) : "N/A"}</span>

                            </div>

                            <div className={classes.description}>
                              UPC Bucket Share -
                              <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.shareBreakDown ?
                                (this.state.tripDetails.paymentInfo.shareBreakDown.upcBucketShare).toFixed(2) : "N/A"}</span>
                            </div>

                            <div className={classes.description}>
                              Chauffeur Coordinator share -
                              <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.shareBreakDown ?
                                (this.state.tripDetails.paymentInfo.shareBreakDown.driverCoordinatorShare).toFixed(2) : "N/A"}</span>
                            </div>

                            <div className={classes.description}>
                              Investor Share -
                              <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.shareBreakDown ?
                                (this.state.tripDetails.paymentInfo.shareBreakDown.investorShare).toFixed(2) : "N/A"}</span>
                            </div>

                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <div style={{ textAlign: "right" }}>
                              <Button
                                size="small"
                                color="default"
                                variant="contained"
                                className={classes.customMargin}
                                onClick={this.closeDetailsBreakdownModal}
                              >
                                Cancel
                              </Button>

                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Modal>
                </Paper>
              </Grid>
            </Grid>
          </div>) : (
          <div
            style={{
              textAlign: "center",
              padding: "10px",
              backgroundColor: "#fff",
              borderRadius: "10px"
            }}
          >
            {this.props.data.literalsReducer.Common.Loading}
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTrip: (id) => dispatch(fetchTrip(id)),
    resetDetails: () => dispatch(resetDetails())
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...modalStyles(theme),
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(BillingDetailsSettled)
);
