// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  GET_RATE_CHART,
  GET_SAPPHIRE_RATE_CHART,
  UPDATE_FIXED_RATE_CHART,
  UPDATE_SAPPHIRE_FIXED_RATE_CHART,
  FIXED_RATE_CHART_UPDATED_DONE,
  FIXED_SAPPHIRE_RATE_CHART_UPDATED_DONE,
  UPDATE_DURATION_RATE_CHART,
  UPDATE_SAPPHIRE_DURATION_RATE_CHART,
  DURATION_RATE_CHART_UPDATED_DONE,
  DURATION_SAPPHIRE_RATE_CHART_UPDATED_DONE,
  RESET_RATE_CHART_DETAILS,
  RESET_SAPPHIRE_RATE_CHART_DETAILS
} from "../constants/action-types";

// //////////////////// Get Dashboard  Details // //////////////////
export const getRateChart = () => {
  return {
    type: GET_RATE_CHART,
    payload: {
      request: {
        url: apiURLs.rateChart,
        method: "GET"
      }
    },
    hideToast: true
  };
};

export const getSapphireRateChart = () => {
  return {
    type: GET_SAPPHIRE_RATE_CHART,
    payload: {
      request: {
        url: apiURLs.rateChart + "/sapphire",
        method: "GET"
      }
    },
    hideToast: true
  };
};

export const updateFixedRateChart = data => {
  return {
    type: UPDATE_FIXED_RATE_CHART,
    payload: {
      request: {
        url: apiURLs.rateChart + "/fixed",
        method: "PUT",
        data: data
      }
    }
  };
};

export const updateFixedRateChartForSapphire = data => {
  return {
    type: UPDATE_SAPPHIRE_FIXED_RATE_CHART,
    payload: {
      request: {
        url: apiURLs.rateChart + "/sapphire-fixed",
        method: "PUT",
        data: data
      }
    }
  };
};

export const fixedRateChartDataUpdatedDone = () => {
  return {
    type: FIXED_RATE_CHART_UPDATED_DONE
  };
};

export const sapphireFixedRateChartDataUpdatedDone = () => {
  return {
    type: FIXED_SAPPHIRE_RATE_CHART_UPDATED_DONE
  };
};

export const updateDurationRateChart = data => {
  return {
    type: UPDATE_DURATION_RATE_CHART,
    payload: {
      request: {
        url: apiURLs.rateChart + "/duration",
        method: "PUT",
        data: data
      }
    }
  };
};

export const updateDurationRateChartForSapphire = data => {
  return {
    type: UPDATE_SAPPHIRE_DURATION_RATE_CHART,
    payload: {
      request: {
        url: apiURLs.rateChart + "/sapphire-duration",
        method: "PUT",
        data: data
      }
    }
  };
};

export const durationRateChartDataUpdatedDone = () => {
  return {
    type: DURATION_RATE_CHART_UPDATED_DONE
  };
};

export const sapphiredurationRateChartDataUpdatedDone = () => {
  return {
    type: DURATION_SAPPHIRE_RATE_CHART_UPDATED_DONE
  };
};

export const resetDetails = () => {
  return {
    type: RESET_RATE_CHART_DETAILS
  };
};

export const resetDetails1 = () => {
  return {
    type: RESET_SAPPHIRE_RATE_CHART_DETAILS
  };
};
