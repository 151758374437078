import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Logo from "../../assets/images/logo.png";
import LogInImage from "../../assets/images/login_front.jpg";
import { Link, Redirect } from "react-router-dom";

import globalstyles from "../../globalstyles";
import { styles } from "./styles";

import { connect } from "react-redux";
import { forgotPassword,sentOtpDone } from "../../store/actions/auth";
import { AUTH_DATA } from "../../config/local-storage-keys";

import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import classNames from "classnames";

import TextField from "@material-ui/core/TextField";

import theme from "../../app/theme";

class RequestOTPForgotPassword extends Component {
  constructor(props) {
    super(props);

    // initially assuming that user is logged out
    let user = {
      isLoggedIn: false
    };

    // if user is logged in, his details can be found from local storage
    try {
      let userJsonString = localStorage.getItem(AUTH_DATA);
      if (userJsonString) {
        user.data = JSON.parse(userJsonString);
        if (user.data.token) {
          user.isLoggedIn = true;
        }
      }
    } catch (exception) {
      console.log("exception", exception);
    }

    this.state = {
      fields: {
        email: ""
      },
      errors: {},
      isLoggedIn: user.isLoggedIn,
      toastMessage: false
    };
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleChange = event => {
    let fields = this.state.fields;
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;
    errors.emailInvalid = false;

    this.setState({ ...this.state, fields, errors });
  };

  handleValidation = () => {
    let fields = { ...this.state.fields };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    for (let name in fields) {
      if (fields[name].trim() === "") {
        errors[name] = true;
        hasErrors = true;
      }
    }

    if (
      fields.email.trim().length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields.email)
    ) {
      errors["emailInvalid"] = true;
      hasErrors = true;
    } else {
      errors["emailInvalid"] = false;
    }

    this.setState({ errors: errors });
    return hasErrors;
  };

  submitHandler = event => {
    event.preventDefault();
    if (!this.handleValidation()) {
      let requestData = { ...this.state.fields };
      //this.props.history.push("/forgot-password/reset-password");
      this.props.dispatch(forgotPassword(requestData));
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      // console.log(this.props.data.authReducer.forgotPasswordData,"this.props.data.authReducer.forgotPasswordData")
      if (this.props.data.authReducer.forgotPasswordData.resetPasswordSuccess) {
        this.props.dispatch(sentOtpDone());
        localStorage.setItem("email", this.state.fields.email);
        this.props.history.push("/forgot-password/reset-password");
      }
    }
  }

  render(props) {
    const { classes } = this.props;

    if (this.state.isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/web/auth/home",
            state: {
              from: this.props.location
            }
          }}
        />
      );
    } else {
      return (
        <MuiThemeProvider theme={theme}>
          <div className={classes.root} before-login>
            <Grid container spacing={24} className={classes.signInWrapper}>
              <Grid
                item
                xs={12}
                sm={7}
                className={classes.loginImage}
                style={{
                  backgroundImage: `url(${LogInImage})`
                }}
              />
              <Grid item xs={12} sm={5} className={classes.signinBox}>
                <Paper className={classes.signinBoxPaper}>
                  <Typography
                    component="h1"
                    variant="h5"
                    className={classes.formHeaderLogo}
                  >
                    <Link to="/login">
                      <img src={Logo} alt="logo" />
                    </Link>
                  </Typography>
                  <Typography
                    component="h1"
                    variant="h5"
                    className={classes.formHeader}
                  >
                    {this.props.data.literalsReducer.Home.ForgotPasswordHeader}
                  </Typography>
                  <form className={classes.form} onSubmit={this.submitHandler}>
                    <TextField
                      variant="outlined"
                      className={classes.textField}
                      margin="normal"
                      autoComplete="email"
                      style={{ marginBottom: 0 }}
                      label={
                        this.props.data.literalsReducer.Home
                          .UserNameOrEmailAddress
                      }
                      name="email"
                      value={this.state.fields["email"]}
                      onChange={this.handleChange}
                      error={
                        this.state.errors.email ||
                        this.state.errors.emailInvalid
                      }
                    />
                    {this.state.errors.email ? (
                      <FormHelperText id="validation-error">
                        {
                          this.props.data.literalsReducer.ValidationErrors
                            .Required
                        }
                      </FormHelperText>
                    ) : null}
                    {this.state.errors.emailInvalid ? (
                      <FormHelperText id="validation-error">
                        {
                          this.props.data.literalsReducer.ValidationErrors
                            .InvalidEmail
                        }
                      </FormHelperText>
                    ) : null}
                    <div className={classes.formFooter}>
                      <div className={classes.formFooterRight}>
                        <Button
                          type="Button"
                          variant="contained"
                          color="primary"
                          className={classNames(
                            classes.submit,
                            classes.buttonHalf,
                            classes.linkButton
                          )}
                        >
                          <Link to="/login" className={classes.link}>
                            Back
                          </Link>
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classNames(
                            classes.submit,
                            classes.buttonHalf,
                            classes.linkButton
                          )}
                        >
                          {this.props.data.literalsReducer.Home.RequestOtp}
                        </Button>
                      </div>
                    </div>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </MuiThemeProvider>
      );
    }
  }
}

RequestOTPForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return { data: state };
};

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(RequestOTPForgotPassword)
);
