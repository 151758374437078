import { SNACKBAR_OPEN, SNACKBAR_CLEAR } from "../constants/action-types";

export const showSnackbar = (message, variant) => {
    return {
        type: SNACKBAR_OPEN,
        message,
        variant
    };
};

export const clearSnackbar = () => {
    return {
        type: SNACKBAR_CLEAR
    };
};