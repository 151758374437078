import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Connector } from 'mqtt-react';
import { subscribe } from 'mqtt-react';
import App from "../app";
import { mqttUrl } from "../store/api-config/api.util";
import MqttComponent from '../components/MqttComponent'

let userId = ""
if (window.localStorage.upcAuthData) {
  const userData = JSON.parse(window.localStorage.upcAuthData)
  userId = userData.user._id
  console.log(userId);
}
const MessageContainer = subscribe({ topic: `upc/admin/${userId}` })(MqttComponent);
const Root = ({ store }) => (
  <Connector mqttProps={mqttUrl}>
    <Provider store={store}>
      <Router basename="/web">
        <Route path="/" component={App} />

      </Router>
      {window.localStorage.upcAuthData ? <MessageContainer /> : null}
    </Provider>
  </Connector>
);

Root.propTypes = {
  store: PropTypes.object.isRequired
};

export default Root;
