// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  GET_ZONE,
  UPDATE_ZONE,
  ZONE_UPDATED_DONE,
  RESET_ZONE_DETAILS
} from "../constants/action-types";

// //////////////////// Get Dashboard  Details // //////////////////
export const getZone = () => {
  return {
    type: GET_ZONE,
    payload: {
      request: {
        url: apiURLs.zone + "list",
        method: "POST"
      }
    },
    hideToast: true
  };
};
export const updateZone = (data, id) => {
  return {
    type: UPDATE_ZONE,
    payload: {
      request: {
        url: apiURLs.zone + id,
        method: "PUT",
        data: data
      }
    }
  };
};

export const zoneUpdatedDone = () => {
  return {
    type: ZONE_UPDATED_DONE
  };
};

export const resetDetails = () => {
  return {
    type: RESET_ZONE_DETAILS
  };
};
