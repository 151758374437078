// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  FETCH_TRIP,
  RESET_TRIP_DETAILS,
  SEARCH_DRIVER,
  ASSIGN_DRIVER,
  DRIVER_ASSIGNED_DONE
} from "../constants/action-types";

export const fetchTrip = (id, type) => {
  return {
    type: FETCH_TRIP,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs[type] + "/" + id,
        method: "GET"
      }
    }
  };
};
export const searchDriver = (data, id) => {
  return {
    type: SEARCH_DRIVER,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.searchDriver + "/" + id,
        method: "POST",
        data: data
      }
    }
  };
};
export const assignDriver = (tripId, driverId) => {
  return {
    type: ASSIGN_DRIVER,
    payload: {
      request: {
        url: apiURLs.assignDriver + tripId + "/" + driverId,
        method: "POST"
      }
    }
  };
};

export const resetDetails = () => {
  return {
    type: RESET_TRIP_DETAILS
  };
};

export const driverAssignedDone = () => {
  return {
    type: DRIVER_ASSIGNED_DONE
  };
};
