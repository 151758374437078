import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import { 
  FETCH_NOTIFICATIONS,
  NOTIFICATIONS_SEEN,
  NOTIFICATIONS_SEEN_DONE,
  NOTIFICATIONS_MQTT_SUCEESS,
  NOTIFICATIONS_MQTT_SUCEESS_DONE,
  FETCH_NOTIFICATIONS_COUNT,
  FETCH_NOTIFICATIONS_COUNT_DONE
 } from "../constants/action-types";

export const getNotifications = searchObj => {
  return {
    type: FETCH_NOTIFICATIONS,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.getNotifications,
        method: "GET",
        params: {
          skip: searchObj.skip,
          limit: searchObj.limit
        },
      }
    }
  };
};

export const getNotificationsCount = () => {
  return {
    type: FETCH_NOTIFICATIONS_COUNT,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.getNotificationCount,
        method: "GET",
      }
    }
  };
};


export const updateNotifications = id => {
  return {
    type: NOTIFICATIONS_SEEN,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.updateNotification + id,
        method: "PUT"
      }
    }
  };
};
export const seenNotificationDone = () => {
  return {
    type: NOTIFICATIONS_SEEN_DONE

  };
};


export const fetchNotificationCountDone = () => {
  return {
    type: FETCH_NOTIFICATIONS_COUNT_DONE

  };
};

export const mqttNotification = (data) => {
  return {
    type: NOTIFICATIONS_MQTT_SUCEESS,
    payload: data
  };
};

export const mqttNotificationDone = () => {
  return {
    type: NOTIFICATIONS_MQTT_SUCEESS_DONE
  };
};

