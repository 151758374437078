import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../../app/theme";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import BlankProfileImage from "../../../../assets/images/default-profile-pic.png";
import contentStyles from "../../../auth-layout/content.styles";
import styles from "./styles";
import globalstyles from "../../../../globalstyles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid, InputAdornment } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import FormHelperText from "@material-ui/core/FormHelperText";
import CountryCodesConfig from "../../../../config/country-codes";
import ScrollArea from "react-scrollbar";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import CustomTable from "../../../../components/CustomTable";
import {
  fetchRider,
  editRider,
  changeRiderStatus,
  riderUpdatedDone,
  statusUpdatedDone,
  searchUpc,
  addUpc,
  upcUpdatedDone,
  resetDetails,
  addUpcCredit,
  resetRiderPassword
} from "../../../../store/actions/rider";
import StarRatings from "react-star-ratings";
import { ToastStore } from "react-toasts";
import {
  faStar,
  faStarHalfAlt,
  faUpload,
  faInfoCircle,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showSnackbar } from "../../../../store/actions/snackbar";
import { replace } from "../../../../utils/SupportUtils";
library.add(faStar, faStarHalfAlt, faUpload, faInfoCircle, faPlus);

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  };
}

const modalStyles = theme => ({
  modalPaper: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    "& $profileImagePlaceholder": {
      margin: "0 auto 20px"
    },
    "& $profileImageUploaderContainer": {
      left: "0",
      right: "0",
      margin: "auto"
    }
  }
});

const countries = CountryCodesConfig.filter(each => each.code === 'US');

const userType = {
  1: "Chauffeur",
  2: "Rider"
};

class RiderDetails extends Component {
  state = {
    editUserModalOpen: false,
    editableMode: false,
    addUpcCodeModalOpen: false,
    addUpcMoneyModalOpen: false,
    single: null,
    labelWidth: 0,
    newUpcData: {
      addUpcCode: "",
      addUpcMoney: "",
    },
    displaySuggestion: false,
    favModalOpen: false,
    upcSuggestions: [],
    selectedUpc: {},
    fetchSuggestions: false,
    riderDetails: {
      favoriteList: [],
      firstName: "",
      lastName: "",
      fullName: "",
      displayId: "",
      email: "",
      phoneNumber: "",
      countryCode: "",
      number: "",
      profilePicture: "",
      upcCode: "",
      usedUpcCode: "",
      accountStatus: "",
      createdAt: "",
      upcCodeUsedBy: 0,
      upcCreditsEarned: 0,
      upcCreditPresent: 0,
      avgRating: 0,
      completedTrips: 0,
      requestedTrips: 0,
      canceledTrips: 0,
      upComingRequest: 0,
      ongoingRides: 0,
      favoriteDriversCount: 0,
      businessInfo: {
        additionalInfo: null,
        address: null,
        email: null,
        name: null,
        phone: null,
        website: null
      }
    },
    errors: {},
    editableRiderDetails: {},
    haveImage: false,
    confirmationModalOpen: false,
    visibleColumns: ["Image", "Name", "Contact No."],
    data: {
      total: 1,
      data: []
    },
    headerFormat: {
      riderImage: {
        head: "Image",
        key: "riderImage",
        isImage: true
      },
      "personalInfo.fullName": {
        head: "Name",
        key: ["personalInfo", "fullName"],
        populate: true
      },
      contact: {
        head: "Contact No.",
        key: "contact"
      }
    }
  };

  statusList = {
    1: "Active",
    2: "Inactive",
    3: "Expired"
  };

  handleChange = event => {
    let fields = { ...this.state.editableRiderDetails };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    if (event.target.name === "number") {
      errors.invalidPhoneNumber = false;
    }

    if (event.target.name === "email") {
      errors.emailInvalid = false;
    }

    this.setState({ ...this.state, editableRiderDetails: fields, errors });
  };

  redirect = data => {
    this.props.history.push("/web/auth/drivers/details/" + data._id);
  };

  handleUpcChange = event => {
    let fields = { ...this.state.newUpcData };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;
    errors.invalidUpcMoney = false;

    if (event.target.name === "addUpcCode") {
      this.searchUPC(event.target.value);
    }
    if (event.target.name === "addUpcMoney") {
      if (event.target.value < 1) {
        errors.invalidUpcMoney = true
      }
    }

    this.setState({ ...this.state, newUpcData: fields, errors });
  };

  driverInfoValidation = () => {
    let fields = { ...this.state.editableRiderDetails };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    if (!fields.firstName.trim()) {
      errors.firstName = true;
      hasErrors = true;
    }
    if (!fields.lastName.trim()) {
      errors.lastName = true;
      hasErrors = true;
    }
    if (!fields.number.trim()) {
      errors.number = true;
      hasErrors = true;
    }
    if (fields.number.trim().length && fields.number.trim().length < 10) {
      errors.invalidPhoneNumber = true;
      hasErrors = true;
    }

    if (!fields.email.trim()) {
      errors.email = true;
      hasErrors = true;
    }

    if (
      fields.email.trim().length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields.email)
    ) {
      errors["emailInvalid"] = true;
      hasErrors = true;
    } else {
      errors["emailInvalid"] = false;
    }

    console.log("hasErrors ", hasErrors);
    console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  upcInfoValidation = (key) => {
    let fields = { ...this.state.newUpcData };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    if (!fields[key].trim()) {
      errors[key] = true;
      hasErrors = true;
    }
    this.setState({ errors: errors });
    return hasErrors;
  };

  componentDidMount() {
    let localStorageData = window.localStorage.upcAuthData;
    if (localStorageData && JSON.parse(localStorageData)) {
      if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
        JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
          each => {
            if (each.moduleName === "rider") {
              if (each.role === 2) {
                this.setState({
                  ...this.state,
                  editableMode: true,
                  loading: true
                });
              } else {
                this.setState({
                  ...this.state,
                  loading: true
                });
              }
            }
          }
        );
      } else {
        this.setState({
          ...this.state,
          editableMode: true,
          loading: true
        });
      }
    }
    this.props.fetchRider(this.props.match.params.riderId);
  }

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.detailsFetched &&
      Object.keys(this.props.data.riderReducer.riderDetails).length
    ) {
      console.log("inside ", this.props.data.riderReducer.riderDetails);
      let localRider = { ...this.state.riderDetails };
      localRider.firstName = this.props.data.riderReducer.riderDetails.personalInfo.firstName;
      localRider.lastName = this.props.data.riderReducer.riderDetails.personalInfo.lastName;
      localRider.fullName = this.props.data.riderReducer.riderDetails.personalInfo.fullName;
      localRider.profilePicture = this.props.data.riderReducer.riderDetails.personalInfo.profilePicture;
      localRider.email = this.props.data.riderReducer.riderDetails.personalInfo.email;
      localRider.phoneNumber =
        CountryCodesConfig.filter(item => {
          return (
            item.code ===
            this.props.data.riderReducer.riderDetails.personalInfo.phone
              .countryCode
          );
        })[0].dial_code +
        this.formatNumber(
          this.props.data.riderReducer.riderDetails.personalInfo.phone.number
        );
      localRider.countryCode = this.props.data.riderReducer.riderDetails.personalInfo.phone.countryCode;
      localRider.number = this.props.data.riderReducer.riderDetails.personalInfo.phone.number;
      localRider.displayId = this.props.data.riderReducer.riderDetails.displayId;
      localRider.accountStatus = this.props.data.riderReducer.riderDetails.accountStatus;
      localRider.createdAt = moment(
        this.props.data.riderReducer.riderDetails.createdAt
      ).format("MM-DD-YYYY");
      localRider.upcCode = this.props.data.riderReducer.riderDetails.upcBucketInfo.upcCode;
      localRider.usedUpcCode = this.props.data.riderReducer.riderDetails
        .upcBucketInfo.upcCodeUsed
        ? this.props.data.riderReducer.riderDetails.upcBucketInfo.upcCodeUsed
          .upcCode
        : "";
      localRider.upcCreditsEarned =
        this.props.data.riderReducer.riderDetails.upcBucketInfo &&
          this.props.data.riderReducer.riderDetails.upcBucketInfo.upcCreditsEarned
          ? this.props.data.riderReducer.riderDetails.upcBucketInfo.upcCreditsEarned.toFixed(
            2
          )
          : 0;
      if (
        this.props.data.riderReducer.riderDetails.upcBucketInfo.upcCreditsEarned
      ) {
        if (
          this.props.data.riderReducer.riderDetails.upcBucketInfo
            .upcCreditsSpent
        ) {
          localRider.upcCreditPresent = (
            this.props.data.riderReducer.riderDetails.upcBucketInfo
              .upcCreditsEarned -
            this.props.data.riderReducer.riderDetails.upcBucketInfo
              .upcCreditsSpent
          ).toFixed(2);
        } else {
          localRider.upcCreditPresent = this.props.data.riderReducer.riderDetails.upcBucketInfo.upcCreditsEarned.toFixed(
            2
          );
        }
      } else {
        localRider.upcCreditPresent = 0;
      }

      localRider.upcCodeUsedBy = this.props.data.riderReducer.riderDetails.upcBucketInfo.upcCodeUsedBy.length;
      if (
        this.props.data.riderReducer.riderDetails.ratingData &&
        Object.keys(this.props.data.riderReducer.riderDetails.ratingData)
          .length &&
        this.props.data.riderReducer.riderDetails.ratingData.total &&
        this.props.data.riderReducer.riderDetails.ratingData.count
      ) {
        localRider.avgRating = (
          this.props.data.riderReducer.riderDetails.ratingData.total /
          this.props.data.riderReducer.riderDetails.ratingData.count
        ).toFixed(1);
      } else {
        localRider.avgRating = 0;
      }

      localRider.completedTrips = this.props.data.riderReducer.riderDetails.tripInfo.completedTrips;
      localRider.requestedTrips = this.props.data.riderReducer.riderDetails.tripInfo.requestedTrips;
      localRider.canceledTrips = this.props.data.riderReducer.riderDetails.tripInfo.canceledTrips;
      localRider.upComingRequest =
        this.props.data.riderReducer.riderDetails.tripInfo.upComingRequest || 0;
      localRider.ongoingRides =
        this.props.data.riderReducer.riderDetails.tripInfo.ongoingRides || 0;
      localRider.favoriteDriversCount = this.props.data.riderReducer
        .riderDetails.otherInfo.favoriteDrivers
        ? this.props.data.riderReducer.riderDetails.otherInfo.favoriteDrivers
          .length
        : 0;

      let localData = { ...this.state.data };
      localData.data = this.props.data.riderReducer.riderDetails.otherInfo.favoriteDrivers;
      if (localData.data.length) {
        localData.data.forEach(each => {
          each.riderImage = each.personalInfo.profilePicture;
          each.contact =
            CountryCodesConfig.filter(item => {
              return item.code === each.personalInfo.phone.countryCode;
            })[0].dial_code + this.formatNumber(each.personalInfo.phone.number);
        });
      }

      localRider.businessInfo.additionalInfo = this.props.data.riderReducer.riderDetails.businessInfo ?
        this.props.data.riderReducer.riderDetails.businessInfo.additionalInfo : null;
      localRider.businessInfo.address = this.props.data.riderReducer.riderDetails.businessInfo ?
        this.props.data.riderReducer.riderDetails.businessInfo.address : null;
      localRider.businessInfo.email = this.props.data.riderReducer.riderDetails.businessInfo ?
        this.props.data.riderReducer.riderDetails.businessInfo.email : null;
      localRider.businessInfo.name = this.props.data.riderReducer.riderDetails.businessInfo ?
        this.props.data.riderReducer.riderDetails.businessInfo.name : null;
      localRider.businessInfo.website = this.props.data.riderReducer.riderDetails.businessInfo ?
        this.props.data.riderReducer.riderDetails.businessInfo.website : null;
      localRider.businessInfo.phone = this.props.data.riderReducer.riderDetails.businessInfo ?
        this.props.data.riderReducer.riderDetails.businessInfo.phone : null;

      this.setState({
        ...this.state,
        riderDetails: localRider,
        detailsFetched: true,
        data: localData,
        loading: false
      });
    }
    if (this.props.data.riderReducer.riderUpdated) {
      this.props.riderUpdatedDone();
      this.setState({
        ...this.state,
        detailsFetched: false,
        editUserModalOpen: false
      });
    }
    if (this.props.data.riderReducer.statusUpdated) {
      this.props.statusUpdatedDone();
      this.setState({
        ...this.state,
        detailsFetched: false,
        confirmationModalOpen: false
      });
    }
    if (this.props.data.riderReducer.upcUpdated) {
      this.props.upcUpdatedDone();
      this.setState({
        ...this.state,
        detailsFetched: false,
        addUpcCodeModalOpen: false,
        addUpcMoneyModalOpen: false,
        newUpcData: { addUpcCode: "", addUpcMoney: "" }
      });
    }

    if (
      this.props.data.riderReducer.upcData.length &&
      !this.state.fetchSuggestions
    ) {
      this.setState({
        ...this.state,
        upcSuggestions: this.props.data.riderReducer.upcData,
        fetchSuggestions: true,
        displaySuggestion: true
      });
    }
    if (
      !this.props.data.riderReducer.upcData.length &&
      this.state.fetchSuggestions
    ) {
      this.setState({
        ...this.state,
        upcSuggestions: this.props.data.riderReducer.upcData,
        fetchSuggestions: false,
        displaySuggestion: true
      });
    }
    // return;
  }

  componentWillUnmount() {
    this.props.resetDetails();
  }
  handleFavModalOpen = () => {
    this.setState({ favModalOpen: true });
  };
  handleFavModalClose = () => {
    this.setState({ favModalOpen: false });
  };

  saveHandler = () => {
    if (!this.driverInfoValidation()) {
      let requestData = {
        firstName: this.state.editableRiderDetails.firstName,
        lastName: this.state.editableRiderDetails.lastName,
        email: this.state.editableRiderDetails.email,
        phoneCountryCode: this.state.editableRiderDetails.countryCode.split(
          " ("
        )[0],
        phoneNumber: this.state.editableRiderDetails.number,
        profilePicture: this.state.editableRiderDetails.profilePicture
      };
      console.log("here ", requestData);
      let formData = new FormData();
      for (let name in requestData) {
        formData.append(name, requestData[name]);
      }

      // return;
      this.props.editRider(this.props.match.params.riderId, formData);
    }
  };

  saveUpcCodeHandler = () => {
    if (!this.upcInfoValidation('addUpcCode')) {
      let requestData = { ...this.state.newUpcData };
      requestData.riderId = this.props.match.params.riderId;
      console.log("this.state.selectedUpc ", this.state.selectedUpc);
      if (requestData.addUpcCode.trim().length) {
        if (
          !Object.keys(this.state.selectedUpc).length ||
          (Object.keys(this.state.selectedUpc).length &&
            this.state.selectedUpc.upcBucketInfo.upcCode !==
            requestData.addUpcCode)
        ) {
          this.props.showSnackbar("Invalid UPC code", 'error');
          return;
        }
      }

      if (Object.keys(this.state.selectedUpc).length) {
        requestData.code = requestData.addUpcCode;
        requestData.referredBy = this.state.selectedUpc._id;
        requestData.referredByType = this.state.selectedUpc.userType;
        delete requestData.addUpcCode;
      }
      // if (!requestData.addUpcCode.trim().length) {
      //   delete requestData.upcCodeReferredBy;
      //   delete requestData.upcCodeReferredByType;
      //   delete requestData.addUpcCode;
      // }
      console.log("upc ", requestData);

      // return;
      this.props.addUpc(requestData);
    }
  };

  saveUpcMoneyHandler = () => {
    if (!this.upcInfoValidation('addUpcMoney')) {
      let amount = this.state.newUpcData.addUpcMoney;
      console.log("upc ", amount);
      this.props.addUpcCredit(this.props.match.params.riderId, { amount });
    }
  };

  changeRiderStatus = () => {
    console.log("this.statusList ", this.statusList);
    let requestData = {};
    if (this.props.data.riderReducer.riderDetails.accountStatus === 1) {
      requestData.accountStatus = 2;
    } else {
      requestData.accountStatus = 1;
    }
    // return;
    this.props.changeRiderStatus(this.props.match.params.riderId, requestData);
  };

  mapCountryCode = (code) => {
    if (countries.find(item => item.code === code)) {
      return `${code} (${countries.find(item => item.code === code).dial_code})`;
    } else {
      return `${countries[0].code} (${countries[0].dial_code})`;
    }
  }

  handleModalOpen = () => {
    let localRider = {
      firstName: this.state.riderDetails.firstName,
      lastName: this.state.riderDetails.lastName,
      email: this.state.riderDetails.email,
      countryCode: this.mapCountryCode(this.state.riderDetails.countryCode),
      number: this.state.riderDetails.number,
      profilePicture: this.state.riderDetails.profilePicture
    };
    this.setState({
      editUserModalOpen: true,
      editableRiderDetails: localRider
    });
  };

  handleUpcCodeModalOpen = () => {
    this.setState({ addUpcCodeModalOpen: true });
  };

  handleUpcMoneyModalOpen = () => {
    this.setState({ addUpcMoneyModalOpen: true });
  };

  openConfirmationModal = () => {
    this.setState({ confirmationModalOpen: true });
  };
  closeConfirmationModal = () => {
    this.setState({ confirmationModalOpen: false });
  };

  handleUpcModalClose = () => {
    let localUpcData = { ...this.state.newUpcData };
    localUpcData.addUpcCode = "";
    this.setState({ addUpcCodeModalOpen: false, addUpcMoneyModalOpen: false, newUpcData: localUpcData });
  };

  handleModalClose = () => {
    this.setState({ editUserModalOpen: false });
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  };

  searchUPC = searchData => {
    this.props.searchUpc(searchData, this.props.match.params.riderId);
    // if (searchData.trim().length) {
    this.setState({
      ...this.state,
      displaySuggestion: true,
      fetchSuggestions: false
    });
    // }
  };

  selectUpc = suggestion => {
    let localUpcData = { ...this.state.newUpcData };
    localUpcData.addUpcCode = suggestion.upcBucketInfo.upcCode;
    this.setState({
      ...this.state,
      selectedUpc: { ...suggestion },
      displaySuggestion: false,
      newUpcData: localUpcData
    });
  };

  uploadLogo = e => {
    let localRiderData = { ...this.state.editableRiderDetails };
    localRiderData.profilePicture = e.target.files[0];
    this.setState({ ...this.state, editableRiderDetails: localRiderData });
  };

  resetPassword = () => {
    //sending password to email
    this.props.resetRiderPassword(this.props.match.params.riderId);
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        {!this.state.loading ? (
          <Grid container spacing={16}>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                  <Typography component="p">
                    <span className={classes.pageHeader}>
                      {this.props.data.literalsReducer.RiderDetails.PageHeader}
                    </span>
                    <span className={classes.PageDescription}>
                      {
                        this.props.data.literalsReducer.RiderDetails
                          .PageDescription
                      }
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.headerRightButton}
                    onClick={this.backToPrevPage}
                  >
                    <ArrowBackIosIcon className={classes.leftIcon} /> Back
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Paper style={{ width: "100%" }}>
                  <Grid item xs={12} sm={12} className={classes.rightPaper}>
                    <Grid item xs={12} sm={12}>
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={3} className={classes.riderLeft}>
                          <div className={classes.profileImagePlaceholder}>
                            {this.state.riderDetails.profilePicture ? (
                              <div
                                style={{
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  width: "100%",
                                  height: "100%",
                                  backgroundImage: `url(${this.state.riderDetails.profilePicture})`
                                }}
                              />
                            ) : (
                              <img
                                src={BlankProfileImage}
                                alt="profile-image"
                                style={{ width: "100%" }}
                              />
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={18} sm={6}>
                          <div className={classes.nameHeading}>
                            {this.state.riderDetails.fullName}
                          </div>
                          <div
                            className={classNames(
                              classes.description,
                              classes.userId
                            )}
                          >
                            ({this.state.riderDetails.displayId})
                          </div>
                          <div className={classes.description}>
                            <span
                              className={classes.activeStatus}
                              style={{ marginLeft: "0" }}
                            >
                              {
                                this.statusList[
                                this.state.riderDetails.accountStatus
                                ]
                              }
                            </span>
                          </div>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .MemberSince
                            }
                            -<span>{this.state.riderDetails.createdAt}</span>
                          </div>
                          <div
                            className={classNames(
                              classes.ratingShow,
                              classes.description
                            )}
                          >
                            <span>{this.state.riderDetails.avgRating}/5</span>
                            <StarRatings
                              rating={Number(this.state.riderDetails.avgRating)}
                              starRatedColor="#ffc000"
                              numberOfStars={5}
                              name="rating"
                              starDimension="20px"
                              starSpacing="2px"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={2} sm={3} >
                          <Button
                            size="large"
                            color="default"
                            variant="contained"
                            className={classNames(classes.customMargin, classes.activeGoButton)}
                            onClick={this.resetPassword}
                          >
                            Reset Password
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider variant="middle" style={{ margin: "25px 0" }} />
                    <Grid item xs={12} sm={12} className={classes.descSection}>
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={4}>
                          <h3>Basic Details</h3>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .PhoneNo
                            }{" "}
                            -
                            <span>
                              {this.state.riderDetails.phoneNumber || "N/A"}
                            </span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.RiderDetails.Email}{" "}
                            -
                            <span>
                              {this.state.riderDetails.email || "N/A"}
                            </span>
                          </div>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .UpcCode
                            }{" "}
                            -{" "}
                            <span>
                              {this.state.riderDetails.upcCode || "N/A"}
                            </span>
                          </div>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .NoOfPeople
                            }
                            -{" "}
                            <span>
                              {this.state.riderDetails.upcCodeUsedBy || "0"}
                            </span>
                          </div>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .TotalAmountMade
                            }
                            -{" "}
                            <span>
                              &#36;
                              {this.state.riderDetails.upcCreditsEarned || "0"}
                            </span>

                          </div>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .UpcMoneyPresent
                            }
                            -{" "}
                            <span>
                              &#36;
                              {this.state.riderDetails.upcCreditPresent || "0"}
                            </span>
                            <Button
                              size="small"
                              color="primary"
                              variant="contained"
                              className={classNames(
                                classes.addButton,
                                classes.activeGoButton
                              )}
                              onClick={this.handleUpcMoneyModalOpen}
                            >Add Ur PC money</Button>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h3>Trip/Req. Info</h3>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .NoOfCompletedTrips
                            }
                            -
                            <span>
                              {this.state.riderDetails.completedTrips || "0"}
                            </span>
                          </div>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .TotalNoOfRequests
                            }
                            -
                            <span>
                              {this.state.riderDetails.requestedTrips || "0"}
                            </span>
                          </div>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .NoOfCancellations
                            }
                            -
                            <span>
                              {this.state.riderDetails.canceledTrips || "0"}
                            </span>
                          </div>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .NoOfUpcomingRideRequests
                            }
                            -
                            <span>
                              {this.state.riderDetails.upComingRequest || "0"}
                            </span>
                          </div>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .NoOfOngoingRides
                            }
                            -
                            <span>
                              {this.state.riderDetails.ongoingRides || "0"}
                            </span>
                          </div>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .NoOfDriversfavorited
                            }
                            -
                            <span>
                              {this.state.riderDetails.favoriteDriversCount ||
                                "0"}
                              {this.state.riderDetails.favoriteDriversCount ? (
                                <span
                                  onClick={this.handleFavModalOpen}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon icon={faInfoCircle} />
                                </span>
                              ) : null}
                            </span>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <h3>Ur PC Code</h3>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .UpccodeLinked
                            }
                            -
                            <span>
                              {this.state.riderDetails.usedUpcCode || "None"}
                            </span>
                            {!this.state.riderDetails.usedUpcCode &&
                              this.state.editableMode ? (
                              <Button
                                size="small"
                                variant="contained"
                                className={classNames(
                                  classes.addButton,
                                  classes.activeGoButton
                                )}
                                onClick={this.handleUpcCodeModalOpen}
                              >
                                Add Ur PC Code
                              </Button>
                            ) : null}
                          </div>
                          {/* Business info */}
                          <div className={classes.verticalGap}></div>
                          <h3>Business Info</h3>
                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .AdditionalInfo
                            }
                            -
                            <span>
                              {this.state.riderDetails.businessInfo.additionalInfo || 'NA'}
                            </span>
                          </div>

                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .Address
                            }
                            -
                            <span>
                              {this.state.riderDetails.businessInfo.address || "NA"}
                            </span>
                          </div>

                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .Email
                            }
                            -
                            <span>
                              {this.state.riderDetails.businessInfo.email || "NA"}
                            </span>
                          </div>

                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .Name
                            }
                            -
                            <span>
                              {this.state.riderDetails.businessInfo.name || "NA"}
                            </span>
                          </div>

                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .PhoneNo
                            }
                            -
                            <span>
                              {replace(this.state.riderDetails.businessInfo.phone, '_', '-') || "NA"}
                            </span>
                          </div>

                          <div className={classes.description}>
                            {
                              this.props.data.literalsReducer.RiderDetails
                                .Website
                            }
                            -
                            <span>
                              {this.state.riderDetails.businessInfo.website || "NA"}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.editableMode ? (
                    <Grid item xs={12} sm={12}>
                      <div className={classes.actionContainer}>
                        <Button
                          size="large"
                          color="default"
                          variant="contained"
                          className={classes.customMargin}
                          onClick={this.openConfirmationModal}
                        >
                          {this.state.riderDetails.accountStatus === 1
                            ? "Suspend"
                            : "Activate"}
                        </Button>
                        <Button
                          size="large"
                          color="primary"
                          variant="contained"
                          className={classNames(
                            classes.customMargin,
                            classes.activeGoButton
                          )}
                          onClick={this.handleModalOpen}
                        >
                          Edit Rider
                        </Button>
                      </div>
                    </Grid>
                  ) : null}
                </Paper>
              </Grid>
            </Grid>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.favModalOpen}
              onClose={this.handleFavModalClose}
            >
              <div style={getModalStyle()} className={classes.modalPaper}>
                <Grid item xs={12} sm={12} className={classes.descSection}>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                      {Object.keys(this.state.data).length ? (
                        <CustomTable
                          rows={this.state.data}
                          headerFormat={this.state.headerFormat}
                          visibleColumns={this.state.visibleColumns}
                          redirect={this.redirect}
                          hideColumnDropdown={true}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Modal>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.editUserModalOpen}
              onClose={this.handleModalClose}
            >
              <div style={getModalStyle()} className={classes.modalPaper}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={classNames(
                    classes.descSection,
                    classes.modalContent
                  )}
                >
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.profileImagePlaceholder}>
                        {this.state.editableRiderDetails.profilePicture ? (
                          typeof this.state.editableRiderDetails
                            .profilePicture === "object" ? (
                            <div
                              style={{
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                width: "100%",
                                height: "100%",
                                backgroundImage: `url( ${window.URL.createObjectURL(
                                  this.state.editableRiderDetails.profilePicture
                                )} )`
                              }}
                            />
                          ) : (
                            <img
                              src={
                                this.state.editableRiderDetails.profilePicture
                              }
                              alt="image"
                              style={{ width: "100%", height: "100%" }}
                            />
                          )
                        ) : (
                          <img
                            src={BlankProfileImage}
                            alt="no-menu-image"
                            style={{ width: "100%" }}
                          />
                        )}
                      </div>
                      <div className={classes.profileImageUploaderContainer}>
                        <div
                          className={classes.uploadImageButtonFile}
                          onClick={e => {
                            this.refs["file-upload"].click();
                          }}
                        >
                          <input
                            ref={"file-upload"}
                            type="file"
                            style={{
                              display: "none"
                            }}
                            onChange={this.uploadLogo}
                          />
                          <FontAwesomeIcon
                            icon={faUpload}
                            style={{ fontSize: "30px", color: "#fff" }}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.inlineField}>
                        <TextField
                          id="rider-first-name"
                          label={
                            this.props.data.literalsReducer.AddRider.FirstName
                          }
                          variant="outlined"
                          className={classNames(classes.editableTextfield)}
                          name="firstName"
                          value={this.state.editableRiderDetails.firstName}
                          onChange={this.handleChange}
                          error={this.state.errors.firstName}
                        />
                        {this.state.errors.firstName ? (
                          <FormHelperText id="validation-error2">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}

                        <TextField
                          id="rider-first-name"
                          label={
                            this.props.data.literalsReducer.AddRider.LastName
                          }
                          variant="outlined"
                          className={classNames(classes.editableTextfield)}
                          name="lastName"
                          value={this.state.editableRiderDetails.lastName}
                          onChange={this.handleChange}
                          error={this.state.errors.lastName}
                        />
                        {this.state.errors.lastName ? (
                          <FormHelperText id="validation-error2">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </div>
                      <div className={classes.inlineField}>
                        <TextField
                          id="select-country"
                          select
                          variant="outlined"
                          className={classes.textField}
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                          name="countryCode"
                          value={this.state.editableRiderDetails.countryCode}
                          onChange={this.handleChange}
                        >
                          {countries.map((option, index) => (
                            <option
                              key={index}
                              value={`${option.code} (${option.dial_code})`}
                            >
                              {`${option.code} (${option.dial_code})`}
                            </option>
                          ))}
                        </TextField>
                        <TextField
                          id="rider-phone-no"
                          label={
                            this.props.data.literalsReducer.AddRider.PhoneNo
                          }
                          variant="outlined"
                          fullWidth
                          className={classNames(
                            classes.editableTextfield,
                            classes.textField
                          )}
                          name="number"
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          inputProps={{
                            minLength: 10,
                            maxLength: 10
                          }}
                          value={this.state.editableRiderDetails.number}
                          onChange={this.handleChange}
                          error={
                            this.state.errors.number ||
                            this.state.errors.invalidPhoneNumber
                          }
                        />
                        {this.state.errors.number ? (
                          <FormHelperText
                            id="validation-error2"
                            style={{ marginLeft: "33%" }}
                          >
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                        {this.state.errors.invalidPhoneNumber &&
                          !this.state.errors.number ? (
                          <FormHelperText
                            id="validation-error2"
                            style={{ marginLeft: "33%" }}
                          >
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .InvalidPhoneNumber
                            }
                          </FormHelperText>
                        ) : null}
                      </div>
                      <TextField
                        id="rider-email"
                        label={this.props.data.literalsReducer.AddRider.Email}
                        variant="outlined"
                        fullWidth
                        type="email"
                        className={classes.editableTextfield}
                        name="email"
                        onChange={this.handleChange}
                        value={this.state.editableRiderDetails.email}
                        error={
                          this.state.errors.email ||
                          this.state.errors.emailInvalid
                        }
                      />
                      {this.state.errors.email ? (
                        <FormHelperText id="validation-error2">
                          {
                            this.props.data.literalsReducer.ValidationErrors
                              .Required
                          }
                        </FormHelperText>
                      ) : null}
                      {!this.state.errors.email &&
                        this.state.errors.emailInvalid ? (
                        <FormHelperText id="validation-error">
                          {
                            this.props.data.literalsReducer.ValidationErrors
                              .InvalidEmail
                          }
                        </FormHelperText>
                      ) : null}
                      <div style={{ textAlign: "right" }}>
                        <Button
                          size="small"
                          color="default"
                          variant="contained"
                          className={classes.customMargin}
                          onClick={this.handleModalClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          style={{ marginLeft: "10px" }}
                          size="small"
                          color="primary"
                          variant="contained"
                          className={classNames(
                            classes.customMargin,
                            classes.activeGoButton
                          )}
                          onClick={this.saveHandler}
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Modal>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.addUpcCodeModalOpen}
              onClose={this.handleUpcModalClose}
            >
              <div style={getModalStyle()} className={classes.modalPaper}>
                <Grid item xs={12} sm={12} className={classes.descSection}>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                      <p className={classes.description}>Add Ur PC Code</p>
                      <div className={classes.description}>
                        <TextField
                          id="add-upc-code"
                          label="Add Ur PC Code"
                          variant="outlined"
                          fullWidth
                          name="addUpcCode"
                          value={this.state.newUpcData.addUpcCode}
                          onChange={this.handleUpcChange}
                          error={this.state.errors.addUpcCode}
                        />
                        {this.state.errors.addUpcCode ? (
                          <FormHelperText id="validation-error2">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                        {this.props.data.riderReducer.upcData.length &&
                          this.state.displaySuggestion ? (
                          <div className={classes.suggestionList}>
                            <ScrollArea
                              speed={0.8}
                              className={classes.scrollContent}
                              contentClassName="content"
                              horizontal={false}
                            >
                              {this.props.data.riderReducer.upcData.map(
                                (suggestion, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={classes.suggestionItem}
                                      onClick={() => this.selectUpc(suggestion)}
                                    >
                                      {suggestion.personalInfo.fullName} (
                                      {userType[suggestion.userType]}) -{" "}
                                      {suggestion.upcBucketInfo.upcCode}
                                    </div>
                                  );
                                }
                              )}
                            </ScrollArea>
                          </div>
                        ) : null}
                      </div>

                      <div style={{ textAlign: "right" }}>
                        <Button
                          size="small"
                          color="default"
                          variant="contained"
                          className={classes.customMargin}
                          onClick={this.handleUpcModalClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          style={{ marginLeft: "10px" }}
                          size="small"
                          color="primary"
                          variant="contained"
                          className={classNames(
                            classes.customMargin,
                            classes.activeGoButton
                          )}
                          onClick={this.saveUpcCodeHandler}
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Modal>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.addUpcMoneyModalOpen}
              onClose={this.handleUpcModalClose}
            >
              <div style={getModalStyle()} className={classes.modalPaper}>
                <Grid item xs={12} sm={12} className={classes.descSection}>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                      <p className={classes.description}>Add Ur PC Money</p>
                      <div className={classes.description}>
                        <TextField
                          id="add-upc-code"
                          variant="outlined"
                          fullWidth
                          name="addUpcMoney"
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          value={this.state.newUpcData.addUpcMoney}
                          onChange={this.handleUpcChange}
                          error={this.state.errors.addUpcMoney}
                        />
                        {this.state.errors.addUpcMoney ? (
                          <FormHelperText id="validation-error2">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}

                        {this.state.errors.invalidUpcMoney ? (
                          <FormHelperText id="validation-error2">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .InvalidUpcMoney
                            }
                          </FormHelperText>
                        ) : null}
                      </div>

                      <div style={{ textAlign: "right" }}>
                        <Button
                          size="small"
                          color="default"
                          variant="contained"
                          className={classes.customMargin}
                          onClick={this.handleUpcModalClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          style={{ marginLeft: "10px" }}
                          size="small"
                          color="primary"
                          variant="contained"
                          className={classNames(
                            classes.customMargin,
                            classes.activeGoButton
                          )}
                          onClick={this.saveUpcMoneyHandler}
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Modal>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.confirmationModalOpen}
              onClose={this.closeConfirmationModal}
            >
              <div style={getModalStyle()} className={classes.modalPaper}>
                <Grid item xs={12} sm={12} className={classes.descSection}>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                      <h2>Change Rider Status</h2>
                      <div
                        className={classes.description}
                        style={{ marginBottom: "20px" }}
                      >
                        Are you sure you want to change the status of the rider?
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Button
                          size="small"
                          color="default"
                          variant="contained"
                          className={classes.customMargin}
                          onClick={this.closeConfirmationModal}
                        >
                          Cancel
                        </Button>
                        <Button
                          style={{ marginLeft: "10px" }}
                          size="small"
                          color="primary"
                          variant="contained"
                          className={classNames(
                            classes.customMargin,
                            classes.activeGoButton
                          )}
                          onClick={this.changeRiderStatus}
                        >
                          Yes
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Modal>
          </Grid>
        ) : (
          <div
            style={{
              textAlign: "center",
              padding: "10px",
              backgroundColor: "#fff",
              borderRadius: "10px"
            }}
          >
            {this.props.data.literalsReducer.Common.Loading}
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchRider: id => dispatch(fetchRider(id)),
    editRider: (id, payload) => dispatch(editRider(id, payload)),
    changeRiderStatus: (id, payload) =>
      dispatch(changeRiderStatus(id, payload)),
    riderUpdatedDone: () => dispatch(riderUpdatedDone()),
    statusUpdatedDone: () => dispatch(statusUpdatedDone()),
    upcUpdatedDone: () => dispatch(upcUpdatedDone()),
    resetDetails: () => dispatch(resetDetails()),
    searchUpc: (payload, id) => dispatch(searchUpc(payload, id)),
    addUpc: payload => dispatch(addUpc(payload)),
    addUpcCredit: (id, payload) => dispatch(addUpcCredit(id, payload)),
    resetRiderPassword: (id, payload) => dispatch(resetRiderPassword(id, payload)),
    showSnackbar: (message, variant) => dispatch(showSnackbar(message, variant))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...modalStyles(theme),
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(RiderDetails)
);
