import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
const google = window.google;
let map;
let polygon;

const styles = theme => ({});

class Map extends Component {
  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.props.zonePlotted &&
      this.props.zoneList &&
      this.props.zoneList.length &&
      this.props.frmPage === "operational"
    ) {
      this.initMap();
      this.setZone();
    }
    if (this.props.frmPage === "ongoingTripDetail" && !this.props.zonePlotted) {
      this.initMap();
      this.setDirection();
    }
    if (this.props.frmPage === "scheduleTrip" && (prevProps.origin.lat != this.props.origin.lat
      || prevProps.origin.lng != this.props.origin.lng
      || prevProps.destination.lat != this.props.destination.lat
      || prevProps.destination.lng != this.props.destination.lng)) {
      this.initMap();
      this.setDirection();
    }
  }
  setDirection = () => {
    let directionsService = new google.maps.DirectionsService();
    let directionsDisplay = new google.maps.DirectionsRenderer();
    let centerLocation = new google.maps.LatLng(this.props.origin.lat, this.props.origin.lng);
    let mapOptions = {
      zoom: 7,
      center: centerLocation
    }
    let map = new google.maps.Map(document.getElementById('map'), mapOptions);
    directionsDisplay.setMap(map);
    let request = {
      origin: this.props.origin,
      destination: this.props.destination,
      travelMode: 'DRIVING'
    };
    directionsService.route(request, (response, status) => {
      if (status == 'OK') {
        directionsDisplay.setDirections(response);
        this.props.setDirections(response);
      }
    });
    this.props.plotZone();
  }
  setZone() {
    if (this.props.zoneList && this.props.zoneList.length) {
      this.drawPolygon([...this.props.zoneList]);
    }
    if (this.props.currentZone && Object.keys(this.props.currentZone).length) {
      let bounds = this.polygonCenter(this.props.currentZone.coordinates);
      let cent = bounds.getCenter();
      map.setCenter({
        lat: cent.lat(),
        lng: cent.lng()
      });
      map.fitBounds(bounds);
      map.setZoom(7);
    }
    this.props.plotZone();
  }

  initMap() {

    let center = {
      lat: -34.397,
      lng: 150.644
    };

    if (this.props.frmPage === "ongoingTripDetail") {
      center = {
        lat: this.props.origin.lat,
        lng: this.props.origin.lng
      };
    }

    map = new google.maps.Map(document.getElementById("map"), {
      center: center
    });

    map.setZoom(1);

    // if (this.props.createPolygon) {
    //   this.initAddPolygon();
    // }
  }


  polygonCenter(path) {
    var bounds = new google.maps.LatLngBounds();
    var i;

    // The Bermuda Triangle

    var polygonCoords = [];

    path.forEach(function (each) {
      polygonCoords.push(new google.maps.LatLng(each.lat, each.lng));
    });

    for (i = 0; i < polygonCoords.length; i++) {
      bounds.extend(polygonCoords[i]);
    }

    return bounds;
  }

  initAddPolygon() {
    let self = this;
    let drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: "polygon",
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["polygon"],
        editable: true
      }
    });
    drawingManager.setMap(map);
    google.maps.event.addListener(drawingManager, "polygoncomplete", function (
      newPolygon
    ) {
      let path = newPolygon.getPath();
      let coordinates = [];

      for (let i = 0; i < path.length; i++) {
        coordinates.push({
          lat: path.getAt(i).lat(),
          lng: path.getAt(i).lng()
        });
      }
      coordinates.push(coordinates[0]);
      // self.props.getCoordinates(coordinates);
    });
  }

  drawPolygon(zones) {
    let self = this;
    zones.forEach(function (each) {
      let bermudaTriangle = new google.maps.Polygon({
        paths: each.coordinates,
        strokeColor: "#FF0000",
        id: each._id,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        editable: self.props.editableMode,
        suppressUndo: true
      });

      google.maps.event.addListener(
        bermudaTriangle.getPath(),
        "set_at",
        function (index, obj) {
          let coordinates = [];
          bermudaTriangle
            .getPath()
            .getArray()
            .forEach(each => {
              coordinates.push([each.lng(), each.lat()]);
            });
          self.props.getCoordinates(coordinates, bermudaTriangle.id);
        }
      );
      bermudaTriangle.setMap(map);
    });
  }

  render() {
    return <div id="map" />;
  }
}

export default withStyles(styles)(Map);
