import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
const styles = theme => ({
  root: {
    width: "300px",
    backgroundColor: theme.palette.background.paper,
    maxHeight: "300px",
    overflowY: "auto",
    marginTop: "10px",
    "& a": {
      textDecoration: "none"
    },
    "& li": {
      padding: "5px 16px"
    }
  },
  notficationHeader: {
    display: "inline",
    marginRight: "5px"
  },
  readNotfication: {
    color: "#a8a8a8"
  },
  unreadNotification: {
    color: "#eb5c00"
  },
  createdAt: {
    color: "#a8a8a8",
    display: "block",
    marginTop: "0px",
    fontSize: "11px"
  }
});


function AlignItemsList(props) {
  const { classes } = props;
  
  return (
    <List className={classes.root}>
      {props.data.map((notification, index) => {
        let notificationItem = notification;
   
        return (
          <ListItem alignItems="flex-start" key={index}>
            <Link to={notification.to} key={index} onClick={()=>props.listItemClick(notification)}>
              {notification.read ? (
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        className={classes.readNotfication}
                      >
                        {notification.content}
                      </Typography>
                      <Typography
                        component="span"
                        className={classes.createdAt}
                      >
                        {notification.createdAt}
                      </Typography>
                    </React.Fragment>
                  }
                />
              ) : (
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        className={classes.unreadNotification}
                      >
                        {notification.content}
                      </Typography>
                      <Typography
                        component="span"
                        className={classes.createdAt}
                      >
                        {notification.createdAt}
                      </Typography>
                    </React.Fragment>
                  }
                />
              )}
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
}

AlignItemsList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AlignItemsList);
