const styles = theme => ({
  textField: {
    marginBottom: theme.spacing.unit
  },
  halfField: {
    width: "100%"
  },
  selectField: {
    margin: theme.spacing.unit,
    marginTop: 0
  },
  optionTextField: {
    margin: theme.spacing.unit,
    marginTop: 0,
    width: "90%"
  },
  riderLeft: {
    position: "relative"
  },
  rightPaper: {
    padding: "32px 38px !important",
    marginTop: "initial",
    marginBottom: "initial"
  },
  title: {
    fontWeight: "400",
    fontSize: "22px",
    marginTop: "10px",
    marginBottom: "10px"
  },
  description: {
    fontWeight: 400,
    margin: "0 0 10px 0",
    fontSize: "14px",
    "& span": {
      marginLeft: "10px",
      color: "rgba(0, 0, 0, 0.7)",
      fontSize: "15px",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif"
    }
  },
  chip: {
    marginLeft: "10px",
    "& span": {
      marginLeft: "0"
    }
  },
  activeStatus: {
    color: "#69af00 !important"
  },
  inActiveStatus: {
    color: "#b9331b !important"
  },
  ongoingStatus: {
    color: "#ea5a01 !important"
  },
  profileImageUploaderContainer: {
    position: "absolute",
    top: "32px",
    width: "170px",
    height: "170px"
  },
  profileImageWrap: {
    marginBottom: "15px",
    "&> div": {
      marginTop: "0",
      display: "inline-block",
      verticalAlign: "middle"
    },
    "& p": {
      display: "inline-block",
      verticalAlign: "middle",
      margin: "0",
      marginLeft:"10px",
      "& span": {
        display: "block",
        marginBottom: "5px",
        fontWeight: "300",
        "&:first-child": {
          fontWeight: "500",
        }
      }
    }
  },
  profileImagePlaceholder: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    fontSize: "14px",
    borderRadius: "100%",
    overflow: "hidden",
    background: "#dadada",
    width: "80px",
    height: "80px",
    marginTop: "20px",
    boxShadow: "inset 0 0px 1px 2px rgb(206, 206, 206)",
    border: "1px solid #e6e6e6"
  },
  uploadImageButtonFile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    position: "absolute",
    bottom: "0",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    borderRadius: "100%",
    padding: "8px",
    textAlign: "center",
    transition: "all 0.2s ease-in",
    "&>img": {
      verticalAlign: "-webkit-baseline-middle"
    },
    "&>span": {
      paddingLeft: "8px",
      fontSize: "12px",
      verticalAlign: "sub"
    },
    "& svg": {
      display: "none"
    },
    "&:hover": {
      background: "rgba(0,0,0,0.3)"
    },
    "&:hover svg": {
      display: "block"
    }
  },
  actionContainer: {
    padding: "20px 48px",
    borderTop: "1px solid #ccc",
    textAlign: "right",
    "&>Button": {
      textTransform: "capitalize",
      "&:first-child": {
        marginRight: "12px"
      }
    }
  },
  cancel: {
    height: "36px",
    width: "36px",
    background: "#eee",
    padding: 0,
    minWidth: "32px",
    borderRadius: 0,
    float: "right",
    top: "12px",
    right: "4px",
    "&:hover": {
      background: "#ddd"
    }
  },
  root: {
    flexGrow: 1,
    "&:before": {
      borderColor: "#fff"
    },
    "&:after": {
      borderColor: "#fff"
    }
  },
  select: {
    "&:before": {
      borderColor: "#fff"
    },
    "&:after": {
      borderColor: "#fff"
    }
  },
  input: {
    display: "flex",
    padding: 0
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  selectCategoryWrapper: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginTop: "10px",
    height: "60px"
  },
  colorSwitchBase: {
    color: "#EB5C00",
    "&$colorChecked": {
      color: "#EB5C00",
      "& + $colorBar": {
        backgroundColor: "#EB5C00"
      }
    }
  },
  colorView: {
    width: "15px",
    display: "inline-block",
    height: "14px",
    verticalAlign: "middle",
    borderRadius: "3px"
  },
  colorBar: {},
  colorChecked: {},
  toggleText: {
    color: "#777",
    fontSize: "16px"
  },
  editableTextfield: {
    display: "inline",
    border: "none",
    marginLeft: "10px",
    "& > div:before": {
      borderBottom: "none"
    },
    "& > div:hover:before, & > div:focus:before, & > div:after": {
      borderBottom: "none !important"
    },
    "& input": {
      padding: "0"
    }
  },
  nameHeading: {
    display: "block",
    marginLeft: "0",
    "&>div": {
      fontSize: "30px",
      fontWeight: "600"
    }
  },
  ratingShow: {
    display: "inline-block",
    "& svg": {
      color: "#dadada"
    }
  },
  lists: {
    display: "block",
    padding: "15px 0px 15px 0",
    color: "#a8a8a8",
    fontSize: "14px",
    minHeight: "50px",
    lineHeight: "20px",
    "& span": {
      color: "#000",
      marginRight: "5px",
      fontWeight: "500"
    },
    "& div": {
      marginBottom: "0"
    }
  },
  searchLists: {
    "& $profileImageWrap": {
      marginBottom: "0",
      "& p": {
        fontSize: "14px",
        "& span": {
          fontWeight: "400"
        }
      },
      "& $profileImagePlaceholder": {
        width: "50px",
        height: "50px",
      }
    }
  },
  listRightPart: {
    margin: "0",
    position: "absolute",
    top: "50%",
    right: "0",
    textAlign: "right",
    transform: "translateY(-50%)",
    "& span": {
      display: "block",
      marginRight: "0",
      fontSize: "14px"
    },
    "& button": {
      marginTop: "10px",
      "& span": {
        color: "#fff"
      }
    }
  },
  liDivider: {
    margin: "0"
  },
  linkElem: {
    display: "block",
    marginTop: "20px"
  },
  
  tabPan: {
    "& span": {
      fontSize: "18px",
      fontWeight: "500",
      textTransform: "initial"
    }
  },
  breakDownPart: {
    padding: "0 20px",
    borderLeft: "1px solid #e1e1e1"
  },
  breakDownPartAction: {
    "& button": {
      marginRight: "5px"
    }
  }
});

export default styles;
