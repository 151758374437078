import {
  FETCH_TRIP_SUCCESS,
  FETCH_TRIP,
  FETCH_TRIP_FAIL,
  RESET_TRIP_DETAILS,
  SEARCH_DRIVER_SUCCESS,
  ASSIGN_DRIVER_SUCCESS,
  DRIVER_ASSIGNED_DONE
} from "../constants/action-types";

const initialState = {
  tripDetails: {},
  isFetching: false,
  driverAssigned: false,
  driverList: []
};

const assignDriverReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRIP:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_TRIP_SUCCESS:
      return {
        ...state,
        tripDetails: action.payload.data,
        isFetching: false
      };
    case FETCH_TRIP_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case RESET_TRIP_DETAILS:
      return {
        ...state,
        tripDetails: {}
      };
    case SEARCH_DRIVER_SUCCESS:
      return {
        ...state,
        driverList: action.payload.data
      };
    case ASSIGN_DRIVER_SUCCESS:
      return {
        ...state,
        driverAssigned: true
      };
    case DRIVER_ASSIGNED_DONE:
      return {
        ...state,
        driverAssigned: false
      };
    default:
      return state;
  }
};

export default assignDriverReducer;
