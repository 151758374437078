import {
    GET_DASHBOARD_DETAILS_SUCCESS,
} from "../constants/action-types";

import {AUTH_DATA} from "../../config/local-storage-keys";

const initialState = {
    dashBoardDetails: {},
    
};

const dashBoardAPIData = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DETAILS_SUCCESS:
            return {
                ...state, 
                dashBoardDetails: action.payload.data
            };
        default:
            return state;
    }
};

export default dashBoardAPIData;