import 'date-fns';
import React, { Component } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker, InlineDatePicker } from 'material-ui-pickers';
import reactCSS from 'reactcss';
import keycode from 'keycode';
import { connect } from "react-redux";
import classNames from "classnames";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../../app/theme";
import Modal from '@material-ui/core/Modal';
import DemoUserImage from "../../../../assets/images/John-Doe.jpg";
import PlaceholderImage from "../../../../assets/images/960-750.jpg";
import contentStyles from "../../../auth-layout/content.styles";
import styles from "./styles";
import globalstyles from "../../../../globalstyles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import CustomTable from "../../../../components/CustomTable";
import CountryCodesConfig from "../../../../config/country-codes";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import moment from "moment";
import StarRatings from "react-star-ratings";
import {
  fetchDriver,
  resetDetails,
  changeDriverStatus,
  statusUpdatedDone,
  resetDriverPassword
} from "../../../../store/actions/driver";

import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faStar,
  faStarHalfAlt,
  faUpload,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faStar, faStarHalfAlt, faUpload, faInfoCircle);

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

const modalStyles = theme => ({
  modalPaper: {
    position: 'absolute',
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  modalPaperLarge: {
    width: theme.spacing.unit * 150,
    overflowY: 'scroll',
    height: '90%'
  }
});

const countries = CountryCodesConfig;

class DriverDetails extends Component {

  state = {
    editableMode: false,
    selectedClosedDate: {
      date: new Date(),
      reason: ""
    },
    editUserModalOpen: false,
    favModalOpen: false,
    documentModalOpen: false,
    detailsFetched: false,
    inputValue: '',
    selectedItem: [],
    cartype: '1',
    driverDetails: {
      id: "",
      displayId: "",
      fullName: "",
      email: "",
      dob: "",
      profilePicture: "",
      createdAt: "",
      phoneNumber: "",
      accountStatus: 1,
      upcCode: "",
      avgRating: 0,
      vehicleType: "",
      vehicleColor: "",
      vehicleMake: "",
      vehicleModel: "",
      vehicleRegistrationNo: "",
      vehicleModalYear: "",
      rateChartType: "",
      languages: "",
      address: "",
      city: "",
      zip: "",
      state: "",
      upcCodeUsedBy: 0,
      upcIncome: 0,
      completedTrips: 0,
      requestRejectIgnored: 0,
      requestAssignedByAdmin: 0,
      upcomingRequest: 0,
      ridersFavorite: 0,
      stripeInfo: "",
      license: "",
      insurance: "",
      registrationSticker: "",
      limoSticker: "",

    },
    single: null,
    confirmationModalOpen: false,
    labelWidth: 0,
    selectedDate: new Date('2014-08-18T21:11:54'),
    menu: {
      dishName: "",
      dishDescription: "",
      price: 0,
      available: false,
      isVeg: false,
      topItem: false,
      preparationTime: 0,
      menuImage: "",
      addOns: []
    },
    errors: {},
    haveImage: false,
    data: {
      total: 1,
      data: []
    },
    visibleColumns: [
      "Image",
      "Name",
      "Contact No.",
    ],
    headerFormat: {
      riderImage: {
        head: "Image",
        key: "riderImage",
        isImage: true
      },
      "personalInfo.fullName": {
        head: "Name",
        key: ["personalInfo", "fullName"],
        populate: true
      },
      contact: {
        head: "Contact No.",
        key: "contact"
      }
    },
    displayColorPicker: false,
    color: {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    }
  };

  operatorTypes = [{
    head: "Select operator",
    key: -1
  },
  {
    head: "OR (Radio button)",
    key: 1
  },
  {
    head: "AND (Checkbox button)",
    key: 2
  }
  ];

  addOnData = {
    name: "",
    single: this.operatorTypes[0].key,
    options: []
  };

  optionData = {
    name: "",
    price: ""
  };

  statusList = {
    1: "Active",
    2: "Inactive",
    3: "Expired"
  };

  cartypes = {
    1: "Sedan",
    2: "SUV"
  }

  handleKeyDown = event => {
    const { inputValue, selectedItem } = this.state;
    if (selectedItem.length && !inputValue.length && keycode(event) === 'backspace') {
      this.setState({
        selectedItem: selectedItem.slice(0, selectedItem.length - 1),
      });
    }
  };

  changeDriverModalStatus = () => {
    let requestData = {};
    if (this.props.data.driverReducer.driverDetails.accountStatus === 1) {
      requestData.accountStatus = 2;
    } else {
      requestData.accountStatus = 1;
    }
    // return;
    this.props.changeDriverStatus(this.props.match.params.driverId, requestData);
  };


  handleInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleLanguageChange = item => {
    let { selectedItem } = this.state;

    if (selectedItem.indexOf(item) === -1) {
      selectedItem = [...selectedItem, item];
    }

    this.setState({
      inputValue: '',
      selectedItem,
    });
  };

  handleDelete = item => () => {
    this.setState(state => {
      const selectedItem = [...state.selectedItem];
      selectedItem.splice(selectedItem.indexOf(item), 1);
      return { selectedItem };
    });
  };

  handleCarTypeChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClosedDateChange = date => {
    let newClosedDate = { ...this.state.selectedClosedDate };
    newClosedDate.date = date;
    this.setState({ ...this.state, selectedClosedDate: newClosedDate });
  };



  handleAutoSearchChange = name => value => {
    this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleChange = event => {
    let menu = this.state.menu;
    if (event.target.name === "price" || event.target.name === "category") {
      menu[event.target.name] = Number(event.target.value);
    } else {
      menu[event.target.name] = event.target.value;
    }
    this.setState({
      ...this.state,
      menu
    });
  };

  menuValidation = () => {
    let error = false;
    let errors = {
      ...this.state.errors
    };

    for (let item in this.state.menu) {
      error = false;
      if (!this.state.menu[item] && item !== "menuImage") {
        error = true;
      }
      errors[item] = error;
    }
    this.setState({
      ...this.state,
      errors: {
        ...errors
      }
    });
    if (!Object.keys(errors).some(each => errors[each])) {
      return true;
    } else {
      return false;
    }
  };

  componentDidMount() {
    let localStorageData = window.localStorage.upcAuthData;
    if (localStorageData && JSON.parse(localStorageData)) {
      if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
        JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
          each => {
            if (each.moduleName === "driver") {
              if (each.role === 2) {
                this.setState({
                  ...this.state,
                  editableMode: true,
                  loading: true
                });
              } else {
                this.setState({
                  ...this.state,
                  loading: true
                });
              }
            }
          }
        );
      } else {
        this.setState({
          ...this.state,
          editableMode: true,
          loading: true
        });
      }
    }
    this.props.fetchDriver(this.props.match.params.driverId)
  }

  redirect = data => {
    console.log('data ', data);
    this.props.history.push("/web/auth/riders/details/" + data._id);
  };

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.detailsFetched &&
      Object.keys(this.props.data.driverReducer.driverDetails).length
    ) {
      let localDriverdata = { ...this.state.driverDetails };
      localDriverdata.displayId = this.props.data.driverReducer.driverDetails.displayId;
      localDriverdata.fullName = this.props.data.driverReducer.driverDetails.personalInfo.fullName;
      localDriverdata.email = this.props.data.driverReducer.driverDetails.personalInfo.email;

      localDriverdata.dob = moment(
        this.props.data.driverReducer.driverDetails.personalInfo.dob
      ).format("MM-DD-YYYY");
      localDriverdata.createdAt = moment(
        this.props.data.driverReducer.driverDetails.createdAt
      ).format("MM-DD-YYYY");
      localDriverdata.phoneNumber =
        CountryCodesConfig.filter(item => {
          return (
            item.code ===
            this.props.data.driverReducer.driverDetails.personalInfo.phone
              .countryCode
          );
        })[0].dial_code +
        this.formatNumber(this.props.data.driverReducer.driverDetails.personalInfo.phone.number);
      localDriverdata.accountStatus = this.props.data.driverReducer.driverDetails.accountStatus;
      localDriverdata.upcCode = this.props.data.driverReducer.driverDetails.upcBucketInfo.upcCode;
      localDriverdata.upcCodeUsedBy = this.props.data.driverReducer.driverDetails.upcBucketInfo.upcCodeUsedBy;
      localDriverdata.upcIncome = this.props.data.driverReducer.driverDetails.upcBucketInfo.upcIncome ? (this.props.data.driverReducer.driverDetails.upcBucketInfo.upcIncome).toFixed(2) : 0;
      localDriverdata.profilePicture = this.props.data.driverReducer.driverDetails.personalInfo.profilePicture;
      localDriverdata.avgRating = this.props.data.driverReducer.driverDetails.ratingData && this.props.data.driverReducer.driverDetails.ratingData.total ?
        (this.props.data.driverReducer.driverDetails.ratingData.total / this.props.data.driverReducer.driverDetails.ratingData.count).toFixed(1) : 0;
      localDriverdata.vehicleType = this.props.data.driverReducer.driverDetails.vehicleInfo.vehicleType;
      localDriverdata.vehicleColor = this.props.data.driverReducer.driverDetails.vehicleInfo.vehicleColor;
      localDriverdata.vehicleMake = this.props.data.driverReducer.driverDetails.vehicleInfo.vehicleMake;
      localDriverdata.vehicleModel = this.props.data.driverReducer.driverDetails.vehicleInfo.vehicleModel;
      localDriverdata.vehicleRegistrationNo = this.props.data.driverReducer.driverDetails.vehicleInfo.vehicleRegistrationNo;
      localDriverdata.vehicleModalYear = this.props.data.driverReducer.driverDetails.vehicleInfo.vehicleModalYear;
      localDriverdata.rateChartType = this.props.data.driverReducer.driverDetails.rateChartType;
      localDriverdata.languages = this.props.data.driverReducer.driverDetails.settings.languageSpoken;
      localDriverdata.address = this.props.data.driverReducer.driverDetails.personalInfo.address.line;
      localDriverdata.city = this.props.data.driverReducer.driverDetails.personalInfo.address.city;
      localDriverdata.zip = this.props.data.driverReducer.driverDetails.personalInfo.address.zip;
      localDriverdata.state = this.props.data.driverReducer.driverDetails.personalInfo.address.state;
      localDriverdata.completedTrips = this.props.data.driverReducer.driverDetails.tripInfo.completedTrips;
      localDriverdata.requestRejectIgnored = this.props.data.driverReducer.driverDetails.tripInfo.requestRejectIgnored || 0;
      localDriverdata.requestAssignedByAdmin = this.props.data.driverReducer.driverDetails.tripInfo.requestAssignedByAdmin;
      localDriverdata.upcomingRequest = this.props.data.driverReducer.driverDetails.tripInfo.upcomingRequest || 0;
      localDriverdata.ridersFavorite = this.props.data.driverReducer.driverDetails.tripInfo.favoriteList
        && this.props.data.driverReducer.driverDetails.tripInfo.favoriteList.data ? this.props.data.driverReducer.driverDetails.tripInfo.favoriteList.data.length : 0;
      localDriverdata.stripeInfo = this.props.data.driverReducer.driverDetails.paymentInfo.payment[0].id;
      localDriverdata.license = this.props.data.driverReducer.driverDetails.documents.license;
      localDriverdata.licenseExpirationDate = this.props.data.driverReducer.driverDetails.documents.licenseExpirationDate ? moment(
        this.props.data.driverReducer.driverDetails.documents.licenseExpirationDate
      ).format("MM-DD-YYYY") : 'Not available';
      localDriverdata.limoSticker = this.props.data.driverReducer.driverDetails.documents.limoSticker;

      localDriverdata.limoStickerExpirationDate = this.props.data.driverReducer.driverDetails.documents.limoStickerExpirationDate ? moment(
        this.props.data.driverReducer.driverDetails.documents.limoStickerExpirationDate
      ).format("MM-DD-YYYY") : 'Not available';
      localDriverdata.registrationSticker = this.props.data.driverReducer.driverDetails.documents.registrationSticker;
      localDriverdata.insurance = this.props.data.driverReducer.driverDetails.documents.insurance;
      localDriverdata.insuranceExpirationDate = this.props.data.driverReducer.driverDetails.documents.insuranceExpirationDate ? moment(
        this.props.data.driverReducer.driverDetails.documents.insuranceExpirationDate
      ).format("MM-DD-YYYY") : 'Not available';
      let localData = { ...this.state.data };
      localData.data = this.props.data.driverReducer.driverDetails.tripInfo && this.props.data.driverReducer.driverDetails.tripInfo.favoriteList &&
        this.props.data.driverReducer.driverDetails.tripInfo.favoriteList.data ? this.props.data.driverReducer.driverDetails.tripInfo.favoriteList.data : [];
      if (localData.data.length) {
        localData.data.forEach(each => {
          each.riderImage = each.personalInfo.profilePicture;
          each.contact =
            CountryCodesConfig.filter(item => {
              return item.code === each.personalInfo.phone.countryCode;
            })[0].dial_code + this.formatNumber(each.personalInfo.phone.number);
        });
      }
      console.log('localData ', localData);

      this.setState({
        ...this.state,
        driverDetails: localDriverdata,
        detailsFetched: true,
        data: localData,
        loading: false
      });
    }
    if (this.props.data.driverReducer.statusUpdated) {
      this.props.statusUpdatedDone();
      this.setState({
        ...this.state,
        detailsFetched: false,
        confirmationModalOpen: false
      });
    }

    //  localDriverdata.stripeInfo=this.props.data.driverReducer.driverDetails.personalInfo.
  }

  componentWillUnmount() {
    this.props.resetDetails();
  }

  handleToggleChange = name => {
    let localMenu = { ...this.state.menu };
    localMenu[name] = !localMenu[name];
    this.setState({ ...this.state, menu: localMenu });
  };

  saveHandler = () => {
    if (this.menuValidation()) {
      let menuPayload = { ...this.state.menu };
      menuPayload.contains = this.state.tags.map(v => v.text);
      this.props.createMenu(menuPayload);
    }
  };


  openConfirmationModal = () => {
    this.setState({ confirmationModalOpen: true });
  };
  closeConfirmationModal = () => {
    this.setState({ confirmationModalOpen: false });
  };

  handleModalOpen = () => {
    this.setState({ editUserModalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ editUserModalOpen: false });
  };

  handleFavModalOpen = () => {
    this.setState({ favModalOpen: true });
  };

  handleFavModalClose = () => {
    this.setState({ favModalOpen: false });
  };

  handleDocumentModalOpen = () => {
    this.setState({ documentModalOpen: true });
  };

  handleDocumentModalClose = () => {
    this.setState({ documentModalOpen: false });
  };

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };
  backToPrevPage = () => {
    this.props.history.goBack();
  }
  handleColorPicker = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleColorPickerClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleColorChange = (color) => {
    this.setState({ color: color.rgb })
  };

  moveToEditDriver = () => {
    this.props.history.push('/web/auth/drivers/edit/' + this.props.match.params.driverId);
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  }
  resetPassword = () => {
    //sending password to email
    this.props.resetDriverPassword(this.props.match.params.driverId)
  }

  render() {
    const { classes, theme } = this.props;
    let driverDetails = { ...this.state.driverDetails }
    console.log(driverDetails);
    let tmpLanguages = driverDetails.languages;
    let strLanguage = "";
    if (tmpLanguages.length !== 0) {
      for (let i = 0; i < tmpLanguages.length; i++) {
        if (i === 0) {
          strLanguage += tmpLanguages[i];
        } else {
          strLanguage += "," + tmpLanguages[i];
        }
      }

    }


    const colorpickerStyles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });


    return (
      <MuiThemeProvider theme={theme}>
        {!this.state.loading ? (
          <Grid container spacing={16}>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                  <Typography component="p">
                    <span className={classes.pageHeader}>
                      {this.props.data.literalsReducer.DriverDetails.PageHeader}
                    </span>
                    <span className={classes.PageDescription}>
                      {this.props.data.literalsReducer.DriverDetails.PageDescription}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.headerRightButton}
                    onClick={this.backToPrevPage}
                  >

                    <ArrowBackIosIcon className={classes.leftIcon} /> Back
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Paper>
                  <Grid container>
                    <Grid item xs={12} sm={12} className={classes.rightPaper}>
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={3} className={classes.riderLeft}>
                            <div className={classes.profileImagePlaceholder}>

                              <div style={{
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                width: "100%",
                                height: "100%",
                                backgroundImage: `url(  ${this.state.driverDetails.profilePicture} )`
                              }}>

                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className={classes.nameHeading}>{this.state.driverDetails.fullName}</div>
                            <div className={classNames(classes.description, classes.userId)}>({this.state.driverDetails.displayId})</div>
                            <div className={classes.description}>
                              <span className={classes.activeStatus} style={{ marginLeft: "0" }}>{this.statusList[this.state.driverDetails.accountStatus]}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.MemberSince} -
                              <span>{this.state.driverDetails.createdAt}</span>
                            </div>
                            <div className={classNames(classes.ratingShow, classes.description)}>
                              <span>{this.state.driverDetails.avgRating}/10</span>
                              <StarRatings
                                rating={Number(this.state.driverDetails.avgRating)}
                                starRatedColor="#ffc000"
                                numberOfStars={10}
                                name="rating"
                                starDimension="15px"
                                starSpacing="2px"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={3} >
                            <Button
                              size="large"
                              color="default"
                              variant="contained"
                              className={classNames(classes.customMargin, classes.activeGoButton)}
                              onClick={this.resetPassword}
                            >
                              Reset Password
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider variant="middle" style={{ "margin": "25px 0" }} />
                      <Grid item xs={12} sm={12} className={classes.descSection}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={4}>
                            <h3>Basic Details</h3>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.Email} -
                              <span>{this.state.driverDetails.email}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.PhoneNo} -
                              <span>{this.state.driverDetails.phoneNumber}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.Language} -
                              <span>{strLanguage}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.Dob} -
                              <span>{this.state.driverDetails.dob}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <h3>Address Details</h3>
                            <div className={classes.description}>
                              {this.state.driverDetails.address}
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.AddressState} -
                              <span>{this.state.driverDetails.state}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.AddressCity} -
                              <span>{this.state.driverDetails.city}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.AddressPin} -
                              <span>{this.state.driverDetails.zip}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <h3>Ur PC Info</h3>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.Upccode} -
                              <span>{this.state.driverDetails.upcCode}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.NoOfPeople} -
                              <span>{this.state.driverDetails.upcCodeUsedBy.length}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.IncomeFromUpc} -
                              <span>&#36;{this.state.driverDetails.upcIncome}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <h3>Car Details</h3>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.CarType} -
                              <span>{this.cartypes[this.state.driverDetails.vehicleType]}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.CarMaker} -
                              <span>{this.state.driverDetails.vehicleMake}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.CarModel} -
                              <span>{this.state.driverDetails.vehicleModel}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.CarColor} -
                              <span className={classes.colorView} style={{ background: this.state.driverDetails.vehicleColor }}></span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.carLicensePlateNumber} -
                              <span>{this.state.driverDetails.vehicleRegistrationNo}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.carModalYear} -
                              <span>{this.state.driverDetails.vehicleModalYear}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.rateChartType} -
                              <span>{this.state.driverDetails.rateChartType}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <h3>Trip/Req. Info</h3>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.NoOfCompletedTrips} -
                              <span>{this.state.driverDetails.completedTrips}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.RequestRejectIgnored} -
                              <span>{this.state.driverDetails.requestRejectIgnored}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.RequestAssignedByAdmin} -
                              <span>{this.state.driverDetails.requestAssignedByAdmin}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.NoOfUpcomingRideRequests} -
                              <span>{this.state.driverDetails.upcomingRequest}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.NoOfRidersfavorited} -
                              <span>{this.state.driverDetails.ridersFavorite}</span>
                              {this.state.driverDetails.ridersFavorite ?
                                <span onClick={this.handleFavModalOpen} style={{ cursor: "pointer" }}>
                                  <FontAwesomeIcon
                                    icon={faInfoCircle}
                                  />
                                </span>
                                : null}
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <h3>Stripe Info</h3>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.DriverDetails.StripeAc} -
                              <span style={{ fontSize: 14 }}>{this.state.driverDetails.stripeInfo}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <h3>Documents</h3>

                            <div className={classes.description}>
                              <Grid container spacing={8} className={classes.documentImage}>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Paper elevation={0} className={classes.docCard}>
                                    <Typography variant="subtitle1" component="h4">Chauffeur's License</Typography>
                                    <Typography variant="caption" component="span">Expiring on: {this.state.driverDetails.licenseExpirationDate}</Typography>
                                    <div style={{
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: 'center',
                                      marginTop: 15,
                                      width: "100%",
                                      height: "173px",
                                      backgroundImage: `url( ${this.state.driverDetails.license} )`
                                    }}>
                                    </div>
                                  </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Paper elevation={0} className={classes.docCard}>
                                    <Typography variant="subtitle1" component="h4">Insurance</Typography>
                                    <Typography variant="caption" component="span">Expiring on: {this.state.driverDetails.insuranceExpirationDate}</Typography>
                                    <div style={{
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: 'center',
                                      marginTop: 15,
                                      width: "100%",
                                      height: "173px",
                                      backgroundImage: `url( ${this.state.driverDetails.insurance} )`
                                    }}></div>
                                  </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Paper elevation={0} className={classes.docCard}>
                                    <Typography variant="subtitle1" component="h4">ARA Document</Typography>
                                    <Typography variant="caption" component="span">Expiring on: {this.state.driverDetails.insuranceExpirationDate}</Typography>
                                    <div style={{
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: 'center',
                                      marginTop: 15,
                                      width: "100%",
                                      height: "173px",
                                      backgroundImage: `url( ${this.state.driverDetails.limoSticker} )`
                                    }}></div>
                                  </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Paper elevation={0} className={classes.docCard}>
                                    <Typography variant="subtitle1" component="h4">Registration Sticker</Typography>
                                    <div style={{
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: 'center',
                                      marginTop: 15,
                                      width: "100%",
                                      height: "173px",
                                      backgroundImage: `url( ${this.state.driverDetails.registrationSticker} )`
                                    }}></div>
                                  </Paper>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {this.state.editableMode ?
                      <Grid item xs={12} sm={12}>
                        <div className={classes.actionContainer}>
                          <Button
                            size="large"
                            color="default"
                            variant="contained"
                            className={classes.customMargin}
                            onClick={this.openConfirmationModal}
                          >
                            {this.state.driverDetails.accountStatus === 1
                              ? "Suspend"
                              : "Activate"}
                          </Button>
                          <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            className={classNames(classes.customMargin, classes.activeGoButton)}
                            onClick={this.moveToEditDriver}
                          >
                            Edit Chauffeur
                          </Button>
                        </div>
                      </Grid>
                      : null}
                  </Grid>
                  <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.favModalOpen}
                    onClose={this.handleFavModalClose}>
                    <div style={getModalStyle()} className={classes.modalPaper}>
                      <Grid item xs={12} sm={12} className={classes.descSection}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12}>
                            {Object.keys(this.state.data).length ? (
                              <CustomTable
                                rows={this.state.data}
                                headerFormat={this.state.headerFormat}
                                visibleColumns={this.state.visibleColumns}
                                redirect={this.redirect}
                                hideColumnDropdown={true}
                              />
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Modal>
                  <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.confirmationModalOpen}
                    onClose={this.closeConfirmationModal}
                  >
                    <div style={getModalStyle()} className={classes.modalPaper}>
                      <Grid item xs={12} sm={12} className={classes.descSection}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12}>
                            <h2>Change Chauffeur Status</h2>
                            <div
                              className={classes.description}
                              style={{ marginBottom: "20px" }}
                            >
                              Are you sure you want to change the status of the chauffeur?
                            </div>
                            <div style={{ textAlign: "right" }}>
                              <Button
                                size="small"
                                color="default"
                                variant="contained"
                                className={classes.customMargin}
                                onClick={this.closeConfirmationModal}
                              >
                                Cancel
                              </Button>
                              <Button
                                style={{ marginLeft: "10px" }}
                                size="small"
                                color="primary"
                                variant="contained"
                                className={classNames(
                                  classes.customMargin,
                                  classes.activeGoButton
                                )}
                                onClick={this.changeDriverModalStatus}
                              >
                                Yes
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Modal>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div
            style={{
              textAlign: "center",
              padding: "10px",
              backgroundColor: "#fff",
              borderRadius: "10px"
            }}
          >
            {this.props.data.literalsReducer.Common.Loading}
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDriver: id => dispatch(fetchDriver(id)),
    resetDetails: () => dispatch(resetDetails()),
    changeDriverStatus: (id, payload) =>
      dispatch(changeDriverStatus(id, payload)),
    statusUpdatedDone: () => dispatch(statusUpdatedDone()),
    resetDriverPassword: id => dispatch(resetDriverPassword(id))
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...modalStyles(theme),
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }), { withTheme: true }
  )(DriverDetails)
);