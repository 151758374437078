import {
  FETCH_UPC_RIDERS_SUCCESS,
  FETCH_UPC_RIDER_SUCCESS,
  FETCH_UPC_RIDER,
  FETCH_UPC_RIDER_FAIL,
  RESET_DETAILS
} from "../constants/action-types";

const initialState = {
  riderList: {},
  riderDetails: {},
  isFetching: false
};

const upcRiderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UPC_RIDERS_SUCCESS:
      return {
        ...state,
        riderList: action.payload.data
      };

    case FETCH_UPC_RIDER:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_UPC_RIDER_SUCCESS:
      return {
        ...state,
        riderDetails: action.payload.data,
        isFetching: false
      };
    case FETCH_UPC_RIDER_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case RESET_DETAILS:
      return {
        ...state,
        riderDetails: {}
      };
    default:
      return state;
  }
};

export default upcRiderReducer;
