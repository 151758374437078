import { 
  FETCH_NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_SEEN_SUCCESS,
  NOTIFICATIONS_SEEN_DONE,
  NOTIFICATIONS_MQTT_SUCEESS,
  NOTIFICATIONS_MQTT_SUCEESS_DONE,
  FETCH_NOTIFICATIONS_COUNT_DONE,
  FETCH_NOTIFICATIONS_COUNT_SUCCESS
 } from "../constants/action-types";

const initialState = {
  notifications: [],
  mqttNotification: {},
  countNotification: 0,
  countUpdating: false, 
  isUpdating: false,
  mqttComing: false,
  updateId:""
};

const notificationsReducer = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_NOTIFICATIONS_SUCCESS:
      return  {
        ...state,
      
        notifications: action.payload.data
      };
    case FETCH_NOTIFICATIONS_COUNT_SUCCESS: 
     
      return {
        ...state,
        countNotification: 0,
        countUpdating: true,
        countNotification: action.payload.data
      }
    case FETCH_NOTIFICATIONS_COUNT_DONE: 
     
      return {
        ...state,
        countUpdating: false,
      }
    case NOTIFICATIONS_SEEN_SUCCESS:
       return {
      ...state,
      notifications: [],
      isUpdating: true,
     
      };
    case NOTIFICATIONS_SEEN_DONE:
    return {
      ...state,
      isUpdating: false
      };

    case NOTIFICATIONS_MQTT_SUCEESS: 
    return {
      ...state,
      mqttComing: true,
      //mqttNotification:{},
      mqttNotification: action.payload
      };

 
    case NOTIFICATIONS_MQTT_SUCEESS_DONE:
    return {
      ...state,
      mqttComing: false
      };
    default:
      return state;
  }
};

export default notificationsReducer;
