import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../app/theme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import styles from "./styles";
import globalstyles from "../../../globalstyles";
import CustomTable from "../../../components/CustomTable";
import Button from "@material-ui/core/Button";
import ReactPaginate from "react-paginate";
import Drawer from "@material-ui/core/Drawer";
import FilterListIcon from "@material-ui/icons/FilterList";
import cancel from "../../../assets/images/cancel.png";
import CountryCodesConfig from "../../../config/country-codes";
import { UPCDriverHeaderFormat } from "../../../config/header-format";
import { getUPCDriverList } from "../../../store/actions/upcDriver";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const skip = 0;
const limit = 6;
const countries = CountryCodesConfig;

class UpcDrivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBoxFocused: false,
      searchKeyword: "",
      offset: 0,
      pendingStatus: 4,
      right: false,
      data: {
        total: 0,
        data: []
      },
      visibleColumns: [
        "Chauffeur Name",
        "Contact No.",
        "Ur PC Code",
        "Email",
        "Chauffeur Id",
        "No. of Riders Referred",
        "Ur PC Money Made",
        "Ur PC Money Spent"
      ],
      toastMessage: false,
      skip: 0,
      limit: limit,
      errors: {},
      filterData: {
        name: "",
        email: "",
        contactNumber: "",
        displayId: "",
        upcCode: "",
        countryCode: `${countries[0].code} (${countries[0].dial_code})`
      },
      searchData: {},
      headerFormat: UPCDriverHeaderFormat
    };
  }

  columnWidth = 100;
  minColumnLength = 5;

  redirect = data => {
    //event.preventDefault();
    this.props.history.push("/web/auth/upc-drivers/details/" + data._id);
    //console.log("id ", id);
  };

  handleColumnVisibilityChange = event => {
    let newState = { ...this.state };
    if (event.target.value.length > this.minColumnLength) {
      document.getElementById("custom_table").style.width =
        document.getElementById("custom_table").clientWidth +
        this.columnWidth +
        "px";
    } else {
      document.getElementById("custom_table").style.width = "1000px";
    }
    if (event.target.value.length > 1) {
      newState.visibleColumns = event.target.value;
    }
    this.setState(newState);
  };

  handleAvailabilityChange = index => event => {
    let localData = { ...this.state.data };
    let dataArray = [...localData.data];
    dataArray[index].availability = event.target.checked;
    localData.data = [...dataArray];
    this.setState({ ...this.state, data: localData });
  };

  handleSortClick = (option, header, index) => {
    let newState = {
      ...this.state
    };
    newState.headerFormat[header].option.forEach(element => {
      element.isActive = false;
    });
    newState.headerFormat[header].option[index].isActive = true;
    let localSortData = { ...this.state.sortData };
    if (header === "personalInfo.fullName") {
      localSortData.name = option.type;
    }
    newState.sortData = localSortData;
    this.setState(newState);
    this.props.getUPCDriverList({
      skip: this.state.skip,
      limit: this.state.limit,
      sortConfig: localSortData,
      filters: this.getFilteredData()
    });
  };

  componentDidMount() {
    this.setState({ ...this.state, loading: true });
    this.props.getUPCDriverList({
      skip: skip,
      limit: limit,
      sortConfig: {},
      filters: {}
    });
  }

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (Object.keys(this.props.data.upcDriverData.driverList).length) {
        let localData = { ...this.state.data };
        localData.total = this.props.data.upcDriverData.driverList.total;
        localData.data = this.props.data.upcDriverData.driverList.data;
        if (localData.data.length) {
          localData.data.forEach(each => {
            each.noOfRiderReferred =
              each.upcBucketInfo.upcCodeUsedBy.length || "0";
            each.upcMoneyMade = each.upcBucketInfo.upcCreditsEarned
              ? each.upcBucketInfo.upcCreditsEarned.toFixed(2)
              : "0";
            each.upcMoneySpent = each.upcBucketInfo.upcCreditsSpent
              ? each.upcBucketInfo.upcCreditsSpent.toFixed(2)
              : "0";
            each.upcCodeAttached = each.upcBucketInfo.upcCodeUsed
              ? each.upcBucketInfo.upcCodeUsed.upcCode
              : "N/A";
            each.personalInfo.phoneNumber =
              CountryCodesConfig.filter(item => {
                return item.code === each.personalInfo.phone.countryCode;
              })[0].dial_code +
              this.formatNumber(each.personalInfo.phone.number);
          });
        }
        this.setState({
          ...this.state,
          data: localData,
          loading: false
        });
      }
    }
  }

  handleSearchChange = event => {
    this.setState({ ...this.state, searchKeyword: event.target.value });
  };

  getFilteredData = () => {
    let localFilterData = {};
    for (let item in this.state.filterData) {
      if (this.state.filterData[item].trim().length) {
        if (item !== "contactNumber" && item !== "countryCode") {
          localFilterData[item] = this.state.filterData[item];
        } else if (this.state.filterData.contactNumber) {
          localFilterData.phone = {
            countryCode: this.state.filterData.countryCode.split(" (")[0],
            number: this.state.filterData.contactNumber
          };
        }
      }
    }
    // console.log("localFilterData ", localFilterData);
    return localFilterData;
  };

  componentWillUnmount() { }

  handlePageClick = data => {
    this.props.getUPCDriverList({
      skip: data.selected * this.state.limit,
      limit: this.state.limit,
      sortConfig: { ...this.state.sortData },
      filters: this.getFilteredData()
    });
    this.setState({ ...this.state, skip: data.selected * this.state.limit });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      ...this.state,
      [side]: open
    });
  };

  driverFilterInfoValidation = () => {
    let fields = { ...this.state.filterData };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    if (
      fields.contactNumber.trim().length &&
      fields.contactNumber.trim().length < 10
    ) {
      errors.invalidContactNumber = true;
      hasErrors = true;
    }

    if (
      fields.email.trim().length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields.email)
    ) {
      errors["emailInvalid"] = true;
      hasErrors = true;
    } else {
      errors["emailInvalid"] = false;
    }

    console.log("hasErrors ", hasErrors);
    console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  resetFilter = () => {
    this.props.getUPCDriverList({
      skip: skip,
      limit: limit,
      sortConfig: {},
      filters: {}
    });
    this.setState({
      ...this.state,
      right: false,
      filterData: {
        name: "",
        email: "",
        contactNumber: "",
        displayId: "",
        upcCode: "",
        countryCode: `${countries[0].code} (${countries[0].dial_code})`
      }
    });
  };

  filterChange = event => {
    let fields = { ...this.state.filterData };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    if (event.target.name === "contactNumber") {
      errors.invalidContactNumber = false;
    }

    if (event.target.name === "email") {
      errors.emailInvalid = false;
    }

    this.setState({ ...this.state, filterData: fields, errors });
  };

  filterHandler = () => {
    if (!this.driverFilterInfoValidation()) {
      let localFilterData = this.getFilteredData();
      this.props.getUPCDriverList({
        skip: 0,
        limit: this.state.limit,
        sortConfig: { ...this.state.sortData },
        filters: localFilterData
      });
      this.setState({
        ...this.state,
        right: false
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                  <Typography component="p">
                    <span className={classes.pageHeader}>
                      {
                        this.props.data.literalsReducer.UpcDriversList
                          .PageHeader
                      }
                    </span>
                    <span className={classes.PageDescription}>
                      {
                        this.props.data.literalsReducer.UpcDriversList
                          .PageDescription
                      }
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2} className={classes.topButtons}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.addButton}
                    onClick={this.toggleDrawer("right", true)}
                  >
                    <FilterListIcon className={classes.leftIcon} />
                    Filter
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
              {" "}
              {Object.keys(this.state.data).length &&
                this.state.data.data.length &&
                !this.state.loading ? (
                <CustomTable
                  rows={this.state.data}
                  headerFormat={this.state.headerFormat}
                  sort={this.handleSortClick}
                  rowsPerPage={this.state.limit}
                  page={this.state.skip}
                  total={this.state.data.total}
                  handleAvailabilityChange={this.handleAvailabilityChange}
                  visibleColumns={this.state.visibleColumns}
                  redirect={this.redirect}
                  handleColumnVisibilityChange={
                    this.handleColumnVisibilityChange
                  }
                />
              ) : !this.state.loading ? (
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "#fff",
                    borderRadius: "10px"
                  }}
                >
                  No Ur PC chauffeurs found!
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "#fff",
                    borderRadius: "10px"
                  }}
                >
                  {this.props.data.literalsReducer.Common.Loading}
                </div>
              )}
            </Grid>
            {Object.keys(this.state.data).length &&
              this.state.data.data.length &&
              this.state.data.total > this.state.limit &&
              !this.state.loading ? (
              <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                <ReactPaginate
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.data.total / this.state.limit}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </Grid>
            ) : null}
            <Drawer
              anchor="right"
              open={this.state.right}
              PaperProps={{ className: classes.drawerWrapper }}
            >
              <div tabIndex={0} role="button">
                <Grid item xs={12} sm={12} className={classes.rightPaper}>
                  <Grid item xs={12} sm={12} md={12} align="right">
                    <IconButton
                      onClick={this.toggleDrawer("right", false)}
                      className={classes.cancel}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                      <p className={classes.description}>Chauffeur Id</p>
                      <TextField
                        label="Id"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="displayId"
                        value={this.state.filterData.displayId}
                        onChange={this.filterChange}
                      />
                      <p className={classes.description}>Name</p>
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="name"
                        value={this.state.filterData.name}
                        onChange={this.filterChange}
                      />
                      <p className={classes.description}>Email</p>
                      <TextField
                        type="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="email"
                        value={this.state.filterData.email}
                        onChange={this.filterChange}
                        error={this.state.errors.emailInvalid}
                      />
                      <p className={classes.description}>Ur PC Code</p>
                      <TextField
                        label="Ur PC Code"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="upcCode"
                        value={this.state.filterData.upcCode}
                        onChange={this.filterChange}
                      />
                      <p className={classes.description}>Contact No.</p>
                      <div className={classes.inlineField}>
                        <TextField
                          id="select-car-type"
                          select
                          variant="outlined"
                          className={classes.textField}
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                        >
                          {countries.filter(each => each.code === 'US').map((option, index) => (
                            <option
                              key={index}
                              value={`${option.code} (${option.dial_code})`}
                            >
                              {`${option.code} (${option.dial_code})`}
                            </option>
                          ))}
                        </TextField>
                        <TextField
                          id="car-maker"
                          label="Contact No."
                          variant="outlined"
                          className={classes.textField}
                          name="contactNumber"
                          value={this.state.filterData.contactNumber}
                          onChange={this.filterChange}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          inputProps={{
                            minLength: 10,
                            maxLength: 10
                          }}
                          error={this.state.errors.invalidContactNumber}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.actionContainer}>
                        <Button
                          size="large"
                          color="default"
                          variant="contained"
                          onClick={this.resetFilter}
                        >
                          Reset
                        </Button>
                        <Button
                          size="large"
                          color="primary"
                          variant="contained"
                          className={classes.activeGoButton}
                          onClick={this.filterHandler}
                        >
                          Go
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Drawer>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    getUPCDriverList: payload => dispatch(getUPCDriverList(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(UpcDrivers)
);
