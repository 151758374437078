import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../../app/theme";
import Divider from "@material-ui/core/Divider";
import contentStyles from "../../../auth-layout/content.styles";
import styles from "./styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import globalstyles from "../../../../globalstyles";
import classNames from "classnames";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import RoleCodesConfig from "../../../../config/role-config";
import Modal from "@material-ui/core/Modal";
import {
  fetchRole,
  editRole,
  roleUpdatedDone,
  deleteRole,
  roleDeleteDone,
  resetRole
} from "../../../../store/actions/role";
import { showSnackbar } from "../../../../store/actions/snackbar";
library.add(faUpload);

// const roles = RoleCodesConfig;
const accessModules = RoleCodesConfig.map(accessModules => ({
  value: accessModules.code,
  label: accessModules.label
}));

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  };
}

const modalStyles = theme => ({
  modalPaper: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  modalPaperLarge: {
    width: theme.spacing.unit * 150,
    overflowY: "scroll",
    height: "90%"
  }
});

class EditRole extends Component {
  state = {
    single: null,
    labelWidth: 0,
    confirmationModalOpen: false,
    roleDetails: {
      roleName: ""
    },
    modules: [
      {
        value: "1",
        selected: true,
        text: "Dashboard",
        name: "home",
        access: accessModules[0].value
      },
      {
        value: "2",
        selected: false,
        text: "Rider",
        name: "rider",
        access: accessModules[0].value
      },
      {
        value: "3",
        selected: false,
        text: "Role",
        name: "role",
        access: accessModules[0].value
      },
      {
        value: "4",
        selected: false,
        text: "Fare Chart",
        name: "fareChart",
        access: accessModules[0].value
      },
      {
        value: "5",
        selected: false,
        text: "Past Trip",
        name: "pastTrip",
        access: accessModules[0].value
      },
      {
        value: "6",
        selected: false,
        text: "Booking Request",
        name: "bookingRequest",
        access: accessModules[0].value
      },
      {
        value: "7",
        selected: false,
        text: "Ongoing Trip",
        name: "ongoingTrip",
        access: accessModules[0].value
      },
      {
        value: "8",
        selected: false,
        text: "Ongoing Trip Alert",
        name: "ongoingTripAlert",
        access: accessModules[0].value
      },
      {
        value: "9",
        selected: false,
        text: "Booking Request Alert",
        name: "bookingRequestAlert",
        access: accessModules[0].value
      },
      {
        value: "10",
        selected: false,
        text: "Revenue",
        name: "revenue",
        access: accessModules[0].value
      },
      {
        value: "11",
        selected: false,
        text: "Global Settings",
        name: "globalSettings",
        access: accessModules[0].value
      },
      {
        value: "12",
        selected: false,
        text: "Operational Parameter",
        name: "operationalParameter",
        access: accessModules[0].value
      },
      {
        value: "13",
        selected: false,
        text: "Driver",
        name: "driver",
        access: accessModules[0].value
      },

      {
        value: "14",
        selected: false,
        text: "Zone",
        name: "zone",
        access: accessModules[0].value
      },
      {
        value: "15",
        selected: false,
        text: "Admin",
        name: "admin",
        access: accessModules[0].value
      },
      {
        value: "16",
        selected: false,
        text: "UPC Driver",
        name: "UPCDriver",
        access: accessModules[0].value
      },
      {
        value: "17",
        selected: false,
        text: "UPC Rider",
        name: "UPCRider",
        access: accessModules[0].value
      },
      {
        value: "18",
        selected: false,
        text: "Can cancel booking",
        name: "canCancelBooking",
        access: accessModules[0].value
      },
      {
        value: "19",
        selected: false,
        text: "Can End Trip",
        name: "canEndTrip",
        access: accessModules[0].value
      }
    ],
    errors: {},
    detailsFetched: false,
    haveImage: false
  };

  handleChange = event => {
    let fields = { ...this.state.roleDetails };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;
    this.setState({ ...this.state, roleDetails: fields, errors });
  };

  roleInfoValidation = () => {
    let fields = { ...this.state.roleDetails };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    if (!fields.roleName.trim()) {
      errors.roleName = true;
      hasErrors = true;
    }

    console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  handleModuleAccessChange = index => {
    let localModulesAccess = [...this.state.modules];
    if (localModulesAccess[index].access === 1) {
      localModulesAccess[index].access = 2;
    } else {
      localModulesAccess[index].access = 1;
    }
    this.setState({ ...this.state, modules: localModulesAccess });
  };

  componentDidMount() {
    this.setState({ ...this.state, loading: true });
    this.props.fetchRole(this.props.match.params.roleId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (ReactDOM.findDOMNode(this.InputLabelRef)) {
      this.setState({
        ...this.state,
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
      });
    }

    if (
      !this.state.detailsFetched &&
      Object.keys(this.props.data.roleReducer.roleDetails).length
    ) {
      let localModuleData = [...this.state.modules];
      let tmpAccess = this.props.data.roleReducer.roleDetails.permissions;
      let tmpName = this.props.data.roleReducer.roleDetails.name;
      let localRoleDetails = { ...this.state.roleDetails };
      localRoleDetails.roleName = tmpName;
      localModuleData.forEach(each => {
        tmpAccess.forEach(element => {
          if (element.moduleName === each.name) {
            each.selected = true;
            each.access = element.role;
          }
        });
      });
      if (this.props.data.roleReducer.roleDetails.canEndTrip) {
        localModuleData.forEach(each => {
          if (each.value === "19") {
            each.selected = true;
          }
        });
      }
      if (this.props.data.roleReducer.roleDetails.canCancelBooking) {
        localModuleData.forEach(each => {
          if (each.value === "18") {
            each.selected = true;
          }
        });
      }
      this.setState({
        ...this.state,
        modules: localModuleData,
        roleDetails: localRoleDetails,
        detailsFetched: true,
        loading: false
      });
    }

    if (this.props.data.roleReducer.roleUpdated) {
      this.props.roleUpdatedDone();
      this.setState({
        ...this.state,
        detailsFetched: false
      });
      this.props.history.push("/web/auth/roles");
    }

    if (this.props.data.roleReducer.roleDeleted) {
      this.props.roleDeleteDone();
      this.setState({
        ...this.state,
        detailsFetched: false
      });
      this.props.history.push("/web/auth/roles");
    }
  }

  handleModuleSelectionChange = index => {
    let localModules = [...this.state.modules];
    localModules[index].selected = !localModules[index].selected;
    this.setState({ ...this.state, modules: localModules });
  };

  componentWillUnmount() {
    this.props.resetRole();
  }

  saveHandler = () => {
    if (!this.roleInfoValidation()) {
      let tmpModule = [...this.state.modules];
      let permissions = [];
      for (let i = 0; i < tmpModule.length; i++) {
        if (i !== 17 && i !== 18) {
          if (tmpModule[i].selected) {
            permissions.push({
              moduleName: tmpModule[i].name,
              role: tmpModule[i].access
            });
          }
        }
      }
      if (permissions.length === 1) {
        this.props.showSnackbar("Please select atleast one module", 'error');
        return;
      } else {
        let payload = {
          name: this.state.roleDetails.roleName,
          permissions: permissions,
          canCancelBooking: tmpModule[17].selected,
          canEndTrip: tmpModule[18].selected
        };
        this.props.editRole(this.props.match.params.roleId, payload);
      }
    }
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  };

  openConfirmationModal = () => {
    this.setState({ confirmationModalOpen: true });
  };
  closeConfirmationModal = () => {
    this.setState({ confirmationModalOpen: false });
  };
  deleteDriver = () => {
    this.props.deleteRole(this.props.match.params.roleId);
  };

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        {!this.state.loading ? (
          <div>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                    <Typography component="p">
                      <span className={classes.pageHeader}>
                        {this.props.data.literalsReducer.Roles.PageHeader}
                      </span>
                      <span className={classes.PageDescription}>
                        {
                          this.props.data.literalsReducer.EditRole
                            .PageDescription
                        }
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.headerRightButton}
                      onClick={this.backToPrevPage}
                    >
                      <ArrowBackIosIcon className={classes.leftIcon} /> Back
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <Grid container className={classes.rightPaper}>
                    <Grid item xs={12} sm={12}>
                      <h3>Role Name</h3>
                      <div
                        className={classes.description}
                        style={{ width: "50%" }}
                      >
                        <TextField
                          variant="outlined"
                          label="Role Name"
                          placeholder="Enter Role Name"
                          className={classes.textField}
                          name="roleName"
                          value={this.state.roleDetails.roleName}
                          onChange={this.handleChange}
                          error={this.state.errors.roleName}
                        />
                        {this.state.errors.roleName ? (
                          <FormHelperText id="validation-error2">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </div>
                      <Divider
                        variant="middle"
                        style={{ margin: "30px 0 25px" }}
                      />
                      <h3>Accessibility Modules</h3>
                      <FormGroup className={classes.selectList}>
                        <Grid container>
                          {this.state.modules && this.state.modules.length
                            ? this.state.modules.map((type, index) => {
                                return (
                                  <Grid item xs={6}>
                                    <div key={index}>
                                      {type.value !== "1" ? (
                                        <Checkbox
                                          checked={type.selected}
                                          onChange={() =>
                                            this.handleModuleSelectionChange(
                                              index
                                            )
                                          }
                                          value={type.value}
                                          color="primary"
                                        />
                                      ) : (
                                        <Checkbox
                                          checked={type.selected}
                                          disabled
                                          onChange={() =>
                                            this.handleModuleSelectionChange(
                                              index
                                            )
                                          }
                                          value={type.value}
                                          color="primary"
                                        />
                                      )}{" "}
                                      <span style={{ marginRight: "10px" }}>
                                        {type.text}
                                      </span>
                                      {type.value !== "1" &&
                                      type.value !== "18" &&
                                      type.value !== "19" ? (
                                        <TextField
                                          select
                                          variant="outlined"
                                          className={classes.textField}
                                          SelectProps={{
                                            native: true
                                          }}
                                          onChange={() =>
                                            this.handleModuleAccessChange(index)
                                          }
                                          value={type.access}
                                          disabled={!type.selected}
                                        >
                                          {accessModules.map(option => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                        </TextField>
                                      ) : null}
                                    </div>
                                  </Grid>
                                );
                              })
                            : null}
                        </Grid>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.actionContainer}>
                        <Button
                          size="large"
                          color="default"
                          variant="contained"
                          className={classes.customMargin}
                          onClick={this.backToPrevPage}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="large"
                          color="secondary"
                          variant="contained"
                          className={classes.customMargin}
                          onClick={this.openConfirmationModal}
                        >
                          Delete
                        </Button>
                        <Button
                          size="large"
                          color="primary"
                          variant="contained"
                          className={classNames(
                            classes.customMargin,
                            classes.activeGoButton
                          )}
                          onClick={this.saveHandler}
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.confirmationModalOpen}
                    onClose={this.closeConfirmationModal}
                  >
                    <div style={getModalStyle()} className={classes.modalPaper}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.descSection}
                      >
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12}>
                            <h2>Role Delete</h2>
                            <div
                              className={classes.description}
                              style={{ marginBottom: "20px" }}
                            >
                              Are you sure you want to delete this role?
                            </div>
                            <div style={{ textAlign: "right" }}>
                              <Button
                                size="small"
                                color="default"
                                variant="contained"
                                className={classes.customMargin}
                                onClick={this.closeConfirmationModal}
                              >
                                Cancel
                              </Button>
                              <Button
                                style={{ marginLeft: "10px" }}
                                size="small"
                                color="primary"
                                variant="contained"
                                className={classNames(
                                  classes.customMargin,
                                  classes.activeGoButton
                                )}
                                onClick={this.deleteDriver}
                              >
                                Yes
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Modal>
                </Paper>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              padding: "10px",
              backgroundColor: "#fff",
              borderRadius: "10px"
            }}
          >
            {this.props.data.literalsReducer.Common.Loading}
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchRole: id => dispatch(fetchRole(id)),
    editRole: (id, payload) => dispatch(editRole(id, payload)),
    roleUpdatedDone: () => dispatch(roleUpdatedDone()),
    deleteRole: id => dispatch(deleteRole(id)),
    roleDeleteDone: () => dispatch(roleDeleteDone()),
    resetRole: () => dispatch(resetRole()),
    showSnackbar: (message, variant) => dispatch(showSnackbar(message, variant))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...modalStyles(theme),
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(EditRole)
);
