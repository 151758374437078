import {
  GET_ZONE_SUCCESS,
  UPDATE_ZONE_SUCCESS,
  ZONE_UPDATED_DONE,
  RESET_ZONE_DETAILS
} from "../constants/action-types";

const initialState = {
  zoneDetails: {},
  zoneUpdated: false
};

const zoneData = (state = initialState, action) => {
  switch (action.type) {
    case GET_ZONE_SUCCESS:
      return {
        ...state,
        zoneDetails: action.payload.data
      };
    case UPDATE_ZONE_SUCCESS:
      return {
        ...state,
        zoneUpdated: true,
        zoneDetails: action.payload.data
      };
    case ZONE_UPDATED_DONE:
      return {
        ...state,
        zoneUpdated: false
      };
    case RESET_ZONE_DETAILS:
      return {
        ...state,
        zoneDetails: {}
      };
    default:
      return state;
  }
};

export default zoneData;
