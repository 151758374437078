import {
  FETCH_ADMINS_SUCCESS,
  FETCH_UPC_SUCCESS,
  FETCH_ADMIN_SUCCESS,
  FETCH_ADMIN,
  FETCH_ADMIN_FAIL,
  ADD_ADMIN_SUCCESS,
  ADMIN_CREATED_DONE,
  RESET_ADMIN_DETAILS,
  EDIT_ADMIN_SUCCESS,
  ADMIN_UPDATED_DONE,
  CHANGE_STATUS_ADMIN_SUCCESS,
  ADMIN_STATUS_UPDATED_DONE
} from "../constants/action-types";

const initialState = {
  adminList: {},
  adminDetails: {},
  adminCreated: false,
  isFetching: false,
  adminUpdated: false,
  statusUpdated: false
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        adminList: action.payload.data
      };
    case FETCH_UPC_SUCCESS:
      return {
        ...state,
        upcData: action.payload.data
      };
    case FETCH_ADMIN:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_ADMIN_SUCCESS:
      return {
        ...state,
        adminDetails: action.payload.data,
        isFetching: false
      };
    case FETCH_ADMIN_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case RESET_ADMIN_DETAILS:
      return {
        ...state,
        adminDetails: {}
      };
    case ADD_ADMIN_SUCCESS:
      return {
        ...state,
        adminCreated: true
      };

    case EDIT_ADMIN_SUCCESS:
      let localDataEdit = { ...state.adminDetails };
      localDataEdit = action.payload.data;
      return {
        ...state,
        adminDetails: {
          ...localDataEdit
        },
        adminUpdated: true
      };
    case CHANGE_STATUS_ADMIN_SUCCESS:
      let localData = { ...state.adminDetails };
      let localTrip = { ...localData.tripInfo };
      localData = action.payload.data;
      localData.tripInfo = localTrip;
      return {
        ...state,
        adminDetails: {
          ...localData
        },
        statusUpdated: true
      };
    case ADMIN_STATUS_UPDATED_DONE:
      return {
        ...state,
        statusUpdated: false
      };
    case ADMIN_UPDATED_DONE:
      return {
        ...state,
        adminUpdated: false
      };

    case ADMIN_CREATED_DONE:
      return {
        ...state,
        adminCreated: false
      };
    default:
      return state;
  }
};

export default adminReducer;
