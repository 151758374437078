// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  FETCH_PAYMENT_CONFIG,
  FETCH_CANCELLATION_FEE,
  EDIT_PAYMENT,
  PAYMENT_UPDATED_DONE,
  EDIT_CANCELLATION_FEE,
  EDIT_CANCELLATION_FEE_DONE,
  RESET_PAYMENT,
  RESET_CANCELLATION_FEE,
  FETCH_GRAPH
} from "../constants/action-types";

export const getPaymentConfig = () => {
  return {
    type: FETCH_PAYMENT_CONFIG,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.revenue + "percentage",
        method: "GET"
      }
    }
  };
};

export const getCancelFeeConfig = () => {
  return {
    type: FETCH_CANCELLATION_FEE,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.revenue + "cancellation-fee",
        method: "GET"
      }
    }
  };
};

export const editCancelFeeConfig = canceFeeData => {
  return {
    type: EDIT_CANCELLATION_FEE,
    payload: {
      request: {
        url: apiURLs.revenue + "cancellation-fee",
        method: "PUT",
        data: canceFeeData
      }
    }
  };
};
export const getGraphData = config => {
  // if (config.daily) {
  config.startDate = new Date(
    new Date(config.startDate).setUTCHours(0, 0, 0, 0)
  ).toISOString();
  config.endDate = new Date(
    new Date(config.endDate).setUTCHours(23, 59, 59, 0)
  ).toISOString();
  // }
  return {
    type: FETCH_GRAPH,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.revenue + "graph",
        method: "POST",
        data: config
      }
    }
  };
};
export const cancelFeeUpdateDone = () => {
  return {
    type: EDIT_CANCELLATION_FEE_DONE
  };
};

export const editPaymentConfig = paymentData => {
  return {
    type: EDIT_PAYMENT,
    payload: {
      request: {
        url: apiURLs.revenue + "percentage",
        method: "PUT",
        data: paymentData
      }
    }
  };
};
export const paymentUpdateDone = () => {
  return {
    type: PAYMENT_UPDATED_DONE
  };
};
export const resetPayment = () => {
  return {
    type: RESET_PAYMENT
  };
};

export const resetCancelFee = () => {
  return {
    type: RESET_CANCELLATION_FEE
  };
};
