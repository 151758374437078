import {
    LOGOUT_SUCCESS,
    CHANGE_PASSWORD_SUCCESS,
    LOGOUT_ADMIN_SUCCESS
} from "../constants/action-types";

import {AUTH_DATA} from "../../config/local-storage-keys";

const initialState = {
    logout: false ,
    changePasswordData: {
        changePasswordSuccess: false
    },
};

const profileAPIData = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT_SUCCESS:
            localStorage.removeItem(AUTH_DATA); 
            return {
                ...state, 
                logout: true
            };
        case LOGOUT_ADMIN_SUCCESS:
            return {
              ...state,
              logout: false
            };
        
        
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state, 
                changePasswordData: {
                    changePasswordSuccess: action.payload.success
                }
            };
        default:
            return state;
    }
};

export default profileAPIData;