import {
  FETCH_PAYMENT_CONFIG,
  FETCH_PAYMENT_CONFIG_SUCCESS,
  FETCH_CANCELLATION_FEE,
  FETCH_CANCELLATION_FEE_SUCCESS,
  EDIT_PAYMENT_SUCCESS,
  PAYMENT_UPDATED_DONE,
  EDIT_CANCELLATION_FEE_SUCCESS,
  EDIT_CANCELLATION_FEE_DONE,
  RESET_PAYMENT,
  RESET_GRAPH,
  RESET_CANCELLATION_FEE,
  FETCH_GRAPH,
  FETCH_GRAPH_SUCCESS
} from "../constants/action-types";

const initialState = {
  paymentConfig: {},
  cancelFeeConfig: {},
  revenueGraphData: {},
  isFetching: false,
  isFetchingGraph: false,
  paymentUpdated: false,
  cancelFeeUpdated: false
};

const revenueReducer = (state = initialState, action) => {
  // console.log("revenue_action",action)
  switch (action.type) {
    case FETCH_PAYMENT_CONFIG:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_PAYMENT_CONFIG_SUCCESS:
      return {
        ...state,
        paymentConfig: action.payload.data,
        isFetching: false
      };
    case FETCH_CANCELLATION_FEE:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_GRAPH:
      return {
        ...state,
        isFetchingGraph: true,
        revenueGraphData: {}
      };
    case FETCH_CANCELLATION_FEE_SUCCESS:
      return {
        ...state,
        cancelFeeConfig: action.payload.data,
        isFetching: false
      };
    case FETCH_GRAPH_SUCCESS:
      return {
        ...state,
        revenueGraphData: action.payload.data,
        isFetchingGraph: false
      };
    case EDIT_PAYMENT_SUCCESS:
      return {
        ...state,
        // paymentConfig: action.payload.data.payment,
        paymentUpdated: true
      };
    case PAYMENT_UPDATED_DONE:
      return {
        ...state,
        paymentUpdated: false
      };
    case EDIT_CANCELLATION_FEE_SUCCESS:
      return {
        ...state,
        cancelFeeConfig: action.payload.data.cancellationFee,
        cancelFeeUpdated: true
      };
    case EDIT_CANCELLATION_FEE_DONE:
      return {
        ...state,
        cancelFeeUpdated: false
      };

    case RESET_PAYMENT:
      return {
        ...state,
        paymentConfig: {}
      };
    case RESET_GRAPH:
      return {
        ...state,
        revenueGraphData: {}
      };
    case RESET_CANCELLATION_FEE:
      return {
        ...state,
        cancelFeeConfig: {}
      };

    default:
      return state;
  }
};

export default revenueReducer;
