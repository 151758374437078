// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  FETCH_RIDERS,
  FETCH_UPC,
  ADD_RIDER,
  ADD_UPC,
  EDIT_RIDER,
  CHANGE_RIDER_STATUS,
  FETCH_RIDER,
  SET_PLOT_COORDINATE,
  RIDER_CREATED_DONE,
  RIDER_UPDATED_DONE,
  RIDER_STATUS_UPDATED_DONE,
  UPC_UPDATED_DONE,
  RESET_DETAILS,
  FETCH_RIDER_CARD_LIST,
  ADD_UPC_CREDIT,
  RESET_RIDER_PASSWORD
} from "../constants/action-types";

export const getRiderList = searchObj => {
  return {
    type: FETCH_RIDERS,
    hideToast: true,
    payload: {
      request: {
        url: `${apiURLs.rider}list`,
        method: "POST",
        params: {
          skip: searchObj.skip,
          limit: searchObj.limit
        },
        data: {
          sortConfig: searchObj.sortConfig,
          filters: searchObj.filters
        }
      }
    }
  };
};
export const searchUpc = (searchKey, id) => {
  return {
    type: FETCH_UPC,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.searchUpc,
        method: "GET",
        params: {
          searchKey: searchKey,
          riderId: id
        }
      }
    }
  };
};

export const addRider = riderData => {
  return {
    type: ADD_RIDER,
    payload: {
      request: {
        url: apiURLs.rider + "add",
        method: "POST",
        data: riderData
      }
    }
  };
};
export const addUpc = upcData => {
  return {
    type: ADD_UPC,
    payload: {
      request: {
        url: apiURLs.addUpc,
        method: "POST",
        data: upcData
      }
    }
  };
};
export const addUpcCredit = (id, upcData) => {
  return {
    type: ADD_UPC_CREDIT,
    payload: {
      request: {
        url: apiURLs.riderAddCredit(id),
        method: "PUT",
        data: upcData
      }
    }
  };
};


export const editRider = (id, riderData) => {
  return {
    type: EDIT_RIDER,
    payload: {
      request: {
        url: apiURLs.rider + id,
        method: "PUT",
        data: riderData
      }
    }
  };
};
export const changeRiderStatus = (id, riderData) => {
  return {
    type: CHANGE_RIDER_STATUS,
    payload: {
      request: {
        url: apiURLs.rider + id + "/change-status",
        method: "PUT",
        data: riderData
      }
    }
  };
};

export const fetchRider = id => {
  return {
    type: FETCH_RIDER,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.rider + id,
        method: "GET"
      }
    }
  };
};

export const fetchRiderCardList = (riderId) => {
  return {
    type: FETCH_RIDER_CARD_LIST,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.riderCardList + riderId,
        method: "POST"
      }
    }
  };
}


export const setPlotCoordiate = (data, multiple) => {
  return {
    type: SET_PLOT_COORDINATE,
    plotData: data,
    multiple: multiple
  };
};

export const riderCreatedDone = () => {
  return {
    type: RIDER_CREATED_DONE
  };
};
export const riderUpdatedDone = () => {
  return {
    type: RIDER_UPDATED_DONE
  };
};
export const statusUpdatedDone = () => {
  return {
    type: RIDER_STATUS_UPDATED_DONE
  };
};
export const upcUpdatedDone = () => {
  return {
    type: UPC_UPDATED_DONE
  };
};

export const resetDetails = () => {
  return {
    type: RESET_DETAILS
  };
};

export const resetRiderPassword = (id) => {
  return {
    type: RESET_RIDER_PASSWORD,
    payload: {
      request: {
        url: apiURLs.rider + id + "/reset-password",
        method: "PUT",
      }
    }
  };
};
