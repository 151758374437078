import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import ScrollArea from "react-scrollbar";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import DemoUserImage from "../../../../assets/images/default-profile-pic.png";
import contentStyles from "../../../auth-layout/content.styles";
import globalstyles from "../../../../globalstyles";
import styles from "./styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import StarRatings from "react-star-ratings";
import Modal from "@material-ui/core/Modal";
import classNames from "classnames";
import {
  faStar, faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchBill, resetDetails, settleBill, settleTransfer, editBill, billUpdatedDone } from "../../../../store/actions/billing";
import CountryCodesConfig from "../../../../config/country-codes";
import {
  PastTripStatusObject,
  TripTypeObject,
  ActivityList,
  RiderActivityList,
  DriverActivityList,
  AdminActivityList,
  PaymentStatus
} from "../../../../config/trip";
import moment from "moment";
library.add(faStar, faUpload);

function TabContainer({ children }) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  };
}

const modalStyles = theme => ({
  modalPaper: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    "& $profileImagePlaceholder": {
      margin: "0 auto 20px"
    },
    "& $profileImageUploaderContainer": {
      left: "0",
      right: "0",
      margin: "auto"
    }
  }
});


class BillingDetailsUnsettled extends Component {
  state = {
    single: null,
    labelWidth: 0,
    breakdownDetailModal: false,
    editbillModal: false,
    settleBillModal: false,
    settleShareBillModal: false,
    editTripDetails: {},
    currentTripPaymentDetails: {},
    billingDetails: {
      riderName: "",
      riderAvgRating: 0,
      riderEmail: "",
      riderPhoneNumber: "",
      riderId: "",
      driverName: "",
      driverAvgRating: 0,
      driverEmail: "",
      driverPhoneNumber: "",
      driverId: "",
      driverVehicleType: "",
      driverVehicleMake: "",
      driverVehicleModel: "",
      driverVehicleColor: "",
      tripReqBookingId: "",
      tripType: "Fixed",
      noOfPassenger: 0,
      tripReqTime: "",
      pickupTimeRequested: "",
      pickupTimeActual: "",
      pickupLocationRequested: "",
      pickupLocationActual: "",
      dropAddressRequested: "",
      dropAddressActual: "",
      destinationAddressActual: "",
      dropOffTime: "",
      tripStatus: 0,
      travelDistance: 0,
      waitingTime: 0,
      travelTime: 0,
      suvFare: 0,
      sedanFare: 0,
      paymentStatus: "",
      settleStatus: 1,
      editableBill: false,
      transferStatus: 1,
      activityList: [],
      upcCreditUsed: 0
    },
    errors: {},
    haveImage: false,
    tabValue: 0
  };

  tripTypeList = TripTypeObject;

  vehicleTypes = {
    1: "Sedan",
    2: "SUV"
  };

  statusList = PastTripStatusObject;

  activityLogMessageList = ActivityList;

  riderActivities = RiderActivityList;
  driverActivities = DriverActivityList;
  adminActivities = AdminActivityList;

  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true
    });
    this.props.fetchBill(this.props.match.params.tripId);
  }

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }
  billInfoValidation = () => {
    let fields = { ...this.state.editTripDetails };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    if (!fields.baseCharge.toString().trim()) {
      errors.baseCharge = true;
      hasErrors = true;
    }
    if (!fields.remainingTripCharge.toString().trim()) {
      errors.remainingTripCharge = true;
      hasErrors = true;
    }

    if (!fields.tip.toString().trim()) {
      errors.tip = true;
      hasErrors = true;
    }

    if (!fields.waitingCharge.toString().trim()) {
      errors.waitingCharge = true;
      hasErrors = true;
    }

    if (!fields.additionalChargeForDriver.toString().trim()) {
      errors.additionalChargeForDriver = true;
      hasErrors = true;
    }

    if (!fields.discount.toString().trim()) {
      errors.discount = true;
      hasErrors = true;
    }

    this.setState({ errors: errors });
    return hasErrors;


  }

  settleBillEdit = () => {
    if (!this.billInfoValidation()) {
      let payload = { ...this.state.editTripDetails }

      payload.baseCharge = Number(payload.baseCharge)
      payload.waitingCharge = Number(payload.waitingCharge)
      payload.tip = Number(payload.tip)
      payload.remainingTripCharge = Number(payload.remainingTripCharge)
      payload.additionalChargeForDriver = Number(payload.additionalChargeForDriver)

      let objUpdate = {}
      objUpdate.modifiedPaymentBreakdown = payload
      this.props.editBill(this.props.match.params.tripId, objUpdate)
      this.setState({ ...this.state, detailsFetched: false, editbillModal: false, loading: true })


    }

  }

  settleBill = () => {
    this.setState({ ...this.state, detailsFetched: false, settleBillModal: false, loading: true })
    this.props.settleBill(this.props.match.params.tripId);
  }

  settleShareBill = () => {
    this.setState({ ...this.state, detailsFetched: false, settleShareBillModal: false, loading: true })
    this.props.settleTransfer(this.props.match.params.tripId);
  }



  filterChange = event => {

    let fields = { ...this.state.editTripDetails };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    let presentData = { ...this.props.data.billingReducer.billingDetails }
    let presentPaymentData = { ...presentData.paymentInfo.paymentBreakdown }

    let baseCharge = Number(fields.baseCharge);
    let remainingTripCharge = Number(fields.remainingTripCharge);
    let waitingCharge = Number(fields.waitingCharge);
    let additionalChargeForDriver = Number(fields.additionalChargeForDriver);
    let penaltyCharge = Number(fields.penaltyCharge);

    let tip = Number(fields.tip);
    let discount = Number(fields.discount)
    let payableAmount = 0
    let totalFare = 0
    // console.log(presentData)
    // console.log(presentData.tripType,"presentData.tripType")
    switch (presentData.tripType) {
      case 1:
        totalFare = baseCharge + remainingTripCharge + waitingCharge + additionalChargeForDriver
        payableAmount =
          (totalFare + tip) - discount;
        fields.payableAmount = payableAmount;
        fields.totalFare = totalFare


        break;
      case 2:
        totalFare = baseCharge + remainingTripCharge + waitingCharge + additionalChargeForDriver
        payableAmount =
          (totalFare + tip) - discount;
        fields.payableAmount = payableAmount;
        fields.totalFare = totalFare


        break;
      case 3:

        totalFare = baseCharge + remainingTripCharge + waitingCharge + additionalChargeForDriver
        payableAmount =
          (totalFare + tip) - discount;
        fields.payableAmount = payableAmount;
        fields.totalFare = totalFare

        break;
      case 4:
        payableAmount =
          (baseCharge + remainingTripCharge + waitingCharge + additionalChargeForDriver + penaltyCharge + tip) - discount;
        fields.payableAmount = payableAmount;

        break;
      case 5:
        payableAmount =
          (baseCharge + remainingTripCharge + waitingCharge + additionalChargeForDriver + penaltyCharge + tip) - discount;
        fields.payableAmount = payableAmount;

        break;
    }


    this.setState({ ...this.state, editTripDetails: fields, errors });
  };

  timeDifference = (date1, date2) => {
    var difference = date1 - date2;

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60

    // var secondsDifference = Math.floor(difference/1000);

    let strTimeDiff = "";
    if (daysDifference !== 0) {
      strTimeDiff += daysDifference + ' day '
    }
    if (hoursDifference !== 0) {
      strTimeDiff += hoursDifference + ' hour '
    }
    if (minutesDifference !== 0) {
      strTimeDiff += minutesDifference + ' minute '
    }

    return strTimeDiff
  }


  componentDidUpdate(prevProps, prevState) {

    if (this.props.data.billingReducer.billingDetails &&
      Object.keys(this.props.data.billingReducer.billingDetails).length) {
      if (this.props.data.billingReducer.billingDetails.transactionInfo.paymentStatus === 2 &&
        this.props.data.billingReducer.billingDetails.transactionInfo.transferStatus === 2
      ) {
        this.props.history.push("/web/auth/billing");
      }
    }
    if (
      !this.state.detailsFetched &&
      Object.keys(this.props.data.billingReducer.billingDetails).length
    ) {
      // console.log("ssssssssssssssssssssssIn")
      let localTripData = { ...this.state.billingDetails };
      localTripData.riderProfilePicture = this.props.data.billingReducer.billingDetails.rider.profilePicture;

      localTripData.riderName = this.props.data.billingReducer.billingDetails.rider.fullName || "N/A";
      localTripData.riderAvgRating = this.props.data.billingReducer.billingDetails.rider.ratingData && this.props.data.billingReducer.billingDetails.rider.ratingData.total ?
        (this.props.data.billingReducer.billingDetails.rider.ratingData.total / this.props.data.billingReducer.billingDetails.rider.ratingData.count).toFixed(1) : 0;
      localTripData.riderEmail = this.props.data.billingReducer.billingDetails.rider.email || "N/A";
      localTripData.riderPhoneNumber = this.props.data.billingReducer.billingDetails.rider.phone ?
        CountryCodesConfig.filter(item => {
          return item.code === this.props.data.billingReducer.billingDetails.rider.phone.countryCode;
        })[0].dial_code + this.formatNumber(this.props.data.billingReducer.billingDetails.rider.phone.number) : "N/A";
      localTripData.riderId = this.props.data.billingReducer.billingDetails.rider.displayId || "N/A";

      localTripData.driverProfilePicture = this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.profilePicture;
      localTripData.driverName = this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.fullName || "N/A";
      localTripData.driverAvgRating = this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.ratingData &&
        this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.ratingData.total ?
        (this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.ratingData.total / this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.ratingData.count).toFixed(1) : 0;
      localTripData.driverEmail = this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.email || "N/A";
      localTripData.driverPhoneNumber = this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.phone ?
        CountryCodesConfig.filter(item => {
          return item.code === this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.phone.countryCode;
        })[0].dial_code +
        this.formatNumber(this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.phone.number) : "N/A";
      localTripData.driverId = this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.displayId || "N/A";

      localTripData.driverVehicleType = this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.vehicleInfo ? this.vehicleTypes[this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.vehicleInfo.vehicleType] : "N/A";
      localTripData.driverVehicleMake = this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.vehicleInfo.vehicleMake : "N/A";
      localTripData.driverVehicleModel = this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.vehicleInfo.vehicleModel : "N/A";
      localTripData.driverVehicleColor = this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.billingReducer.billingDetails.assignedDriverInfo.driver.vehicleInfo.vehicleColor : null;

      localTripData.tripReqBookingId = this.props.data.billingReducer.billingDetails.displayId || "N/A";

      localTripData.tripType = this.tripTypeList[this.props.data.billingReducer.billingDetails.tripType] || "N/A";
      localTripData.noOfPassenger = this.props.data.billingReducer.billingDetails.requestedNumberOfSeats || "0";
      if (this.props.data.billingReducer.billingDetails.bookingTime) {
        localTripData.tripReqTime = moment(this.props.data.billingReducer.billingDetails.bookingTime).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.tripReqTime = "";
      }
      if (this.props.data.billingReducer.billingDetails.pickupTime.requested) {
        localTripData.pickupTimeRequested = moment(this.props.data.billingReducer.billingDetails.pickupTime.requested).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.pickupTimeRequested = "";
      }
      if (this.props.data.billingReducer.billingDetails.pickupTime.actual) {
        localTripData.pickupTimeActual = moment(this.props.data.billingReducer.billingDetails.pickupTime.actual).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.pickupTimeActual = "";
      }
      console.log(this.props.data.billingReducer.billingDetails.paymentInfo, 'this.props.data.billingReducer.billingDetails.paymentInfo')
      localTripData.paymentInfo = this.props.data.billingReducer.billingDetails.paymentInfo;

      localTripData.pickupLocationRequested = this.props.data.billingReducer.billingDetails.pickupAddress.requested || "N/A";
      localTripData.pickupLocationActual = this.props.data.billingReducer.billingDetails.pickupAddress.actual || "N/A";

      localTripData.dropAddressRequested = this.props.data.billingReducer.billingDetails.dropAddress.requested || "N/A";
      localTripData.dropAddressActual = this.props.data.billingReducer.billingDetails.dropAddress.actual || "N/A";

      localTripData.destinationAddressActual = this.props.data.billingReducer.billingDetails.dropAddress.actual || "N/A"; //it needs to be handled depending on the trip type

      if (this.props.data.billingReducer.billingDetails.dropTime.actual) {
        localTripData.dropOffTime = moment(this.props.data.billingReducer.billingDetails.dropTime.actual).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.dropOffTime = "";
      }

      localTripData.tripStatus = this.props.data.billingReducer.billingDetails.tripStatus || "N/A";

      localTripData.travelDistance = this.props.data.billingReducer.billingDetails.travelDistance.actual || "0";
      localTripData.waitingTime = this.props.data.billingReducer.billingDetails.waitingTime || "0";
      // console.log(this.props.data.billingReducer.billingDetails,"this.props.data.billingReducer.billingDetails")
      if (this.props.data.billingReducer.billingDetails.dropTime.actual && this.props.data.billingReducer.billingDetails.pickupTime.actual) {
        // console.log(new Date(this.props.data.billingReducer.billingDetails.dropTime.actual).getTime(),"pickupActual")
        // console.log(new Date(this.props.data.billingReducer.billingDetails.dropTime.actual) - new Date(this.props.data.billingReducer.billingDetails.pickupTime.actual))

        let localPickupActual = new Date(this.props.data.billingReducer.billingDetails.pickupTime.actual).getTime();
        let localDropActual = new Date(this.props.data.billingReducer.billingDetails.dropTime.actual).getTime();

        let differenceTime = this.timeDifference(localDropActual, localPickupActual)
        // console.log(differenceTime,"differenceTime")

        // localTripData.travelTime = Math.ceil((new Date(this.props.data.billingReducer.billingDetails.dropTime.actual) - new Date(this.props.data.billingReducer.billingDetails.pickupTime.actual)) / 3600000)
        localTripData.travelTime = differenceTime;
      } else {
        localTripData.travelTime = "0";
      }

      //calculating SUV & Sedan fare
      let fareChartInfo = this.props.data.billingReducer.billingDetails.fareChartInfo;
      let dropTime = this.props.data.billingReducer.billingDetails.dropTime;
      let pickupTime = this.props.data.billingReducer.billingDetails.pickupTime;
      let travelDistance = this.props.data.billingReducer.billingDetails.travelDistance;



      let localEditTrip = { ...this.state.editTripDetails }
      localEditTrip = { ...this.props.data.billingReducer.billingDetails.paymentInfo.paymentBreakdown };
      for (let key in localEditTrip) {
        if (localEditTrip.hasOwnProperty(key)) {
          localEditTrip[key] = localEditTrip[key].toFixed(2)
        }
      }
      switch (this.props.data.billingReducer.billingDetails.tripType) {
        case 1:
          localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.suv
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
          localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.sedan
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;
          if (travelDistance.requested > 10) {
            localTripData.suvFare -= (localTripData.suvFare * (fareChartInfo.fixed.discountPercentage / 100));
            localTripData.sedanFare -= (localTripData.sedanFare * (fareChartInfo.fixed.discountPercentage / 100));
          }
          break;
        case 2:
          localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.suv
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
          localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.sedan
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;
          if (travelDistance.requested > 10) {
            localTripData.suvFare -= (localTripData.suvFare * (fareChartInfo.fixed.discountPercentage / 100));
            localTripData.sedanFare -= (localTripData.sedanFare * (fareChartInfo.fixed.discountPercentage / 100));
          }
          break;
        case 3:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + this.props.data.billingReducer.billingDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + this.props.data.billingReducer.billingDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.sedan;
          break;
        case 4:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + fareChartInfo.duration.halfDay.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + fareChartInfo.duration.halfDay.perHourCost.sedan;
          break;
        case 5:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + fareChartInfo.duration.fullDay.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + fareChartInfo.duration.fullDay.perHourCost.sedan;
          break;
      }
      localTripData.upcCreditUsed = this.props.data.billingReducer.billingDetails.transactionInfo.upcCreditsUsed
      localTripData.paymentStatus = this.props.data.billingReducer.billingDetails.transactionInfo &&
        this.props.data.billingReducer.billingDetails.transactionInfo.paymentStatus;
      localTripData.transferStatus = this.props.data.billingReducer.billingDetails.transactionInfo &&
        this.props.data.billingReducer.billingDetails.transactionInfo.transferStatus;
      localTripData.activityList = this.props.data.billingReducer.billingDetails.activityLog;
      // console.log(this.props.data.billingReducer.billingDetails.transactionInfo.paymentStatus,"this.props.data.billingReducer.billingDetails.transactionInfo.paymentStatus")
      localTripData.settleStatus = this.props.data.billingReducer.billingDetails.transactionInfo.paymentStatus
      localTripData.transferStatus = this.props.data.billingReducer.billingDetails.transactionInfo.transferStatus

      let distanceTravelled = this.props.data.billingReducer.billingDetails.travelDistance.actual
      if (distanceTravelled > 10) {
        localTripData.suvFare = localTripData.suvFare - ((localTripData.suvFare) * (fareChartInfo.discountPercentage / 100))
        localTripData.sedanFare = localTripData.sedanFare - ((localTripData.sedanFare) * (fareChartInfo.discountPercentage / 100))
      }

      this.setState({
        ...this.state,
        billingDetails: localTripData,
        editTripDetails: localEditTrip,
        currentTripPaymentDetails: localEditTrip,
        detailsFetched: true,
        loading: false
      });
    }
  }

  componentWillUnmount() {
    this.props.resetDetails();
  }

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  }
  openDetailsBreakdownModal = () => {
    this.setState({ breakdownDetailModal: true });
  };
  closeDetailsBreakdownModal = () => {
    this.setState({ ...this.state, breakdownDetailModal: false });
  };
  openEditBillModal = () => {
    this.setState({ editbillModal: true });
  };
  closeEditBillModal = () => {
    let currentTripPaymentdata = { ...this.state.currentTripPaymentDetails }
    this.setState({ ...this.state, editbillModal: false, editTripDetails: currentTripPaymentdata });
  };

  openSettleBillModal = () => {
    this.setState({ ...this.state, settleBillModal: true });
  };
  closeSettleBillModal = () => {
    this.setState({ ...this.state, settleBillModal: false });
  };

  openSettleShareBillModal = () => {
    this.setState({ ...this.state, settleShareBillModal: true });
  };
  closeSettleShareBillModal = () => {
    this.setState({ ...this.state, settleShareBillModal: false });
  };

  render() {
    const { classes, theme } = this.props;
    const { tabValue } = this.state;


    return (
      <MuiThemeProvider theme={theme}>
        {!this.state.loading ? (
          <div>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                    <Typography component="p">
                      <span className={classes.pageHeader}>
                        {
                          this.props.data.literalsReducer.Billing.PageHeader
                        }
                      </span>
                      <span className={classes.PageDescription}>

                        {
                          this.props.data.literalsReducer.UnSettledBillDetails
                            .PageDescription
                        }
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.headerRightButton}
                      onClick={this.backToPrevPage}
                    >
                      <ArrowBackIosIcon className={classes.leftIcon} /> Back
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <Grid container className={classes.rightPaper}>
                    <Grid item xs={12} sm={9}>
                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Tabs value={tabValue} onChange={this.handleTabChange} className={classes.tabPan}>
                              <Tab label="Chauffeur Details" />
                              <Tab label="Rider Details" />
                            </Tabs>
                            {tabValue === 0 && <TabContainer>
                              {this.state.billingDetails.driverName.length ?
                                <div className={classes.tabDetailsCont}>
                                  <div className={classes.profileImageWrap}>
                                    <div className={classes.profileImagePlaceholder}>
                                      {this.state.billingDetails.driverProfilePicture ?

                                        <div style={{
                                          backgroundSize: "cover",
                                          backgroundRepeat: "no-repeat",
                                          width: "100%",
                                          height: "100%",
                                          backgroundImage: `url( ${this.state.billingDetails.driverProfilePicture} )`
                                        }}></div>
                                        : <img
                                          src={DemoUserImage}
                                          alt="no-menu-image"
                                          style={{ width: "100%" }}
                                        />}
                                    </div>
                                    <div style={{ marginLeft: "5px" }}>
                                      <span>{this.state.billingDetails.driverName || "N/A"}</span>

                                      <div
                                        className={classNames(
                                          classes.ratingShow,
                                          classes.description
                                        )}
                                      >
                                        <StarRatings
                                          rating={Number(this.state.billingDetails.driverAvgRating)}
                                          starRatedColor="#ffc000"
                                          numberOfStars={10}
                                          name="rating"
                                          starDimension="15px"
                                          starSpacing="2px"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.Email} -
                                    <span>{this.state.billingDetails.driverEmail || "N/A"}</span>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.PhoneNo} -
                                    <span>{this.state.billingDetails.driverPhoneNumber || "N/A"}</span>
                                  </div>
                                  {/* <div className={classes.description}>
                                {this.props.data.literalsReducer.DriverDetails.DriverId} - 
                                <span>{this.state.billingDetails.driverId || "N/A"}</span>
                              </div> */}
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.CarType} -
                                    <span>{this.state.billingDetails.driverVehicleType || "N/A"}</span>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.CarMaker} -
                                    <span>{this.state.billingDetails.driverVehicleMake || "N/A"}</span>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.CarModel} -
                                    <span>{this.state.billingDetails.driverVehicleModel || "N/A"}</span>
                                  </div>
                                  <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.CarColor} -
                                    {this.state.billingDetails.driverVehicleColor ?
                                      <span className={classes.colorView} style={{ background: this.state.billingDetails.driverVehicleColor }}></span>
                                      : <span>N/A</span>}
                                  </div>
                                </div>
                                : <div className={classes.tabDetailsCont} style={{ textAlign: "center" }}> Driver not assigned</div>}
                            </TabContainer>}
                            {tabValue === 1 && <TabContainer>
                              <div className={classes.tabDetailsCont}>
                                <div className={classes.profileImageWrap}>
                                  <div className={classes.profileImagePlaceholder}>
                                    {this.state.billingDetails.riderProfilePicture ?

                                      <div style={{
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        width: "100%",
                                        height: "100%",
                                        backgroundImage: `url( ${this.state.billingDetails.riderProfilePicture} )`
                                      }}></div>
                                      : <img
                                        src={DemoUserImage}
                                        alt="no-menu-image"
                                        style={{ width: "100%" }}
                                      />}
                                  </div>
                                  <div style={{ marginLeft: "5px" }}>
                                    <span>{this.state.billingDetails.riderName || "N/A"}</span>

                                    <div
                                      className={classNames(
                                        classes.ratingShow,
                                        classes.description
                                      )}
                                    >
                                      <StarRatings
                                        rating={Number(this.state.billingDetails.riderAvgRating)}
                                        starRatedColor="#ffc000"
                                        numberOfStars={10}
                                        name="rating"
                                        starDimension="20px"
                                        starSpacing="2px"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.RiderDetails.Email} -
                                  <span>{this.state.billingDetails.riderEmail || "N/A"}</span>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.RiderDetails.PhoneNo} -
                                  <span>{this.state.billingDetails.riderPhoneNumber || "N/A"}</span>
                                </div>
                                {/* <div className={classes.description}>
                                {this.props.data.literalsReducer.RiderDetails.RiderId} - 
                                <span>{this.state.billingDetails.riderId || "N/A"}</span>
                              </div> */}
                              </div>
                            </TabContainer>}
                          </Grid>
                          <Grid item xs={12} sm={6} className={classes.breakDownPart}>
                            <h3>Fare Breakdown</h3>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.Estimation} -
                              <Chip label="SUV" className={classes.chip} /><span>&#36;{this.state.billingDetails.suvFare.toFixed(2) || "0"}</span>
                              {this.state.billingDetails.noOfPassenger < 5 ?
                                <span>
                                  <Chip label="SEDAN" className={classes.chip} /><span>&#36;{this.state.billingDetails.sedanFare.toFixed(2) || "0"}</span>
                                </span> : null}
                            </div>
                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                            {this.state.billingDetails.tripStatus === 13 || this.state.billingDetails.tripStatus === 14 ? <span>
                              <div >
                                <div style={{ width: '75%', display: 'inline-block' }}><h3>Payment Breakdown</h3></div>
                                <div style={{ width: '25%', display: 'inline-block' }}>
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    className={classes.addButton}
                                    onClick={this.openDetailsBreakdownModal}
                                  >
                                    Shares
                                  </Button>
                                </div>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.PaymentStatus} -
                                <span className={classes.activeStatus} style={{ float: "right" }}>{PaymentStatus[this.state.billingDetails.paymentStatus]}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.TransferStatus} -
                                <span className={classes.activeStatus} style={{ float: "right" }}>{PaymentStatus[this.state.billingDetails.transferStatus]}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.BaseFare} -
                                <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.billingDetails.paymentInfo.paymentBreakdown.baseCharge).toFixed(2) : "N/A"}</span>

                              </div>

                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.RemainingTripCharge} -
                                <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.billingDetails.paymentInfo.paymentBreakdown.remainingTripCharge).toFixed(2) : "N/A"}</span>
                              </div>

                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.WaitingCharge} -
                                <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.billingDetails.paymentInfo.paymentBreakdown.waitingCharge).toFixed(2) : "N/A"}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.AdditionalChargeForDriver} -
                                <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.billingDetails.paymentInfo.paymentBreakdown.additionalChargeForDriver).toFixed(2) : "N/A"}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.CancelCharge} -
                                <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.billingDetails.paymentInfo.paymentBreakdown.cancellationCharge).toFixed(2) : "N/A"}</span>
                              </div>
                              <hr />
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.SubTotalFare} -
                                <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.billingDetails.paymentInfo.paymentBreakdown.totalFare).toFixed(2) : "N/A"}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.Tip} -
                                <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.billingDetails.paymentInfo.paymentBreakdown.tip).toFixed(2) : "N/A"}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.Discount} -
                                <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.billingDetails.paymentInfo.paymentBreakdown.discount).toFixed(2) : "N/A"}</span>
                              </div>
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.RiderCredit} -
                                <span style={{ float: "right" }}>&#36;{this.state.billingDetails.upcCreditUsed !== "" ?
                                  (this.state.billingDetails.upcCreditUsed).toFixed(2) : "N/A"}</span>
                              </div>
                              <hr />
                              <div className={classes.description}>
                                {this.props.data.literalsReducer.PastTripDetails.TotalFare} -
                                <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.paymentBreakdown ?
                                  (this.state.billingDetails.paymentInfo.paymentBreakdown.payableAmount).toFixed(2) : "N/A"}</span>
                              </div>
                              {/* <div>
                                <a href={`/web/past-trips/download-invoice/${this.props.match.params.tripId}`} className={classes.linkElem} target="_blank">Download Invoice</a>
                            </div> */}
                            </span> : null}

                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                            <div className={classes.breakDownPartAction}>
                              <Fragment>

                                <Button
                                  size="small"
                                  color="secondary"
                                  variant="contained"
                                  onClick={this.openEditBillModal}
                                  disabled={(this.state.billingDetails.settleStatus === 2 || this.state.billingDetails.transferStatus === 2) ? true : false}
                                >
                                  Edit Bill
                                </Button>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  disabled={this.state.billingDetails.settleStatus === 1 ? false : true}
                                  className={classes.activeGoButton}
                                  onClick={this.openSettleBillModal}
                                >
                                  Settle Bill
                                </Button>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  disabled={this.state.billingDetails.transferStatus === 1 ? false : true}
                                  className={classes.activeGoButton}
                                  onClick={this.openSettleShareBillModal}
                                >
                                  Settle Transfer
                                </Button>
                              </Fragment>

                            </div>
                          </Grid>

                        </Grid>
                      </Grid>
                      <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.editbillModal}
                        onClose={this.closeEditBillModal}
                      >
                        <div style={getModalStyle()} className={classes.modalPaper}>
                          <Grid item xs={12} sm={12} className={classes.descSection}>
                            <Grid container spacing={24}>
                              <Grid item xs={12} sm={12}>
                                <h2>Edit Bill</h2>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12} sm={6}>
                                  <div className={classes.modalLabel}>Base Fare :</div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <div
                                    className={classes.description}
                                    style={{ marginBottom: "20px" }}

                                  >
                                    <TextField
                                      id="additionalCharge"
                                      label="Base Fare($)"
                                      className={classes.textField}
                                      name="baseCharge"
                                      value={this.state.editTripDetails.baseCharge}
                                      onChange={this.filterChange}
                                      onInput={e => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9\.]/g,
                                          ""
                                        );
                                      }}
                                      inputProps={{
                                        minLength: 1,
                                        maxLength: 5
                                      }}
                                      error={this.state.errors.baseCharge}
                                    />
                                    {this.state.errors.baseCharge ? (
                                      <FormHelperText id="validation-error">
                                        {
                                          this.props.data.literalsReducer.ValidationErrors
                                            .Required
                                        }
                                      </FormHelperText>
                                    ) : null}
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12} sm={6}>
                                  <div className={classes.modalLabel}>{this.props.data.literalsReducer.PastTripDetails.RemainingTripCharge} :</div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <div
                                    className={classes.description}
                                    style={{ marginBottom: "20px" }}

                                  >
                                    <TextField
                                      id="tripFare"
                                      label="Trip Fare($)"
                                      className={classes.textField}
                                      name="remainingTripCharge"
                                      value={this.state.editTripDetails.remainingTripCharge}
                                      onChange={this.filterChange}
                                      onInput={e => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9\.]/g,
                                          ""
                                        );
                                      }}
                                      inputProps={{
                                        minLength: 1,
                                        maxLength: 5
                                      }}
                                      error={this.state.errors.remainingTripCharge}
                                    />
                                    {this.state.errors.remainingTripCharge ? (
                                      <FormHelperText id="validation-error">
                                        {
                                          this.props.data.literalsReducer.ValidationErrors
                                            .Required
                                        }
                                      </FormHelperText>
                                    ) : null}
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12} sm={6}>
                                  <div className={classes.modalLabel}>Tip :</div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <div
                                    className={classes.description}
                                    style={{ marginBottom: "20px" }}

                                  >
                                    <TextField
                                      id="Tip"
                                      label="Tip($)"
                                      className={classes.textField}
                                      name="tip"
                                      value={this.state.editTripDetails.tip}
                                      onChange={this.filterChange}
                                      onInput={e => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9\.]/g,
                                          ""
                                        );
                                      }}
                                      inputProps={{
                                        minLength: 1,
                                        maxLength: 5
                                      }}
                                      error={this.state.errors.tip}
                                    />
                                    {this.state.errors.tip ? (
                                      <FormHelperText id="validation-error">
                                        {
                                          this.props.data.literalsReducer.ValidationErrors
                                            .Required
                                        }
                                      </FormHelperText>
                                    ) : null}
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12} sm={6}>
                                  <div className={classes.modalLabel}>Waiting Charge :</div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <div
                                    className={classes.description}
                                    style={{ marginBottom: "20px" }}

                                  >
                                    <TextField
                                      id="WaitingCharge"
                                      label="Waiting Charge($)"
                                      className={classes.textField}
                                      name="waitingCharge"
                                      value={this.state.editTripDetails.waitingCharge}
                                      onChange={this.filterChange}
                                      onInput={e => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9\.]/g,
                                          ""
                                        );
                                      }}
                                      inputProps={{
                                        minLength: 1,
                                        maxLength: 5
                                      }}
                                      error={this.state.errors.waitingCharge}
                                    />
                                    {this.state.errors.waitingCharge ? (
                                      <FormHelperText id="validation-error">
                                        {
                                          this.props.data.literalsReducer.ValidationErrors
                                            .Required
                                        }
                                      </FormHelperText>
                                    ) : null}
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12} sm={6}>
                                  <div className={classes.modalLabel}>Additional Charge :</div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <div
                                    className={classes.description}
                                    style={{ marginBottom: "20px" }}

                                  >
                                    <TextField
                                      id="additionalCharge"
                                      label="Additional Charge ($)"
                                      className={classes.textField}
                                      name="additionalChargeForDriver"
                                      value={this.state.editTripDetails.additionalChargeForDriver}
                                      onChange={this.filterChange}
                                      onInput={e => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9\.]/g,
                                          ""
                                        );
                                      }}
                                      inputProps={{
                                        minLength: 1,
                                        maxLength: 5
                                      }}
                                      error={this.state.errors.additionalChargeForDriver}
                                    />
                                    {this.state.errors.additionalChargeForDriver ? (
                                      <FormHelperText id="validation-error">
                                        {
                                          this.props.data.literalsReducer.ValidationErrors
                                            .Required
                                        }
                                      </FormHelperText>
                                    ) : null}
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12} sm={6}>
                                  <div className={classes.modalLabel}>Discount :</div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <div
                                    className={classes.description}
                                    style={{ marginBottom: "20px" }}

                                  >
                                    <TextField
                                      id="discount"
                                      label="Discount ($)"
                                      className={classes.textField}
                                      name="discount"
                                      value={this.state.editTripDetails.discount}
                                      onChange={this.filterChange}
                                      onInput={e => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9\.]/g,
                                          ""
                                        );
                                      }}
                                      inputProps={{
                                        minLength: 1,
                                        maxLength: 5
                                      }}
                                      error={this.state.errors.discount}
                                    />
                                    {this.state.errors.discount ? (
                                      <FormHelperText id="validation-error">
                                        {
                                          this.props.data.literalsReducer.ValidationErrors
                                            .Required
                                        }
                                      </FormHelperText>
                                    ) : null}
                                  </div>
                                </Grid>
                              </Grid>
                              <Divider variant="middle" style={{ "margin": "25px 0" }} />
                              <Grid container>
                                <Grid item xs={12} sm={6}>
                                  <div className={classes.modalLabel}>Total Fare :</div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <div
                                    className={classes.description}
                                    style={{ marginTop: "22px" }}
                                  >
                                    {this.state.editTripDetails.payableAmount}
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <div style={{ textAlign: "right" }}>
                                  <Button
                                    size="small"
                                    color="default"
                                    variant="contained"
                                    className={classes.customMargin}
                                    onClick={this.closeEditBillModal}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    style={{ marginLeft: "10px" }}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    className={classNames(
                                      classes.customMargin,
                                      classes.activeGoButton
                                    )}
                                    onClick={this.settleBillEdit}
                                  >
                                    Yes
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Modal>

                      <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.settleBillModal}
                        onClose={this.closeSettleBillModal}
                      >
                        <div style={getModalStyle()} className={classes.modalPaper}>
                          <Grid item xs={12} sm={12} className={classes.descSection}>
                            <Grid container spacing={24}>
                              <Grid item xs={12} sm={12}>
                                <h2>Settle Bill</h2>
                                <div
                                  className={classes.description}
                                  style={{ marginBottom: "20px" }}
                                >
                                  Are you sure you want to settle this bill?
                                </div>
                                <div style={{ textAlign: "right" }}>
                                  <Button
                                    size="small"
                                    color="default"
                                    variant="contained"
                                    className={classes.customMargin}
                                    onClick={this.closeSettleBillModal}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    style={{ marginLeft: "10px" }}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    className={classNames(
                                      classes.customMargin,
                                      classes.activeGoButton
                                    )}
                                    onClick={this.settleBill}
                                  >
                                    Yes
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Modal>

                      <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.settleShareBillModal}
                        onClose={this.closeSettleShareBillModal}
                      >
                        <div style={getModalStyle()} className={classes.modalPaper}>
                          <Grid item xs={12} sm={12} className={classes.descSection}>
                            <Grid container spacing={24}>
                              <Grid item xs={12} sm={12}>
                                <h2>Settle Transfer</h2>
                                <div
                                  className={classes.description}
                                  style={{ marginBottom: "20px" }}
                                >
                                  Are you sure you want to settle the transfer?
                                </div>
                                <div style={{ textAlign: "right" }}>
                                  <Button
                                    size="small"
                                    color="default"
                                    variant="contained"
                                    className={classes.customMargin}
                                    onClick={this.closeSettleShareBillModal}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    style={{ marginLeft: "10px" }}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    className={classNames(
                                      classes.customMargin,
                                      classes.activeGoButton
                                    )}
                                    onClick={this.settleShareBill}
                                  >
                                    Yes
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Modal>
                      <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.breakdownDetailModal}
                        onClose={this.closeDetailsBreakdownModal}
                      >
                        <div style={getModalStyle()} className={classes.modalPaper}>
                          <Grid item xs={12} sm={12} className={classes.descSection}>
                            <Grid container spacing={24}>
                              <Grid item xs={12} sm={12}>
                                <h2 style={{ textAlign: 'center' }}>Share Breakdown</h2>
                                <hr />
                                <div className={classes.description}>
                                  Admin Share -
                                  <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.shareBreakDown ?
                                    (this.state.billingDetails.paymentInfo.shareBreakDown.adminShare).toFixed(2) : "N/A"}</span>

                                </div>
                                <div className={classes.description}>
                                  Chauffeur Share -
                                  <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.shareBreakDown ?
                                    (this.state.billingDetails.paymentInfo.shareBreakDown.driverShare).toFixed(2) : "N/A"}</span>

                                </div>

                                <div className={classes.description}>
                                  UPC Bucket Share -
                                  <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.shareBreakDown ?
                                    (this.state.billingDetails.paymentInfo.shareBreakDown.upcBucketShare).toFixed(2) : "N/A"}</span>
                                </div>

                                <div className={classes.description}>
                                  Chauffeur Coordinator share -
                                  <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.shareBreakDown ?
                                    (this.state.billingDetails.paymentInfo.shareBreakDown.driverCoordinatorShare).toFixed(2) : "N/A"}</span>
                                </div>

                                <div className={classes.description}>
                                  Investor Share -
                                  <span style={{ float: "right" }}>&#36;{this.state.billingDetails.paymentInfo && this.state.billingDetails.paymentInfo.shareBreakDown ?
                                    (this.state.billingDetails.paymentInfo.shareBreakDown.investorShare).toFixed(2) : "N/A"}</span>
                                </div>

                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <div style={{ textAlign: "right" }}>
                                  <Button
                                    size="small"
                                    color="default"
                                    variant="contained"
                                    className={classes.customMargin}
                                    onClick={this.closeDetailsBreakdownModal}
                                  >
                                    Cancel
                                  </Button>

                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Modal>

                      <Divider variant="middle" style={{ "margin": "25px 0" }} />
                      <Grid item xs={12} sm={12}>
                        <h3>Req. Details</h3>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={6}>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.BookingId} -
                              <span>{this.state.billingDetails.tripReqBookingId || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.BookingType} -
                              <span>{this.state.billingDetails.tripType || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.NoOfPassenger} -
                              <span>{this.state.billingDetails.noOfPassenger || "0"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ReqAt} -
                              <span>{this.state.billingDetails.tripReqTime || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.PickupTime} -
                              <span>{this.state.billingDetails.pickupTimeRequested || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualPickupTime} -
                              <span>{this.state.billingDetails.pickupTimeActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ReqPickupLocation} -
                              <span>{this.state.billingDetails.pickupLocationRequested || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ReqDestination} -
                              <span>{this.state.billingDetails.dropAddressRequested || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualPickupLocation} -
                              <span>{this.state.billingDetails.pickupLocationActual || "N/A"}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualDestinationLocation} -
                              <span>{this.state.billingDetails.destinationAddressActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualDropOffLocation} -
                              <span>{this.state.billingDetails.dropAddressActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.TimeofDropoff} -
                              <span>{this.state.billingDetails.dropOffTime || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.Status} -
                              <span className={classes.activeStatus}>{this.statusList[this.state.billingDetails.tripStatus]}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.KmsTravelled} -
                              <span>{this.state.billingDetails.travelDistance ? this.state.billingDetails.travelDistance + " Mile" : "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.WaitTime} -
                              <span>{this.state.billingDetails.waitingTime ? this.state.billingDetails.waitingTime + " hour" : "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.TimeOfTravel} -
                              <span>{this.state.billingDetails.travelTime ? this.state.billingDetails.travelTime : "N/A"}</span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.activityLog}>
                      <h3>Activity Log</h3>
                      <ScrollArea
                        speed={0.8}
                        className={classes.scrollContent}
                        contentClassName="content"
                        horizontal={false}
                      >
                        {this.state.billingDetails.activityList.length ?
                          <List className={classes.root}>
                            {this.state.billingDetails.activityList.map(activity => {
                              return <ListItem alignItems="flex-start" key={activity._id}>
                                <span>
                                  {this.riderActivities.indexOf(activity.actionType) > -1 ?
                                    activity.rider.fullName : this.driverActivities.indexOf(activity.actionType) > -1 ?
                                       (activity.driver && activity.driver.fullName || 'Chauffeur') : this.adminActivities.indexOf(activity.actionType) > -1 ?
                                        activity.admin.fullName : null}
                                </span>{this.activityLogMessageList[activity.actionType]}
                                <p>
                                  <span>{moment(activity.timeStamp).format("MM-DD-YYYY, h:mm a")}</span>
                                </p>
                              </ListItem>
                            })}

                          </List> : <div
                            style={{
                              textAlign: "center",
                              padding: "10px",
                              backgroundColor: "#fff",
                              borderRadius: "10px"
                            }}
                          >No activity found!</div>}
                      </ScrollArea>

                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>) : (
          <div
            style={{
              textAlign: "center",
              padding: "10px",
              backgroundColor: "#fff",
              borderRadius: "10px"
            }}
          >
            {this.props.data.literalsReducer.Common.Loading}
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchBill: (id) => dispatch(fetchBill(id)),
    resetDetails: () => dispatch(resetDetails()),
    settleBill: (id) => dispatch(settleBill(id)),
    settleTransfer: (id) => dispatch(settleTransfer(id)),
    editBill: (id, billData) => dispatch(editBill(id, billData)),
    billUpdatedDone: () => dispatch(billUpdatedDone())
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...modalStyles(theme),
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(BillingDetailsUnsettled)
);
