import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../app/theme";
import contentStyles from "../../auth-layout/content.styles";
import classNames from "classnames";
import globalstyles from "../../../globalstyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import styles from "./styles";
import MapComponent from "../../../components/Map";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import CreateMenu from "./create-menu";
import {
  getZone,
  updateZone,
  zoneUpdatedDone,
  resetDetails
} from "../../../store/actions/zone";

class OperationalZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageName:"operational",
      zonePlotted: false,
      editableMode: false,
      selectedZoneId: "",
      zoneDetails: {},
      coordinates: [],
      currentZone: {},
      filterData: {
        zoneName: ""
      },
      zoneList: []
    };
  }

  componentDidMount() {
    let localStorageData = window.localStorage.upcAuthData;
    if (localStorageData && JSON.parse(localStorageData)) {
      if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
        JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
          each => {
            if (each.moduleName === "zone") {
              if (each.role === 2) {
                this.setState({
                  ...this.state,
                  editableMode: true
                });
              }
            }
          }
        );
      } else {
        this.setState({
          ...this.state,
          editableMode: true
        });
      }
    }
    this.props.getZone();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(this.props.data.zoneData.zoneDetails).length &&
      !this.state.detailsFetched
    ) {
      let localZones = [...this.state.zoneList];
      let localFilteredData = { ...this.state.filterData };
      this.props.data.zoneData.zoneDetails.data.forEach(each => {
        let zone = {
          _id: each._id,
          coordinates: [],
          name: each.name
        };

        each.geoFence.coordinates[0].forEach(each => {
          let coordinateObj = {
            lat: each[1],
            lng: each[0]
          };
          zone.coordinates.push({ ...coordinateObj });
        });
        localZones.push({ ...zone });
      });
      localFilteredData.zoneName = localZones[1]._id;
      console.log("localZones ", localZones);
      this.setState({
        detailsFetched: true,
        zoneList: [...localZones],
        currentZone: { ...localZones[1] },
        filterData: localFilteredData
      });
    }

    if (this.props.data.zoneData.zoneUpdated) {
      this.props.zoneUpdatedDone();
    }
  }

  componentWillUnmount() {
    this.props.resetDetails();
  }

  saveHandler = () => {
    if (
      this.state.coordinates &&
      this.state.coordinates.length &&
      this.state.selectedZoneId.length
    ) {
      let requestData = {};
      if (
        this.state.coordinates[0][0] !==
          this.state.coordinates[this.state.coordinates.length - 1][0] &&
        this.state.coordinates[0][1] !==
          this.state.coordinates[this.state.coordinates.length - 1][1]
      ) {
        this.state.coordinates.push(this.state.coordinates[0]);
      }
      this.props.data.zoneData.zoneDetails.data.forEach(each => {
        if (each._id === this.state.selectedZoneId) {
          requestData.name = each.name;
          requestData.geoFence = {
            type: "Polygon",
            coordinates: [[...this.state.coordinates]]
          };
        }
      });
      this.props.updateZone(requestData, this.state.selectedZoneId);
    }
  };

  getCoordinates = (coordinates, id) => {
    let newState = { ...this.state };
    newState.coordinates = coordinates;
    newState.selectedZoneId = id;
    this.setState(newState);
  };

  plotZone = () => {
    this.setState({ ...this.state, zonePlotted: true });
  };

  resetZone = () => {
    this.setState({
      ...this.state,
      detailsFetched: false,
      zoneList: [],
      zonePlotted: false
    });
  };

  filterChange = event => {
    let fields = { ...this.state.filterData };
    let localZones = [...this.state.zoneList];
    let currentZoneData = {};

    localZones.forEach(each => {
      if (each._id === event.target.value) {
        currentZoneData = each;
      }
    });

    fields[event.target.name] = event.target.value;
  
    this.setState({
      ...this.state,
      filterData: fields,
      currentZone: currentZoneData,
      zonePlotted: false
    });
  };

  render() {
    const { classes, theme } = this.props;
    console.log("this.state ", this.state);
    return (
      <div>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                <Typography component="p">
                  <span className={classes.pageHeader}>
                    {this.props.data.literalsReducer.OperationalZone.PageHeader}
                  </span>
                  <span className={classes.PageDescription}>
                    {
                      this.props.data.literalsReducer.OperationalZone
                        .PageDescription
                    }
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper>
              {this.state.zoneList && this.state.zoneList.length ? (
                <TextField
                  id="select-car-type"
                  select
                  variant="outlined"
                  className={classes.textField + " " + classes.selectZone}
                  value={this.state.filterData.zoneName}
                  onChange={this.filterChange}
                  name="zoneName"
                  SelectProps={{
                    native: true,
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                >
                  {this.state.zoneList.map((zone, index) => (
                    <option key={index} value={zone._id}>
                      {zone.name}
                    </option>
                  ))}
                </TextField>
              ) : null}
              {this.state.editableMode ? (
                <Grid
                  container
                  className={classes.rightPaper + " " + classes.fullHeight}
                >
                  <Grid item xs={12} sm={12} className={classes.fullHeight}>
                    <MapComponent
                      frmPage={this.state.pageName}
                      getCoordinates={this.getCoordinates}
                      zoneList={[...this.state.zoneList]}
                      currentZone={this.state.currentZone}
                      plotZone={this.plotZone}
                      zonePlotted={this.state.zonePlotted}
                      editableMode={this.state.editableMode}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container className={classes.fullHeight}>
                  <Grid item xs={12} sm={12} className={classes.fullHeight}>
                    <MapComponent
                      frmPage={this.state.pageName}
                      getCoordinates={this.getCoordinates}
                      zoneList={[...this.state.zoneList]}
                      currentZone={this.state.currentZone}
                      plotZone={this.plotZone}
                      zonePlotted={this.state.zonePlotted}
                      editableMode={this.state.editableMode}
                    />
                  </Grid>
                </Grid>
              )}
              {this.state.editableMode ? (
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.actionContainer}>
                      <Button
                        size="large"
                        color="default"
                        variant="contained"
                        className={classes.customMargin}
                        onClick={this.resetZone}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        className={classNames(
                          classes.customMargin,
                          classes.activeGoButton
                        )}
                        onClick={this.saveHandler}
                      >
                        Save
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    getZone: () => dispatch(getZone()),
    zoneUpdatedDone: () => dispatch(zoneUpdatedDone()),
    resetDetails: () => dispatch(resetDetails()),
    updateZone: (payload, id) => dispatch(updateZone(payload, id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    styles,
    theme => ({
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(OperationalZone)
);
