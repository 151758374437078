import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../app/theme";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import PropTypes from 'prop-types';
import styles from "./styles";
import globalstyles from "../../../globalstyles";
import CustomTable from "../../../components/CustomTable";
import Button from "@material-ui/core/Button";
import ReactPaginate from "react-paginate";
import Drawer from "@material-ui/core/Drawer";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FilterListIcon from "@material-ui/icons/FilterList";
import cancel from "../../../assets/images/cancel.png";
import CountryCodesConfig from "../../../config/country-codes";
import {
  PastTripStatusList,
  TripTypes,
  PastTripStatusObject,
  TripTypeObject,
  PaymentStatusList
} from "../../../config/trip";
import { BillingHeaderFormat } from "../../../config/header-format";
import { VehicleTypes } from "../../../config/vehicle";

import moment from "moment";
import { getSettledBillList, getUnSettledBillList } from "../../../store/actions/billing";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const tripTypes = TripTypes;

const statusList = PastTripStatusList;

const vehicleTypes = VehicleTypes;

const paymentStatusList = PaymentStatusList;

const countries = CountryCodesConfig;

const skip = 0;
const limit = 6;

function TabContainer({ children }) {
  return (
    <Typography component="div" style={{ padding: 2 * 5 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};


class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBoxFocused: false,
      searchKeyword: "",
      offset: 0,
      pendingStatus: 4,
      tabValue: 0,
      right: false,
      data: {
        total: 50,
        data: []
      },
      visibleColumns: [
        "Booking Id",
        "Rider Name",
        "Chauffeur Id",
        "Chauffeur Name",
        "Vehicle Type",
        "Trip Type",
        "Status"
      ],
      toastMessage: false,
      skip: 0,
      limit: 6,
      errors: {},
      filterData: {
        riderName: "",
        driverName: "",
        driverEmail: "",
        riderEmail: "",
        riderContactNumber: "",
        driverContactNumber: "",
        displayId: "",
        riderId: "",
        driverId: "",
        riderCountryCode: `${countries[0].code} (${countries[0].dial_code})`,
        driverCountryCode: `${countries[0].code} (${countries[0].dial_code})`,
        tripStatus: statusList[0].value,
        paymentStatus: paymentStatusList[0].value,
        vehicleType: vehicleTypes[0].value,
        tripType: tripTypes[0].value
      },
      searchData: {},
      headerFormat: BillingHeaderFormat
    };
  }

  columnWidth = 100;
  minColumnLength = 5;

  statusList = PastTripStatusObject;

  tripTypeList = TripTypeObject;

  vehicleTypes = {
    1: "Sedan",
    2: "SUV"
  };

  handleColumnVisibilityChange = event => {
    let newState = { ...this.state };
    if (event.target.value.length > this.minColumnLength) {
      document.getElementById("custom_table").style.width =
        document.getElementById("custom_table").clientWidth +
        this.columnWidth +
        "px";
    } else {
      document.getElementById("custom_table").style.width = "1000px";
    }
    if (event.target.value.length > 1) {
      newState.visibleColumns = event.target.value;
    }
    this.setState(newState);
  };

  redirect = data => {
    if (this.state.tabValue === 0) {
      console.log("1")
      this.props.history.push("/web/auth/billing/settled-details/" + data._id);
    } else {
      console.log("2")
      this.props.history.push("/web/auth/billing/unsettled-details/" + data._id);
    }

  };

  handleAvailabilityChange = index => event => {
    let localData = { ...this.state.data };
    let dataArray = [...localData.data];
    dataArray[index].availability = event.target.checked;
    localData.data = [...dataArray];
    this.setState({ ...this.state, data: localData });
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleSortClick = (option, header, index) => {
    let newState = {
      ...this.state
    };
    for (let item in newState.headerFormat) {
      if (newState.headerFormat[item].sort) {
        newState.headerFormat[item].option.forEach(element => {
          element.isActive = false;
        });
      }
    }

    newState.headerFormat[header].option[index].isActive = true;
    let localSortData = {};
    if (header === "displayId") {
      localSortData.displayId = option.type;
    }
    if (header === "rider.fullName") {
      localSortData.riderName = option.type;
    }
    if (header === "rider.displayId") {
      localSortData.riderId = option.type;
    }
    if (header === "assignedDriverInfo.driver.fullName") {
      localSortData.driverName = option.type;
    }
    if (header === "assignedDriverInfo.driver.displayId") {
      localSortData.driverId = option.type;
    }
    newState.sortData = localSortData;
    this.setState(newState);
    this.props.getSettledBillList({
      skip: this.state.skip,
      limit: this.state.limit,
      sortConfig: localSortData,
      filters: this.getFilteredData()
    });
  };

  getFilteredData = () => {
    let localFilterData = {};

    for (let item in this.state.filterData) {
      if (this.state.filterData[item].trim().length) {
        if (
          item !== "riderContactNumber" &&
          item !== "driverContactNumber" &&
          item !== "riderCountryCode" &&
          item !== "driverCountryCode" &&
          item !== "tripStatus" &&
          item !== "paymentStatus" &&
          item !== "vehicleType" &&
          item !== "tripType"
        ) {
          localFilterData[item] = this.state.filterData[item];
        } else if (item === "tripStatus") {
          if (this.state.filterData[item] !== "-1") {
            localFilterData[item] = Number(this.state.filterData[item]);
          } else {
            delete localFilterData.tripStatus;
          }
        } else if (item === "paymentStatus") {
          if (this.state.filterData[item] !== "-1") {
            localFilterData[item] = Number(this.state.filterData[item]);
          } else {
            delete localFilterData.paymentStatus;
          }
        } else if (item === "vehicleType") {
          if (this.state.filterData[item] !== "-1") {
            localFilterData[item] = Number(this.state.filterData[item]);
          } else {
            delete localFilterData.vehicleType;
          }
        } else if (item === "tripType") {
          if (this.state.filterData[item] !== "-1") {
            localFilterData[item] = Number(this.state.filterData[item]);
          } else {
            delete localFilterData.tripType;
          }
        } else if (
          item === "riderContactNumber" &&
          this.state.filterData.riderContactNumber
        ) {
          localFilterData.riderPhone = {
            countryCode: this.state.filterData.riderCountryCode.split(" (")[0],
            number: this.state.filterData.riderContactNumber
          };
        } else if (
          item === "driverContactNumber" &&
          this.state.filterData.driverContactNumber
        ) {
          localFilterData.driverPhone = {
            countryCode: this.state.filterData.driverCountryCode.split(" (")[0],
            number: this.state.filterData.driverContactNumber
          };
        }
      }
    }
    console.log("localFilterData ", localFilterData);
    return localFilterData;
  };

  handleChangePage = (event, page) => {
    let newState = {
      ...this.state,
      skip: page
    };
    this.setState(newState);
    this.fetchList(page * this.state.limit, this.state.limit);
  };

  handleChangeRowsPerPage = event => {
    let newState = {
      ...this.state,
      limit: event.target.value
    };
    this.setState(newState);
    this.fetchList(this.state.skip * this.state.limit, event.target.value);
  };


  handleTabChange = (event, tabValue) => {
    let errors = { ...this.state.errors };
    if (Object.keys(errors).length) {
      for (let item in errors) {
        errors[item] = false;
      }
    }

    let data = {
      total: 50,
      data: []
    }
    if (tabValue === 0) {
      this.props.getSettledBillList({
        skip: skip,
        limit: limit,
        sortConfig: {},
        filters: {}
      });
    } else {
      this.props.getUnSettledBillList({
        skip: skip,
        limit: limit,
        sortConfig: {},
        filters: {}
      });
    }
    this.setState({
      ...this.state,
      right: false,
      tabValue,
      errors,
      detailsFetched: false,
      loading: true,
      data,
      filterData: {
        riderName: "",
        driverName: "",
        driverEmail: "",
        riderEmail: "",
        riderContactNumber: "",
        driverContactNumber: "",
        displayId: "",
        riderId: "",
        driverId: "",
        riderCountryCode: `${countries[0].code} (${countries[0].dial_code})`,
        driverCountryCode: `${countries[0].code} (${countries[0].dial_code})`,
        tripStatus: statusList[0].value,
        paymentStatus: paymentStatusList[0].value,
        vehicleType: vehicleTypes[0].value,
        tripType: tripTypes[0].value
      }
    });

  };


  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true
    });
    this.props.getSettledBillList({
      skip: skip,
      limit: limit,
      sortConfig: {},
      filters: {}
    });
  }

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (Object.keys(this.props.data.billingReducer.billList).length) {
        let localData = { ...this.state.data };
        localData.total = this.props.data.billingReducer.billList.total;
        localData.data = this.props.data.billingReducer.billList.data;
        if (localData.data.length) {
          localData.data.forEach(each => {
            each.pickupTime = moment(each.pickupTime.requested).format(
              "MM-DD-YYYY, h:mm a"
            );
            each.rider.phoneNumber =
              CountryCodesConfig.filter(item => {
                return item.code === each.rider.phone.countryCode;
              })[0].dial_code + this.formatNumber(each.rider.phone.number);
            if (each.assignedDriverInfo.driver.phone) {
              each.assignedDriverInfo.driver.phoneNumber =
                CountryCodesConfig.filter(item => {
                  return (
                    item.code ===
                    each.assignedDriverInfo.driver.phone.countryCode
                  );
                })[0].dial_code +
                this.formatNumber(each.assignedDriverInfo.driver.phone.number);
            }
            if (
              each.assignedDriverInfo &&
              each.assignedDriverInfo.driver &&
              each.assignedDriverInfo.driver.vehicleInfo &&
              each.assignedDriverInfo.driver.vehicleInfo.vehicleType
            ) {
              each.vehicleType = this.vehicleTypes[
                each.assignedDriverInfo.driver.vehicleInfo.vehicleType
              ];
            }
            each.tripType = this.tripTypeList[each.tripType];
            if (each.pickupAddress.actual) {
              each.pickupLocation = each.pickupAddress.actual
            } else {
              each.pickupLocation = each.pickupAddress.requested
            }
            if (each.dropAddress.actual) {
              each.dropLocation = each.dropAddress.actual
            } else {
              each.dropLocation = each.dropAddress.requested
            }
            each.paymentStatus =
              each.transactionInfo && each.transactionInfo.riderTransactionId
                ? "Payment done"
                : "Not paid yet";
          });
        }

        this.setState({
          ...this.state,
          data: localData,
          loading: false
        });
      }
    }
  }

  handleSearchChange = event => {
    this.setState({ ...this.state, searchKeyword: event.target.value });
  };

  componentWillUnmount() { }

  handlePageClick = data => {
    if (this.state.tabValue === 0) {
      this.props.getSettledBillList({
        skip: data.selected * this.state.limit,
        limit: this.state.limit,
        sortConfig: { ...this.state.sortData },
        filters: this.getFilteredData()
      });
      this.setState({ ...this.state, skip: data.selected * this.state.limit });
    } else {
      this.props.getUnSettledBillList({
        skip: data.selected * this.state.limit,
        limit: this.state.limit,
        sortConfig: { ...this.state.sortData },
        filters: this.getFilteredData()
      });
      this.setState({ ...this.state, skip: data.selected * this.state.limit });
    }


  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      ...this.state,
      [side]: open
    });
  };

  tripFilterInfoValidation = () => {
    let fields = { ...this.state.filterData };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    if (
      fields.riderContactNumber.trim().length &&
      fields.riderContactNumber.trim().length < 10
    ) {
      errors.invalidRiderContactNumber = true;
      hasErrors = true;
    }

    if (
      fields.driverContactNumber.trim().length &&
      fields.driverContactNumber.trim().length < 10
    ) {
      errors.invalidDriverContactNumber = true;
      hasErrors = true;
    }

    if (
      fields.riderEmail.trim().length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields.riderEmail)
    ) {
      errors["riderEmailInvalid"] = true;
      hasErrors = true;
    } else {
      errors["riderEmailInvalid"] = false;
    }
    if (
      fields.driverEmail.trim().length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields.driverEmail)
    ) {
      errors["driverEmailInvalid"] = true;
      hasErrors = true;
    } else {
      errors["driverEmailInvalid"] = false;
    }

    console.log("hasErrors ", hasErrors);
    console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  resetFilter = () => {
    if (this.state.tabValue === 0) {
      this.props.getSettledBillList({
        skip: skip,
        limit: limit,
        sortConfig: {},
        filters: {}
      });
    } else {
      this.props.getUnSettledBillList({
        skip: skip,
        limit: limit,
        sortConfig: {},
        filters: {}
      });
    }
    this.setState({
      ...this.state,
      right: false,
      filterData: {
        riderName: "",
        driverName: "",
        driverEmail: "",
        riderEmail: "",
        riderContactNumber: "",
        driverContactNumber: "",
        displayId: "",
        riderId: "",
        driverId: "",
        riderCountryCode: `${countries[0].code} (${countries[0].dial_code})`,
        driverCountryCode: `${countries[0].code} (${countries[0].dial_code})`,
        tripStatus: statusList[0].value,
        paymentStatus: paymentStatusList[0].value,
        vehicleType: vehicleTypes[0].value,
        tripType: tripTypes[0].value
      }
    });
  };

  filterChange = event => {
    let fields = { ...this.state.filterData };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    if (event.target.name === "riderContactNumber") {
      errors.invalidRiderContactNumber = false;
    }
    if (event.target.name === "driverContactNumber") {
      errors.invalidDriverContactNumber = false;
    }

    if (event.target.name === "riderEmail") {
      errors.riderEmailInvalid = false;
    }
    if (event.target.name === "driverEmail") {
      errors.driverEmailInvalid = false;
    }

    this.setState({ ...this.state, filterData: fields, errors });
  };

  filterHandler = () => {
    if (!this.tripFilterInfoValidation()) {
      let localFilterData = this.getFilteredData();
      if (this.state.tabValue === 0) {
        this.props.getSettledBillList({
          skip: 0,
          limit: this.state.limit,
          sortConfig: { ...this.state.sortData },
          filters: localFilterData
        });
      } else {
        this.props.getUnSettledBillList({
          skip: 0,
          limit: this.state.limit,
          sortConfig: { ...this.state.sortData },
          filters: localFilterData
        });
      }
      this.setState({
        ...this.state,
        right: false
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { tabValue } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Grid container spacing={24} >
            <Grid item xs={12} sm={10}>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
                  <Typography component="p">
                    <span className={classes.pageHeader}>
                      {this.props.data.literalsReducer.Billing.PageHeader}
                    </span>
                    <span className={classes.PageDescription}>
                      {
                        this.props.data.literalsReducer.Billing
                          .PageDescription
                      }
                    </span>
                  </Typography>
                </Grid>
              </Grid>

            </Grid>

            <Grid container className={classes.rightPaper}>
              <Grid item xs={12} sm={12}>
                <Tabs
                  value={tabValue}
                  onChange={this.handleTabChange}
                  className={classes.tabPan}
                  variant="fullWidth"
                >
                  <Tab label={this.props.data.literalsReducer.Billing.Settled} />
                  <Tab label={this.props.data.literalsReducer.Billing.unSettled} />
                </Tabs>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
              <Grid container style={{ padding: "0px 10px" }} >
                <Grid item xs={12} sm={8} style={{ paddingTop: "5px" }}>
                  <Typography component="p">
                    <span className={classes.PageDescription} style={{ fontSize: "24px" }}>
                      {this.state.tabValue === 0 ? "Settled Bills" : "Unsettled Bills"}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.topButtons}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.addButton}
                    onClick={this.toggleDrawer("right", true)}
                  >
                    <FilterListIcon className={classes.leftIcon} />
                    Filter
                  </Button>
                </Grid>
              </Grid>
              <Grid container >
                <Grid item xs={12} sm={12} >
                  {tabValue === 0 && <TabContainer>
                    {Object.keys(this.state.data).length &&
                      this.state.data.data.length &&
                      !this.state.loading ? (
                      <CustomTable
                        rows={this.state.data}
                        headerFormat={this.state.headerFormat}
                        sort={this.handleSortClick}
                        rowsPerPage={this.state.limit}
                        page={this.state.skip}
                        total={this.state.data.total}
                        handleChangePage={this.handleChangePage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        handleAvailabilityChange={this.handleAvailabilityChange}
                        visibleColumns={this.state.visibleColumns}
                        redirect={this.redirect}
                        handleColumnVisibilityChange={
                          this.handleColumnVisibilityChange
                        }
                        statusList={this.statusList}
                      />
                    ) : !this.state.loading ? (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          backgroundColor: "#fff",
                          borderRadius: "10px"
                        }}
                      >
                        No Settled bills found!
                      </div>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          backgroundColor: "#fff",
                          borderRadius: "10px"
                        }}
                      >
                        {this.props.data.literalsReducer.Common.Loading}
                      </div>
                    )}
                  </TabContainer>}

                  {tabValue === 1 && <TabContainer>
                    {Object.keys(this.state.data).length &&
                      this.state.data.data.length &&
                      !this.state.loading ? (
                      <CustomTable
                        rows={this.state.data}
                        headerFormat={this.state.headerFormat}
                        sort={this.handleSortClick}
                        rowsPerPage={this.state.limit}
                        page={this.state.skip}
                        total={this.state.data.total}
                        handleChangePage={this.handleChangePage}
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        handleAvailabilityChange={this.handleAvailabilityChange}
                        visibleColumns={this.state.visibleColumns}
                        redirect={this.redirect}
                        handleColumnVisibilityChange={
                          this.handleColumnVisibilityChange
                        }
                        statusList={this.statusList}
                      />
                    ) : !this.state.loading ? (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          backgroundColor: "#fff",
                          borderRadius: "10px"
                        }}
                      >
                        No unsettled bills found!
                      </div>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          backgroundColor: "#fff",
                          borderRadius: "10px"
                        }}
                      >
                        {this.props.data.literalsReducer.Common.Loading}
                      </div>
                    )}
                  </TabContainer>}
                </Grid>
              </Grid>
            </Grid>

            {Object.keys(this.state.data).length &&
              this.state.data.data.length &&
              this.state.data.total > this.state.limit ? (
              <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                <ReactPaginate
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.data.total / this.state.limit}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </Grid>
            ) : null}
            <Drawer
              anchor="right"
              open={this.state.right}
              PaperProps={{ className: classes.drawerWrapper }}
            >
              <div tabIndex={0} role="button">
                <Grid item xs={12} sm={12} className={classes.rightPaper}>
                  <Grid item xs={12} sm={12} md={12} align="right">
                    <IconButton
                      onClick={this.toggleDrawer("right", false)}
                      className={classes.cancel}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                      <p className={classes.description}>Booking Id</p>
                      <TextField
                        label="Id"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="displayId"
                        value={this.state.filterData.displayId}
                        onChange={this.filterChange}
                      />
                      {/* <p className={classes.description}>Rider Id</p>
                      <TextField
                        label="Id"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="riderId"
                        value={this.state.filterData.riderId}
                        onChange={this.filterChange}
                      /> */}
                      <p className={classes.description}>Chauffeur Id</p>
                      <TextField
                        label="Id"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="driverId"
                        value={this.state.filterData.driverId}
                        onChange={this.filterChange}
                      />
                      <p className={classes.description}>Rider Name</p>
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="riderName"
                        value={this.state.filterData.riderName}
                        onChange={this.filterChange}
                      />
                      <p className={classes.description}>Chauffeur Name</p>
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="driverName"
                        value={this.state.filterData.driverName}
                        onChange={this.filterChange}
                      />
                      <p className={classes.description}>Chauffeur Email</p>
                      <TextField
                        type="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="driverEmail"
                        value={this.state.filterData.driverEmail}
                        onChange={this.filterChange}
                        error={this.state.errors.driverEmailInvalid}
                      />
                      <p className={classes.description}>Rider Email</p>
                      <TextField
                        type="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="riderEmail"
                        value={this.state.filterData.riderEmail}
                        onChange={this.filterChange}
                        error={this.state.errors.riderEmailInvalid}
                      />
                      <p className={classes.description}>
                        Chauffeur Contact No.
                      </p>
                      <div className={classes.inlineField}>
                        <TextField
                          id="select-car-type"
                          select
                          variant="outlined"
                          className={classes.textField}
                          value={this.state.filterData.driverCountryCode}
                          onChange={this.filterChange}
                          name="driverCountryCode"
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                        >
                          {countries.filter(each => each.code === 'US').map((option, index) => (
                            <option
                              key={index}
                              value={`${option.code} (${option.dial_code})`}
                            >
                              {`${option.code} (${option.dial_code})`}
                            </option>
                          ))}
                        </TextField>
                        <TextField
                          id="car-maker"
                          label="Contact No."
                          variant="outlined"
                          className={classes.textField}
                          name="driverContactNumber"
                          value={this.state.filterData.driverContactNumber}
                          onChange={this.filterChange}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          inputProps={{
                            minLength: 10,
                            maxLength: 10
                          }}
                          error={this.state.errors.invalidDriverContactNumber}
                        />
                      </div>
                      <p className={classes.description}>Rider Contact No.</p>
                      <div className={classes.inlineField}>
                        <TextField
                          id="select-car-type"
                          select
                          variant="outlined"
                          value={this.state.filterData.riderCountryCode}
                          onChange={this.filterChange}
                          name="riderCountryCode"
                          className={classes.textField}
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                        >
                          {countries.filter(each => each.code === 'US').map((option, index) => (
                            <option
                              key={index}
                              value={`${option.code} (${option.dial_code})`}
                            >
                              {`${option.code} (${option.dial_code})`}
                            </option>
                          ))}
                        </TextField>
                        <TextField
                          id="car-maker"
                          label="Contact No."
                          variant="outlined"
                          className={classes.textField}
                          name="riderContactNumber"
                          value={this.state.filterData.riderContactNumber}
                          onChange={this.filterChange}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          inputProps={{
                            minLength: 10,
                            maxLength: 10
                          }}
                          error={this.state.errors.invalidRiderContactNumber}
                        />
                      </div>
                      <p className={classes.description}>Status</p>
                      <TextField
                        id="select-car-type"
                        select
                        variant="outlined"
                        value={this.state.filterData.tripStatus}
                        onChange={this.filterChange}
                        name="tripStatus"
                        fullWidth
                        className={classes.textField}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                      >
                        {statusList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>

                      <p className={classes.description}>Trip Type</p>
                      <TextField
                        id="select-car-type"
                        select
                        variant="outlined"
                        value={this.state.filterData.tripType}
                        onChange={this.filterChange}
                        name="tripType"
                        fullWidth
                        className={classes.textField}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                      >
                        {tripTypes.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                      <p className={classes.description}>Vehicle Type</p>
                      <TextField
                        id="select-car-type"
                        select
                        variant="outlined"
                        fullWidth
                        value={this.state.filterData.vehicleType}
                        onChange={this.filterChange}
                        name="vehicleType"
                        className={classes.textField}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                      >
                        {vehicleTypes.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                      <p className={classes.description}>Payment Status</p>
                      <TextField
                        id="select-car-type"
                        select
                        variant="outlined"
                        fullWidth
                        value={this.state.filterData.paymentStatus}
                        onChange={this.filterChange}
                        name="paymentStatus"
                        className={classes.textField}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                      >
                        {paymentStatusList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.actionContainer}>
                        <Button
                          size="large"
                          color="default"
                          variant="contained"
                          onClick={this.resetFilter}
                        >
                          Reset
                        </Button>
                        <Button
                          size="large"
                          color="primary"
                          variant="contained"
                          className={classes.activeGoButton}
                          onClick={this.filterHandler}
                        >
                          Go
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Drawer>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    getSettledBillList: searchObject => dispatch(getSettledBillList(searchObject)),
    getUnSettledBillList: searchObject => dispatch(getUnSettledBillList(searchObject))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(Billing)
);
