import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Logo from "../../assets/images/logo.png";
import LogInImage from "../../assets/images/login_front.jpg";
import { Link, Redirect } from "react-router-dom";

import globalstyles from "../../globalstyles";
import { styles } from "./styles";

import { connect } from "react-redux";
import { login } from "../../store/actions/auth";
import { AUTH_DATA } from "../../config/local-storage-keys";

import Grid from "@material-ui/core/Grid";

import classNames from "classnames";

import TextField from "@material-ui/core/TextField";

import theme from "../../app/theme";

class SignIn extends Component {
  constructor(props) {
    super(props);

    // initially assuming that user is logged out
    let user = {
      isLoggedIn: false
    };

    // if user is logged in, his details can be found from local storage
    try {
      let userJsonString = localStorage.getItem(AUTH_DATA);
      if (userJsonString) {
        user.data = JSON.parse(userJsonString);
        if (user.data.token) {
          user.isLoggedIn = true;
        }
      }
    } catch (exception) {
      console.log("exception", exception);
    }

    this.state = {
      fields: {
        email: "",
        password: ""
      },
      errors: {},
      isLoggedIn: user.isLoggedIn,
      toastMessage: false,
      loggingIn: false
    };
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleChange = event => {
    let fields = { ...this.state.fields };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;
    if (event.target.name === "email") {
      errors.emailInvalid = false;
    }

    this.setState({ ...this.state, fields, errors });
  };

  handleValidation = () => {
    let fields = { ...this.state.fields };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    for (let name in fields) {
      if (fields[name].trim() === "") {
        errors[name] = true;
        hasErrors = true;
      }
    }

    if (
      fields.email.trim().length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields.email)
    ) {
      errors["emailInvalid"] = true;
      hasErrors = true;
    } else {
      errors["emailInvalid"] = false;
    }
     console.log(errors,"errors")
    this.setState({ errors: errors });
    return hasErrors;
  };

  submitHandler = event => {
    event.preventDefault();
    if (!this.handleValidation()) {
      let requestData = { ...this.state.fields };
      this.props.dispatch(login(requestData));
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      // if success
      if (this.props.data.authReducer.userData.token) {
        this.setState({
          ...this.state,
          isLoggedIn: true
        });
      }
    }
  }

  render(props) {
    const { classes } = this.props;
    // console.log("this.props.location",this.props.location)
    // console.log("this.state.isLoggedIn",this.state.isLoggedIn)
    if (this.state.isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/web/auth/home",
            state: {
              from: this.props.location
            }
          }}
        />
      );
    } else {
      return (
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <Grid container spacing={24} className={classes.signInWrapper}>
              <Grid
                item
                xs={12}
                sm={7}
                className={classes.loginImage}
                style={{
                  backgroundImage: `url(${LogInImage})`
                }}
              />
              <Grid item xs={12} sm={5} className={classes.signinBox}>
                <Paper className={classes.signinBoxPaper}>
                  <Typography
                    component="h1"
                    variant="h5"
                    className={classes.formHeaderLogo}
                  >
                    <Link to="/login">
                      <img src={Logo} alt="logo" />
                    </Link>
                  </Typography>
                  <Typography
                    component="h1"
                    variant="h5"
                    className={classes.formHeader}
                  >
                    {this.props.data.literalsReducer.Home.SignInHeader}
                  </Typography>
                  <form className={classes.form} onSubmit={this.submitHandler}>
                    <TextField
                      className={classes.textField}
                      style={{ marginBottom: 0 }}
                      margin="normal"
                      autoComplete="email"
                      label={
                        this.props.data.literalsReducer.Home
                          .UserNameOrEmailAddress
                      }
                      variant="outlined"
                      name="email"
                      value={this.state.fields["email"]}
                      onChange={this.handleChange}
                      error={
                        this.state.errors.email ||
                        this.state.errors.emailInvalid
                      }
                    />
                    {this.state.errors.email ? (
                      <FormHelperText id="validation-error">
                        {
                          this.props.data.literalsReducer.ValidationErrors
                            .Required
                        }
                      </FormHelperText>
                    ) : null}
                    {this.state.errors.emailInvalid ? (
                      <FormHelperText id="validation-error">
                        {
                          this.props.data.literalsReducer.ValidationErrors
                            .InvalidEmail
                        }
                      </FormHelperText>
                    ) : null}
                    <TextField
                      className={classNames(
                        classes.textField,
                        classes.passwordInput
                      )}
                      variant="outlined"
                      type={this.state.showPassword ? "text" : "password"}
                      label={this.props.data.literalsReducer.Home.Password}
                      name="password"
                      autoComplete="new-password"
                      value={this.state.fields["password"]}
                      onChange={this.handleChange}
                      error={this.state.errors.password}
                    />
                    {this.state.errors.password ? (
                      <FormHelperText id="validation-error">
                        {
                          this.props.data.literalsReducer.ValidationErrors
                            .Required
                        }
                      </FormHelperText>
                    ) : null}

                    <div className={classes.formFooter}>
                      <div className={classes.formFooterRight}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          disabled={this.state.loggingIn}
                        >
                          {this.props.data.literalsReducer.Home.Login}
                        </Button>
                      </div>
                      <div className={classes.formFooterLeft}>
                        <Link
                          variant="contained"
                          color="primary"
                          to="/forgot-password/request-otp"
                          className={classes.link}
                        >
                          {
                            this.props.data.literalsReducer.Home
                              .RequestForNewPassword
                          }
                        </Link>
                      </div>
                    </div>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </MuiThemeProvider>
      );
    }
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return { data: state };
};

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(SignIn)
);
