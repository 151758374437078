import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../app/theme";
import globalstyles from "../../../globalstyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import styles from "./styles";
import CustomTable from "../../../components/CustomTable";
import Button from "@material-ui/core/Button";
import ReactPaginate from "react-paginate";
import Drawer from "@material-ui/core/Drawer";
import AddIcon from "@material-ui/icons/Add";
// import CreateMenu from "./create-menu";
import FilterListIcon from "@material-ui/icons/FilterList";
import { getAdminList } from "../../../store/actions/admin";
import { AdminHeaderFormat } from "../../../config/header-format";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const skip = 0;
const limit = 6;
class Admins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBoxFocused: false,
      editableMode: false,
      searchKeyword: "",
      offset: 0,
      pendingStatus: 4,
      right: false,
      data: {
        total: 0,
        data: []
      },
      visibleColumns: ["Admin Name", "Email", "Admin", "Role Name"],
      toastMessage: false,
      skip: skip,
      limit: limit,
      errors: {},
      filterData: {
        name: "",
        adminSuper: "",
        email: ""
      },
      sortData: {},
      headerFormat: AdminHeaderFormat
    };
  }

  columnWidth = 100;
  minColumnLength = 5;

  handleAvailabilityChange = index => event => {
    let localData = { ...this.state.data };
    let dataArray = [...localData.data];
    dataArray[index].availability = event.target.checked;
    localData.data = [...dataArray];
    this.setState({ ...this.state, data: localData });
  };

  getFilteredData = () => {
    let localFilterData = {};
    for (let item in this.state.filterData) {
      if (this.state.filterData[item].trim().length) {
        localFilterData[item] = this.state.filterData[item];
      }
    }
    return localFilterData;
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleSortClick = (option, header, index) => {
    let newState = {
      ...this.state
    };
    newState.headerFormat[header].option.forEach(element => {
      element.isActive = false;
    });
    newState.headerFormat[header].option[index].isActive = true;
    let localSortData = { ...this.state.sortData };
    if (header === "personalInfo.fullName") {
      localSortData.name = option.type;
    }
    newState.sortData = localSortData;
    this.setState(newState);
    this.props.getAdminList({
      skip: this.state.skip,
      limit: this.state.limit,
      sortConfig: localSortData,
      filters: this.getFilteredData()
    });
  };

  handleChangePage = (event, page) => {
    let newState = {
      ...this.state,
      skip: page
    };
    this.setState(newState);
    this.fetchList(page * this.state.limit, this.state.limit);
  };

  handleChangeRowsPerPage = event => {
    let newState = {
      ...this.state,
      limit: event.target.value
    };
    this.setState(newState);
    this.fetchList(this.state.skip * this.state.limit, event.target.value);
  };

  addAdmin = event => {
    event.preventDefault();
    this.props.history.push("/web/auth/admins/add");
  };

  componentDidMount() {
    let localStorageData = window.localStorage.upcAuthData;
    if (localStorageData && JSON.parse(localStorageData)) {
      if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
        JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
          each => {
            if (each.moduleName === "admin") {
              if (each.role === 2) {
                this.setState({
                  ...this.state,
                  editableMode: true,
                  loading: true
                });
              } else {
                this.setState({
                  ...this.state,
                  loading: true
                });
              }
            }
          }
        );
      } else {
        this.setState({
          ...this.state,
          editableMode: true,
          loading: true
        });
      }
    }
    this.props.getAdminList({
      skip: skip,
      limit: limit,
      sortConfig: {},
      filters: {}
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      // if success
      if (Object.keys(this.props.data.adminReducer.adminList).length) {
        let localData = { ...this.state.data };
        localData.total = this.props.data.adminReducer.adminList.total;
        localData.data = this.props.data.adminReducer.adminList.data;
        if (localData.data.length) {
          localData.data.forEach(each => {
            each.name = each.personalInfo.fullName;
            each.email = each.personalInfo.email;
            if (each.roleInfo.isSuperAdmin) {
              each.roleName = "Super Admin";
              each.adminSuper = "Yes";
            } else {
              each.roleName = each.roleInfo.roleId.name;
              each.adminSuper = "No";
            }
          });
        }

        this.setState({
          ...this.state,
          data: localData,
          loading: false
        });
      }
    }
  }

  handleColumnVisibilityChange = event => {
    let newState = { ...this.state };
    if (event.target.value.length > this.minColumnLength) {
      document.getElementById("custom_table").style.width =
        document.getElementById("custom_table").clientWidth +
        this.columnWidth +
        "px";
    } else {
      document.getElementById("custom_table").style.width = "1000px";
    }
    newState.visibleColumns = event.target.value;
    this.setState(newState);
  };

  handleSearchChange = event => {
    this.setState({ ...this.state, searchKeyword: event.target.value });
  };

  componentWillUnmount() { }

  redirect = data => {
    if (this.state.editableMode) {
      if (
        window.localStorage.upcAuthData &&
        JSON.parse(window.localStorage.upcAuthData).user._id !== data._id
      ) {
        this.props.history.push("/web/auth/admins/edit/" + data._id);
      }
    }
  };

  handlePageClick = data => {
    this.props.getAdminList({
      skip: data.selected * this.state.limit,
      limit: this.state.limit,
      sortConfig: { ...this.state.sortData },
      filters: this.getFilteredData()
    });
    this.setState({ ...this.state, skip: data.selected * this.state.limit });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      ...this.state,
      [side]: open
    });
  };

  tripFilterInfoValidation = () => {
    let fields = { ...this.state.filterData };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    if (
      fields.email.trim().length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields.email)
    ) {
      errors["emailInvalid"] = true;
      hasErrors = true;
    } else {
      errors["emailInvalid"] = false;
    }

    console.log("hasErrors ", hasErrors);
    console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  resetFilter = () => {
    this.props.getAdminList({
      skip: skip,
      limit: limit,
      sortConfig: {},
      filters: {}
    });
    this.setState({
      ...this.state,
      filterData: {
        name: "",
        email: ""
      },
      right: false
    });
  };

  filterChange = event => {
    let fields = { ...this.state.filterData };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    if (event.target.name === "email") {
      errors.emailInvalid = false;
    }

    this.setState({ ...this.state, filterData: fields, errors });
  };

  filterHandler = () => {
    if (!this.tripFilterInfoValidation()) {
      let localFilterData = this.getFilteredData();
      // console.log("localFilterData",localFilterData)
      this.props.getAdminList({
        skip: 0,
        limit: this.state.limit,
        sortConfig: { ...this.state.sortData },
        filters: localFilterData
      });
      this.setState({
        ...this.state,
        right: false
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={8} style={{ paddingTop: "10px" }}>
                  <Typography component="p">
                    <span className={classes.pageHeader}>
                      {this.props.data.literalsReducer.Admins.PageHeader}
                    </span>
                    <span className={classes.PageDescription}>
                      {this.props.data.literalsReducer.Admins.PageDescription}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.topButtons}>
                  {this.state.editableMode ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.addButton}
                      onClick={this.addAdmin}
                    >
                      <AddIcon className={classes.leftIcon} />
                      Add Admin{" "}
                    </Button>
                  ) : null}
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.addButton}
                    onClick={this.toggleDrawer("right", true)}
                  >
                    <FilterListIcon className={classes.leftIcon} />
                    Filter
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
              {" "}
              {Object.keys(this.state.data).length &&
                this.state.data.data.length &&
                !this.state.loading ? (
                <CustomTable
                  rows={this.state.data}
                  headerFormat={this.state.headerFormat}
                  sort={this.handleSortClick}
                  rowsPerPage={this.state.limit}
                  page={this.state.skip}
                  total={this.state.data.total}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleAvailabilityChange={this.handleAvailabilityChange}
                  visibleColumns={this.state.visibleColumns}
                  redirect={this.redirect}
                  handleColumnVisibilityChange={
                    this.handleColumnVisibilityChange
                  }
                  hideColumnDropdown={true}
                />
              ) : !this.state.loading ? (
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "#fff",
                    borderRadius: "10px"
                  }}
                >
                  No admins found!
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "#fff",
                    borderRadius: "10px"
                  }}
                >
                  {this.props.data.literalsReducer.Common.Loading}
                </div>
              )}
            </Grid>
            {Object.keys(this.state.data).length &&
              this.state.data.data.length &&
              this.state.data.total > this.state.limit ? (
              <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                <ReactPaginate
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.data.total / this.state.limit}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </Grid>
            ) : null}
            <Drawer
              anchor="right"
              open={this.state.right}
              PaperProps={{ className: classes.drawerWrapper }}
            >
              <div tabIndex={0} admin="button">
                <Grid item xs={12} sm={12} className={classes.rightPaper}>
                  <Grid item xs={12} sm={12} md={12} align="right">
                    <IconButton
                      onClick={this.toggleDrawer("right", false)}
                      className={classes.cancel}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                      <p className={classes.description}>Admin Name</p>
                      <TextField
                        label="Admin Name"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="name"
                        value={this.state.filterData.name}
                        onChange={this.filterChange}
                      />

                      <p className={classes.description}>Admin Email</p>
                      <TextField
                        label="Admin Email"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="email"
                        value={this.state.filterData.email}
                        onChange={this.filterChange}
                        error={this.state.errors.emailInvalid}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.actionContainer}>
                        <Button
                          size="large"
                          color="default"
                          variant="contained"
                          onClick={this.resetFilter}
                        >
                          Reset
                        </Button>
                        <Button
                          size="large"
                          color="primary"
                          variant="contained"
                          className={classes.activeGoButton}
                          onClick={this.filterHandler}
                        >
                          Go
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Drawer>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    getAdminList: searchObject => dispatch(getAdminList(searchObject))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(Admins)
);
