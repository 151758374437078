// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  FETCH_BOOKING_REQUESTS,
  FETCH_BOOKING_REQUEST,
  RESET_BOOKING_REQUEST_DETAILS,
  CANCEL_BOOKING_REQUEST,
  BOOKING_CANCELED_DONE,
  CREATE_BOOKING_REQUEST,
  ASSIGN_DRIVERS_TO_BOOKING_REQUEST,
  UPDATE_SCHEDULE_TIME,
  UPDATE_SCHEDULE_TIME_DONE
} from "../constants/action-types";

export const createBookingRequest = (id, requestObj) => {
  return {
    type: CREATE_BOOKING_REQUEST,
    payload: {
      request: {
        url: apiURLs.createBookingRequest + id,
        method: "POST",
        data: requestObj
      }
    }
  };
}

export const assignDriversToBookingRequest = (id, requestObj) => {
  return {
    type: ASSIGN_DRIVERS_TO_BOOKING_REQUEST,
    payload: {
      request: {
        url: apiURLs.assignDriversToBookingRequest(id),
        method: "PUT",
        data: requestObj
      }
    }
  };
}

export const adjustScheduleTime = (id, data) => {
  return {
    type: UPDATE_SCHEDULE_TIME,
    payload: {
      request: {
        url: apiURLs.adjustScheduleTime(id),
        method: "PUT",
        data
      }
    }
  };
};

export const getBookingRequestList = searchObj => {
  return {
    type: FETCH_BOOKING_REQUESTS,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.bookingRequest,
        method: "POST",
        params: {
          skip: searchObj.skip,
          limit: searchObj.limit
        },
        data: {
          sortConfig: searchObj.sortConfig,
          filters: searchObj.filters
        }
      }
    }
  };
};

export const fetchTrip = id => {
  return {
    type: FETCH_BOOKING_REQUEST,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.bookingRequest + "/" + id,
        method: "GET"
      }
    }
  };
};

export const cancelRequest = id => {
  return {
    type: CANCEL_BOOKING_REQUEST,
    payload: {
      request: {
        url: apiURLs.bookingRequest + "/reject/" + id,
        method: "PUT"
      }
    }
  };
};

export const resetDetails = () => {
  return {
    type: RESET_BOOKING_REQUEST_DETAILS
  };
};

export const requestCanceledDone = () => {
  return {
    type: BOOKING_CANCELED_DONE
  };
};

export const adjustScheduleTimeDone = () => {
  return {
    type: UPDATE_SCHEDULE_TIME_DONE,
  };
};