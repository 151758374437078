
import React from 'react';
import store from '../store';
import { ToastStore } from "react-toasts";

import { mqttNotification } from "../store/actions/notifications";
import { showSnackbar } from '../store/actions/snackbar';

export default class MessageContainer extends React.Component {

    getMessage=()=>{
        let tmpCount = 0 
        if(this.props.data && this.props.data.length) {
            
            let tmpMqttdata = this.props.data[0]
            let tmpMsg = "";
            let objNotifation = {}
            switch (tmpMqttdata.type) {

              case 7 :
              tmpMsg += `A trip created with no Chauffeur assigned.Assign a chauffeur`;
              break;

              case 6 :
              tmpMsg += `Chauffeur (${tmpMqttdata.data.assignedDriverInfo.driver.fullName}) could not find the rider at the pickup address.`;
              break;
  
  
              case 5 :
              tmpMsg += `Chauffeur (${tmpMqttdata.data.assignedDriverInfo.driver.fullName}) is opting additional charge.`;
              break;
  
              case 4 :
              tmpMsg += `Chauffeur (${tmpMqttdata.data.assignedDriverInfo.driver.fullName}) has failed to start the trip in time.`;
              break;
  
              case 3 :
              tmpMsg += `Chauffeur (${tmpMqttdata.data.assignedDriverInfo.driver.fullName}) has failed to arrive the pickup address in time.`;
              break;
  
              case 2 :
              tmpMsg += `Chauffeur (${tmpMqttdata.data.assignedDriverInfo.driver.fullName}) has cancelled a trip.Assign another chauffeur`;
              break;
  
  
              case 1 :
              tmpMsg += `Could not find any chauffeur .Assign a chauffeur`;
              break;
             
              default:
              break;
            }
            tmpCount++;
            store.dispatch(showSnackbar(tmpMsg, 'info'));
            store.dispatch(mqttNotification(tmpMqttdata));
          
        }
        
    };
    

  render(){
    this.getMessage();
    return (
      <div>
        
      </div>
    )

  }
}