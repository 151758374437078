import {
  FETCH_ONGOING_TRIPS_SUCCESS,
  FETCH_ONGOING_TRIP_SUCCESS,
  FETCH_ONGOING_TRIP,
  FETCH_ONGOING_TRIP_FAIL,
  RESET_ONGOING_TRIP_DETAILS,
  APPROVE_NO_SHOW_SUCCESS,
  REJECT_NO_SHOW_SUCCESS,
  NO_SHOW_REJECTED_DONE,
  NO_SHOW_APPROVED_DONE,
  ADDITIONAL_CHARGE_APPROVED_DONE,
  ADDITIONAL_CHARGE_REJECTED_DONE,
  APPROVE_ADDITIONAL_CHARGE_SUCCESS,
  REJECT_ADDITIONAL_CHARGE_SUCCESS,
  END_TRIP_SUCCESS,
  END_TRIP_DONE
} from "../constants/action-types";

const initialState = {
  ongoingTripList: {},
  tripDetails: {},
  isFetching: false,
  noShowApproved: false,
  noShowRejected: false,
  additionalChargeApproved: false,
  additionalChargeRejected: false,
  endTripDone: false
};

const ongoingTripReducer = (state = initialState, action) => {
 
  switch (action.type) {
    case FETCH_ONGOING_TRIPS_SUCCESS:
      return {
        ...state,
        ongoingTripList: action.payload.data
      };
    case FETCH_ONGOING_TRIP:
      return {
        ...state,
        tripDetails:{},
        isFetching: true
      };
    case APPROVE_NO_SHOW_SUCCESS:
      return {
        ...state,
        noShowApproved: true
      };
    case REJECT_NO_SHOW_SUCCESS:
      return {
        ...state,
        noShowRejected: true
      };
    case APPROVE_ADDITIONAL_CHARGE_SUCCESS:
      return {
        ...state,
        additionalChargeApproved: true
      };
    case END_TRIP_SUCCESS:
      return {
        ...state,
        endTripDone: true
      };
    case REJECT_ADDITIONAL_CHARGE_SUCCESS:
      return {
        ...state,
        additionalChargeRejected: true
      };
    case FETCH_ONGOING_TRIP_SUCCESS:
      return {
        ...state,
        tripDetails: action.payload.data,
        isFetching: false
      };
    case FETCH_ONGOING_TRIP_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case RESET_ONGOING_TRIP_DETAILS:
      return {
        ...state,
        tripDetails: {}
      };
    case NO_SHOW_REJECTED_DONE:
      return {
        ...state,
        noShowRejected: false
      };
    case END_TRIP_DONE:
      return {
        ...state,
        endTripDone: false
      };
    case NO_SHOW_APPROVED_DONE:
      return {
        ...state,
        noShowApproved: false
      };
    case ADDITIONAL_CHARGE_APPROVED_DONE:
      return {
        ...state,
        additionalChargeApproved: false
      };
    case ADDITIONAL_CHARGE_REJECTED_DONE:
      return {
        ...state,
        additionalChargeRejected: false
      };
    default:
      return state;
  }
};

export default ongoingTripReducer;
