import {
  FETCH_RIDERS_SUCCESS,
  FETCH_UPC_SUCCESS,
  FETCH_RIDER_SUCCESS,
  FETCH_RIDER,
  FETCH_RIDER_FAIL,
  ADD_RIDER_SUCCESS,
  ADD_UPC_SUCCESS,
  EDIT_RIDER_SUCCESS,
  CHANGE_RIDER_STATUS_SUCCESS,
  SET_PLOT_COORDINATE,
  RIDER_CREATED_DONE,
  RIDER_UPDATED_DONE,
  UPC_UPDATED_DONE,
  RIDER_STATUS_UPDATED_DONE,
  RESET_DETAILS,
  FETCH_RIDER_CARD_LIST,
  FETCH_RIDER_CARD_LIST_SUCCESS,
  FETCH_RIDER_CARD_LIST_FAIL,
  ADD_UPC_CREDIT_SUCCESS
} from "../constants/action-types";

const initialState = {
  riderList: {},
  upcData: [],
  riderDetails: {},
  projectCreated: false,
  riderCreated: false,
  riderUpdated: false,
  statusUpdated: false,
  upcUpdated: false,
  isFetching: false,
  plotData: [],
  projectId: null,
  hasProject: false,
  cardList: []
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RIDERS_SUCCESS:
      return {
        ...state,
        riderList: action.payload.data
      };
    case FETCH_UPC_SUCCESS:
      return {
        ...state,
        upcData: action.payload.data
      };
    case FETCH_RIDER:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_RIDER_SUCCESS:
      return {
        ...state,
        riderDetails: action.payload.data,
        isFetching: false
      };
    case FETCH_RIDER_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case FETCH_RIDER_CARD_LIST:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_RIDER_CARD_LIST_SUCCESS:
      return {
        ...state,
        cardList: action.payload.data,
        isFetching: false
      };
    case FETCH_RIDER_CARD_LIST_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case ADD_RIDER_SUCCESS:
      return {
        ...state,
        riderCreated: true
      };
    case EDIT_RIDER_SUCCESS:
      return {
        ...state,
        riderDetails: action.payload.data,
        riderUpdated: true
      };
    case ADD_UPC_SUCCESS:
      return {
        ...state,
        riderDetails: action.payload.data,
        upcUpdated: true
      };
    case ADD_UPC_CREDIT_SUCCESS:
      return {
        ...state,
        riderDetails: action.payload.data,
        upcUpdated: true
      };
    case CHANGE_RIDER_STATUS_SUCCESS:
      return {
        ...state,
        riderDetails: action.payload.data,
        statusUpdated: true
      };
    case SET_PLOT_COORDINATE:
      return {
        ...state,
        plotData: [
          ...state.plotData,
          {
            plot: action.plotData
          }
        ]
      };
    case RESET_DETAILS:
      return {
        ...state,
        riderDetails: {},
        upcData: []
      };

    case RIDER_CREATED_DONE:
      return {
        ...state,
        riderCreated: false
      };
    case RIDER_UPDATED_DONE:
      return {
        ...state,
        riderUpdated: false
      };
    case UPC_UPDATED_DONE:
      return {
        ...state,
        upcUpdated: false
      };
    case RIDER_STATUS_UPDATED_DONE:
      return {
        ...state,
        statusUpdated: false
      };
    default:
      return state;
  }
};

export default projectReducer;
