import React, { Component } from "react";
import { Switch } from "react-router-dom";

import "./style.css";
import Progress from "react-progress-2";
import "react-progress-2/main.css";

import { ToastContainer, ToastStore } from "react-toasts";

import AuthGuard from "../containers/auth-guard";
import { routes } from "../routes/index";
import RouteWithSubRoutes from "../routes/routeWithSubRoutes";

import Grid from "@material-ui/core/Grid";
import { AUTH_DATA } from "../config/local-storage-keys";
import { connect } from "react-redux";
import { Snackbar } from "@material-ui/core";
import CustomSnackbarContents from "../components/CustomSnackbarContents";
import { clearSnackbar } from "../store/actions/snackbar";
class App extends Component {
  constructor(props) {
    super(props);
    // if user is logged in, his details can be found from local storage
    try {
      let userJsonString = localStorage.getItem(AUTH_DATA);
      if (userJsonString) {
        let data = JSON.parse(userJsonString);
        if (data.token) {
          this.state = {
            isLoggedIn: true
          };
        } else {
          this.state = {
            isLoggedIn: false
          };
        }
      } else {
        this.state = {
          isLoggedIn: false
        };
      }
    } catch (exception) {
      this.state = {
        isLoggedIn: false
      };
      console.log("exception", exception);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (this.props.data.authReducer.isAuthenticated) {
        this.setState({
          ...this.state,
          isLoggedIn: true
        });
      }
    }
  }
  componentDidMount() {
    // console.log = function(){};
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.clearSnackbar();
  };

  render() {
    const routesDom = routes.map((route, i) => (
      <RouteWithSubRoutes key={i} {...route} />
    ));
    let css = "app-header";

    if (this.state.isLoggedIn) {
      css = `${css} loggin-header`;
    }

    return (
      <div className="App">
        <Progress.Component />
        {/* <ToastContainer store={ToastStore} /> */}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={this.props.data.snackbarReducer.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <CustomSnackbarContents
            onClose={this.handleClose}
            variant={this.props.data.snackbarReducer.snackbarVariant}
            message={this.props.data.snackbarReducer.snackbarMessage}
          />
        </Snackbar>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Switch>
              {routesDom}
              <AuthGuard />
            </Switch>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    clearSnackbar: () => dispatch(clearSnackbar())
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(App);
