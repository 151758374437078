import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import ScrollArea from "react-scrollbar";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import DemoUserImage from "../../../../assets/images/default-profile-pic.png";
import contentStyles from "../../../auth-layout/content.styles";
import globalstyles from "../../../../globalstyles";
import styles from "./styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import StarRatings from "react-star-ratings";
import classNames from "classnames";
import {
  faStar, faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchTrip, resetDetails } from "../../../../store/actions/pastTrip";
import CountryCodesConfig from "../../../../config/country-codes";
import {
  PastTripStatusObject,
  TripTypeObject,
  ActivityList,
  RiderActivityList,
  DriverActivityList,
  AdminActivityList,
  PaymentStatus
} from "../../../../config/trip";
import moment from "moment";
library.add(faStar, faUpload);

function TabContainer({ children }) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class PastTripDetails extends Component {
  state = {
    single: null,
    labelWidth: 0,
    tripDetails: {
      riderName: "",
      riderAvgRating: 0,
      riderEmail: "",
      riderPhoneNumber: "",
      riderId: "",
      driverName: "",
      driverAvgRating: 0,
      driverEmail: "",
      driverPhoneNumber: "",
      driverId: "",
      driverVehicleType: "",
      driverVehicleMake: "",
      driverVehicleModel: "",
      driverVehicleColor: "",
      tripReqBookingId: "",
      tripType: "Fixed",
      noOfPassenger: 0,
      tripReqTime: "",
      pickupTimeRequested: "",
      pickupTimeActual: "",
      pickupLocationRequested: "",
      pickupLocationActual: "",
      dropAddressRequested: "",
      dropAddressActual: "",
      destinationAddressActual: "",
      dropOffTime: "",
      tripStatus: 0,
      travelDistance: 0,
      waitingTime: 0,
      travelTime: 0,
      suvFare: 0,
      sedanFare: 0,
      paymentStatus: "",
      transferStatus: "",
      activityList: [],
      upcCreditUsed: 0
    },
    errors: {},
    haveImage: false,
    tabValue: 0
  };

  tripTypeList = TripTypeObject;

  vehicleTypes = {
    1: "Sedan",
    2: "SUV"
  };

  statusList = PastTripStatusObject;

  activityLogMessageList = ActivityList;

  riderActivities = RiderActivityList;
  driverActivities = DriverActivityList;
  adminActivities = AdminActivityList;

  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true
    });
    this.props.fetchTrip(this.props.match.params.tripId);
  }

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.detailsFetched &&
      Object.keys(this.props.data.pastTripReducer.tripDetails).length
    ) {
      let localTripData = { ...this.state.tripDetails };
      localTripData.riderProfilePicture = this.props.data.pastTripReducer.tripDetails.rider.profilePicture;

      localTripData.riderName = this.props.data.pastTripReducer.tripDetails.rider.fullName || "N/A";
      localTripData.riderAvgRating = this.props.data.pastTripReducer.tripDetails.rider.ratingData && this.props.data.pastTripReducer.tripDetails.rider.ratingData.total ?
        (this.props.data.pastTripReducer.tripDetails.rider.ratingData.total / this.props.data.pastTripReducer.tripDetails.rider.ratingData.count).toFixed(1) : 0;
      localTripData.riderEmail = this.props.data.pastTripReducer.tripDetails.rider.email || "N/A";
      localTripData.riderPhoneNumber = this.props.data.pastTripReducer.tripDetails.rider.phone ?
        CountryCodesConfig.filter(item => {
          return item.code === this.props.data.pastTripReducer.tripDetails.rider.phone.countryCode;
        })[0].dial_code + this.formatNumber(this.props.data.pastTripReducer.tripDetails.rider.phone.number) : "N/A";
      localTripData.riderId = this.props.data.pastTripReducer.tripDetails.rider.displayId || "N/A";

      localTripData.driverProfilePicture = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.profilePicture;
      localTripData.driverName = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.fullName || "N/A";
      localTripData.driverAvgRating = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.ratingData &&
        this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.ratingData.total ?
        (this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.ratingData.total / this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.ratingData.count).toFixed(1) : 0;
      localTripData.driverEmail = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.email || "N/A";
      localTripData.driverPhoneNumber = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.phone ?
        CountryCodesConfig.filter(item => {
          return item.code === this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.phone.countryCode;
        })[0].dial_code +
        this.formatNumber(this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.phone.number) : "N/A";
      localTripData.driverId = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.displayId || "N/A";

      localTripData.driverVehicleType = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.vehicleTypes[this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleType] : "N/A";
      localTripData.driverVehicleMake = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleMake : "N/A";
      localTripData.driverVehicleModel = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleModel : "N/A";
      localTripData.driverVehicleColor = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleColor : null;

      localTripData.tripReqBookingId = this.props.data.pastTripReducer.tripDetails.displayId || "N/A";

      localTripData.tripType = this.tripTypeList[this.props.data.pastTripReducer.tripDetails.tripType] || "N/A";
      localTripData.noOfPassenger = this.props.data.pastTripReducer.tripDetails.requestedNumberOfSeats || "0";
      if (this.props.data.pastTripReducer.tripDetails.bookingTime) {
        localTripData.tripReqTime = moment(this.props.data.pastTripReducer.tripDetails.bookingTime).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.tripReqTime = "";
      }
      if (this.props.data.pastTripReducer.tripDetails.pickupTime.requested) {
        localTripData.pickupTimeRequested = moment(this.props.data.pastTripReducer.tripDetails.pickupTime.requested).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.pickupTimeRequested = "";
      }
      if (this.props.data.pastTripReducer.tripDetails.pickupTime.actual) {
        localTripData.pickupTimeActual = moment(this.props.data.pastTripReducer.tripDetails.pickupTime.actual).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.pickupTimeActual = "";
      }

      localTripData.paymentInfo = this.props.data.pastTripReducer.tripDetails.paymentInfo;

      localTripData.pickupLocationRequested = this.props.data.pastTripReducer.tripDetails.pickupAddress.requested || "N/A";
      localTripData.pickupLocationActual = this.props.data.pastTripReducer.tripDetails.pickupAddress.actual || "N/A";

      localTripData.dropAddressRequested = this.props.data.pastTripReducer.tripDetails.dropAddress.requested || "N/A";
      localTripData.dropAddressActual = this.props.data.pastTripReducer.tripDetails.dropAddress.actual || "N/A";

      localTripData.destinationAddressActual = this.props.data.pastTripReducer.tripDetails.dropAddress.actual || "N/A"; //it needs to be handled depending on the trip type

      if (this.props.data.pastTripReducer.tripDetails.dropTime.actual) {
        localTripData.dropOffTime = moment(this.props.data.pastTripReducer.tripDetails.dropTime.actual).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.dropOffTime = "";
      }

      localTripData.tripStatus = this.props.data.pastTripReducer.tripDetails.tripStatus || "N/A";

      localTripData.travelDistance = this.props.data.pastTripReducer.tripDetails.travelDistance.actual || "0";
      localTripData.waitingTime = this.props.data.pastTripReducer.tripDetails.waitingTime || "0";
      if (this.props.data.pastTripReducer.tripDetails.dropTime.actual && this.props.data.pastTripReducer.tripDetails.pickupTime.actual) {
        localTripData.travelTime = Math.ceil((new Date(this.props.data.pastTripReducer.tripDetails.dropTime.actual) - new Date(this.props.data.pastTripReducer.tripDetails.pickupTime.actual)) / 3600000);
      } else {
        localTripData.travelTime = "0";
      }

      //calculating SUV & Sedan fare
      let fareChartInfo = this.props.data.pastTripReducer.tripDetails.fareChartInfo;
      let dropTime = this.props.data.pastTripReducer.tripDetails.dropTime;
      let pickupTime = this.props.data.pastTripReducer.tripDetails.pickupTime;
      let travelDistance = this.props.data.pastTripReducer.tripDetails.travelDistance;

      // if (this.props.data.bookingRequestReducer.tripDetails.tripType === 1 || this.props.data.bookingRequestReducer.tripDetails.tripType === 2) {
      //   localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + Math.ceil((new Date(dropTime.requested) -
      //     new Date(pickupTime.requested)) / 60000) * fareChartInfo.fixed.chargePerMinute.suv
      //     + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
      //   localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + Math.ceil((new Date(dropTime.requested) -
      //     new Date(pickupTime.requested)) / 60000) * fareChartInfo.fixed.chargePerMinute.sedan
      //     + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;
      // } else {
      //   localTripData.suvFare = fareChartInfo.duration.baseFare + this.props.data.bookingRequestReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.suv;
      //   localTripData.sedanFare = fareChartInfo.duration.baseFare + this.props.data.bookingRequestReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.sedan;
      // }

      switch (this.props.data.pastTripReducer.tripDetails.tripType) {
        case 1:
          localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.suv
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
          localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.sedan
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;
          if (travelDistance.requested > 10) {
            localTripData.suvFare -= (localTripData.suvFare * (fareChartInfo.fixed.discountPercentage / 100));
            localTripData.sedanFare -= (localTripData.sedanFare * (fareChartInfo.fixed.discountPercentage / 100));
          }
          break;
        case 2:
          localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.suv
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
          localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.sedan
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;
          if (travelDistance.requested > 10) {
            localTripData.suvFare -= (localTripData.suvFare * (fareChartInfo.fixed.discountPercentage / 100));
            localTripData.sedanFare -= (localTripData.sedanFare * (fareChartInfo.fixed.discountPercentage / 100));
          }
          break;
        case 3:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + this.props.data.pastTripReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + this.props.data.pastTripReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.sedan;
          break;
        case 4:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + fareChartInfo.duration.halfDay.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + fareChartInfo.duration.halfDay.perHourCost.sedan;
          break;
        case 5:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + fareChartInfo.duration.fullDay.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + fareChartInfo.duration.fullDay.perHourCost.sedan;
          break;
      }
      localTripData.upcCreditUsed = this.props.data.pastTripReducer.tripDetails.transactionInfo.upcCreditsUsed
      localTripData.paymentStatus = this.props.data.pastTripReducer.tripDetails.transactionInfo &&
        this.props.data.pastTripReducer.tripDetails.transactionInfo.paymentStatus;
      localTripData.transferStatus = this.props.data.pastTripReducer.tripDetails.transactionInfo &&
        this.props.data.pastTripReducer.tripDetails.transactionInfo.transferStatus;
      localTripData.activityList = this.props.data.pastTripReducer.tripDetails.activityLog;

      localTripData.activityList = this.props.data.pastTripReducer.tripDetails.activityLog;
      let distanceTravelled = this.props.data.pastTripReducer.tripDetails.travelDistance.actual
      if (distanceTravelled > 10) {
        localTripData.suvFare = localTripData.suvFare - ((localTripData.suvFare) * (fareChartInfo.discountPercentage / 100))
        localTripData.sedanFare = localTripData.sedanFare - ((localTripData.sedanFare) * (fareChartInfo.discountPercentage / 100))
      }

      this.setState({
        ...this.state,
        tripDetails: localTripData,
        detailsFetched: true,
        loading: false
      });
    }
  }

  componentWillUnmount() {
    this.props.resetDetails();
  }

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  }

  print = () => {
    window.print();
  };

  render() {
    const { classes, theme } = this.props;
    const { tabValue } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        {!this.state.loading ? (
          <div>

            <Grid container>
              <Grid item xs={12} sm={12}>
                <Paper>
                  {/* <h3>Invoice</h3> */}
                  <Grid container className={classes.rightPaper}>

                    <Grid item xs={12} sm={10} style={{ textAlign: "center" }}>
                      <h2 style={{ marginTop: 0 }}>Invoice</h2>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        id="printPageButton"
                        variant="outlined"
                        color="primary"
                        className={classes.headerRightButton}
                        onClick={this.print}
                      >
                        Print
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>

                      {this.state.tripDetails.driverName.length ?
                        <div className={classes.tabDetailsCont}>
                          <div>Driver</div>
                          <Divider variant="middle" style={{ "margin": "15px 50px 15px 0" }} />
                          <div className={classes.profileImageWrap}>

                            <div>
                              <span>{this.state.tripDetails.driverName || "N/A"}</span>


                            </div>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.DriverDetails.Email} -
                            <span>{this.state.tripDetails.driverEmail || "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.DriverDetails.PhoneNo} -
                            <span>{this.state.tripDetails.driverPhoneNumber || "N/A"}</span>
                          </div>
                          {/* <div className={classes.description}>
                                {this.props.data.literalsReducer.DriverDetails.DriverId} - 
                                <span>{this.state.tripDetails.driverId || "N/A"}</span>
                              </div> */}

                        </div>
                        : <div className={classes.tabDetailsCont} style={{ textAlign: "center" }}> Driver not assigned</div>}


                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div>Rider</div>
                      <Divider variant="middle" style={{ "margin": "15px 50px 15px 0" }} />

                      <div className={classes.tabDetailsCont}>
                        <div className={classes.profileImageWrap}>

                          <div>
                            <span>{this.state.tripDetails.riderName || "N/A"}</span>


                          </div>
                        </div>
                        <div className={classes.description}>
                          {this.props.data.literalsReducer.RiderDetails.Email} -
                          <span>{this.state.tripDetails.riderEmail || "N/A"}</span>
                        </div>
                        <div className={classes.description}>
                          {this.props.data.literalsReducer.RiderDetails.PhoneNo} -
                          <span>{this.state.tripDetails.riderPhoneNumber || "N/A"}</span>
                        </div>

                      </div>


                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {this.state.tripDetails.tripStatus === 13 || this.state.tripDetails.tripStatus === 14 ? <span>
                        <h3 style={{ marginTop: "0" }}>Payment Breakdown</h3>
                        <div className={classes.description}>
                          {this.props.data.literalsReducer.PastTripDetails.PaymentStatus} -
                          <span className={classes.activeStatus} style={{ float: "right" }}>{PaymentStatus[this.state.tripDetails.paymentStatus]}</span>
                        </div>
                        <div className={classes.description}>
                          {this.props.data.literalsReducer.PastTripDetails.TransferStatus} -
                          <span className={classes.activeStatus} style={{ float: "right" }}>{PaymentStatus[this.state.tripDetails.transferStatus]}</span>
                        </div>
                        <div className={classes.description}>
                          {this.props.data.literalsReducer.PastTripDetails.BaseFare} -
                          <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                            (this.state.tripDetails.paymentInfo.paymentBreakdown.baseCharge).toFixed(2) : "N/A"}</span>

                        </div>

                        <div className={classes.description}>
                          {this.props.data.literalsReducer.PastTripDetails.RemainingTripCharge} -
                          <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                            (this.state.tripDetails.paymentInfo.paymentBreakdown.remainingTripCharge).toFixed(2) : "N/A"}</span>
                        </div>

                        <div className={classes.description}>
                          {this.props.data.literalsReducer.PastTripDetails.WaitingCharge} -
                          <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                            (this.state.tripDetails.paymentInfo.paymentBreakdown.waitingCharge).toFixed(2) : "N/A"}</span>
                        </div>
                        <div className={classes.description}>
                          {this.props.data.literalsReducer.PastTripDetails.AdditionalChargeForDriver} -
                          <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                            (this.state.tripDetails.paymentInfo.paymentBreakdown.additionalChargeForDriver).toFixed(2) : "N/A"}</span>
                        </div>
                        <div className={classes.description}>
                          {this.props.data.literalsReducer.PastTripDetails.CancelCharge} -
                          <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                            (this.state.tripDetails.paymentInfo.paymentBreakdown.cancellationCharge).toFixed(2) : "N/A"}</span>
                        </div>
                        <hr />
                        <div className={classes.description}>
                          {this.props.data.literalsReducer.PastTripDetails.SubTotalFare} -
                          <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                            (this.state.tripDetails.paymentInfo.paymentBreakdown.totalFare).toFixed(2) : "N/A"}</span>
                        </div>
                        <div className={classes.description}>
                          {this.props.data.literalsReducer.PastTripDetails.Tip} -
                          <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                            (this.state.tripDetails.paymentInfo.paymentBreakdown.tip).toFixed(2) : "N/A"}</span>
                        </div>
                        <div className={classes.description}>
                          {this.props.data.literalsReducer.PastTripDetails.Discount} -
                          <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                            (this.state.tripDetails.paymentInfo.paymentBreakdown.discount).toFixed(2) : "N/A"}</span>
                        </div>
                        <div className={classes.description}>
                          {this.props.data.literalsReducer.PastTripDetails.RiderCredit} -
                          <span style={{ float: "right" }}>&#36;{this.state.tripDetails.upcCreditUsed && this.state.tripDetails.upcCreditUsed ?
                            (this.state.tripDetails.upcCreditUsed).toFixed(2) : "N/A"}</span>
                        </div>
                        <hr />
                        <div className={classes.description}>
                          {this.props.data.literalsReducer.PastTripDetails.TotalFare} -
                          <span style={{ float: "right" }}>&#36;{this.state.tripDetails.paymentInfo && this.state.tripDetails.paymentInfo.paymentBreakdown ?
                            (this.state.tripDetails.paymentInfo.paymentBreakdown.payableAmount).toFixed(2) : "N/A"}</span>
                        </div>

                      </span> : null}


                    </Grid>




                    <Grid item xs={12} sm={12}>
                      <h3>Req. Details</h3>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.BookingId} -
                            <span>{this.state.tripDetails.tripReqBookingId || "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.BookingType} -
                            <span>{this.state.tripDetails.tripType || "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.NoOfPassenger} -
                            <span>{this.state.tripDetails.noOfPassenger || "0"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.ReqAt} -
                            <span>{this.state.tripDetails.tripReqTime || "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.PickupTime} -
                            <span>{this.state.tripDetails.pickupTimeRequested || "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.ActualPickupTime} -
                            <span>{this.state.tripDetails.pickupTimeActual || "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.ReqPickupLocation} -
                            <span>{this.state.tripDetails.pickupLocationRequested || "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.ReqDestination} -
                            <span>{this.state.tripDetails.dropAddressRequested || "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.ActualPickupLocation} -
                            <span>{this.state.tripDetails.pickupLocationActual || "N/A"}</span>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.ActualDestinationLocation} -
                            <span>{this.state.tripDetails.destinationAddressActual || "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.ActualDropOffLocation} -
                            <span>{this.state.tripDetails.dropAddressActual || "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.TimeofDropoff} -
                            <span>{this.state.tripDetails.dropOffTime || "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.Status} -
                            <span className={classes.activeStatus}>{this.statusList[this.state.tripDetails.tripStatus]}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.KmsTravelled} -
                            <span>{this.state.tripDetails.travelDistance ? this.state.tripDetails.travelDistance + " Mile" : "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.WaitTime} -
                            <span>{this.state.tripDetails.waitingTime ? this.state.tripDetails.waitingTime + " hour" : "N/A"}</span>
                          </div>
                          <div className={classes.description}>
                            {this.props.data.literalsReducer.PastTripDetails.TimeOfTravel} -
                            <span>{this.state.tripDetails.travelTime ? this.state.tripDetails.travelTime + " hour" : "N/A"}</span>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Paper>
              </Grid>
            </Grid>

          </div>) : (
          <div
            style={{
              textAlign: "center",
              padding: "10px",
              backgroundColor: "#fff",
              borderRadius: "10px"
            }}
          >
            {this.props.data.literalsReducer.Common.Loading}
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTrip: (id) => dispatch(fetchTrip(id)),
    resetDetails: () => dispatch(resetDetails())
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(PastTripDetails)
);
