// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  FETCH_ADMINS,
  ADD_ADMIN,
  FETCH_ADMIN,
  ADMIN_CREATED_DONE,
  RESET_ADMIN_DETAILS,
  EDIT_ADMIN,
  CHANGE_STATUS_ADMIN,
  ADMIN_UPDATED_DONE,
  CHANGE_ADMIN_STATUS,
  ADMIN_STATUS_UPDATED_DONE
} from "../constants/action-types";

export const getAdminList = searchObj => {
  return {
    type: FETCH_ADMINS,
    hideToast: true,
    payload: {
      request: {
        url: `${apiURLs.admin}list`,
        method: "POST",
        params: {
          skip: searchObj.skip,
          limit: searchObj.limit
        },
        data: {
          sortConfig: searchObj.sortConfig,
          filters: searchObj.filters
        }
      }
    }
  };
};

export const addAdmin = adminData => {
  return {
    type: ADD_ADMIN,
    payload: {
      request: {
        url: apiURLs.admin + "add",
        method: "POST",
        data: adminData
      }
    }
  };
};

export const fetchAdmin = id => {
  return {
    type: FETCH_ADMIN,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.admin + id,
        method: "GET"
      }
    }
  };
};

export const editAdmin = (id, adminData) => {
  return {
    type: EDIT_ADMIN,
    payload: {
      request: {
        url: apiURLs.admin + id,
        method: "PUT",
        data: adminData
      }
    }
  };
};
export const changeAdminUserStatus = (id, status) => {
  return {
    type: CHANGE_STATUS_ADMIN,
    payload: {
      request: {
        url: apiURLs.admin + "change-status/" + id,
        method: "PUT",
        data: { accountStatus: status }
      }
    }
  };
};
export const adminUpdatedDone = () => {
  return {
    type: ADMIN_UPDATED_DONE
  };
};

export const adminCreatedDone = () => {
  return {
    type: ADMIN_CREATED_DONE
  };
};

export const resetAdmin = () => {
  return {
    type: RESET_ADMIN_DETAILS
  };
};

export const statusUpdatedDone = () => {
  return {
    type: ADMIN_STATUS_UPDATED_DONE
  };
};
