// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  FETCH_UPC_RIDERS,
  FETCH_UPC_RIDER,
  RESET_DETAILS
} from "../constants/action-types";

export const getUPCRiderList = searchObj => {
  return {
    type: FETCH_UPC_RIDERS,
    hideToast: true,
    payload: {
      request: {
        url: `${apiURLs.rider}list`,
        method: "POST",
        params: {
          skip: searchObj.skip,
          limit: searchObj.limit
        },
        data: {
          sortConfig: searchObj.sortConfig,
          filters: searchObj.filters
        }
      }
    }
  };
};

export const fetchUPCRider = id => {
  return {
    type: FETCH_UPC_RIDER,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.upcRider + id,
        method: "GET"
      }
    }
  };
};

export const resetDetails = () => {
  return {
    type: RESET_DETAILS
  };
};
