// API Config
import {apiURLs} from '../api-config/api.urls';
// Action Types Constant
import {
  LOGOUT ,
  CHANGE_PASSWORD,
  LOGOUT_ADMIN_SUCCESS
} from "../constants/action-types";



///////////////////////////////// Logout // ///////////////////////////////
export const logoutAdmin = () => {
  return {
    type: LOGOUT,
    payload: {
      request: {
        url: apiURLs.logout,
        method: "POST"
      }
    }
  };
};


export const changePassword = (payload) => {
  return {
    type: CHANGE_PASSWORD,
    payload: {
      request: {
        url: apiURLs.changePassword,
        method: "PUT",
        data: payload
      }
    }
  };
};


///////////////////////////////// Logout // ///////////////////////////////
export const logoutSuccess = () => {
  return { type: LOGOUT_ADMIN_SUCCESS };
};



