export const OngoingTripStatusList = [
  {
    value: "-1",
    label: "All"
  },
  { value: "5", label: "Chauffeur marked enrouted" },
  { value: "6", label: "Chauffeur has arrived" },
  { value: "7", label: "No show requested" },
  { value: "11", label: "Chauffeur waiting" },
  { value: "12", label: "Ongoing" }
];

export const PastTripStatusList = [
  {
    value: "-1",
    label: "All"
  },
  { value: "1", label: "Trip requested" },
  { value: "2", label: "Chauffeur assigned" },
  { value: "3", label: "Chauffeur has not assigned yet" },
  { value: "4", label: "Chauffeur marked as ready" },
  { value: "5", label: "Chauffeur marked enrouted" },
  { value: "6", label: "Chauffeur has arrived" },
  { value: "7", label: "No show requested" },
  {
    value: "8",
    label: "Chauffeur did not mark ready"
  },
  { value: "9", label: "Chauffeur did not mark enroute" },
  {
    value: "10",
    label: "Chauffeur canceled the trip request"
  },
  { value: "11", label: "Chauffeur waiting" },
  { value: "12", label: "Ongoing" },
  { value: "13", label: "Completed" },
  { value: "14", label: "Canceled" },
  { value: "15", label: "Timeout" },
  { value: "16", label: "Completed by admin" }
];

export const TripTypes = [
  {
    value: "-1",
    label: "All"
  },
  { value: "1", label: "Fixed one way" },
  { value: "2", label: "Fixed round trip" },
  { value: "3", label: "Duration hourly" },
  { value: "4", label: "Duration half day" },
  { value: "5", label: "Duration full day" }
];

export const OngoingTripStatusObject = {
  1: "Trip requested",
  2: "Chauffeur assigned",
  3: "Chauffeur has not assigned yet",
  4: "Chauffeur marked as ready",
  5: "Chauffeur marked enrouted",
  6: "Chauffeur has arrived",
  7: "No show requested",
  8: "Chauffeur did not mark ready",
  9: "Chauffeur did not mark enroute",
  10: "Chauffeur canceled the trip request",
  11: "Waiting",
  12: "Ongoing",
  13: "Completed",
  14: "Canceled",
  15: "Timeout",
  16: "Completed by admin"
};

export const PastTripStatusObject = {
  1: "Trip requested",
  2: "Chauffeur assigned",
  3: "Chauffeur has not assigned yet",
  4: "Chauffeur marked as ready",
  5: "Chauffeur marked enrouted",
  6: "Chauffeur has arrived",
  7: "No show requested",
  8: "Chauffeur did not mark ready",
  9: "Chauffeur did not mark enroute",
  10: "Chauffeur canceled the trip request",
  11: "Waiting",
  12: "Ongoing",
  13: "Completed",
  14: "Canceled",
  15: "Timeout",
  16: "Completed by admin"
};

export const BookingRequestStatusObject = {
  1: "Trip requested",
  2: "Chauffeur assigned",
  3: "Chauffeur has not assigned yet",
  4: "Chauffeur marked as ready",
  5: "Chauffeur marked enrouted",
  6: "Chauffeur has arrived",
  7: "No show requested",
  8: "Chauffeur did not mark ready",
  9: "Chauffeur did not mark enroute",
  10: "Chauffeur canceled the trip request",
  11: "Waiting",
  12: "Ongoing",
  13: "Completed",
  14: "Canceled",
  15: "Timeout",
  16: "Completed by admin"
};

export const TripType = {
  FIXED_ONE_WAY:1, 
  FIXED_ROUND_TRIP:2, 
  DURATION_HOURLY:3,
  DURATION_HALF_DAY:4, 
  DURATION_FULL_DAY:5, 
};

export const TripTypeObject = {
  1: "Fixed one way",
  2: "Fixed round trip",
  3: "Duration hourly",
  4: "Duration half day",
  5: "Duration full day"
};

export const ActivityList = {
  1: "(rider) has requested the trip.",
  2: "(chauffeur) got a new trip request.",
  3: "(chauffeur) has rejected the trip request.",
  4: "(chauffeur) has not responded to the trip request.",
  5: "(chauffeur) has accepted the trip request.",
  6: "No chauffeur assigned to the trip.",
  7: "Chauffeur has been assigned to the trip.",
  8: "Free waiting time to reach the pickup location has been passed.",
  9: "(chauffeur) got a reminder regarding the trip.",
  10: "(chauffeur) has not marked ready.",
  11: "(chauffeur) has marked as ready.",
  12: "(chauffeur) has not marked enroute.",
  13: "(chauffeur) has marked enroute.",
  14: "(chauffeur) has arrived to the pickup location.",
  15: "(chauffeur) has not arrived to the pickup location.",
  16: "(chauffeur) has started the trip.",
  17: "(chauffeur) has not started the trip yet.",
  18: "(chauffeur) has requested No show.",
  19: "(admin) has approved No show.",
  20: "(admin) has rejected No show.",
  21: "(chauffeur) has paused the trip.",
  22: "(chauffeur) has resumed the trip.",
  23: "(chauffeur) has requested additional charge.",
  24: "(admin) has approved additional charge.",
  25: "(admin) has rejected additional charge.",
  26: "(chauffeur) has ended the trip.",
  27: "(chauffeur) has submitted a feedback.",
  28: "(chauffeur) has canceled the trip.",
  29: "(rider) has canceled the trip.",
  30: "(admin) has canceled the trip.",
  31: "(admin) has assigned a chauffeur.",
  32: "The trip has been timed out.",
  33: "(rider) has paid the trip fare.",
  34: "(admin) has ended the trip."
};

export const PaymentStatusList = [
  {
    value: "-1",
    label: "All"
  },
  {
    value: "2",
    label: "Payment done"
  },
  {
    value: "1",
    label: "Not paid yet"
  }
];

export const PaymentStatus = {
  1: "Not paid yet",
  2: "Done"
}

export const RiderActivityList = [1, 29, 33];
export const AdminActivityList = [19, 20, 24, 25, 30, 31, 34];
export const DriverActivityList = [
  2,
  3,
  4,
  5,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  21,
  22,
  23,
  26,
  27,
  28
];

export const AlertTypes = {
  1: "No chauffeur assigned",
  2: "Chauffeur canceled",
  3: "Chauffeur did not mark ready",
  4: "Chauffeur did not mark enroute",
  5: "Additional charge requested",
  6: "No show requested"
};
