import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AUTH_DATA } from "../../config/local-storage-keys";
import { connect } from "react-redux";
import AuthLayout from "../auth-layout";
import Dashboard from "../auth/home";
class AuthGuard extends Component {
  constructor(props) {
    super(props);

    // initially assuming that user is logged out
    let user = {
      isLoggedIn:
        (this.props.data.authReducer.userData &&
          this.props.data.authReducer.userData.success) ||
        false
    };

    // if user is logged in, his details can be found from local storage
    try {
      let userJsonString = localStorage.getItem(AUTH_DATA);
      if (userJsonString) {
        user.data = JSON.parse(userJsonString);
        if (user.data.token) {
          user.isLoggedIn = true;
        }
      }
    } catch (exception) {
      console.log("exception", exception);
    }
    // updating the state
    this.state = {
      user: user
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.authReducer !== this.props.data.authReducer) {
      if (
        localStorage.upcAuthData &&
        JSON.parse(localStorage.upcAuthData).token
      ) {
        this.setState({
          user: { isLoggedIn: true }
        });
      } else {
        this.setState({
          user: { isLoggedIn: false }
        });
      }
    }
  }

  render() {
    return this.props.data && this.props.data.email ? (
      <Redirect
        to={{
          pathname: "/forgot-password/reset-password",
          state: { from: this.props.location }
        }}
      />
    ) : this.state.user.isLoggedIn ? (
      this.props.location.pathname === "/" ? (
        <Redirect
          to={{
            pathname: "/web/auth/home",
            state: { from: this.props.location }
          }}
        />
      ) : (
        <Route path={this.props.location.pathname} component={AuthLayout} />
      )
    ) : (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: this.props.location }
        }}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state
  };
};

export default connect(mapStateToProps)(AuthGuard);
