const styles = theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    margin: {
        margin: theme.spacing.unit
    },
    paper: {
        marginTop: theme.spacing.unit * 3,
        color: theme.palette.text.secondary,
        whiteSpace: "nowrap",
        marginBottom: theme.spacing.unit * 3,
        margin: "24px 5px"
    },
    paperHeaderContainer: {
        position: "relative"
    },
    paperHeader: {
        padding: "0 0 0 16px",
        marginBottom: 0,
        float: "left",
        color: "#000"
    },
    scrollContent: {
        margin: "0 auto",
        height: "550px",
        paddingBottom: "15px"
    },
    actionContainer: {
        padding: "20px 48px",
        borderTop: "1px solid #ccc",
        textAlign: "right",
        "&>Button": {
            textTransform: "capitalize",
            "&:first-child": {
                marginRight: "12px"
            }
        }
    },
    leftIcon: {
        marginRight: theme.spacing.unit
    },
    rightIcon: {
        marginLeft: theme.spacing.unit
    },
    rightPaper: {
        padding: "32px 38px !important"
    },
    userAvatar: {
        width: 30,
        height: 30,
    },
    activeGoButton: {
        color: "#fff",
        background: "#eb5c00",
        borderColor: "#eb5c00",
        "&:hover": {
            background: "#cc5000 !important",
            borderColor: "#cc5000 !important"
        }
    },
    addressField: {
        "& input": {
            height: "56px",
            width: "100%",
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderRadius: "5px",
            borderWidth: "1px",
            padding: "18.5px 14px",
            font: "inherit",
            color: "currentColor",
            transition: "border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            "&:hover": {
                borderColor: "rgba(0,0,0,1)"
            },
            "&:focus": {
                border: "2px solid #eb5c00",
                outline: "none"
            }
        }
    },
    profileImagePlaceholder: {
        width: "30px",
        height: "30px",
        borderRadius: "100%",
        marginRight: 15,
        overflow: "hidden",
        background: "#dadada",
    },
});

export default styles;
