import {
  GET_PARAMETER_SUCCESS,
  UPDATE_PARAMETER_SUCCESS,
  PARAMETER_UPDATED_DONE,
  RESET_PARAMETER_DETAILS
} from "../constants/action-types";

const initialState = {
  operationalParameterDetails: {},
  parameterUpdated: false
};

const operationalParameterData = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARAMETER_SUCCESS:
      return {
        ...state,
        operationalParameterDetails: action.payload.data
      };
    case UPDATE_PARAMETER_SUCCESS:
      return {
        ...state,
        parameterUpdated: true
      };
    case PARAMETER_UPDATED_DONE:
      return {
        ...state,
        parameterUpdated: false
      };
    case RESET_PARAMETER_DETAILS:
      return {
        ...state,
        operationalParameterDetails: {}
      };
    default:
      return state;
  }
};

export default operationalParameterData;
