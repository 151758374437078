import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../../app/theme";
import moment from "moment";
import globalstyles from "../../../../globalstyles";
import CustomTable from "../../../../components/CustomTable";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Modal from "@material-ui/core/Modal";
import DemoUserImage from "../../../../assets/images/super-admin.jpg";
import contentStyles from "../../../auth-layout/content.styles";
import styles from "./styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import {
  fetchUPCDriver,
  resetDetails
} from "../../../../store/actions/upcDriver";
import CountryCodesConfig from "../../../../config/country-codes";
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faStar,
  faStarHalfAlt,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faStar, faStarHalfAlt, faUpload);

function TabContainer({ children }) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class UpcDriverDetails extends Component {
  state = {
    editUserModalOpen: false,
    single: null,
    labelWidth: 0,
    errors: {},
    haveImage: false,
    tabValue: 0,
    data: {
      total: 0,
      data: []
    },
    visibleColumns: [
      "Name",
      "Contact No.",
      "Email",
      "No. of Completed Rides",
      "Joining Date"
    ],
    toastMessage: false,
    skip: 0,
    skip2: 0,
    limit: 6,

    searchData: {},
    headerFormat: {
      "personalInfo.fullName": {
        head: "Name",
        key: ["personalInfo", "fullName"],
        populate: true
      },
      "personalInfo.email": {
        head: "Email",
        key: ["personalInfo", "email"],
        populate: true
      },
      contact: {
        head: "Contact No.",
        key: "contact"
      },
      createdAt: {
        head: "Joining Date",
        key: "createdAt"
      },
      "tripInfo.completedTrips": {
        head: "No. of Completed Rides",
        key: ["tripInfo", "completedTrips"],
        populate: true
      }
    },
    data2: {
      total: 0,
      data: []
    },
    visibleColumns2: [
      "Trip ID",
      "Referred Rider Name",
      "Date and Time of Trip Completion",
      "Ur PC Money Received"
    ],
    headerFormat2: {
      "tripId.displayId": {
        head: "Trip ID",
        key: ["tripId", "displayId"],
        populate: true
      },
      "tripId.rider.fullName": {
        head: "Referred Rider Name",
        key: ["tripId", "rider", "fullName"],
        populate: true
      },
      dropTime: {
        head: "Date and Time of Trip Completion",
        key: "dropTime"
      },
      amountCredited: {
        head: "Ur PC Money Received",
        key: "amountCredited"
      }
    },
    driverDetails: {
      fullName: "",
      displayId: "",
      email: "",
      phoneNumber: "",
      profilePicture: "",
      upcCode: "",
      upcCodeUsedBy: 0,
      upcCreditsEarned: 0,
      upcCreditSpent: 0
    }
  };

  componentDidMount() {
    this.setState({ ...this.state, loading: true });
    this.props.fetchUPCDriver(this.props.match.params.driverId);
  }

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.detailsFetched &&
      Object.keys(this.props.data.upcDriverData.driverDetails).length
    ) {
      let localRider = { ...this.state.driverDetails };
      // let localReferredRiders = { ...this.state.data };
      // let localEarningHistory = { ...this.state.data2 };
      localRider.fullName = this.props.data.upcDriverData.driverDetails.personalInfo.fullName;
      localRider.profilePicture = this.props.data.upcDriverData.driverDetails.personalInfo.profilePicture;
      localRider.email = this.props.data.upcDriverData.driverDetails.personalInfo.email;
      localRider.phoneNumber =
        CountryCodesConfig.filter(item => {
          return (
            item.code ===
            this.props.data.upcDriverData.driverDetails.personalInfo.phone
              .countryCode
          );
        })[0].dial_code +
        this.formatNumber(
          this.props.data.upcDriverData.driverDetails.personalInfo.phone.number
        );
      localRider.displayId = this.props.data.upcDriverData.driverDetails.displayId;

      localRider.upcCode =
        this.props.data.upcDriverData.driverDetails.upcBucketInfo.upcCode ||
        "N/A";

      localRider.upcCreditsEarned = this.props.data.upcDriverData.driverDetails
        .upcBucketInfo.upcIncome
        ? this.props.data.upcDriverData.driverDetails.upcBucketInfo.upcIncome.toFixed(
            2
          )
        : 0;

      localRider.upcCodeUsedBy = this.props.data.upcDriverData.driverDetails.upcBucketInfo.upcCodeUsedBy.length;

      this.setState({
        ...this.state,
        driverDetails: localRider,
        data: this.getReferrerList({
          skip: this.state.skip,
          limit: this.state.limit
        }),
        data2: this.getEarningList({
          skip: this.state.skip,
          limit: this.state.limit
        }),
        detailsFetched: true,
        loading: false
      });
    }
  }

  redirect = data => {
    this.props.history.push("/web/auth/riders/details/" + data._id);
  };

  redirectFromHistory = data => {
    this.props.history.push("/web/auth/riders/details/" + data.refRiderId._id);
  };

  componentWillUnmount() {
    this.props.resetDetails();
  }

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  };

  handlePageClick = data => {
    this.setState({
      ...this.state,
      skip: data.selected * this.state.limit,
      limit: this.state.limit,
      data: this.getReferrerList({
        skip: data.selected * this.state.limit,
        limit: this.state.limit
      })
    });
  };

  handlePageClick2 = data => {
    this.setState({
      ...this.state,
      skip2: data.selected * this.state.limit,
      limit: this.state.limit,
      data2: this.getEarningList({
        skip: data.selected * this.state.limit,
        limit: this.state.limit
      })
    });
  };

  getReferrerList = filterData => {
    let localReferredRiders = { ...this.state.data };
    if (
      this.props.data.upcDriverData.driverDetails.upcBucketInfo.upcCodeUsedBy &&
      this.props.data.upcDriverData.driverDetails.upcBucketInfo.upcCodeUsedBy
        .length
    ) {
      localReferredRiders.total = this.props.data.upcDriverData.driverDetails.upcBucketInfo.upcCodeUsedBy.length;

      localReferredRiders.data = [];
      for (
        let item = filterData.skip;
        item <= filterData.limit * filterData.skip + filterData.limit;
        item++
      ) {
        if (
          this.props.data.upcDriverData.driverDetails.upcBucketInfo
            .upcCodeUsedBy[item]
        ) {
          localReferredRiders.data.push(
            this.props.data.upcDriverData.driverDetails.upcBucketInfo
              .upcCodeUsedBy[item]
          );
        }
      }
      if (localReferredRiders.data.length) {
        localReferredRiders.data.forEach(each => {
          each.createdAt = moment(each.createdAt).format("MM-DD-YYYY");
          each.contact =
            CountryCodesConfig.filter(item => {
              return item.code === each.personalInfo.phone.countryCode;
            })[0].dial_code + each.personalInfo.phone.number;
        });
      }
    }

    return localReferredRiders;
  };

  getEarningList = filterData => {
    let localEarningHistory = { ...this.state.data2 };
    if (
      this.props.data.upcDriverData.driverDetails.balanceHistory &&
      this.props.data.upcDriverData.driverDetails.balanceHistory.length
    ) {
      localEarningHistory.total = this.props.data.upcDriverData.driverDetails.balanceHistory.length;

      localEarningHistory.data = [];
      for (
        let item = filterData.skip;
        item <= filterData.limit * filterData.skip + filterData.limit;
        item++
      ) {
        if (this.props.data.upcDriverData.driverDetails.balanceHistory[item]) {
          localEarningHistory.data.push(
            this.props.data.upcDriverData.driverDetails.balanceHistory[item]
          );
        }
      }
      if (localEarningHistory.data.length) {
        localEarningHistory.data.forEach(each => {
          each.dropTime = moment(each.tripId.dropTime).format(
            "MM-DD-YYYY, h:mm a"
          );
          each.amountCredited = each.amountCredited
            ? each.amountCredited.toFixed(2)
            : 0;
        });
      }
    }

    return localEarningHistory;
  };

  render() {
    const { classes, theme } = this.props;
    const { tabValue } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        {!this.state.loading ? (
          <div>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                    <Typography component="p">
                      <span className={classes.pageHeader}>
                        {
                          this.props.data.literalsReducer.UpcDriverDetails
                            .PageHeader
                        }
                      </span>
                      <span className={classes.PageDescription}>
                        {
                          this.props.data.literalsReducer.UpcDriverDetails
                            .PageDescription
                        }
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.headerRightButton}
                      onClick={this.backToPrevPage}
                    >
                      <ArrowBackIosIcon className={classes.leftIcon} /> Back
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <Grid container>
                    <Grid item xs={12} sm={12} className={classes.rightPaper}>
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={12}>
                            <Tabs
                              value={tabValue}
                              onChange={this.handleTabChange}
                              className={classes.tabPan}
                              variant="fullWidth"
                            >
                              <Tab label="Ur PC Chauffeur Details" />
                              <Tab label="Ur PC Earning History" />
                            </Tabs>
                            {tabValue === 0 && (
                              <TabContainer>
                                <Grid
                                  container
                                  className={classes.upcDriverDetailsCont}
                                >
                                  <Grid item xs={12} sm={3}>
                                    <div
                                      className={
                                        classes.profileImagePlaceholder
                                      }
                                    >
                                      <div
                                        style={{
                                          backgroundSize: "cover",
                                          backgroundRepeat: "no-repeat",
                                          width: "100%",
                                          height: "100%",
                                          backgroundImage: `url( ${this.state.driverDetails.profilePicture} )`
                                        }}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <div className={classes.nameHeading}>
                                      {this.state.driverDetails.fullName}
                                    </div>
                                    <div
                                      className={classNames(
                                        classes.description,
                                        classes.userId
                                      )}
                                    >
                                      ({this.state.driverDetails.displayId})
                                    </div>
                                    <div className={classes.description}>
                                      Email -
                                      <span>
                                        {this.state.driverDetails.email}
                                      </span>
                                    </div>
                                    <div className={classes.description}>
                                      Contact -
                                      <span>
                                        {" "}
                                        {this.state.driverDetails.phoneNumber}
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} sm={5}>
                                    <div className={classes.description}>
                                      Ur PC Code -
                                      <span>
                                        {this.state.driverDetails.upcCode}
                                      </span>
                                    </div>
                                    <div className={classes.description}>
                                      No. of people referred -
                                      <span>
                                        {this.state.driverDetails.upcCodeUsedBy}
                                      </span>
                                    </div>
                                    <div className={classes.description}>
                                      Ur PC Money Made -
                                      <span>
                                        $
                                        {
                                          this.state.driverDetails
                                            .upcCreditsEarned
                                        }
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Divider
                                  variant="middle"
                                  style={{ margin: "25px 0" }}
                                />
                                <Grid container>
                                  <Grid item xs={12} sm={12}>
                                    <h3>Referred Riders</h3>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    style={{ paddingTop: 0 }}
                                  >
                                    {Object.keys(this.state.data).length &&
                                    this.state.data.data.length ? (
                                      <CustomTable
                                        rows={this.state.data}
                                        headerFormat={this.state.headerFormat}
                                        sort={this.handleSortClick}
                                        rowsPerPage={this.state.limit}
                                        page={this.state.skip}
                                        total={this.state.data.total}
                                        handleChangePage={this.handleChangePage}
                                        handleChangeRowsPerPage={
                                          this.handleChangeRowsPerPage
                                        }
                                        handleAvailabilityChange={
                                          this.handleAvailabilityChange
                                        }
                                        visibleColumns={
                                          this.state.visibleColumns
                                        }
                                        redirect={this.redirect}
                                        hideColumnDropdown={true}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          padding: "10px",
                                          backgroundColor: "#eee",
                                          borderRadius: "10px"
                                        }}
                                      >
                                        No referred riders found!
                                      </div>
                                    )}
                                  </Grid>
                                  {Object.keys(this.state.data).length &&
                                  this.state.data.data.length ? (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      style={{ paddingTop: 0 }}
                                    >
                                      <ReactPaginate
                                        previousLabel={"<<"}
                                        nextLabel={">>"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={
                                          this.state.data.total /
                                          this.state.limit
                                        }
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={
                                          "pages pagination"
                                        }
                                        activeClassName={"active"}
                                      />
                                    </Grid>
                                  ) : null}
                                </Grid>
                              </TabContainer>
                            )}
                            {tabValue === 1 && (
                              <TabContainer>
                                <Grid container>
                                  <Grid item xs={12} sm={12} />
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    style={{ paddingTop: 0 }}
                                  >
                                    {" "}
                                    {Object.keys(this.state.data2).length &&
                                    this.state.data2.data.length ? (
                                      <CustomTable
                                        rows={this.state.data2}
                                        headerFormat={this.state.headerFormat2}
                                        sort={this.handleSortClick}
                                        rowsPerPage={this.state.limit}
                                        page={this.state.skip}
                                        total={this.state.data.total}
                                        handleChangePage={this.handleChangePage}
                                        handleChangeRowsPerPage={
                                          this.handleChangeRowsPerPage
                                        }
                                        handleAvailabilityChange={
                                          this.handleAvailabilityChange
                                        }
                                        visibleColumns={
                                          this.state.visibleColumns2
                                        }
                                        redirect={this.redirectFromHistory}
                                        hideColumnDropdown={true}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          padding: "10px",
                                          backgroundColor: "#eee",
                                          borderRadius: "10px"
                                        }}
                                      >
                                        No earning history found!
                                      </div>
                                    )}
                                  </Grid>
                                  {Object.keys(this.state.data2).length &&
                                  this.state.data2.data.length ? (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      style={{ paddingTop: 0 }}
                                    >
                                      <ReactPaginate
                                        previousLabel={"<<"}
                                        nextLabel={">>"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={
                                          this.state.data2.total /
                                          this.state.limit
                                        }
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={
                                          "pages pagination"
                                        }
                                        activeClassName={"active"}
                                      />
                                    </Grid>
                                  ) : null}
                                </Grid>
                              </TabContainer>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              padding: "10px",
              backgroundColor: "#fff",
              borderRadius: "10px"
            }}
          >
            {this.props.data.literalsReducer.Common.Loading}
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUPCDriver: id => dispatch(fetchUPCDriver(id)),
    resetDetails: () => dispatch(resetDetails())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(UpcDriverDetails)
);
