import React, { Component } from "react";

import { connect } from "react-redux";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import theme from "../../../app/theme";
import BlankProfileImage from "../../../assets/images/default-profile-pic.png";
import styles from "./styles";
import contentStyles from "../../auth-layout/content.styles";
import globalstyles from "../../../globalstyles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import imageIcon from "../../../assets/images/image-icon.png";
import DemoUserImage from "../../../assets/images/super-admin.jpg";
import { library } from "@fortawesome/fontawesome-svg-core";
import { ToastStore } from "react-toasts";
import { faCog, faKey, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getProfileDetail,
  updateProfile,
  updateUserData
} from "../../../store/actions/auth";
import { showSnackbar } from "../../../store/actions/snackbar";

library.add(faCog, faKey, faUpload);
class AccountSettings extends Component {
  state = {
    profile: {
      firstName: "",
      lastName: "",
      fullName: "",
      email: "",
      profilePicture: ""
    },
    imagePreviewUrl: "",
    errors: {}
  };

  handleChange = event => {
    let fields = { ...this.state.profile };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    if (event.target.name === "email") {
      errors.emailInvalid = false;
    }

    this.setState({ ...this.state, profile: fields, errors });
  };

  settingsValidation = () => {
    let fields = { ...this.state.profile };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    if (!fields.firstName.trim()) {
      errors.firstName = true;
      hasErrors = true;
    }
    if (!fields.lastName.trim()) {
      errors.lastName = true;
      hasErrors = true;
    }

    if (!fields.email.trim()) {
      errors.email = true;
      hasErrors = true;
    }

    if (
      fields.email.trim().length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields.email)
    ) {
      errors["emailInvalid"] = true;
      hasErrors = true;
    } else {
      errors["emailInvalid"] = false;
    }
    this.setState({ errors: errors });
    return hasErrors;
  };

  uploadLogo = e => {
    if (
      e.target.files &&
      e.target.files.length &&
      (e.target.files[0].type !== "image/png" &&
        e.target.files[0].type !== "image/jpg" &&
        e.target.files[0].type !== "image/jpeg")
    ) {
      this.props.showSnackbar("Profile image must be of jpg/png", 'error');
      return;
    }

    this.setState({
      ...this.state,
      profile: {
        ...this.state.profile,
        profilePicture: e.target.files[0]
      }
    });
  };

  saveHandler = () => {
    let requestData = { ...this.state.profile };
    let payload = {
      firstName: requestData.firstName,
      lastName: requestData.lastName,
      email: requestData.email,
      profilePicture: requestData.profilePicture
    };
    // let formData = new FormData();

    let formData = new FormData();
    for (let name in requestData) {
      formData.append(name, requestData[name]);
    }

    if (!this.settingsValidation()) {
      this.props.updateProfile(formData);
    } else {
      console.log("Admin Reset Form has errors", this.state);
    }
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  };
  componentDidMount() {
    this.props.getProfileDetail();
  }

  componentDidUpdate(prevProps, prevState) {
    let localData = JSON.parse(localStorage.getItem("upcAuthData"));
    if (prevProps.data !== this.props.data) {
      if (this.props.data.authReducer.updateProfileData.updateProfileSuccess) {
        let requestData = { ...this.state.profile };
        if (localData) {
          localData.user.personalInfo.firstName = requestData.firstName;
          localData.user.personalInfo.lastName = requestData.lastName;
          localData.user.personalInfo.fullName =
            requestData.firstName + " " + requestData.lastName;
          localData.user.personalInfo.email = requestData.email;
          localData.user.personalInfo.profilePicture =
            this.props.data.authReducer.userData &&
            this.props.data.authReducer.userData.user
              ? this.props.data.authReducer.userData.user.personalInfo
                  .profilePicture
              : "";
          localStorage.setItem("upcAuthData", JSON.stringify(localData));
          this.props.updateUserData(localData);
          this.props.getProfileDetail();
        }
      }
      if (Object.keys(this.props.data.authReducer.userData).length) {
        let profileData = this.props.data.authReducer.userData.user;
        let tmpProfileData = {
          firstName: profileData.personalInfo.firstName,
          lastName: profileData.personalInfo.lastName,
          fullName: profileData.personalInfo.fullName,
          email: profileData.personalInfo.email,
          profilePicture: profileData.personalInfo.profilePicture
        };
        localData.user.personalInfo.firstName =
          profileData.personalInfo.firstName;
        localData.user.personalInfo.lastName =
          profileData.personalInfo.lastName;
        localData.user.personalInfo.fullName =
          profileData.personalInfo.firstName +
          " " +
          profileData.personalInfo.lastName;
        localData.user.personalInfo.email = profileData.personalInfo.email;
        localData.user.personalInfo.profilePicture =
          profileData.personalInfo.profilePicture;
        localStorage.setItem("upcAuthData", JSON.stringify(localData));
        this.setState({
          ...this.state,
          profile: tmpProfileData
        });
      }
      // if (JSON.parse(localStorage.getItem("upcAuthData"))) {
      //   let profileData = JSON.parse(localStorage.getItem("upcAuthData")).user;
      //   if (Object.keys(profileData).length !== 0) {
      //     let tmpProfileData = {
      //       firstName: profileData.personalInfo.firstName,
      //       lastName: profileData.personalInfo.lastName,
      //       fullName: profileData.personalInfo.fullName,
      //       email: profileData.personalInfo.email,
      //       profilePicture: profileData.personalInfo.profilePicture
      //     };
      //     console.log("profile ", tmpProfileData);
      //     this.setState({
      //       ...this.state,
      //       profile: tmpProfileData
      //     });
      //   }
      // }
    }
  }

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Paper className={classes.rightPaper}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <h3>
                {this.props.data.literalsReducer.UserProfile.AccountSettings}
              </h3>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12}>
                  <form>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.profileImageSmall}>
                        <div className={classes.profileImagePlaceholderSmall}>
                          {this.state.profile.profilePicture ? (
                            typeof this.state.profile.profilePicture ===
                            "object" ? (
                              <div
                                style={{
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  width: "100%",
                                  height: "100%",
                                  backgroundImage: `url( ${window.URL.createObjectURL(
                                    this.state.profile.profilePicture
                                  )} )`
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  width: "100%",
                                  height: "100%",
                                  backgroundImage: `url( ${
                                    this.state.profile.profilePicture
                                  } )`
                                }}
                              />
                            )
                          ) : (
                            <img
                              src={BlankProfileImage}
                              alt="no-menu-image"
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                        <div
                          className={classes.profileImageUploaderContainerSmall}
                        >
                          <div
                            className={classes.uploadImageButtonFileSmall}
                            onClick={e => {
                              this.refs["file-upload"].click();
                            }}
                          >
                            <input
                              ref={"file-upload"}
                              type="file"
                              style={{
                                display: "none"
                              }}
                              onChange={this.uploadLogo}
                            />
                            <FontAwesomeIcon
                              icon={faUpload}
                              style={{ fontSize: "30px", color: "#fff" }}
                            />
                          </div>
                        </div>
                      </div>
                      <p className={classes.description}>
                        {this.props.data.literalsReducer.UserProfile.FullName}
                      </p>
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            id="first-name"
                            label={
                              this.props.data.literalsReducer.UserProfile
                                .FirstName
                            }
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            name="firstName"
                            value={this.state.profile.firstName || ""}
                            onChange={this.handleChange}
                            error={this.state.errors.firstName}
                          />
                          {this.state.errors.firstName ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .Required
                              }
                            </FormHelperText>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            id="last-name"
                            label={
                              this.props.data.literalsReducer.UserProfile
                                .LastName
                            }
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                            name="lastName"
                            value={this.state.profile.lastName || ""}
                            onChange={this.handleChange}
                            error={this.state.errors.lastName}
                          />
                          {this.state.errors.lastName ? (
                            <FormHelperText id="validation-error2">
                              {
                                this.props.data.literalsReducer.ValidationErrors
                                  .Required
                              }
                            </FormHelperText>
                          ) : null}
                        </Grid>
                      </Grid>
                      <p className={classes.description}>
                        {this.props.data.literalsReducer.UserProfile.Email}
                      </p>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="email"
                          label={
                            this.props.data.literalsReducer.UserProfile
                              .EmailField
                          }
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          name="email"
                          value={this.state.profile.email || ""}
                          onChange={this.handleChange}
                          error={
                            this.state.errors.email ||
                            this.state.errors.emailInvalid
                          }
                        />
                        {this.state.errors.email ? (
                          <FormHelperText id="validation-error2">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                        {!this.state.errors.email &&
                        this.state.errors.emailInvalid ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .InvalidEmail
                            }
                          </FormHelperText>
                        ) : null}
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>

              <div className={classes.actionContainer}>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className={classes.activeGoButton}
                  onClick={this.saveHandler}
                >
                  {this.props.data.literalsReducer.UserProfile.SaveButton}
                </Button>
                <Button
                  size="large"
                  color="default"
                  variant="contained"
                  onClick={this.backToPrevPage}
                >
                  {this.props.data.literalsReducer.UserProfile.CancelButton}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    getProfileDetail: () => dispatch(getProfileDetail()),
    updateProfile: payload => dispatch(updateProfile(payload)),
    updateUserData: payload => dispatch(updateUserData(payload)),
    showSnackbar: (message, variant) => dispatch(showSnackbar(message, variant))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(AccountSettings)
);
