import {
  FETCH_PAST_TRIPS_SUCCESS,
  FETCH_PAST_TRIP_SUCCESS,
  FETCH_PAST_TRIP,
  FETCH_PAST_TRIP_FAIL,
  RESET_PAST_TRIP_DETAILS} from "../constants/action-types";

const initialState = {
  pastTripList: {},
  tripDetails: {},
  isFetching: false
};

const pastTripReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAST_TRIPS_SUCCESS:
      return {
        ...state,
        pastTripList: action.payload.data
      };
    case FETCH_PAST_TRIP:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_PAST_TRIP_SUCCESS:
      return {
        ...state,
        tripDetails: action.payload.data,
        isFetching: false
      };
    case FETCH_PAST_TRIP_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case RESET_PAST_TRIP_DETAILS:
      return {
        ...state,
        tripDetails: {}
      };
    default:
      return state;
  }
};

export default pastTripReducer;
