import {
  FETCH_BOOKING_REQUESTS_SUCCESS,
  FETCH_BOOKING_REQUEST,
  FETCH_BOOKING_REQUEST_SUCCESS,
  FETCH_BOOKING_REQUEST_FAIL,
  RESET_BOOKING_REQUEST_DETAILS,
  CANCEL_BOOKING_REQUEST_SUCCESS,
  BOOKING_CANCELED_DONE,
  CREATE_BOOKING_REQUEST_SUCCESS,
  ASSIGN_DRIVERS_TO_BOOKING_REQUEST_SUCCESS,
  CREATE_BOOKING_REQUEST_FAIL,
  UPDATE_SCHEDULE_TIME_SUCCESS,
  UPDATE_SCHEDULE_TIME_FAIL,
  UPDATE_SCHEDULE_TIME_DONE
} from "../constants/action-types";

const initialState = {
  bookingRequestList: {},
  tripDetails: {},
  isFetching: false,
  bookingCanceled: false
};

const bookingRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BOOKING_REQUEST_SUCCESS:
      return {
        ...state,
        requestResponse: action.payload.data,
        bookingRequested: true,
      };
    case CREATE_BOOKING_REQUEST_FAIL:
      return {
        ...state,
        requestResponse: {
          noDriversFound: true
        },
        bookingRequested: true,
      };
    case ASSIGN_DRIVERS_TO_BOOKING_REQUEST_SUCCESS:
      return {
        ...state,
        assignedDrivers: true,
      };
    case FETCH_BOOKING_REQUESTS_SUCCESS:
      return {
        ...state,
        bookingRequestList: action.payload.data
      };
    case FETCH_BOOKING_REQUEST:
      return {
        ...state,
        tripDetails: {},
        isFetching: true
      };
    case FETCH_BOOKING_REQUEST_SUCCESS:
      return {
        ...state,
        tripDetails: action.payload.data,
        isFetching: false
      };
    case FETCH_BOOKING_REQUEST_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case RESET_BOOKING_REQUEST_DETAILS:
      return {
        ...state,
        tripDetails: {}
      };
    case CANCEL_BOOKING_REQUEST_SUCCESS:
      return {
        ...state,
        bookingCanceled: true
      };
    case BOOKING_CANCELED_DONE:
      return {
        ...state,
        bookingCanceled: false
      };
    case UPDATE_SCHEDULE_TIME_SUCCESS:
      return {
        ...state,
        scheduleTimeUpdated: true
      };
    case UPDATE_SCHEDULE_TIME_FAIL:
    case UPDATE_SCHEDULE_TIME_DONE:
      return {
        ...state,
        scheduleTimeUpdated: false
      };
    default:
      return state;
  }
};

export default bookingRequestReducer;
