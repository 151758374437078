export const VehicleTypes = [
  {
    value: "-1",
    label: "All"
  },
  {
    value: "1",
    label: "Sedan"
  },
  {
    value: "2",
    label: "SUV"
  }
];
