import {
  GET_RATE_CHART_SUCCESS,
  GET_SAPPHIRE_RATE_CHART_SUCCESS,
  UPDATE_FIXED_RATE_CHART_SUCCESS,
  FIXED_RATE_CHART_UPDATED_DONE,
  UPDATE_SAPPHIRE_DURATION_RATE_CHART,
  FIXED_SAPPHIRE_RATE_CHART_UPDATED_DONE,
  DURATION_RATE_CHART_UPDATED_DONE,
  UPDATE_DURATION_RATE_CHART_SUCCESS,
  DURATION_SAPPHIRE_RATE_CHART_UPDATED_DONE,
  RESET_RATE_CHART_DETAILS,
  RESET_SAPPHIRE_RATE_CHART_DETAILS,
  UPDATE_SAPPHIRE_FIXED_RATE_CHART
} from "../constants/action-types";

const initialState = {
  rateChartDetails: {},
  sapphireRateChartDetails: {},
  fixedRateChartUpdated: false,
  durationRateChartUpdated: false
};

const rateChartData = (state = initialState, action) => {
  switch (action.type) {
    case GET_RATE_CHART_SUCCESS:
      return {
        ...state,
        rateChartDetails: action.payload.data
      };
    case GET_SAPPHIRE_RATE_CHART_SUCCESS:
      return {
        ...state,
        sapphireRateChartDetails: action.payload.data
      };
    case UPDATE_FIXED_RATE_CHART_SUCCESS:
      let localRateChartDetails = { ...state.rateChartDetails };
      let localFixedData = { ...localRateChartDetails.fixed };
      localFixedData = action.payload.data;
      localRateChartDetails.fixed = localFixedData;
      return {
        ...state,
        fixedRateChartUpdated: true,
        rateChartDetails: localRateChartDetails
      };
    case UPDATE_SAPPHIRE_FIXED_RATE_CHART:
      let localRateChartDetails1 = { ...state.sapphireRateChartDetails };
      let localFixedData1 = { ...localRateChartDetails1.fixed };
      localFixedData1 = action.payload.data;
      localRateChartDetails1.fixed = localFixedData1;
      return {
        ...state,
        fixedRateChartUpdated: true,
        sapphireRateChartDetails: localRateChartDetails1
      };
    case FIXED_RATE_CHART_UPDATED_DONE:
      return {
        ...state,
        fixedRateChartUpdated: false
      };
    case FIXED_SAPPHIRE_RATE_CHART_UPDATED_DONE:
      return {
        ...state,
        fixedRateChartUpdated: false
      };
    case UPDATE_DURATION_RATE_CHART_SUCCESS:
      let localRateChartData = { ...state.rateChartDetails };
      let localDurationData = { ...localRateChartData.duration };
      localDurationData = action.payload.data;
      localRateChartData.duration = localDurationData;
      return {
        ...state,
        durationRateChartUpdated: true,
        rateChartDetails: localRateChartData
      };
    case UPDATE_SAPPHIRE_DURATION_RATE_CHART:
      let localRateChartData1 = { ...state.sapphireRateChartDetails };
      let localDurationData1 = { ...localRateChartData1.duration };
      localDurationData1 = action.payload.data;
      localRateChartData1.duration = localDurationData1;
      return {
        ...state,
        durationRateChartUpdated: true,
        sapphireRateChartDetails: localRateChartData1
      };
    case DURATION_RATE_CHART_UPDATED_DONE:
      return {
        ...state,
        durationRateChartUpdated: false
      };
    case DURATION_SAPPHIRE_RATE_CHART_UPDATED_DONE:
      return {
        ...state,
        durationRateChartUpdated: false
      };
    case RESET_RATE_CHART_DETAILS:
      return {
        ...state,
        rateChartDetails: {}
      };
    case RESET_SAPPHIRE_RATE_CHART_DETAILS:
      return {
        ...state,
        rateChartDetails: {}
      };
    default:
      return state;
  }
};

export default rateChartData;
