// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  FETCH_PAST_TRIPS,
  FETCH_PAST_TRIP,
  RESET_PAST_TRIP_DETAILS} from "../constants/action-types";

export const getPastTripList = searchObj => {
  return {
    type: FETCH_PAST_TRIPS,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.pastTrip,
        method: "POST",
        params: {
          skip: searchObj.skip,
          limit: searchObj.limit
        },
        data: {
          sortConfig: searchObj.sortConfig,
          filters: searchObj.filters
        }
      }
    }
  };
};

export const fetchTrip = id => {
  return {
    type: FETCH_PAST_TRIP,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.pastTrip + "/" + id,
        method: "GET"
      }
    }
  };
};

export const resetDetails = () => {
  return {
    type: RESET_PAST_TRIP_DETAILS
  };
};




