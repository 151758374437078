// API Config
import { apiURLs } from '../api-config/api.urls';

// Action Types Constant 
import {
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_PROFILE ,
  SESSION_LOGOUT,
  UPDATE_PROFILE,
  UPDATE_USER_SUCCESS,
  OTP_SENT
  
} from "../constants/action-types";


///////////
// Login //
///////////
export const login = (data) => {
  return {
    type: LOGIN,
    payload: {
      request: {
        url: apiURLs.login,
        method: "POST",
        data
      }
    }
  };
};

//////////////////// Get  Profile // //////////////////
export const getProfileDetail = () => {
  return {
    type: GET_PROFILE,
    payload: {
      request: {
        url: apiURLs.getProfile,
        method: "GET"
      }
    },
    hideToast: true
  };
};

// Update Profile //

export const updateProfile = (payload) => {
  return {
    type: UPDATE_PROFILE,
    payload: {
      request: {
        url: apiURLs.updateProfile,
        method: "PUT",
        data: payload
      }
    }
  };
};




///////////////////////
// Forgot Password ///
/////////////////////
export const forgotPassword = (data) => {
  return {
    type: FORGOT_PASSWORD,
    payload: {
      request: {
        url: apiURLs.forgotPassword,
        method: "POST",
        data
      }
    }
  };
};


// Reset Password
export const resetPassword = (data) => {
  return {
    type: RESET_PASSWORD,
    payload: {
      request: {
        url: apiURLs.resetPassword,
        method: "POST",
        data
      }
    }
  };
};

////////////////////
// Session Logout //
////////////////////
export const session_logout = () =>{
  return {
    type: SESSION_LOGOUT
  }
};

////////////////////
// User Update //
////////////////////

export const updateUserData = (data) =>{
  return {
    type: UPDATE_USER_SUCCESS,
    payload: {
      data : data
    }
  }
};


export const sentOtpDone = (data) =>{
  return {
    type:  OTP_SENT
  };
};