import {
  GET_GLOBAL_CONFIG_SUCCESS,
  UPDATE_GLOBAL_CONFIG_SUCCESS,
  CONFIG_UPDATED_DONE,
  RESET_CONFIG_DETAILS
} from "../constants/action-types";

const initialState = {
  globalConfigDetails: {},
  configUpdated: false
};

const globalConfigData = (state = initialState, action) => {
  switch (action.type) {
    case GET_GLOBAL_CONFIG_SUCCESS:
      return {
        ...state,
        globalConfigDetails: action.payload.data
      };
    case UPDATE_GLOBAL_CONFIG_SUCCESS:
      return {
        ...state,
        configUpdated: true
      };
    case CONFIG_UPDATED_DONE:
      return {
        ...state,
        configUpdated: false
      };
    case RESET_CONFIG_DETAILS:
      return {
        ...state,
        globalConfigDetails: {}
      };
    default:
      return state;
  }
};

export default globalConfigData;
