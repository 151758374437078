/* global google */
import React, { Component } from "react";
import {
    Avatar,
    Button,
    FormControlLabel,
    Grid,
    Input,
    Menu,
    MenuItem,
    MuiThemeProvider,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
    withStyles,
    IconButton,
    Drawer,
    Tabs,
    Tab,
    FormHelperText,
    Modal,
    CircularProgress,
    Divider,
    Tooltip,
} from "@material-ui/core";
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import theme from "../../../app/theme";
import { connect } from "react-redux";
import classNames from "classnames";
import styles from "../schedule-trip/styles";
import contentStyles from "../../auth-layout/content.styles";
import globalstyles from "../../../globalstyles";
import CountryCodesConfig from "../../../config/country-codes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import DemoUserImage from "../../../assets/images/default-profile-pic.png";
import {
    faCaretDown, faChevronDown, faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import ScrollArea from "react-scrollbar";
import moment from "moment";
import cancel from "../../../assets/images/cancel.png";
import { fetchRiderCardList, getRiderList } from "../../../store/actions/rider";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "material-ui-pickers";
import { calculatePrice, getRequestedTravelDistance, getRequestedTravelTime, renderQuote } from "./rate-charts";
import { TripTypes } from "../../../config/trip";
import { ToastStore } from "react-toasts";
import MapComponent from "../../../components/Map";
import { getOperationalParameter } from "../../../store/actions/operationalParameter";
import { getRateChart } from "../../../store/actions/rateChart";
import { VehicleTypes } from "../../../config/vehicle";
import { renderFavoriteChauffeurs } from "./favorite-chauffeur-list";
import DraggableList from "../../../components/DraggableList";
import { DropResult } from 'react-beautiful-dnd';
import { reorder, sort } from "../../../utils/SupportUtils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import { assignDriversToBookingRequest, createBookingRequest } from "../../../store/actions/bookingRequest";
import { GoogleApiUtils } from "../../../utils/GoogleApiUtils";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { showSnackbar } from "../../../store/actions/snackbar";

library.add(faChevronDown, faInfoCircle);

const skip = 0;
const limit = Number.MAX_SAFE_INTEGER;
const countries = CountryCodesConfig.filter(each => each.code === 'US');

function TabContainer({ children }) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const modalStyles = theme => ({
    modalPaper: {
        position: "absolute",
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: "none"
    },
    modalPaperLarge: {
        width: theme.spacing.unit * 150,
        overflowY: "scroll",
        height: "90%"
    }
});

class ScheduleTrip extends Component {
    constructor(props) {
        super(props);
        this.originLocationInput = React.createRef();
        this.destinationLocationInput = React.createRef();
        this.originAutocomplete = null;
        this.destinationAutocomplete = null;
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.state = {
            userMenuRef: null,
            data: {
                total: 0,
                data: [],
                masterData: []
            },
            tripDetails: {
                rider: null,
                bookingTime: new Date().toISOString(),
                type: "",
                hours: 0,
                passengers: "",
                scheduleDate: null,
                scheduleTime: null,
                pickupLocation: "",
                dropLocation: "",
                plan: "",
                alertContact1: {
                    code: countries[0].dial_code,
                    number: ""
                },
                alertContact2: {
                    code: countries[0].dial_code,
                    number: ""
                },
                alertContact3: {
                    code: countries[0].dial_code,
                    number: ""
                },
                fixedPrice: 0,
                flightDetails: "",
                alternateRiderName: ""
            },
            directionData: null,
            scheduleTripInterval: 0,
            scheduleTripIntervalFetched: false,
            activeStep: 0,
            errors: {},
            right: false,
            tabValue: 0,
            fixedRateChartDetails: {
                baseFarePerKmSedan: "",
                baseFarePerKmSUV: "",
                chargePerKmSedan: "",
                chargePerKmSUV: "",
                chargePerMinTravelTimeSedan: "",
                chargePerMinTravelTimeSUV: "",
                waitingTimeChargeSedan: "",
                waitingTimeChargeSUV: "",
                discountPercentage: ""
            },
            durationRateChartDetails: {
                baseFareSedan: "",
                baseFareSUV: "",
                chargePerMinWaitingTimeSedan: "",
                chargePerMinWaitingTimeSUV: "",
                hourlyPerHourBookingSedan: "",
                hourlyPerHourBookingSUV: "",
                hourlyPenaltyChargeSedan: "",
                hourlyPenaltyChargeSUV: "",
                hourlyExcessMin: "",
                hourlyExcessChargeSedan: "",
                hourlyExcessChargeSUV: "",
                halfDayPerHourBookingSedan: "",
                halfDayPerHourBookingSUV: "",
                halfDayPenaltyChargeSedan: "",
                halfDayPenaltyChargeSUV: "",
                halfDayExcessMin: "",
                halfDayExcessChargeSedan: "",
                halfDayExcessChargeSUV: "",
                fullDayPerHourBookingSedan: "",
                fullDayPerHourBookingSUV: "",
                fullDayPenaltyChargeSedan: "",
                fullDayPenaltyChargeSUV: "",
                fullDayExcessMin: "",
                fullDayExcessChargeSedan: "",
                fullDayExcessChargeSUV: "",
                discountPercentageDuration: ""
            },
            chartDetailsLoaded: false,
            rateChart: null,
            vehicleType: -1,
            searchedDriverInfo: null,
            suvPriceEstimation: null,
            sedanPriceEstimation: null,
            quoteModalOpen: false,
            quote: {
                total: null,
                direction: null,
                rate: null,
                type: null
            },

        };
        this.tripCreateReq = null;
        this.tripId = null;
    };

    openQuoteModal = (vehicleType) => {
        this.setState({
            ...this.state,
            quoteModalOpen: true,
            quote: {
                total: vehicleType === 1 ? this.state.sedanPriceEstimation : this.state.suvPriceEstimation,
                direction: this.state.directionData,
                rate: this.state.rateChart,
                type: Number(this.state.tripDetails.type),
                vehicleType
            }
        });
    };

    closeQuoteModal = () => {
        this.setState({ quoteModalOpen: false });
    };

    setActiveStep = (step) => {
        this.setState({
            activeStep: step
        })
    }

    openUserMenu = (event) => {
        console.log(event);
        this.setState({
            userMenuRef: event.currentTarget
        })
    };

    handleChange = event => {
        let fields = { ...this.state.tripDetails };
        let errors = { ...this.state.errors };
        fields[event.target.name] = event.target.value;
        errors[event.target.name] = false;
        this.setState({ ...this.state, tripDetails: fields, errors });
    };

    handlePhoneNoChange = key => event => {
        let fields = { ...this.state.tripDetails };
        let errors = { ...this.state.errors };
        fields[event.target.name][key] = event.target.value;
        errors[event.target.name + "_" + key] = false;
        this.setState({ ...this.state, tripDetails: fields, errors });
    };

    tripRequestValidation = () => {
        let fields = { ...this.state.tripDetails };
        let errors = {
            ...this.state.errors
        };
        let hasErrors = false;

        if (!(fields.type && fields.type.trim())) {
            errors.type = true;
            hasErrors = true;
        }
        if ((fields.type != "2" && fields.type != "1") && !fields.hours) {
            errors.hours = true;
            hasErrors = true;
        }
        if (!(fields.passengers)) {
            errors.passengers = true;
            hasErrors = true;
        }
        if (!(fields.scheduleDate)) {
            errors.scheduleDate = true;
            hasErrors = true;
        }
        if (!(fields.scheduleTime)) {
            errors.scheduleTime = true;
            hasErrors = true;
        }

        if (!(fields.pickupLocation)) {
            errors.pickupLocation = true;
            hasErrors = true;
        }

        if (!(fields.dropLocation)) {
            errors.dropLocation = true;
            hasErrors = true;
        }

        if (fields.alertContact1.number.trim() && fields.alertContact1.number.trim().length < 10) {
            errors.alertContact1_number = true;
            hasErrors = true;
        }
        if (fields.alertContact2.number.trim() && fields.alertContact2.number.trim().length < 10) {
            errors.alertContact2_number = true;
            hasErrors = true;
        }

        if (fields.alertContact3.number.trim() && fields.alertContact3.number.trim().length < 10) {
            errors.alertContact3_number = true;
            hasErrors = true;
        }

        console.log("hasErrors ", hasErrors);
        console.log("errors ", errors);
        console.log("fields ", fields);

        this.setState({ errors });
        return hasErrors;
    };

    handleClose = () => {
        this.setState({
            userMenuRef: null
        })
    };

    handleTabChange = (event, tabValue) => {
        this.setState({ tabValue });
    };

    handleDateChange = date => {
        this.setState({
            tripDetails: {
                ...this.state.tripDetails,
                scheduleDate: date,
                scheduleTime: date
            },
            errors: {
                ...this.state.errors, scheduleDate: !date
            }
        });
    };

    handleTimeChange = time => {
        this.setState({
            tripDetails: {
                ...this.state.tripDetails,
                scheduleTime: time
            },
            errors: {
                ...this.state.errors, scheduleTime: !time
            }
        });

    };

    handlePlaceChanged = (key, input) => () => {
        const place = input.getPlace();
        let localTrip = { ...this.state.tripDetails }
        localTrip[key] = place;
        this.setState({ ...this.state, tripDetails: localTrip });
        // this.props.onPlaceChanged(place);
    };

    setRiderList = (prevProps) => {
        if (prevProps.data !== this.props.data) {
            if (Object.keys(this.props.data.riderReducer.riderList).length) {
                let localData = { ...this.state.data };
                localData.total = this.props.data.riderReducer.riderList.total;
                localData.data = sort(this.props.data.riderReducer.riderList.data);
                localData.masterData = localData.data;
                if (localData.data.length) {
                    localData.data.forEach(each => {
                        each.createdAt = moment(each.createdAt).format("MM-DD-YYYY");
                        each.personalInfo.phoneNumber =
                            CountryCodesConfig.filter(item => {
                                return item.code === each.personalInfo.phone.countryCode;
                            })[0].dial_code +
                            this.formatNumber(each.personalInfo.phone.number);
                    });
                }
                this.setState({
                    ...this.state,
                    data: localData,
                    loading: false
                });
            }
        }
    };

    setOperationalParameters = (prevProps) => {
        if (
            Object.keys(
                this.props.data.operationalParameterData.operationalParameterDetails
            ).length &&
            !this.state.scheduleTripIntervalFetched
        ) {
            let localParameter = { ...this.state.scheduleTripInterval };
            localParameter = this.props.data.operationalParameterData.operationalParameterDetails
                .minimumScheduleThreshold;

            this.setState({
                ...this.state,
                scheduleTripInterval: localParameter,
                scheduleTripIntervalFetched: true
            });
        }
    };

    setRateChartDetails = (prevProps) => {
        if (Object.keys(this.props.data.rateChartData.rateChartDetails).length && !this.state.chartDetailsLoaded) {
            let localFixedRateChartData = { ...this.state.fixedRateChartDetails };
            let localDurationRateChartData = { ...this.state.durationRateChartDetails };
            localFixedRateChartData.baseFarePerKmSedan = this.props.data.rateChartData.rateChartDetails.fixed.baseFare.sedan;
            localFixedRateChartData.baseFarePerKmSUV = this.props.data.rateChartData.rateChartDetails.fixed.baseFare.suv;
            localFixedRateChartData.chargePerKmSedan = this.props.data.rateChartData.rateChartDetails.fixed.chargePerDistanceUnit.sedan;
            localFixedRateChartData.chargePerKmSUV = this.props.data.rateChartData.rateChartDetails.fixed.chargePerDistanceUnit.suv;
            localFixedRateChartData.chargePerMinTravelTimeSedan = this.props.data.rateChartData.rateChartDetails.fixed.chargePerMinute.sedan;
            localFixedRateChartData.chargePerMinTravelTimeSUV = this.props.data.rateChartData.rateChartDetails.fixed.chargePerMinute.suv;
            localFixedRateChartData.waitingTimeChargeSedan = this.props.data.rateChartData.rateChartDetails.fixed.waitingChargePerMinute.sedan;
            localFixedRateChartData.waitingTimeChargeSUV = this.props.data.rateChartData.rateChartDetails.fixed.waitingChargePerMinute.suv;
            localFixedRateChartData.discountPercentage = this.props.data.rateChartData.rateChartDetails.fixed.discountPercentage;

            for (let item in localFixedRateChartData) {
                localFixedRateChartData[item] = localFixedRateChartData[item].toString();
            }

            localDurationRateChartData = this.props.data.rateChartData.rateChartDetails.duration;
            this.setState({ ...this.state, rateChart: this.props.data.rateChartData.rateChartDetails, fixedRateChartDetails: localFixedRateChartData, durationRateChartDetails: localDurationRateChartData, chartDetailsLoaded: true });
        }
    }

    formatNumber(number) {
        let str1 = "(";
        for (let i = 0; i < number.length; i++) {
            str1 += number[i];

            if (i === 2) {
                str1 += ") ";
            }
            if (i === 5) {
                str1 += "-";
            }
        }
        return str1;
    };

    setTripType = (type) => {
        this.setState({
            tripDetails: {
                ...this.state.tripDetails,
                type
            }
        })
    }

    selectRider = (value) => {
        this.setState({
            tripDetails: {
                ...this.state.tripDetails,
                rider: value
            }
        })
    };

    toggleDrawer = (side, open) => () => {
        this.setState({
            ...this.state,
            [side]: open
        });
    };

    validateRider = () => {
        this.props.fetchRiderCardList(this.state.tripDetails.rider._id);
    };

    getPhoneNoWithIso = () => {
        let _arr = [];

        if (this.state.tripDetails['alertContact1'].number) {
            _arr.push(this.state.tripDetails['alertContact1'].code + this.state.tripDetails['alertContact1'].number)
        };
        if (this.state.tripDetails['alertContact2'].number) {
            _arr.push(this.state.tripDetails['alertContact2'].code + this.state.tripDetails['alertContact2'].number)
        };
        if (this.state.tripDetails['alertContact3'].number) {
            _arr.push(this.state.tripDetails['alertContact3'].code + this.state.tripDetails['alertContact3'].number)
        };
        return _arr;
    }


    handleTripRequest = () => {
        if (!this.tripRequestValidation()) {
            const fields = { ...this.state.tripDetails };
            const payload = {
                bookingAddress: fields.pickupLocation.formatted_address,
                bookingLocation: [
                    fields.pickupLocation.geometry.location.lng(),
                    fields.pickupLocation.geometry.location.lat()
                ],
                bookingTime: new Date().toISOString(),
                numberOfSeat: fields.passengers,
                pathPolyline: this.state.directionData.routes[0].overview_polyline,
                requestedDropAddress: fields.dropLocation.formatted_address,
                requestedDropLabel: fields.dropLocation.name,
                requestedDropLocation: [
                    fields.dropLocation.geometry.location.lng(),
                    fields.dropLocation.geometry.location.lat()
                ],
                requestedDropTime: new Date(fields.scheduleTime.getTime() + getRequestedTravelTime((this.state.directionData.routes[0].legs[0].duration.value * 1000), Number(fields.type))).toISOString(),
                requestedPickupAddress: fields.pickupLocation.formatted_address,
                requestedPickupLabel: fields.pickupLocation.name,
                requestedPickupLocation: [
                    fields.pickupLocation.geometry.location.lng(),
                    fields.pickupLocation.geometry.location.lat()
                ],
                requestedPickupTime: fields.scheduleTime.toISOString(),
                requestedTravelDistance: getRequestedTravelDistance(this.state.directionData.routes[0].legs[0].distance.value * 0.000621371, Number(fields.type)),
                tripType: Number(fields.type),
                requestedNumberOfHours: fields.hours,
                description: fields.plan,
                notificationContacts: this.getPhoneNoWithIso(),
                fixedPrice: fields.fixedPrice,
                flightDetails: fields.flightDetails,
                alternateRiderName: fields.alternateRiderName
            };
            this.tripCreateReq = payload;
            this.props.createBookingRequest(fields.rider._id, payload);
        }
    };

    handleListSearch = (e) => {
        const value = e.target.value;
        const _data = { ...this.state.data };
        _data.data = _data.masterData.filter(item => item.personalInfo.firstName.toLowerCase().startsWith(value.toLowerCase()))

        this.setState({
            ...this.state,
            data: _data
        })
    };

    assignDriversToTripRequest = () => {
        let { searchedDrivers } = this.state.searchedDriverInfo;
        searchedDrivers = JSON.parse(JSON.stringify(searchedDrivers))
        searchedDrivers.forEach(each => {
            delete each.distance;
            delete each.fare;
        })
        const payload = {
            driverList: searchedDrivers
        }
        this.props.assignDriversToBookingRequest(this.tripId, payload);
    };

    monitorTypeDependency = () => {
        const fields = { ...this.state.tripDetails };

        switch (fields.type) {
            case "3":
                fields.hours = 3;
                break;
            case "4":
                fields.hours = 4;
                break;
            case "5":
                fields.hours = 8;
                break;
        }
        this.setState({
            tripDetails: fields
        })
    };

    monitorDurationDependency = (param) => {
        const fields = { ...this.state.tripDetails };
        switch (fields.hours) {
            case 1:
            case 2:
            case 3:
            case 5:
            case 6:
            case 7:
                fields.type = "3";
                break;
            case 4:
                fields.type = "4";
                break;
            case 8:
                fields.type = "5";
                break;
            default:
                fields.type = "3";

        }
        this.setState({
            tripDetails: fields
        })
    };

    refactorCoordinatesFOrPlotting = (location) => {
        const coord = {
            lat: null, lng: null
        };
        if (location && location.geometry && location.geometry.location) {
            coord.lat = location.geometry.location.lat();
            coord.lng = location.geometry.location.lng();
        }
        return coord;
    }

    initOriginAutocomplete = () => {

        var options = { types: ["geocode"] };

        this.originAutocomplete = new google.maps.places.Autocomplete(
            this.originLocationInput.current, options
        );
        this.originAutocomplete.addListener("place_changed", this.handlePlaceChanged('pickupLocation', this.originAutocomplete));
    };

    initDestinationAutocomplete = () => {
        this.destinationAutocomplete = new google.maps.places.Autocomplete(
            this.destinationLocationInput.current,
            { types: ["geocode"] }
        );
        this.destinationAutocomplete.addListener("place_changed", this.handlePlaceChanged('dropLocation', this.destinationAutocomplete));
    };

    onDragEnd = ({ destination, source }) => {
        // dropped outside the list
        if (!destination) return;
        const newItems = reorder(this.state.searchedDriverInfo.searchedDrivers, source.index, destination.index);
        this.setState({
            ...this.state,
            searchedDriverInfo: {
                ...this.state.searchedDriverInfo,
                searchedDrivers: newItems
            }
        })
    };

    modifyDriverResponse = (createTripResponse) => {
        this.tripId = createTripResponse._id;

        let { searchedDrivers } = createTripResponse.searchedDriversInfo;
        let callbacks = searchedDrivers.length;
        searchedDrivers.forEach(_driver => {
            GoogleApiUtils.getDistanceMatrix(
                {
                    lat: this.state.tripDetails.pickupLocation.geometry.location.lat(),
                    lng: this.state.tripDetails.pickupLocation.geometry.location.lng()
                },
                {
                    // lat: _driver.driverInfo.currentLocationInfo.geolocation.coordinates[1],
                    // lng: _driver.driverInfo.currentLocationInfo.geolocation.coordinates[0]
                    lat: _driver.driverInfo.personalInfo.address.location.coordinates[1],
                    lng: _driver.driverInfo.personalInfo.address.location.coordinates[0]
                },
                (response) => {
                    callbacks--;
                    _driver.distance = response.routes[0].legs[0].distance;
                    _driver.fare = calculatePrice(this.tripCreateReq,
                        createTripResponse.emeraldFareChartInfo,
                        _driver.driverInfo.vehicleInfo.vehicleType);

                    if (callbacks === 0) {
                        createTripResponse.searchedDriversInfo.searchedDrivers = searchedDrivers;
                        this.setState({ ...this.state, filteredDriverInfo: createTripResponse.searchedDriversInfo, searchedDriverInfo: createTripResponse.searchedDriversInfo });
                        this.setActiveStep(2);
                    }
                })
        })
    };

    setSearchedDrivers = (prevProps) => {
        if (this.props.data.bookingRequestReducer.bookingRequested
            && prevProps.data.bookingRequestReducer.requestResponse
            !== this.props.data.bookingRequestReducer.requestResponse) {
            console.log(this.props.data.bookingRequestReducer.requestResponse);
            if (this.props.data.bookingRequestReducer.requestResponse.noDriversFound)
                this.props.history.push(`/web/auth/booking-request`);
            else
                this.modifyDriverResponse(this.props.data.bookingRequestReducer.requestResponse);

        }
    };

    handleAssignedDriversResponse = (prevProps) => {
        if (prevProps.data.bookingRequestReducer.assignedDrivers != this.props.data.bookingRequestReducer.assignedDrivers) {
            console.log(this.props.data.bookingRequestReducer.assignedResponse);
            this.props.history.push(`/web/auth/booking-request/details/${this.tripId}`);
        }
    };

    filterVehicles = (vehicleType) => {
        let localDriverInfo = { ...this.state.searchedDriverInfo };
        if (vehicleType == -1) {
            this.setState({ ...this.state, vehicleType, filteredDriverInfo: localDriverInfo })
            return;
        }
        localDriverInfo.searchedDrivers = localDriverInfo.searchedDrivers.filter(_driver => _driver.driverInfo.vehicleInfo.vehicleType.toString() === vehicleType)
        this.setState({ ...this.state, vehicleType, filteredDriverInfo: localDriverInfo })
    };

    componentDidMount() {
        let localStorageData = window.localStorage.upcAuthData;
        if (localStorageData && JSON.parse(localStorageData)) {
            if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
                JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
                    each => {
                        if (each.moduleName === "rider") {
                            if (each.role === 2) {
                                this.setState({
                                    ...this.state,
                                    editableMode: true,
                                    loading: true
                                });
                            } else {
                                this.setState({
                                    ...this.state,
                                    loading: true
                                });
                            }
                        }
                    }
                );
            } else {
                this.setState({
                    ...this.state,
                    editableMode: true,
                    loading: true
                });
            }
        }
        this.props.getRiderList({
            skip: skip,
            limit: limit,
            sortConfig: {},
            filters: {}
        });

    }

    componentDidUpdate(prevProps, prevState) {
        this.setRiderList(prevProps);
        if (prevState.tripDetails.scheduleTime !== this.state.tripDetails.scheduleTime) {
            const fields = { ...this.state.tripDetails };
            const { errors } = this.state;
            if (fields.scheduleTime.getTime() - new Date().getTime() < this.state.scheduleTripInterval * 60 * 1000) {
                errors.unacceptableScheduleTime = true;
                this.setState({ errors })
            } else {
                errors.unacceptableScheduleTime = false;
                this.setState({ errors })
            }
        }

        if (prevState.directionData !== this.state.directionData
            || prevState.tripDetails.scheduleTime != this.state.tripDetails.scheduleTime
            || prevState.tripDetails.type !== this.state.tripDetails.type) {

            const fields = { ...this.state.tripDetails };
            if (fields.type && fields.scheduleTime && this.state.directionData) {
                const tripCreateReq = {
                    tripType: Number(fields.type),
                    requestedPickupTime: fields.scheduleTime,
                    requestedDropTime: new Date(fields.scheduleTime.getTime() + (this.state.directionData.routes[0].legs[0].duration.value * 1000)).toISOString(),
                    requestedTravelDistance: this.state.directionData.routes[0].legs[0].distance.value / 1000,
                    requestedNumberOfHours: fields.hours
                };

                this.setState({
                    ...this.state,
                    sedanPriceEstimation: calculatePrice(tripCreateReq, this.state.rateChart, 1),
                    suvPriceEstimation: calculatePrice(tripCreateReq, this.state.rateChart, 2)
                });
            } else {
                this.setState({
                    ...this.state,
                    sedanPriceEstimation: null,
                    suvPriceEstimation: null
                });
            }

        }

        if (prevState.tripDetails.type != this.state.tripDetails.type) {
            this.monitorTypeDependency();
        }

        if (prevState.tripDetails.hours != this.state.tripDetails.hours) {
            this.monitorDurationDependency();
        }

        if (prevState.activeStep != this.state.activeStep) {
            if (this.state.activeStep === 1) {
                this.initOriginAutocomplete();
                this.initDestinationAutocomplete();
            }
        }

        if (prevProps.data.riderReducer.cardList !== this.props.data.riderReducer.cardList) {
            // if success
            if (this.props.data.riderReducer.cardList.data && this.props.data.riderReducer.cardList.data.length) {
                this.setActiveStep(1);
                this.props.getOperationalParameter();
                this.props.getRateChart();

            } else {
                this.props.showSnackbar(this.props.data.literalsReducer
                    .Payments.cardLinkRequired, 'error');

            }
        }

        this.setSearchedDrivers(prevProps);
        this.setOperationalParameters(prevProps);
        this.setRateChartDetails(prevProps);
        this.handleAssignedDriversResponse(prevProps);
    }

    render() {
        const { classes } = this.props;
        const isUserMenuOpen = Boolean(this.state.userMenuRef);
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 70,
                },
            },
        };
        const tripTypes = [...TripTypes];
        tripTypes.shift();
        const vehicleTypes = VehicleTypes;

        return (
            <MuiThemeProvider theme={theme}>
                <div>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                                    <Typography component="p">
                                        <span className={classes.pageHeader} >
                                            {this.props.data.literalsReducer.ScheduleTrip.PageHeader}
                                        </span>
                                        <span className={classes.PageDescription}>
                                            {
                                                this.props.data.literalsReducer.ScheduleTrip
                                                    .PageDescription
                                            }
                                        </span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {this.state.activeStep === 0 ? (
                                <Paper>
                                    <Grid container direction="column"
                                        justify="center"
                                        alignItems="center"
                                        spacing={24}
                                        className={classes.rightPaper}>
                                        <Grid item >
                                            <Typography variant="h6" component="h6">Choose a rider for which you want to schedule a trip</Typography>
                                        </Grid>
                                        <Grid item >
                                            <Button
                                                variant="outlined"
                                                onClick={this.openUserMenu}
                                                style={{ minWidth: 250, display: 'flex' }}
                                            >
                                                <div className={classes.profileImagePlaceholder}>
                                                    <Avatar src={(this.state.tripDetails.rider && this.state.tripDetails.rider.personalInfo.profilePicture) || DemoUserImage} className={classNames(classes.leftIcon, classes.userAvatar)} />
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <Typography align="left" component="span" variant="body1" className={classes.textTransformNone}>{(this.state.tripDetails.rider && this.state.tripDetails.rider.personalInfo.fullName) || 'Select rider'}</Typography>
                                                </div>
                                                <KeyboardArrowDownIcon className={classes.rightIcon} />

                                                {/* <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                    className={classes.rightIcon}
                                                    style={{ fontSize: "15px" }}
                                                /> */}
                                            </Button>
                                            <Menu
                                                anchorEl={this.state.userMenuRef}
                                                open={isUserMenuOpen}
                                                onClose={this.handleClose}
                                                onClick={this.handleClose}

                                                PaperProps={localStyles.userMenu}
                                            >
                                                <div style={{ padding: 15 }}>
                                                    <TextField
                                                        placeholder="Search by name"
                                                        size="small"
                                                        fullWidth
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={this.handleListSearch}
                                                        margin="dense"
                                                        variant="outlined" />
                                                </div>

                                                {this.state.data.data.length && !this.state.loading ? (
                                                    this.state.data.data.map(rider => (
                                                        <MenuItem key={rider._id} onClick={() => this.selectRider(rider)}>
                                                            <div className={classes.profileImagePlaceholder}>
                                                                <Avatar src={rider.personalInfo.profilePicture || DemoUserImage} className={classNames(classes.leftIcon, classes.userAvatar)} />
                                                            </div>
                                                            <Typography component="span" variant="body2" className={classes.textTransformNone}>{rider.personalInfo.fullName}</Typography>
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    this.state.loading ? (
                                                        <MenuItem>
                                                            <CircularProgress size={18} thickness={5} />
                                                            <Typography style={{ marginLeft: 15 }} component="span" variant="body2" className={classes.textTransformNone}>{this.props.data.literalsReducer.Common.Loading}</Typography>
                                                        </MenuItem>
                                                    ) : (
                                                        <MenuItem>
                                                            <Typography component="span" variant="body2" className={classes.textTransformNone}>No riders found</Typography>
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Menu>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                size="large"
                                                color="primary"
                                                variant="contained"
                                                onClick={this.validateRider}
                                                disabled={!(this.state.tripDetails.rider)}
                                            // className={classes.activeGoButton}
                                            >
                                                Proceed to booking
                                                <KeyboardArrowRightIcon className={classes.rightIcon} />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ) : null}
                            {this.state.activeStep === 1 ? (
                                <Paper>
                                    <Grid container
                                        spacing={8}
                                        className={classes.rightPaper}>
                                        <Grid container spacing={24}>
                                            <Grid item >
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.headerRightButton}
                                                    onClick={() => this.setActiveStep(0)}
                                                >
                                                    <ArrowBackIosIcon className={classes.leftIcon} /> Back
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <ScrollArea
                                                speed={0.8}
                                                className={classes.scrollContent}
                                                contentClassName="content"
                                                horizontal={false}
                                                style={{ paddingRight: 20, minHeight: "110px" }}
                                            >
                                                <Grid container spacing={16}>
                                                    <Grid item md={12} lg={12}>
                                                        <p className={classes.description}>Choose a trip type</p>
                                                        <Select
                                                            style={{
                                                                border: '1px solid #f50057',
                                                                borderRadius: '5px',
                                                                padding: '10px',
                                                                width: 250
                                                            }}
                                                            name="type"
                                                            value={this.state.tripDetails.type}
                                                            onChange={this.handleChange}
                                                            input={<Input />}
                                                            MenuProps={MenuProps}
                                                            error={
                                                                this.state.errors.type
                                                            }
                                                        >
                                                            {tripTypes.map((_t, index) => (
                                                                <MenuItem key={index} value={_t.value}>
                                                                    {_t.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {this.state.errors.type ? (
                                                            <FormHelperText id="validation-error2">
                                                                {
                                                                    this.props.data.literalsReducer
                                                                        .ValidationErrors.Required
                                                                }
                                                            </FormHelperText>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item md={6} lg={6}>
                                                        <p className={classes.description}>No. of Passengers</p>
                                                        <Select
                                                            style={{
                                                                border: '1px solid #f50057',
                                                                borderRadius: '5px',
                                                                padding: '10px'
                                                            }}
                                                            name="passengers"
                                                            value={this.state.tripDetails.passengers}
                                                            onChange={this.handleChange}
                                                            input={<Input />}
                                                            MenuProps={MenuProps}
                                                            error={
                                                                this.state.errors.passengers
                                                            }
                                                        >
                                                            {[1, 2, 3, 4, 5, 6].map((name, index) => (
                                                                <MenuItem key={index} value={name}>
                                                                    {name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {this.state.errors.passengers ? (
                                                            <FormHelperText id="validation-error2">
                                                                {
                                                                    this.props.data.literalsReducer
                                                                        .ValidationErrors.Required
                                                                }
                                                            </FormHelperText>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item md={6} lg={6}>
                                                        {this.state.tripDetails.type === "3" || this.state.tripDetails.type === "4" || this.state.tripDetails.type === "5" ? (
                                                            <>
                                                                <p className={classes.description}>No. of Hours</p>
                                                                <Select
                                                                    style={{
                                                                        border: '1px solid #f50057',
                                                                        borderRadius: '5px',
                                                                        padding: '10px'
                                                                    }}
                                                                    name="hours"
                                                                    value={this.state.tripDetails.hours}
                                                                    onChange={this.handleChange}
                                                                    input={<Input />}
                                                                    MenuProps={MenuProps}
                                                                    error={
                                                                        this.state.errors.hours
                                                                    }
                                                                >
                                                                    {[1, 2, 3, 4, 5, 6, 7, 8].map((name, index) => (
                                                                        <MenuItem key={index} value={name}>
                                                                            {name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {this.state.errors.hours ? (
                                                                    <FormHelperText id="validation-error2">
                                                                        {
                                                                            this.props.data.literalsReducer
                                                                                .ValidationErrors.Required
                                                                        }
                                                                    </FormHelperText>
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                    </Grid>
                                                    {this.state.tripDetails.type === "3" || this.state.tripDetails.type === "4" || this.state.tripDetails.type === "5" ? (
                                                        <Grid item md={12} lg={12}>
                                                            <p className={classes.description}>Plan</p>
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                className={classes.textField}
                                                                name="plan"
                                                                value={this.state.tripDetails.plan}
                                                                onChange={this.handleChange}
                                                                multiline
                                                                error={
                                                                    this.state.errors.plan
                                                                }
                                                            />
                                                        </Grid>
                                                    ) : null}

                                                    <Grid item md={6} lg={6}>
                                                        <p className={classes.description}>Set trip date</p>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <DatePicker
                                                                className={classes.textField}
                                                                variant="outlined"
                                                                format="dd-MM-Y"
                                                                name="scheduleDate"
                                                                minDate={new Date(new Date().setFullYear(new Date().getFullYear()))}
                                                                value={this.state.tripDetails.scheduleDate}
                                                                onChange={this.handleDateChange}
                                                                error={
                                                                    this.state.errors.scheduleDate
                                                                }
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                        {this.state.errors.scheduleDate ? (
                                                            <FormHelperText id="validation-error2">
                                                                {
                                                                    this.props.data.literalsReducer
                                                                        .ValidationErrors.Required
                                                                }
                                                            </FormHelperText>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item md={6} lg={6}>
                                                        <p className={classes.description}>Set trip time</p>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <TimePicker
                                                                className={classes.textField}
                                                                variant="outlined"
                                                                name="scheduleTime"
                                                                value={this.state.tripDetails.scheduleTime}
                                                                onChange={this.handleTimeChange}
                                                                error={
                                                                    this.state.errors.scheduleTime
                                                                }
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                        {this.state.errors.scheduleTime ? (
                                                            <FormHelperText id="validation-error2">
                                                                {
                                                                    this.props.data.literalsReducer
                                                                        .ValidationErrors.Required
                                                                }
                                                            </FormHelperText>
                                                        ) : null}
                                                        {this.state.errors.unacceptableScheduleTime ? (
                                                            <FormHelperText id="validation-error2">
                                                                {
                                                                    this.props.data.literalsReducer
                                                                        .ValidationErrors.UnacceptableScheduleTime
                                                                }
                                                            </FormHelperText>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item md={12} lg={12}>
                                                        <p className={classes.description}>Enter pickup location</p>
                                                        <div className={classes.addressField}>
                                                            <input
                                                                ref={this.originLocationInput}
                                                                id="autocomplete"
                                                                variant="outlined"
                                                                name="pickupLocation"
                                                                value={this.state.tripDetails.pickupLocation.formatted_address}
                                                                onChange={this.handleChange}
                                                                error={
                                                                    this.state.errors.pickupLocation
                                                                }
                                                            />
                                                        </div>
                                                        {this.state.errors.pickupLocation ? (
                                                            <FormHelperText id="validation-error2">
                                                                {
                                                                    this.props.data.literalsReducer
                                                                        .ValidationErrors.Required
                                                                }
                                                            </FormHelperText>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item md={12} lg={12}>
                                                        <p className={classes.description}>Enter drop location</p>
                                                        <div className={classes.addressField}>
                                                            <input
                                                                ref={this.destinationLocationInput}
                                                                id="autocomplete"
                                                                variant="outlined"
                                                                name="dropLocation"
                                                                value={this.state.tripDetails.dropLocation.formatted_address}
                                                                onChange={this.handleChange}
                                                                error={
                                                                    this.state.errors.dropLocation
                                                                }
                                                            />
                                                        </div>
                                                        {this.state.errors.dropLocation ? (
                                                            <FormHelperText id="validation-error2">
                                                                {
                                                                    this.props.data.literalsReducer
                                                                        .ValidationErrors.Required
                                                                }
                                                            </FormHelperText>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item md={12} lg={12}>
                                                        <Divider />
                                                    </Grid>
                                                    <Grid item md={12} lg={12}>
                                                        <Grid container alignItems="center" spacing={8}>
                                                            <Grid item>
                                                                <h4>Contact details for notifications

                                                                </h4>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip placement="top" title="All trip related notification will be sent to these contact numbers.">
                                                                    <HelpOutlineOutlinedIcon color="primary" />
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <div>
                                                            <p className={classes.description}>{this.props.data.literalsReducer.ScheduleTrip.TripAlert
                                                                .FirstPhoneNo}</p>
                                                            <div className={classes.inlineField}>
                                                                <TextField
                                                                    id="select-country"
                                                                    select
                                                                    name="alertContact1"
                                                                    value={this.state.tripDetails.alertContact1.code}
                                                                    onChange={this.handlePhoneNoChange('code')}
                                                                    variant="outlined"
                                                                    className={classes.textField}
                                                                    SelectProps={{
                                                                        native: true,
                                                                        MenuProps: {
                                                                            className: classes.menu
                                                                        }
                                                                    }}
                                                                >
                                                                    {countries.map((option, index) => (
                                                                        <option
                                                                            key={index}
                                                                            value={option.dial_code}
                                                                        >
                                                                            {`${option.code} (${option.dial_code})`}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                                <TextField
                                                                    id="alert-phone-no"
                                                                    label={
                                                                        this.props.data.literalsReducer.AddRider.PhoneNo
                                                                    }
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    className={classes.textField}
                                                                    name="alertContact1"
                                                                    onInput={e => {
                                                                        e.target.value = e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            ""
                                                                        );
                                                                    }}
                                                                    inputProps={{
                                                                        minLength: 10,
                                                                        maxLength: 10
                                                                    }}
                                                                    value={this.state.tripDetails.alertContact1.number}
                                                                    onChange={this.handlePhoneNoChange('number')}
                                                                    error={
                                                                        this.state.errors.alertContact1_number
                                                                    }
                                                                />
                                                                {this.state.errors.alertContact1_number ? (
                                                                    <FormHelperText
                                                                        id="validation-error2"
                                                                        style={{ marginLeft: "33%" }}
                                                                    >
                                                                        {
                                                                            this.props.data.literalsReducer
                                                                                .ValidationErrors.InvalidPhoneNumber
                                                                        }
                                                                    </FormHelperText>
                                                                ) : null}
                                                            </div>

                                                            <p className={classes.description}>{this.props.data.literalsReducer.ScheduleTrip.TripAlert
                                                                .SecondPhoneNo}</p>
                                                            <div className={classes.inlineField}>
                                                                <TextField
                                                                    id="select-country"
                                                                    select
                                                                    name="alertContact2"
                                                                    value={this.state.tripDetails.alertContact2.code}
                                                                    onChange={this.handlePhoneNoChange('code')}
                                                                    variant="outlined"
                                                                    className={classes.textField}
                                                                    SelectProps={{
                                                                        native: true,
                                                                        MenuProps: {
                                                                            className: classes.menu
                                                                        }
                                                                    }}
                                                                >
                                                                    {countries.map((option, index) => (
                                                                        <option
                                                                            key={index}
                                                                            value={option.dial_code}
                                                                        >
                                                                            {`${option.code} (${option.dial_code})`}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                                <TextField
                                                                    id="alert-phone-no"
                                                                    label={
                                                                        this.props.data.literalsReducer.AddRider.PhoneNo
                                                                    }
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    className={classes.textField}
                                                                    name="alertContact2"
                                                                    onInput={e => {
                                                                        e.target.value = e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            ""
                                                                        );
                                                                    }}
                                                                    inputProps={{
                                                                        minLength: 10,
                                                                        maxLength: 10
                                                                    }}
                                                                    value={this.state.tripDetails.alertContact2.number}
                                                                    onChange={this.handlePhoneNoChange('number')}
                                                                    error={
                                                                        this.state.errors.alertContact2_number
                                                                    }
                                                                />
                                                                {this.state.errors.alertContact2_number ? (
                                                                    <FormHelperText
                                                                        id="validation-error2"
                                                                        style={{ marginLeft: "33%" }}
                                                                    >
                                                                        {
                                                                            this.props.data.literalsReducer
                                                                                .ValidationErrors.InvalidPhoneNumber
                                                                        }
                                                                    </FormHelperText>
                                                                ) : null}
                                                            </div>

                                                            <p className={classes.description}>{this.props.data.literalsReducer.ScheduleTrip.TripAlert
                                                                .ThirdPhoneNo}</p>
                                                            <div className={classes.inlineField}>
                                                                <TextField
                                                                    id="select-country"
                                                                    select
                                                                    name="alertContact3"
                                                                    value={this.state.tripDetails.alertContact3.code}
                                                                    onChange={this.handlePhoneNoChange('code')}
                                                                    variant="outlined"
                                                                    className={classes.textField}
                                                                    SelectProps={{
                                                                        native: true,
                                                                        MenuProps: {
                                                                            className: classes.menu
                                                                        }
                                                                    }}
                                                                >
                                                                    {countries.map((option, index) => (
                                                                        <option
                                                                            key={index}
                                                                            value={option.dial_code}
                                                                        >
                                                                            {`${option.code} (${option.dial_code})`}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                                <TextField
                                                                    id="alert-phone-no"
                                                                    label={
                                                                        this.props.data.literalsReducer.AddRider.PhoneNo
                                                                    }
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    className={classes.textField}
                                                                    name="alertContact3"
                                                                    onInput={e => {
                                                                        e.target.value = e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            ""
                                                                        );
                                                                    }}
                                                                    inputProps={{
                                                                        minLength: 10,
                                                                        maxLength: 10
                                                                    }}
                                                                    value={this.state.tripDetails.alertContact3.number}
                                                                    onChange={this.handlePhoneNoChange('number')}
                                                                    error={
                                                                        this.state.errors.alertContact3_number
                                                                    }
                                                                />
                                                                {this.state.errors.alertContact3_number ? (
                                                                    <FormHelperText
                                                                        id="validation-error2"
                                                                        style={{ marginLeft: "33%" }}
                                                                    >
                                                                        {
                                                                            this.props.data.literalsReducer
                                                                                .ValidationErrors.InvalidPhoneNumber
                                                                        }
                                                                    </FormHelperText>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={12} lg={12}>
                                                        <Divider />
                                                    </Grid>
                                                    <Grid item md={12} lg={12} className={classes.margin}>
                                                        <Grid container spacing={16} >
                                                            <Grid item md={6} lg={6}>
                                                                <Paper style={{ padding: 15 }}>
                                                                    <Grid container direction="row" justify="space-between" >
                                                                        <Grid item>
                                                                            <Typography component="h6" variant="subtitle2">Sedan</Typography>
                                                                            <Typography component="h6" variant="caption">{this.state.sedanPriceEstimation ? `$${this.state.sedanPriceEstimation.toFixed(2)}` : null}</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton disabled={!this.state.sedanPriceEstimation} color="primary" onClick={() => this.openQuoteModal(1)}>
                                                                                <InfoOutlinedIcon />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item md={6} lg={6}>
                                                                <Paper style={{ padding: 15 }}>
                                                                    <Grid container direction="row" justify="space-between" >
                                                                        <Grid item>
                                                                            <Typography component="h6" variant="subtitle2" >SUV</Typography>
                                                                            <Typography component="h6" variant="caption">{this.state.suvPriceEstimation ? `$${this.state.suvPriceEstimation.toFixed(2)}` : null}</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton disabled={!this.state.suvPriceEstimation} color="primary" onClick={() => this.openQuoteModal(2)}>
                                                                                <InfoOutlinedIcon />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item md={12} lg={12}>
                                                    <Grid container alignItems="center" spacing={8}>
                                                        <Grid item>
                                                            <h4>Additional Information</h4>
                                                        </Grid>
                                                    </Grid>

                                                    <div>
                                                        <p className={classes.description}>Fixed Price</p>
                                                        <div className={classes.inlineField}>
                                                            <TextField
                                                                id="fixedPrice"
                                                                label="Fixed Price"
                                                                variant="outlined"
                                                                fullWidth
                                                                className={classes.textField}
                                                                name="fixedPrice"
                                                                onInput={e => {
                                                                    e.target.value = e.target.value.replace(
                                                                        /[^0-9.]/g,
                                                                        ""
                                                                    );
                                                                }}
                                                                inputProps={{
                                                                    minLength: 10,
                                                                    maxLength: 20
                                                                }}
                                                                value={this.state.tripDetails.fixedPrice}
                                                                onChange={this.handleChange}
                                                                error={
                                                                    this.state.errors.fixedPrice
                                                                }
                                                            />
                                                            {this.state.errors.fixedPrice ? (
                                                                <FormHelperText
                                                                    id="validation-error2"
                                                                    style={{ marginLeft: "33%" }}
                                                                >
                                                                    Invalid Fixed Price
                                                                </FormHelperText>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item md={12} lg={12}>
                                                    <p className={classes.description}>Flight Info</p>
                                                    <div>
                                                        <TextField
                                                            id="flightInfo"
                                                            multiline
                                                            rows={3}
                                                            fullWidth
                                                            variant="outlined"
                                                            name="flightDetails"
                                                            onChange={this.handleChange}
                                                            value={this.state.tripDetails.flightDetails}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item md={12} lg={7}>
                                                    <p className={classes.description}>Alternate Rider Name</p>
                                                    <div className={classes.addressField}>
                                                        <input
                                                            variant="outlined"
                                                            name="alternateRiderName"
                                                            onChange={this.handleChange}
                                                            value={this.state.tripDetails.alternateRiderName}
                                                        />
                                                    </div>
                                                </Grid>
                                            </ScrollArea>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <MapComponent
                                                plotZone={() => {
                                                    this.setState({ ...this.state, zonePlotted: true });
                                                }}
                                                setDirections={(response) => this.setState({
                                                    ...this.state,
                                                    directionData: response
                                                })}
                                                zonePlotted={this.state.zonePlotted}
                                                origin={this.refactorCoordinatesFOrPlotting(this.state.tripDetails.pickupLocation)}
                                                destination={this.refactorCoordinatesFOrPlotting(this.state.tripDetails.dropLocation)}
                                                frmPage={'scheduleTrip'}
                                                direction={null} />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12}>
                                            <div className={classes.actionContainer}>
                                                <Button
                                                    size="large"
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={this.handleTripRequest}

                                                // className={classes.activeGoButton}
                                                >
                                                    Confirm booking
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ) : null}
                            {this.state.activeStep === 2 ? (
                                <Paper>
                                    <Grid container direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        className={classes.rightPaper}>
                                        <Grid container spacing={16}>
                                            <Grid item >
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.headerRightButton}
                                                    onClick={() => this.setActiveStep(1)}
                                                >
                                                    <ArrowBackIosIcon className={classes.leftIcon} /> Back
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ marginTop: 20 }}>
                                            <p className={classes.description}>Your Chauffers</p>
                                        </Grid>
                                        <Grid item >
                                            <span>Vehicle</span>
                                            <Select
                                                style={{
                                                    border: '1px solid #f50057',
                                                    borderRadius: '5px',
                                                    paddingLeft: '10px',
                                                    marginLeft: '10px'
                                                }}
                                                name="vehicleType"
                                                value={this.state.vehicleType}
                                                onChange={(e) => {
                                                    this.filterVehicles(e.target.value);
                                                }}
                                                input={<Input />}
                                                MenuProps={MenuProps}
                                            >
                                                {vehicleTypes.map((_vtype, index) => (
                                                    <MenuItem key={index} value={_vtype.value}>
                                                        {_vtype.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Tabs
                                                value={this.state.tabValue}
                                                onChange={this.handleTabChange}
                                                className={classes.tabPan}>
                                                <Tab label="Nearest" />
                                                <Tab label="Price" />
                                            </Tabs>
                                            {this.state.tabValue == 0 && <TabContainer>
                                                <div className={classes.tabDetailsCont}>
                                                    {renderFavoriteChauffeurs(this.state.filteredDriverInfo, 'NEAREST')}
                                                </div>
                                            </TabContainer>}
                                            {this.state.tabValue == 1 && <TabContainer>
                                                <div className={classes.tabDetailsCont}>
                                                    {renderFavoriteChauffeurs(this.state.filteredDriverInfo, 'PRICE')}
                                                </div>
                                            </TabContainer>}
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12}>
                                            <div className={classes.actionContainer}>
                                                <Button
                                                    size="large"
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => this.setActiveStep(3)}

                                                // className={classes.activeGoButton}
                                                >
                                                    Proceed
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ) : null}
                            {this.state.activeStep === 3 ? (
                                <Paper>
                                    <Grid container direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        className={classes.rightPaper}>
                                        <Grid container spacing={16}>
                                            <Grid item >
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.headerRightButton}
                                                    onClick={() => this.setActiveStep(2)}
                                                >
                                                    <ArrowBackIosIcon className={classes.leftIcon} /> Back
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{ marginTop: 20 }}>
                                            <p className={classes.description}>Rearrange trip
                                                <Typography variant="caption" component="p">Drag to rearrange</Typography>
                                            </p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <DraggableList items={this.state.searchedDriverInfo.searchedDrivers} onDragEnd={this.onDragEnd} />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12}>
                                            <div className={classes.actionContainer}>
                                                <Button
                                                    size="large"
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={this.assignDriversToTripRequest}
                                                >
                                                    Confirm
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ) : null}
                            <Modal
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                open={this.state.quoteModalOpen}
                                onClose={this.closeQuoteModal}
                            >
                                <div style={{
                                    top: `50%`,
                                    left: `50%`,
                                    transform: `translate(-50%, -50%)`
                                }} className={classes.modalPaper}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        className={classes.descSection}
                                    >
                                        <Grid container spacing={24}>
                                            <Grid item xs={12} sm={12}>
                                                <h2>Quote</h2>
                                                <div
                                                    className={classes.description}
                                                    style={{ marginBottom: "20px" }}
                                                >
                                                    {renderQuote(this.state.quote)}
                                                </div>
                                                <div style={{ textAlign: "right" }}>
                                                    <Button
                                                        style={{ marginLeft: "10px" }}
                                                        size="small"
                                                        color="primary"
                                                        variant="contained"
                                                        className={classNames(
                                                            classes.customMargin,
                                                            classes.activeGoButton
                                                        )}
                                                        onClick={this.closeQuoteModal}
                                                    >
                                                        Okay
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Modal>
                        </Grid>
                    </Grid>
                </div>
            </MuiThemeProvider >
        );
    }
}

const localStyles = {
    userMenu: {
        sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            minWidth: 180,
            '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
            },
        },
    }
}

Object.freeze(localStyles);

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        getRiderList: searchObject => dispatch(getRiderList(searchObject)),
        fetchRiderCardList: id => dispatch(fetchRiderCardList(id)),
        getOperationalParameter: () => dispatch(getOperationalParameter()),
        getRateChart: () => dispatch(getRateChart()),
        createBookingRequest: (id, requestObj) => dispatch(createBookingRequest(id, requestObj)),
        assignDriversToBookingRequest: (id, driverList) => dispatch(assignDriversToBookingRequest(id, driverList)),
        showSnackbar: (message, variant) => dispatch(showSnackbar(message, variant))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(
    theme => ({
        ...modalStyles(theme),
        ...styles(theme),
        ...contentStyles(theme),
        ...globalstyles(theme)
    }),
    { withTheme: true })
    (ScheduleTrip));