import React, { Component } from "react";

import { connect } from "react-redux";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../app/theme";
import classNames from "classnames";
import styles from "./styles";
import contentStyles from "../../auth-layout/content.styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import globalstyles from "../../../globalstyles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import {
  changePassword
} from "../../../store/actions/profile";


class ChangePassword extends Component {
  state = {
    changePassword: {
      currPassword: "",
      newPassword: "",
      retypeNewPassword: ""
    },
    errors: {}
  };

  handleChange = event => {
    let fields = { ...this.state.changePassword };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    if (event.target.name === "newPassword") {
      errors.passwordLength = false;
    }

    if (event.target.name === "retypeNewPassword") {
      errors.passwordMismatch = false;
    }

    this.setState({ ...this.state, changePassword: fields, errors });
  };

  changePasswordValidation = () => {
    let fields = { ...this.state.changePassword };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    for (let name in fields) {
      if (fields[name].trim() === "") {
        errors[name] = true;
        hasErrors = true;
      }
    }

    if (fields.newPassword !== fields.retypeNewPassword) {
      errors["passwordMismatch"] = true;
      hasErrors = true;
    } else {
      errors["passwordMismatch"] = false;
    }

    if (fields.newPassword.length < 6) {
      errors["passwordLength"] = true;
      hasErrors = true;
    } else {
      errors["passwordLength"] = false;
    }

  

    this.setState({ errors: errors });
    return hasErrors;
  };

  saveHandler = () => {
    let requestData = {...this.state.changePassword} 
    if (!this.changePasswordValidation()) {
      let payload = {
        oldPassword: requestData.currPassword,
        newPassword: requestData.newPassword
      }
      this.props.changePassword(payload)
     
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
        // if success
        if (this.props.data.profileAPIData.changePasswordData.changePasswordSuccess) {
          this.props.history.goBack();
        }
    }
  }


  backToPrevPage = () => {
    this.props.history.goBack();
  }
  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Paper className={classes.rightPaper}>
          <h3>{this.props.data.literalsReducer.UserProfile.ChangePassword}</h3>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <p className={classes.description}>
            {this.props.data.literalsReducer.UserProfile.CurrentPassword}
          </p>
          <TextField
            id="current-password"
            label={this.props.data.literalsReducer.UserProfile.CurrentPassword}
            variant="outlined"
            fullWidth
            type={this.state.showPassword ? "text" : "password"}
            className={classes.textField}
            name="currPassword"
            value={this.state.changePassword.currPassword || ""}
            onChange={this.handleChange}
            error={this.state.errors.currPassword}
          />
          {this.state.errors.currPassword ? (
            <FormHelperText id="validation-error2">
              {this.props.data.literalsReducer.ValidationErrors.Required}
            </FormHelperText>
          ) : null}
          <p className={classes.description}>
            {this.props.data.literalsReducer.UserProfile.NewPassword}
          </p>
          <TextField
            id="new-password"
            label={this.props.data.literalsReducer.UserProfile.NewPassword}
            variant="outlined"
            fullWidth
            type={this.state.showPassword ? "text" : "password"}
            className={classNames(classes.textField)}
            name="newPassword"
            value={this.state.changePassword.newPassword || ""}
            onChange={this.handleChange}
            error={
              this.state.errors.newPassword || this.state.errors.passwordLength
            }
          />
          {this.state.errors.newPassword ? (
            <FormHelperText id="validation-error2">
              {this.props.data.literalsReducer.ValidationErrors.Required}
            </FormHelperText>
          ) : null}
          {!this.state.errors.newPassword && this.state.errors.passwordLength ? (
            <FormHelperText id="validation-error2">
              {this.props.data.literalsReducer.ValidationErrors.PasswordLength}
            </FormHelperText>
          ) : null}
          <TextField
            id="retype-new-password"
            label={this.props.data.literalsReducer.UserProfile.ConfirmPassword}
            variant="outlined"
            fullWidth
            className={classNames(classes.textField)}
            name="retypeNewPassword"
            value={this.state.changePassword.retypeNewPassword || ""}
            onChange={this.handleChange}
            type={this.state.showPassword ? "text" : "password"}
            style={{marginTop: "10px"}}
            error={
              this.state.errors.retypeNewPassword ||
              this.state.errors.passwordMismatch
            }
          />
          {this.state.errors.retypeNewPassword &&
          !this.state.errors.passwordMismatch ? (
            <FormHelperText id="validation-error2">
              {this.props.data.literalsReducer.ValidationErrors.Required}
            </FormHelperText>
          ) : null}
          {this.state.errors.passwordMismatch &&
          !this.state.errors.retypeNewPassword ? (
            <FormHelperText id="validation-error2">
              {this.props.data.literalsReducer.ValidationErrors.PasswordMismatch}
            </FormHelperText>
          ) : null}
            </Grid>
          </Grid>
          
          <div className={classes.actionContainer}>
            <Button
              size="large"
              color="primary"
              variant="contained"
              className={classes.activeGoButton}
              onClick={this.saveHandler}
            >
              {this.props.data.literalsReducer.UserProfile.SaveButton}
            </Button>
            <Button
              size="large"
              color="default"
              variant="contained"
              onClick={this.backToPrevPage}
            >
              {this.props.data.literalsReducer.UserProfile.CancelButton}
            </Button>
          </div>
        </Paper>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    changePassword: payload => dispatch(changePassword(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(ChangePassword)
);
