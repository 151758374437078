import React, { Component } from "react";

import { BrowserRouter as Router, Redirect, Link } from "react-router-dom";

import PropTypes from "prop-types";

import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Grid from "@material-ui/core/Grid";
import logo from "../../assets/images/logo.png";
import MenuListComposition from "../../components/MenuListComposition";

import NotificationList from "../../components/NotificationList";
import ScrollArea from "react-scrollbar";
import LeftMenu from "./left-menu/LeftMenu";
import AuthRoutes from "./auth-routes/AuthRoutes";

import { styles } from "./styles";

import { connect } from "react-redux";
import { getNotifications,mqttNotificationDone,getNotificationsCount,fetchNotificationCountDone } from "../../store/actions/notifications";
import { logoutAdmin, logoutSuccess } from "../../store/actions/profile";
import { session_logout } from "../../store/actions/auth";

import { ToastContainer, ToastStore } from "react-toasts";
const skip = 0
const limit = 10
class AuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      toastMessage: false,
      skip: 0,
      limit:10,
      unreadNotificationCount: 0,
      isDetailsFetched: false,
    };
  }

  componentWillMount() {
    // this
    //   .props
    //   .dispatch(getNotifications(this.state.toastMessage));
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    // console.log("handel 1")
    this.setState({ open: false });
  };

  logoutHandler = () => {
    this.props.logoutAdmin();
  };

  componentDidMount() {
    this.props.getNotifications({
      skip: skip,
      limit: this.state.limit
    })
    this.props.getNotificationsCount()
  }
  handleNotifictaion = () => {
    let tmpSkip = this.state.skip
    
    tmpSkip = tmpSkip+10
    this.setState({...this.setState,skip: tmpSkip})
    this.props.getNotifications({
      skip: tmpSkip,
      limit: this.state.limit
    })
    this.props.getNotificationsCount()
    // console.log("please handle")
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.data.notificationsReducer.mqttComing) {
      this.props.getNotifications({
        skip: skip,
        limit: limit
      })
      this.props.getNotificationsCount()
      this.props.mqttNotificationDone();
    }
    
    if(this.props.data.notificationsReducer.isUpdating) {
      this.props.getNotifications({
        skip: skip,
        limit: limit
      })
      this.props.getNotificationsCount()
    }
    if(!this.state.isDetailsFetched || this.props.data.notificationsReducer.countUpdating) {
      this.setState({...this.state,unreadNotificationCount: this.props.data.notificationsReducer.countNotification,isDetailsFetched: true})
      this.props.fetchNotificationCountDone()
    }
    
    if (
      !this.props.data.authReducer.isAuthenticated ||
      !window.localStorage.upcAuthData
    ) {
      this.props.history.push("/login");
    }

   
    
    if (this.props.data.profileAPIData.logout) {
      // this.props.session_logout();
      this.props.session_logout();
      this.props.logoutSuccess();
      this.props.history.push("/login");
    }
    // Check Toast Message
  }

  render() {
    const { classes } = this.props;
    return !this.props.data.authReducer.isAuthenticated ||
      !window.localStorage.upcAuthData ? (
      <Redirect to="/login" />
    ) : (
      <div>
        <Router>
          <div className={classes.root}>
            <ToastContainer store={ToastStore} />
            <CssBaseline />
            <AppBar
              position="absolute"
              style={{ backgroundColor: "#fff", color: "#000" }}
              className={classNames(
                classes.appBar,
                this.state.open && classes.appBarShift
              )}
            >
              <Toolbar
                disableGutters={!this.state.open}
                className={classes.toolbar}
              >
                <Grid container spacing={16}>
                  <Grid item xs={6}>
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={this.handleDrawerOpen}
                      className={classNames(
                        classes.menuButton,
                        this.state.open && classes.menuButtonHidden
                      )}
                    >
                      <ArrowForwardIcon className={classes.arrowMenuOpen} />
                    </IconButton>
                    {this.state.open ? (
                      <IconButton onClick={this.handleDrawerClose}>
                        <MenuIcon className={classes.menuOpen} />
                      </IconButton>
                    ) : null}
                    <Typography
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      className={classes.title}
                    >
                      {/* {new Date().toString()} */}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={16}>
                      <Grid item xs={2} />
                      <Grid item xs={2}>
                        {!this.props.data.notificationsReducer.isFetching ? (
                          <NotificationList
                            notificationCount={this.state.unreadNotificationCount}
                            handleNotifictaion={this.handleNotifictaion}
                            notifications={
                              this.props.data.notificationsReducer.notifications
                                .data
                            }
                          />
                        ) : (
                          undefined
                        )}
                      </Grid>
                      <Grid item xs={8} className={classes.menuWrapper}>
                        <MenuListComposition logout={this.logoutHandler} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              style={{ height: "100vh" }}
              classes={{
                paper: classNames(
                  classes.drawerPaper,
                  !this.state.open && classes.drawerPaperClose
                )
              }}
              open={this.state.open}
            >
              {this.state.open ? (
                <div
                  className={classes.toolbarIcon}
                  style={{ background: "#fff" }}
                >
                  <div className={classes.sidePanelHeader}>
                    <Link to={"/web/auth/home"}>
                      <img src={logo} alt="logo" />
                    </Link>
                  </div>
                </div>
              ) : null}
              {this.state.open ? null : (
                <div
                  style={{
                    marginTop: "65px",
                    textAlign: "center",
                    padding: "25px 0"
                  }}
                >
                  <Link
                    to={"/web/auth/home"}
                    style={{ textDecoration: "none" }}
                  >
                    UrPC
                  </Link>
                </div>
              )}
              <Divider />
              <ScrollArea
                speed={0.8}
                className={classes.scrollLeftContent}
                contentClassName="content"
                horizontal={false}
              >
                {this.state.open ? (
                  <List className={classes.sidePanelWrapper}>
                    <LeftMenu open={this.state.open} />
                  </List>
                ) : (
                  <List className={classes.sidePanelWrapperClosed}>
                    <LeftMenu open={this.state.open} />
                  </List>
                )}
              </ScrollArea>
            </Drawer>
            <ScrollArea
              speed={0.8}
              className={classes.scrollContent}
              contentClassName="content"
              horizontal={false}
            >
              <main className={classes.content}>
                <AuthRoutes />
              </main>
            </ScrollArea>
          </div>
        </Router>
      </div>
    );
  }
}

AuthLayout.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    getNotifications: (searchObject) => dispatch(getNotifications(searchObject)),
    getNotificationsCount: () => dispatch(getNotificationsCount()),
    logoutAdmin: () => dispatch(logoutAdmin()),
    session_logout: () => dispatch(session_logout()),
    logoutSuccess: () => dispatch(logoutSuccess()),
    mqttNotificationDone: () => dispatch(mqttNotificationDone()),
    fetchNotificationCountDone: () => dispatch(fetchNotificationCountDone())

  };
};

const mapStateToProps = state => {
  return { data: state };
};



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AuthLayout));
