import {
  FETCH_ONGOING_TRIP_ALERTS_SUCCESS,
  FETCH_BOOKING_ALERTS_SUCCESS
} from "../constants/action-types";

const initialState = {
  ongoingTripAlertList: {},
  bookingAlertList: {}
};

const tripAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ONGOING_TRIP_ALERTS_SUCCESS:
      return {
        ...state,
        ongoingTripAlertList: action.payload.data
      };
    case FETCH_BOOKING_ALERTS_SUCCESS:
      return {
        ...state,
        bookingAlertList: action.payload.data
      };
    default:
      return state;
  }
};

export default tripAlertReducer;
