const globalstyles = theme => ({
    /*Global CSS*/
    root: {
        display: "flex",
        flexWrap: "wrap",
        height: "100vh"
    },
    textField: {
        marginLeft: "0px",
        marginRight: "0px",
        width: "100%"
    },
    signInWrapper: {
        width: "70%",
        margin: "30px auto",
        backgroundColor: "#fff",
        borderRadius: "5px"
    },
    home: {
        textAlign: "left"
    },
    loginImage: {
        padding: "0 !important",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px"
    },
    signinBox: {
        padding: "0 !important",
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px",
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px"
    },
    signinBoxPaper: {
        padding: "25px",
        boxShadow: "none"
    },
    margin: {
        margin: theme.spacing.unit
    },
    passwordInput: {
        marginTop: "20px"
    },
    formHeader: {
        padding: "30px 0 0",
        textAlign: "center",
        fontSize: "20px",
        color: "#000"
    },
    formHeaderLogo: {
        padding: "20px 0 0",
        textAlign: "center"
    },
    formFooter: {
        overflow: "hidden",
        paddingTop: "40px",
        height: "80px"
    },
    formFooterLeft: {
        marginTop: "15px",
        textAlign: "center"
            // float: "left",
            // paddingLeft: "22px"
    },
    form: {
        padding: "0 10px"
    },
    link: {
        color: "#b6321c"
    },
    formFooterRight: {
        width: "100%",
        paddingRight: "10px",
        marginTop: "-16px"
    },
    submit: {
        width: "100%",
        paddingTop: "15px",
        paddingBottom: "15px",
        textTransform: "capitalize"
    },
    buttonHalf: {
        width: "49%",
        marginRight: "2%",
        '&:last-child': {
            marginRight: "0"
        },
        '& $link': {
            display: "block",
            width: "100%",
            color :"#fff",
            textDecoration: "none"
        }
    },
    linkButton: {
        padding: "0",
        height: "48px",
        '& span': {
            display: "block",
            height: "48px",
            lineHeight: "48px"
        }
    },
    activeGoButton: {
        color: "#fff",
        background: "#eb5c00",
        borderColor: "#eb5c00",
        "&:hover": {
            background: "#cc5000 !important",
            borderColor: "#cc5000 !important"
        }
    },
    headerRightButton: {
        color: "#EB5C00",
        borderColor: "#EB5C00",
        padding: "7px 12px",
        float: "right",
        margin: 0,
            "&:hover": {
              color: "#fff",
              background: "#EB5C00",
              border: "1px solid #EB5C00"
            }
    },
    pageHeader: {
        fontSize: "26px",
        marginRight: "10px",
        color: "#4b4b4c"
    },
    PageDescription: {
        color: "#777"
    },
    leftIcon: {
        marginRight: theme.spacing.unit
    },
    inlineField: {
        display: "flex",
        flexFlow: "row wrap",
        "& $textField": {
          "&:first-child": {
            minWidth: "130px",
            width: "auto"
          },
          "&:nth-child(2)": {
            width: "67.5%",
            marginLeft: "auto"
          }
        },
        "& $editableTextfield": {
           width: "48.8%",  
          "&:first-child": {
            marginRight: "5px"
          },
          "&:last-child": {
            marginLeft: "5px"
          }
        },
        "& p:last-child": {
            marginLeft: "20%"
        }
    },
    drawerWrapper: {
        width: "40%",
        top: 0,
        flex: "1 0 auto",
        height: "100%",
        display: "flex",
        zIndex: 1200,
        outline: "none",
        position: "fixed",
        overflowY: "auto",
        flexDirection: "column",
        right: 0,
        left: "auto",
        "& $textField": {
          marginBottom: "16px"
        },
        "& $inlineField": {
            "& $textField": {
                "&:nth-child(2)": {
                    width: "64%"
                }
            }
        }
    },
    tabDetailsCont: {
        color: "#000",
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
    },
    activityLog: {
        paddingLeft: "20px",
        borderLeft: "1px solid #e1e1e1",
        "& $scrollContentSmall": {
            margin: "0 auto",
            height: "600px",
            paddingBottom: "15px",
            width: "100%",
            "& > div": {
                paddingRight: "15px"
            }
        },
        "& ul": {
           display: "initial",
           height: "100%"
        },
        "& li": {
          display: "block",
          padding: "10px 0",
          color: "#a8a8a8",
          fontSize: "14px",
          lineHeight: "20px",
          borderBottom: "1px solid #e1e1e1",
          "&:last-child": {
            borderBottom: "none"
          },
          "& span": {
            color: "#000",
            marginRight: "5px",
            fontWeight: "500"
          },
          "& p": {
            margin: "5px 0 0 0",
            "& span": {
              marginRight: "10px",
              fontSize: "13px",
              color: "#a8a8a8",
              fontWeight: "300"
            }
          }
        },
    },
    scrollContentSmall: {},
    modalContent: {
        "& $inlineField": {
            "& $textField": {
              "&:nth-child(2)": {
                width: "66.5%"
              }
            }
        }
    },
    textTransformNone:{
        textTransform:'none'
    }
});

export default globalstyles;