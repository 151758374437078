import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../app/theme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import styles from "./styles";
import Counter from "../../../components/Counter";
import { ToastStore } from "react-toasts";

import { getDashboardDetail } from "../../../store/actions/dashBoard";
import { showSnackbar } from "../../../store/actions/snackbar";

class Home extends Component {
  state = {
    currency: "$",
    dashboardData: {
      riderCount: "0",
      driverCount: "0",
      completedTrip: "0",
      bookingCount: "0",
      ongoingTripCount: "0",
      canceledByDriver: "0",
      noDriverAssignment: "0",
      notMarkedReadyCount: "0",
      notMarkEnRouteCount: "0",
      noShowCount: "0",
      additionalChargeCount: "0"
    }
  };

  componentDidMount() {
    this.props.getDashboardDetail();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      // if success
      if (this.props.data.notificationsReducer.mqttComing) {
        this.props.getDashboardDetail();
      }
      // console.log(this.props.data.dashBoardAPIData.dashBoardDetails,"this.props.data.dashBoardAPIData.dashBoardDetails")
      this.setState({
        ...this.state,
        dashboardData: this.props.data.dashBoardAPIData.dashBoardDetails
      });
    }
  }

  componentWillUnmount() { }

  rediect = (path, moduleName) => {
    let localStorageData = window.localStorage.upcAuthData;
    if (localStorageData && JSON.parse(localStorageData)) {
      if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
        let flag = false;
        JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
          each => {
            if (each.moduleName === moduleName) {
              flag = true;
            }
          }
        );
        if (!flag) {
          this.props.showSnackbar("You don't have access to this module", 'error');
          return;
        } else {
          this.props.history.push(path);
        }
      } else {
        this.props.history.push(path);
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <Typography component="p">
                <span className={classes.pageHeader}>
                  {this.props.data.literalsReducer.Dashboard.PageHeader}
                </span>
                <span className={classes.PageDescription}>
                  {this.props.data.literalsReducer.Dashboard.PageDescription}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography component="p">
                <span className={classes.pageHeaderSmall}>
                  {this.props.data.literalsReducer.Dashboard.PageHeader2}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={24}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.counterWrapper}
                  onClick={() =>
                    this.rediect(
                      "/web/auth/booking-alerts?alertType=1",
                      "bookingRequestAlert"
                    )
                  }
                >
                  <Counter
                    count={this.state.dashboardData.noDriverAssignment}
                    description={
                      this.props.data.literalsReducer.Dashboard.NoDriverAssigned
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.counterWrapper}
                  onClick={() =>
                    this.rediect(
                      "/web/auth/booking-alerts?alertType=2",
                      "bookingRequestAlert"
                    )
                  }
                >
                  <Counter
                    className={classes.counterCount}
                    count={this.state.dashboardData.canceledByDriver}
                    description={
                      this.props.data.literalsReducer.Dashboard.DriverCancelled
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.counterWrapper}
                  onClick={() =>
                    this.rediect(
                      "/web/auth/booking-alerts?alertType=4",
                      "bookingRequestAlert"
                    )
                  }
                >
                  <Counter
                    count={this.state.dashboardData.notMarkEnRouteCount}
                    description={
                      this.props.data.literalsReducer.Dashboard
                        .DriverDidNotMarkEnroute
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.counterWrapper}
                  onClick={() =>
                    this.rediect(
                      "/web/auth/booking-alerts?alertType=3",
                      "bookingRequestAlert"
                    )
                  }
                >
                  <Counter
                    count={this.state.dashboardData.notMarkedReadyCount}
                    description={
                      this.props.data.literalsReducer.Dashboard
                        .DriverDidNotMarkReady
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography component="p">
                <span className={classes.pageHeaderSmall}>
                  {this.props.data.literalsReducer.Dashboard.PageHeader3}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={24}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.counterWrapper}
                  onClick={() =>
                    this.rediect(
                      "/web/auth/ongoing-trip-alerts?alertType=6",
                      "ongoingTripAlert"
                    )
                  }
                >
                  <Counter
                    count={this.state.dashboardData.noShowCount}
                    description={
                      this.props.data.literalsReducer.Dashboard
                        .DriverRequestingNoShow
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.counterWrapper}
                  onClick={() =>
                    this.rediect(
                      "/web/auth/ongoing-trip-alerts?alertType=5",
                      "ongoingTripAlert"
                    )
                  }
                >
                  <Counter
                    className={classes.counterCount}
                    count={this.state.dashboardData.additionalChargeCount}
                    description={
                      this.props.data.literalsReducer.Dashboard
                        .DriverRequestingAdditionalCharge
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography component="p">
                <span className={classes.pageHeaderSmall}>Others</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={24}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.counterWrapper}
                  onClick={() =>
                    this.rediect("/web/auth/booking-request", "bookingRequest")
                  }
                >
                  <Counter
                    count={this.state.dashboardData.bookingCount}
                    description={
                      this.props.data.literalsReducer.Dashboard.BookingRequests
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.counterWrapper}
                  onClick={() =>
                    this.rediect("/web/auth/ongoing-trips", "ongoingTrip")
                  }
                >
                  <Counter
                    count={this.state.dashboardData.ongoingTripCount}
                    description={
                      this.props.data.literalsReducer.Dashboard.OngoingRide
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} />
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.counterWrapper}
                  onClick={() => this.rediect("/web/auth/riders", "rider")}
                >
                  <Counter
                    count={this.state.dashboardData.riderCount}
                    description={
                      this.props.data.literalsReducer.Dashboard.TotalRiders
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.counterWrapper}
                  onClick={() => this.rediect("/web/auth/drivers", "driver")}
                >
                  <Counter
                    className={classes.counterCount}
                    count={this.state.dashboardData.driverCount}
                    description={
                      this.props.data.literalsReducer.Dashboard.TotalDrivers
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  className={classes.counterWrapper}
                  onClick={() =>
                    this.rediect("/web/auth/past-trips", "pastTrip")
                  }
                >
                  <Counter
                    count={this.state.dashboardData.completedTrip}
                    description={
                      this.props.data.literalsReducer.Dashboard.CompletedRides
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    getDashboardDetail: () => dispatch(getDashboardDetail()),
    showSnackbar: (message, variant) => dispatch(showSnackbar(message, variant))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Home));
