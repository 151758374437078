const styles = theme => ({
  halfField: {
    width: "100%"
  },
  selectField: {
    margin: theme.spacing.unit,
    marginTop: 0
  },
  optionTextField: {
    margin: theme.spacing.unit,
    marginTop: 0,
    width: "90%"
  },
  riderLeft: {
    position: "relative"
  },
  pageHeader: {
    fontSize: "26px",
    marginRight: "10px",
    color: "#4b4b4c"
  },
  PageDescription: {
    color: "#777"
  },
  rightPaper: {
    padding: "32px 48px !important"
  },
  title: {
    fontWeight: "400",
    fontSize: "22px",
    marginTop: "10px",
    marginBottom: "10px"
  },
  description: {
    fontWeight: 400
  },
  profileImageUploaderContainer: {
    position: "absolute",
    top: "0px",
    width: "150px",
    height: "150px"
  },
  profileImageUploaderContainerModal: {
    width: "100%",
    height: "174px",
    top: "initial",
    bottom: "22px"
  },
  profileImagePlaceholder: {
    width: "150px",
    height: "150px",
    borderRadius: "100%",
    overflow: "hidden",
    background: "#dadada",
    boxShadow: "inset 0 0px 1px 2px rgb(206, 206, 206)",
    border: "1px solid #e6e6e6"
  },
  uploadImageButtonFile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    position: "absolute",
    bottom: "0",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    borderRadius: "100%",
    padding: "8px",
    textAlign: "center",
    transition: "all 0.2s ease-in",
    "&>img": {
      verticalAlign: "-webkit-baseline-middle"
    },
    "&>span": {
      paddingLeft: "8px",
      fontSize: "12px",
      verticalAlign: "sub"
    },
    "& svg": {
      display: "none"
    },
    "&:hover": {
      background: "rgba(0,0,0,0.3)"
    },
    "&:hover svg": {
      display: "block"
    }
  },
  uploadImageButtonFileModal: {
    borderRadius: "0"
  },
  actionContainer: {
    padding: "20px 48px",
    borderTop: "1px solid #ccc",
    textAlign: "right",
    "&>Button": {
      textTransform: "capitalize",
      "&:first-child": {
        marginRight: "12px"
      }
    }
  },
  cancel: {
    height: "36px",
    width: "36px",
    background: "#eee",
    padding: 0,
    minWidth: "32px",
    borderRadius: 0,
    float: "right",
    top: "12px",
    right: "4px",
    "&:hover": {
      background: "#ddd"
    }
  },
  root: {
    flexGrow: 1,
    "&:before": {
      borderColor: "#fff"
    },
    "&:after": {
      borderColor: "#fff"
    }
  },
  select: {
    "&:before": {
      borderColor: "#fff"
    },
    "&:after": {
      borderColor: "#fff"
    }
  },
  input: {
    display: "flex",
    padding: 0
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  selectCategoryWrapper: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginTop: "10px",
    height: "60px"
  },
  colorSwitchBase: {
    color: "#EB5C00",
    "&$colorChecked": {
      color: "#EB5C00",
      "& + $colorBar": {
        backgroundColor: "#EB5C00"
      }
    }
  },
  colorBar: {},
  colorChecked: {},
  toggleText: {
    color: "#777",
    fontSize: "16px"
  },
  createAddOnButton: {
    color: "#EB5C00",
    borderColor: "#EB5C00",
    padding: "15px 12px",
    margin: "0 0 15px",
    textTransform: "capitalize"
  },
  addOnWrapper: {
    padding: "20px 0",
    borderTop: "1px dashed #ccc"
  },
  rateWrapper: {
    flexDirection: "row"
  },
  radioText: {
    fontWeight: "bold",
    fontSize: "15px"
  },
  removeAddOnButton: {
    textAlign: "center",
    color: "#EB5C00",
    cursor: "pointer",
    fontSize: "14px",
    padding: "10px"
  },
  optionsNumbering: {
    color: "#777",
    fontSize: "12px",
    textAlign: "right",
    marginTop: "20px"
  },
  plusMinusImage: {
    textAlign: "right",
    marginTop: "15px"
  },
  addOnContainer: {
    padding: "5px 0"
  },
  documentImage: {
    marginTop: "10px",
    // "& img": {
    //   width: "100%"
    // },
  },
  editableDocumentImage: {
    // "&> div": {
    //   position: "relative",
    //   display: "inline-block",
    //   verticalAlign: "top",
    //   width: "49%",
    //   marginBottom: "20px",
    //   margin: "0 1% 0px 0",
    //   cursor: "pointer",
    //   height: "195px"
    // },
  },
  profileImageUploaderContainerWrap: {
    position: "relative"
  },
  languageSelect: {
    position: "relative"
  },
  resultPaper: {
    marginTop: "0px"
  },
  addressField: {
    "& input": {
      height: "56px",
      width: "100%",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRadius: "5px",
      borderWidth: "1px",
      padding: "18.5px 14px",
      font: "inherit",
      color: "currentColor",
      transition: "border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      "&:hover": {
        borderColor: "rgba(0,0,0,1)"
      },
      "&:focus": {
        border: "2px solid #eb5c00",
        outline: "none"
      }
    }
  },
  docCard: {
    padding: theme.spacing.unit * 2,
    height: 382
  },
  fileUploadContainer: {
    border: '1px dashed #ccc',
    marginTop: 15,
    borderRadius: 10,
    padding: 25,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',
    height: 210,
    "& button": {
      textTransform: 'capitalize',
      boxShadow: 'none',
      marginTop: theme.spacing.unit * 3
    },
    "&:hover button": {
      opacity: 1
    }
  },
  // -------
  bannerfileUploadContainer: {
    border: '1px dashed #ccc',
    marginTop: 15,
    borderRadius: 10,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',
    height: 290,

    // display: "flex",
    // flexWrap: "wrap",
    // flex: 1,
    // alignItems: "center",
    // overflow: "hidden"

    // padding: 10px;
    // display: flex;
    // position: relative;
    // margin-top: 15px;
    // text-align: center;
    // align-items: center;
    // border-radius: 15px;
    // flex-direction: column;
    // justify-content: center;

    "& button": {
      textTransform: 'capitalize',
      boxShadow: 'none',
      marginTop: theme.spacing.unit * 3
    },
    "&:hover button": {
      opacity: 1
    }
  },

  colorSelector: {
    display: "flex",
    alignItems: 'center',
  },


  // -------

  reuploadButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    opacity: 0,
    marginTop: '0 !important',
    transform: 'translate(-50%, -50%)',
    transition: 'opacity 0.3s ease'
  }
});

export default styles;
