import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTachometerAlt,
  faChartBar,
  faUsers,
  faUser,
  faUserTie,
  faCheckDouble,
  faRoad,
  faExclamationTriangle,
  faMoneyCheckAlt,
  faUsersCog,
  faUserLock,
  faCogs,
  faCoins,
  faHistory
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faTachometerAlt, faChartBar, faUsers, faUserTie, faCheckDouble, faRoad, faExclamationTriangle, faMoneyCheckAlt, faUsersCog, faUserLock, faCogs, faCoins, faHistory);
const styles = theme => ({
  card: {
    minWidth: 275
  },
  count: {
    fontSize: "30px",
    color: "#EB5C00"
  },
  orderDashboardImageWrapper: {
    textAlign: "right"
  },
  countDescription: {
    fontSize: "14px",
    color: "#737373"
  },
  colorPrimary: {
    backgroundColor: "#EB5C00"
  },
  progressTextWrapper: {
    marginTop: "5px",
    color: "#777",
    fontSize: "14px"
  },
  floatLeft: {
    float: "left"
  },
  floatRight: {
    float: "right"
  }
});

class Counter extends Component {
  state = {};

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <div className={classes.count}>{this.props.count}</div>
              <div className={classes.countDescription}>
                {this.props.description}
              </div>
            </Grid>
            {this.props.completed ? (
              <Grid
                item
                xs={12}
                sm={12}
                className={classes.orderDashboardImageWrapper}
              >
                <LinearProgress
                  variant="determinate"
                  value={this.props.completed}
                  classes={{
                    barColorPrimary: classes.colorPrimary
                  }}
                />
                <div className={classes.progressTextWrapper}>
                  <span className={classes.floatLeft}>
                    {this.props.progressText}
                  </span>
                  <span className={classes.floatRight}>
                    {this.props.completed}%
                  </span>
                </div>
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

Counter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Counter);
