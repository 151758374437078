import {
  FETCH_DRIVERS_SUCCESS,
  FETCH_UPC_SUCCESS,
  FETCH_DRIVER_SUCCESS,
  FETCH_DRIVER,
  FETCH_DRIVER_FAIL,
  ADD_DRIVER_SUCCESS,
  DRIVER_CREATED_DONE,
  RESET_DRIVER_DETAILS,
  CHANGE_DRIVER_STATUS_SUCCESS,
  DRIVER_STATUS_UPDATED_DONE,
  EDIT_DRIVER_SUCCESS,
  DRIVER_UPDATED_DONE,
  RESET_DRIVER_PASSWORD_SUCCESS,
  RESET_DRIVER_PASSWORD,
  RESET_DRIVER_PASSWORD_DONE
} from "../constants/action-types";

const initialState = {
  driverList: {},
  driverDetails: {},
  driverCreated: false,
  isFetching: false,
  statusUpdated: false,
  driverUpdated: false,
  resetPassword: false
};

const driverReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DRIVERS_SUCCESS:
      return {
        ...state,
        driverList: action.payload.data
      };
    case FETCH_UPC_SUCCESS:
      return {
        ...state,
        upcData: action.payload.data
      };
    case FETCH_DRIVER:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_DRIVER_SUCCESS:
      return {
        ...state,
        driverDetails: action.payload.data,
        isFetching: false
      };
    case FETCH_DRIVER_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case RESET_DRIVER_DETAILS:
      return {
        ...state,
        driverDetails: {}
      };
    case ADD_DRIVER_SUCCESS:
      return {
        ...state,
        driverCreated: true
      };
    case CHANGE_DRIVER_STATUS_SUCCESS:
      let localData = {...state.driverDetails};
      let localTrip={...localData.tripInfo};
      localData=action.payload.data;
      localData.tripInfo=localTrip;
      return {
        ...state,
        driverDetails: {
            ...localData
        },
        statusUpdated: true
      };
    case  DRIVER_STATUS_UPDATED_DONE:
      return {
        ...state,
        statusUpdated: false
      };
    case EDIT_DRIVER_SUCCESS:
      let localDataEdit = {...state.driverDetails};
      let localTripEdit = {...localDataEdit.tripInfo};
      localDataEdit=action.payload.data;
      localDataEdit.tripInfo=localTripEdit ;
      return {
        ...state,
        driverDetails: {
            ...localDataEdit
        },
        driverUpdated: true
      };
    case DRIVER_UPDATED_DONE:
      return {
        ...state,
        driverUpdated: false
      };
     

    case DRIVER_CREATED_DONE:
      return {
        ...state,
        driverCreated: false
      };
    case RESET_DRIVER_PASSWORD:
      return {
        ...state,
        resetPassword: true
      };
    case RESET_DRIVER_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: false
      };
    default:
      return state;
  }
};

export default driverReducer;
