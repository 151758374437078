import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../app/theme";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import styles from "./styles";
import globalstyles from "../../../globalstyles";
import CustomTable from "../../../components/CustomTable";
import Button from "@material-ui/core/Button";
import ReactPaginate from "react-paginate";
import Drawer from "@material-ui/core/Drawer";
import FilterListIcon from "@material-ui/icons/FilterList";
import CountryCodesConfig from "../../../config/country-codes";
import { OngoingTripAlertHeaderFormat } from "../../../config/header-format";
import { getOngoingTripAlert } from "../../../store/actions/tripAlert";
import { AlertTypes, PastTripStatusObject } from "../../../config/trip";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const alertTypes = [
  {
    value: "-1",
    label: "All"
  },
  {
    value: "5",
    label: "Additional charge request"
  },
  {
    value: "6",
    label: "No show request"
  }
];

const countries = CountryCodesConfig;
const alertTypeObject = AlertTypes;
const skip = 0;
const limit = 6;

class BookingRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBoxFocused: false,
      searchKeyword: "",
      offset: 0,
      pendingStatus: 4,
      right: false,
      data: {
        total: 50,
        data: []
      },
      visibleColumns: [
        "Booking Id",
        "Rider Name",
        "Chauffeur Name",
        "Chauffeur Id",
        "Trip Status",
        "Alert"
      ],
      toastMessage: false,
      skip: 0,
      limit: 6,
      errors: {},
      filterData: {
        riderName: "",
        driverName: "",
        riderContactNumber: "",
        driverCountryCode: `${countries[0].code} (${countries[0].dial_code})`,
        riderCountryCode: `${countries[0].code} (${countries[0].dial_code})`,
        alertType: alertTypes[0].value,
        driverContactNumber: "",
        displayId: "",
        riderId: "",
        driverId: ""
      },
      searchData: {},
      headerFormat: OngoingTripAlertHeaderFormat
    };
  }

  columnWidth = 100;
  minColumnLength = 5;

  redirect = data => {
    this.props.history.push("/web/auth/ongoing-trips/details/" + data.trip._id);
  };

  handleColumnVisibilityChange = event => {
    let newState = { ...this.state };
    if (event.target.value.length > this.minColumnLength) {
      document.getElementById("custom_table").style.width =
        document.getElementById("custom_table").clientWidth +
        this.columnWidth +
        "px";
    } else {
      document.getElementById("custom_table").style.width = "1000px";
    }
    if (event.target.value.length > 1) {
      newState.visibleColumns = event.target.value;
    }
    this.setState(newState);
  };

  handleAvailabilityChange = index => event => {
    let localData = { ...this.state.data };
    let dataArray = [...localData.data];
    dataArray[index].availability = event.target.checked;
    localData.data = [...dataArray];
    this.setState({ ...this.state, data: localData });
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleSortClick = (option, header, index) => {
    let newState = {
      ...this.state
    };
    for (let item in newState.headerFormat) {
      if (newState.headerFormat[item].sort) {
        newState.headerFormat[item].option.forEach(element => {
          element.isActive = false;
        });
      }
    }
    newState.headerFormat[header].option[index].isActive = true;
    let localSortData = {};
    if (header === "trip.displayId") {
      localSortData.displayId = option.type;
    }
    if (header === "trip.rider.fullName") {
      localSortData.riderName = option.type;
    }
    if (header === "trip.rider.displayId") {
      localSortData.riderId = option.type;
    }
    if (header === "trip.assignedDriverInfo.driver.fullName") {
      localSortData.driverName = option.type;
    }
    if (header === "trip.assignedDriverInfo.driver.displayId") {
      localSortData.driverId = option.type;
    }
    newState.sortData = localSortData;
    this.setState(newState);
    this.props.getOngoingTripAlert({
      skip: this.state.skip,
      limit: this.state.limit,
      sortConfig: localSortData,
      filters: this.getFilteredData()
    });
  };

  getFilteredData = () => {
    let localFilterData = {};

    for (let item in this.state.filterData) {
      if (this.state.filterData[item].trim().length) {
        if (
          item !== "riderContactNumber" &&
          item !== "driverContactNumber" &&
          item !== "riderCountryCode" &&
          item !== "driverCountryCode" &&
          item !== "alertType"
        ) {
          localFilterData[item] = this.state.filterData[item];
        } else if (item === "alertType") {
          if (this.state.filterData[item] !== "-1") {
            localFilterData[item] = Number(this.state.filterData[item]);
          } else {
            delete localFilterData.alertType;
          }
        } else if (
          item === "riderContactNumber" &&
          this.state.filterData.riderContactNumber
        ) {
          localFilterData.riderPhone = {
            countryCode: this.state.filterData.riderCountryCode.split(" (")[0],
            number: this.state.filterData.riderContactNumber
          };
        } else if (
          item === "driverContactNumber" &&
          this.state.filterData.driverContactNumber
        ) {
          localFilterData.driverPhone = {
            countryCode: this.state.filterData.driverCountryCode.split(" (")[0],
            number: this.state.filterData.driverContactNumber
          };
        }
      }
    }
    console.log("localFilterData ", localFilterData);
    return localFilterData;
  };

  handleChangePage = (event, page) => {
    let newState = {
      ...this.state,
      skip: page
    };
    this.setState(newState);
    this.fetchList(page * this.state.limit, this.state.limit);
  };

  handleChangeRowsPerPage = event => {
    let newState = {
      ...this.state,
      limit: event.target.value
    };
    this.setState(newState);
    this.fetchList(this.state.skip * this.state.limit, event.target.value);
  };

  componentDidMount() {
    let localFilterData = { ...this.state.filterData };
    let filter = {};
    if (this.props.location.search && this.props.location.search.length) {
      localFilterData.alertType = this.props.location.search[
        this.props.location.search.length - 1
      ];
      filter.alertType = Number(
        this.props.location.search[this.props.location.search.length - 1]
      );
    }
    this.setState({
      ...this.state,
      loading: true,
      filterData: localFilterData
    });
    this.props.getOngoingTripAlert({
      skip: skip,
      limit: limit,
      sortConfig: {},
      filters: filter
    });
  }

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (this.props.data.notificationsReducer.mqttComing) {
        console.log(this.props.data.notificationsReducer.mqttNotification)
        let tmpMqttdata = this.props.data.notificationsReducer.mqttNotification
        let tmpMqttdataType = tmpMqttdata.type
        if (tmpMqttdataType === 5 || tmpMqttdataType === 6) {
          this.props.getOngoingTripAlert({
            skip: skip,
            limit: this.state.limit,
            sortConfig: { ...this.state.sortData },
            filters: this.getFilteredData()
          });
        }
      }
      if (
        Object.keys(this.props.data.tripAlertReducer.ongoingTripAlertList)
          .length
      ) {
        let localData = { ...this.state.data };
        localData.total = this.props.data.tripAlertReducer.ongoingTripAlertList.total;
        localData.data = this.props.data.tripAlertReducer.ongoingTripAlertList.data;
        if (localData.data.length) {
          localData.data.forEach(each => {
            each.trip.rider.phoneNumber =
              CountryCodesConfig.filter(item => {
                return item.code === each.trip.rider.phone.countryCode;
              })[0].dial_code + this.formatNumber(each.trip.rider.phone.number);
            if (each.trip.assignedDriverInfo.driver.phone) {
              each.trip.assignedDriverInfo.driver.phoneNumber =
                CountryCodesConfig.filter(item => {
                  return (
                    item.code ===
                    each.trip.assignedDriverInfo.driver.phone.countryCode
                  );
                })[0].dial_code +
                this.formatNumber(
                  each.trip.assignedDriverInfo.driver.phone.number
                );
            }
            each.alert = alertTypeObject[each.alertType];
            each.tripStatus = PastTripStatusObject[each.trip.tripStatus];

            // each.paymentStatus =
            //   each.transactionInfo && each.transactionInfo.riderTransactionId
            //     ? "Payment done"
            //     : "Not paid yet";
          });
        }

        this.setState({
          ...this.state,
          data: localData,
          loading: false
        });
      }
    }
  }

  handleSearchChange = event => {
    this.setState({ ...this.state, searchKeyword: event.target.value });
  };

  componentWillUnmount() { }

  handlePageClick = data => {
    this.props.getOngoingTripAlert({
      skip: data.selected * this.state.limit,
      limit: this.state.limit,
      sortConfig: { ...this.state.sortData },
      filters: this.getFilteredData()
    });
    this.setState({ ...this.state, skip: data.selected * this.state.limit });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      ...this.state,
      [side]: open
    });
  };

  tripFilterInfoValidation = () => {
    let fields = { ...this.state.filterData };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    if (
      fields.riderContactNumber.trim().length &&
      fields.riderContactNumber.trim().length < 10
    ) {
      errors.invalidRiderContactNumber = true;
      hasErrors = true;
    }

    if (
      fields.driverContactNumber.trim().length &&
      fields.driverContactNumber.trim().length < 10
    ) {
      errors.invalidDriverContactNumber = true;
      hasErrors = true;
    }

    console.log("hasErrors ", hasErrors);
    console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  resetFilter = () => {
    this.props.getOngoingTripAlert({
      skip: skip,
      limit: limit,
      sortConfig: {},
      filters: {}
    });
    if (this.props.location.search && this.props.location.search.length) {
      this.props.history.replace("/web/auth/ongoing-trip-alerts");
    }
    this.setState({
      ...this.state,
      right: false,
      filterData: {
        riderName: "",
        driverName: "",
        riderContactNumber: "",
        driverContactNumber: "",
        alertType: alertTypes[0].value,
        driverCountryCode: `${countries[0].code} (${countries[0].dial_code})`,
        riderCountryCode: `${countries[0].code} (${countries[0].dial_code})`,
        displayId: "",
        riderId: "",
        driverId: ""
      }
    });
  };

  filterChange = event => {
    let fields = { ...this.state.filterData };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    if (event.target.name === "riderContactNumber") {
      errors.invalidRiderContactNumber = false;
    }
    if (event.target.name === "driverContactNumber") {
      errors.invalidDriverContactNumber = false;
    }

    this.setState({ ...this.state, filterData: fields, errors });
  };

  filterHandler = () => {
    if (!this.tripFilterInfoValidation()) {
      let localFilterData = this.getFilteredData();
      this.props.getOngoingTripAlert({
        skip: 0,
        limit: this.state.limit,
        sortConfig: { ...this.state.sortData },
        filters: localFilterData
      });
      this.setState({
        ...this.state,
        right: false
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={8} style={{ paddingTop: "10px" }}>
                  <Typography component="p">
                    <span className={classes.pageHeader}>
                      {
                        this.props.data.literalsReducer.OngoingTripAlerts
                          .PageHeader
                      }
                    </span>
                    <span className={classes.PageDescription}>
                      {
                        this.props.data.literalsReducer.OngoingTripAlerts
                          .PageDescription
                      }
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.topButtons}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.addButton}
                    onClick={this.toggleDrawer("right", true)}
                  >
                    <FilterListIcon className={classes.leftIcon} />
                    Filter
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
              {Object.keys(this.state.data).length &&
                this.state.data.data.length &&
                !this.state.loading ? (
                <CustomTable
                  rows={this.state.data}
                  headerFormat={this.state.headerFormat}
                  sort={this.handleSortClick}
                  rowsPerPage={this.state.limit}
                  page={this.state.skip}
                  total={this.state.data.total}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleAvailabilityChange={this.handleAvailabilityChange}
                  visibleColumns={this.state.visibleColumns}
                  redirect={this.redirect}
                  handleColumnVisibilityChange={
                    this.handleColumnVisibilityChange
                  }
                />
              ) : !this.state.loading ? (
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "#fff",
                    borderRadius: "10px"
                  }}
                >
                  No ongoing trip alert found!
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "#fff",
                    borderRadius: "10px"
                  }}
                >
                  {this.props.data.literalsReducer.Common.Loading}
                </div>
              )}
            </Grid>
            {Object.keys(this.state.data).length &&
              this.state.data.data.length &&
              this.state.data.total > this.state.limit ? (
              <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                <ReactPaginate
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.data.total / this.state.limit}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </Grid>
            ) : null}
            <Drawer
              anchor="right"
              open={this.state.right}
              PaperProps={{ className: classes.drawerWrapper }}
            >
              <div tabIndex={0} role="button">
                
                <Grid item xs={12} sm={12} className={classes.rightPaper}>
                  <Grid item xs={12} sm={12} md={12} align="right">
                    <IconButton
                      onClick={this.toggleDrawer("right", false)}
                      className={classes.cancel}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                      <p className={classes.description}>Booking Id</p>
                      <TextField
                        label="Id"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="displayId"
                        value={this.state.filterData.displayId}
                        onChange={this.filterChange}
                      />
                      {/* <p className={classes.description}>Rider Id</p>
                      <TextField
                        label="Id"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="riderId"
                        value={this.state.filterData.riderId}
                        onChange={this.filterChange}
                      /> */}
                      <p className={classes.description}>Chauffeur Id</p>
                      <TextField
                        label="Id"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="driverId"
                        value={this.state.filterData.driverId}
                        onChange={this.filterChange}
                      />
                      <p className={classes.description}>Rider Name</p>
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="riderName"
                        value={this.state.filterData.riderName}
                        onChange={this.filterChange}
                      />
                      <p className={classes.description}>Chauffeur Name</p>
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="driverName"
                        value={this.state.filterData.driverName}
                        onChange={this.filterChange}
                      />

                      <p className={classes.description}>
                        Chauffeur Contact No.
                      </p>
                      <div className={classes.inlineField}>
                        <TextField
                          id="select-car-type"
                          select
                          variant="outlined"
                          className={classes.textField}
                          value={this.state.filterData.driverCountryCode}
                          onChange={this.filterChange}
                          name="driverCountryCode"
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                        >
                          {countries.filter(each => each.code === 'US').map((option, index) => (
                            <option
                              key={index}
                              value={`${option.code} (${option.dial_code})`}
                            >
                              {`${option.code} (${option.dial_code})`}
                            </option>
                          ))}
                        </TextField>
                        <TextField
                          id="car-maker"
                          label="Contact No."
                          variant="outlined"
                          className={classes.textField}
                          name="driverContactNumber"
                          value={this.state.filterData.driverContactNumber}
                          onChange={this.filterChange}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          inputProps={{
                            minLength: 10,
                            maxLength: 10
                          }}
                          error={this.state.errors.invalidDriverContactNumber}
                        />
                      </div>
                      <p className={classes.description}>Rider Contact No.</p>
                      <div className={classes.inlineField}>
                        <TextField
                          id="select-car-type"
                          select
                          variant="outlined"
                          onChange={this.filterChange}
                          name="riderCountryCode"
                          value={this.state.filterData.riderCountryCode}
                          className={classes.textField}
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                        >
                          {countries.filter(each => each.code === 'US').map((option, index) => (
                            <option
                              key={index}
                              value={`${option.code} (${option.dial_code})`}
                            >
                              {`${option.code} (${option.dial_code})`}
                            </option>
                          ))}
                        </TextField>
                        <TextField
                          id="car-maker"
                          label="Contact No."
                          variant="outlined"
                          className={classes.textField}
                          name="riderContactNumber"
                          value={this.state.filterData.riderContactNumber}
                          onChange={this.filterChange}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          inputProps={{
                            minLength: 10,
                            maxLength: 10
                          }}
                          error={this.state.errors.invalidRiderContactNumber}
                        />
                      </div>
                      <p className={classes.description}>Alerts</p>
                      <TextField
                        id="select-car-type"
                        select
                        variant="outlined"
                        value={this.state.filterData.alertType}
                        onChange={this.filterChange}
                        name="alertType"
                        fullWidth
                        className={classes.textField}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                      >
                        {alertTypes.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.actionContainer}>
                        <Button
                          size="large"
                          color="default"
                          variant="contained"
                          onClick={this.resetFilter}
                        >
                          Reset
                        </Button>
                        <Button
                          size="large"
                          color="primary"
                          variant="contained"
                          className={classes.activeGoButton}
                          onClick={this.filterHandler}
                        >
                          Go
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Drawer>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    getOngoingTripAlert: searchObject =>
      dispatch(getOngoingTripAlert(searchObject))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(BookingRequest)
);
