import {
  FETCH_UPC_DRIVERS_SUCCESS,
  FETCH_UPC_DRIVER_SUCCESS,
  FETCH_UPC_DRIVER,
  FETCH_UPC_DRIVER_FAIL,
  RESET_DETAILS
} from "../constants/action-types";

const initialState = {
  driverList: {},
  driverDetails: {},
  isFetching: false
};

const upcDriverReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UPC_DRIVERS_SUCCESS:
      return {
        ...state,
        driverList: action.payload.data
      };

    case FETCH_UPC_DRIVER:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_UPC_DRIVER_SUCCESS:
      return {
        ...state,
        driverDetails: action.payload.data,
        isFetching: false
      };
    case FETCH_UPC_DRIVER_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case RESET_DETAILS:
      return {
        ...state,
        driverDetails: {}
      };
    default:
      return state;
  }
};

export default upcDriverReducer;
