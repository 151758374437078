import en from "./en.json";
import sv from "./sv.json";

const langs = {
  en,
  sv
};

export default  (lang = "en") => {
  return langs[lang];
};
