import SignIn from "../containers/signin";
import RequestOTPForgotPassword from "../containers/forget-password/request-otp";
import ResetPassword from "../containers/forget-password/reset-password";
import AuthLayout from "../containers/auth-layout";
import DownloadInvoice from "../containers/auth/past-trips/download-invoice/index";
import ReferralSignup from "../containers/referral-signup/index";

export const routes = [
  {
    path: "/web/auth/home",
    component: AuthLayout,
    exact: true
  },
  {
    path: "/login",
    component: SignIn
  },
  {
    path: "/past-trips/download-invoice/:tripId",
    component: DownloadInvoice
  },
  {
    path: "/forgot-password/request-otp",
    component: RequestOTPForgotPassword
  },
  {
    path: "/forgot-password/reset-password",
    component: ResetPassword
  },
  {
    path: "/share/referral-code",
    component: ReferralSignup
  }
];
