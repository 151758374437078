/* global google */
export const GoogleApiUtils = {
    getDistanceMatrix: (origin, destination, successCallback) => {
        let directionsService = new google.maps.DirectionsService();
        let request = { origin, destination, travelMode: 'DRIVING' };
        directionsService.route(request, (response, status) => {
            if (status == 'OK') {
                successCallback(response);
            }
        });
    }
}
