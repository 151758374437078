import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import SedanCarModel from '../assets/images/sedan-car-model.png';
import SuvCarModel from '../assets/images/car-suv.png';
import DemoUserImage from "../assets/images/avatar.png";
import { Grid, Typography } from '@material-ui/core';
import {
    faGripVertical
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { VehicleTypes } from '../config/vehicle';
library.add(faGripVertical);

class DraggableListItem extends Component {
    constructor(props) {
        super(props);
    }
    getVehicleModel = (type) => {
        return VehicleTypes.find(_v => _v.value === type.toString()).label;
    };

    convertToMiles = (value) => {
        return `${(value * 0.000621).toFixed(1)} miles`;
    };

    render() {
        const { item, index } = this.props;

        return (
            <Draggable draggableId={item._id} index={index}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <ListItem
                            style={snapshot.isDragging ? localStyles.draggingListItem : null}
                        >
                            <FontAwesomeIcon
                                icon={faGripVertical}
                                style={{ fontSize: "15px", color: "#aaa", marginRight: 15 }}
                            />
                            <ListItemAvatar>
                                <Avatar src={item.driverInfo.personalInfo.profilePicture || DemoUserImage} />
                            </ListItemAvatar>
                            <ListItemText
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                        >
                                            {item.driverInfo.personalInfo.fullName}
                                        </Typography>
                                        {this.convertToMiles(item.distance.value)} | {`$${item.fare.toFixed(2)}`}
                                    </React.Fragment>
                                }
                            />
                            <Grid justify="flex-end">
                                <Grid item>
                                    <img src={item.driverInfo.vehicleInfo.vehicleType == 1 ? SedanCarModel : SuvCarModel} width="60dp" alt="Sedan model" />
                                </Grid>
                                <Grid item>
                                    <Typography align="right" component="span" variant="caption">{this.getVehicleModel(item.driverInfo.vehicleInfo.vehicleType)}</Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </div>
                )}
            </Draggable>
        );
    }
}

const localStyles = {
    draggingListItem: {
        background: 'rgb(235,235,235)',
        borderRadius: '8px',
        boxShadow: '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)'
    }
}

export default DraggableListItem;