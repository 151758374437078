import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import FormHelperText from "@material-ui/core/FormHelperText";

import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import globalstyles from "../../../globalstyles";

import contentStyles from "../../auth-layout/content.styles";
import styles from "./styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { ToastStore } from "react-toasts";
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import LanguagesConfig from "../../../config/language";
import {
  getOperationalParameter,
  updateOperationalParameter,
  resetDetails
} from "../../../store/actions/operationalParameter";
import { request } from "https";
import { showSnackbar } from "../../../store/actions/snackbar";
library.add(faUpload);

const distanceUnits = [
  { label: "Km", value: "1" },
  { label: "Mile", value: "2" }
].map(distanceUnits => ({
  value: distanceUnits.value,
  label: distanceUnits.label
}));

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  };
}

const modalStyles = theme => ({
  modalPaper: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  modalPaperLarge: {
    width: theme.spacing.unit * 150,
    overflowY: "scroll",
    height: "90%"
  }
});

const languages = [];
for (let item in LanguagesConfig) {
  languages.push(LanguagesConfig[item].name);
}

class OperationalParams extends Component {
  state = {
    single: null,
    labelWidth: 0,
    editableMode: false,
    operationalParameterDetails: {
      distanceUnit: distanceUnits[0].value,
      searchRadius: "",
      recommendedDrivers: "",
      maxTimeBeforeScheduling: "",
      minTimeBeforeScheduling: "",
      durationBeforePickupTime: "",
      responseInterval: "",
      timeBeforePickupTime: "",
      driverWaitingTimeAtPickup: "",
      pickupRequestInterval: "",
      rideRequestInterval: "",
      languageSpokenList: []
    },
    errors: {},
    selectedIndex: null,
    languageModalOpen: false,
    addLanguageModalOpen: false,
    selectedLanguage: {
      language: languages[0]
    },
    spokenLanguages: [
      {
        language: "English"
      },
      {
        language: "Spanish"
      },
      {
        language: "French"
      }
    ]
  };

  handleChange = event => {
    let fields = { ...this.state.operationalParameterDetails };
    let errors = { ...this.state.errors };
    if (event.target.value === "0") {
      return;
    }
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;
    this.setState({
      ...this.state,
      operationalParameterDetails: fields,
      errors
    });
  };

  handleLanguageChange = event => {
    let fields = { ...this.state.selectedLanguage };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;
    this.setState({
      ...this.state,
      selectedLanguage: fields,
      errors
    });
  };

  operationalParameterInfoValidation = () => {
    let fields = { ...this.state.operationalParameterDetails };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    for (let name in fields) {
      if (name !== "languageSpokenList") {
        if (fields[name].trim() === "") {
          errors[name] = true;
          hasErrors = true;
        }
      }
    }

    console.log("hasErrors ", hasErrors);
    console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  languageValidation = () => {
    let fields = { ...this.state.selectedLanguage };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    for (let name in fields) {
      if (fields[name].trim() === "") {
        errors[name] = true;
        hasErrors = true;
      }
    }

    console.log("hasErrors ", hasErrors);
    console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  componentDidMount() {
    let localStorageData = window.localStorage.upcAuthData;
    if (localStorageData && JSON.parse(localStorageData)) {
      if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
        JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
          each => {
            if (each.moduleName === "operationalParameter") {
              if (each.role === 2) {
                this.setState({
                  ...this.state,
                  editableMode: true
                });
              }
            }
          }
        );
      } else {
        this.setState({
          ...this.state,
          editableMode: true
        });
      }
    }
    this.props.getOperationalParameter();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(
        this.props.data.operationalParameterData.operationalParameterDetails
      ).length &&
      !this.state.detailsFetched
    ) {
      let localParameter = { ...this.state.operationalParameterDetails };
      localParameter.distanceUnit = this.props.data.operationalParameterData.operationalParameterDetails.distanceUnit;
      localParameter.searchRadius = this.props.data.operationalParameterData.operationalParameterDetails.maximumSearchRadius;
      localParameter.recommendedDrivers = this.props.data.operationalParameterData.operationalParameterDetails.maximumSearchDriverCount;
      localParameter.maxTimeBeforeScheduling = this.props.data.operationalParameterData.operationalParameterDetails.maximumScheduleThreshold;
      localParameter.minTimeBeforeScheduling = this.props.data.operationalParameterData.operationalParameterDetails.minimumScheduleThreshold;
      localParameter.durationBeforePickupTime = this.props.data.operationalParameterData.operationalParameterDetails.tripReminder.thresholdReminderDuration;
      localParameter.responseInterval = this.props.data.operationalParameterData.operationalParameterDetails.tripReminder.allowableReminderResponseTime;
      localParameter.timeBeforePickupTime = this.props.data.operationalParameterData.operationalParameterDetails.tripEnRouteTimeout;
      localParameter.driverWaitingTimeAtPickup = this.props.data.operationalParameterData.operationalParameterDetails.allowableTripStartWaitingTime;
      localParameter.pickupRequestInterval = this.props.data.operationalParameterData.operationalParameterDetails.searchTimeout.timeoutStepTime;
      localParameter.rideRequestInterval = this.props.data.operationalParameterData.operationalParameterDetails.searchTimeout.allowableTime;
      localParameter.languageSpokenList = [
        ...this.props.data.operationalParameterData.operationalParameterDetails
          .languagesSpoken
      ];
      languages.map((lang, index) => {
        if (localParameter.languageSpokenList.length) {
          localParameter.languageSpokenList.forEach((each, ind) => {
            if (each === lang) {
              languages.splice(index, 1);
            }
          });
        }
      });

      for (let item in localParameter) {
        if (item !== "languageSpokenList") {
          localParameter[item] = localParameter[item].toString();
        }
      }
      this.setState({
        ...this.state,
        operationalParameterDetails: localParameter,
        detailsFetched: true
      });
    }
  }

  componentWillUnmount() {
    this.props.resetDetails();
  }

  saveHandler = () => {
    if (!this.operationalParameterInfoValidation()) {
      let localParameter = { ...this.state.operationalParameterDetails };

      let requestData = {
        distanceUnit: Number(localParameter.distanceUnit),
        maximumSearchRadius: Number(localParameter.searchRadius),
        maximumSearchDriverCount: Number(localParameter.recommendedDrivers),
        maximumScheduleThreshold: Number(
          localParameter.maxTimeBeforeScheduling
        ),
        minimumScheduleThreshold: Number(
          localParameter.minTimeBeforeScheduling
        ),

        tripEnRouteTimeout: Number(localParameter.timeBeforePickupTime),
        allowableTripStartWaitingTime: Number(
          localParameter.driverWaitingTimeAtPickup
        ),
        languagesSpoken: [...localParameter.languageSpokenList],
        tripReminder: {
          thresholdReminderDuration: Number(
            localParameter.durationBeforePickupTime
          ),
          allowableReminderResponseTime: Number(localParameter.responseInterval)
        },
        searchTimeout: {
          allowableTime: Number(localParameter.rideRequestInterval),
          timeoutStepTime: Number(localParameter.pickupRequestInterval)
        }
      };
      if (
        requestData.searchTimeout.allowableTime >=
        requestData.searchTimeout.timeoutStepTime
      ) {
        this.props.showSnackbar("Ride request interval can not be more than pickup request interval", 'error');
        return;
      }

      if (
        requestData.tripReminder.thresholdReminderDuration -
        requestData.tripReminder.allowableReminderResponseTime <=
        requestData.tripEnRouteTimeout
      ) {
        this.props.showSnackbar("The difference between Duration before the pickup time and Interval within which the driver needs to respond must be more than Time before the pick up time ", 'error');
        return;
      }
      this.props.updateOperationalParameter(requestData);
    }
  };

  saveLanguage = () => {
    // if (!this.languageValidation()) {
    let localParam = { ...this.state.operationalParameterDetails };
    let localLang = [...localParam.languageSpokenList];
    // if (this.state.addMode) {
    localLang.push(this.state.selectedLanguage.language);
    languages.map((lang, index) => {
      if (localLang.length) {
        localLang.forEach((each, ind) => {
          if (each === lang) {
            languages.splice(index, 1);
          }
        });
      }
    });
    // }
    localParam.languageSpokenList = localLang;
    this.setState({
      addLanguageModalOpen: false,
      languageModalOpen: false,
      operationalParameterDetails: localParam
    });
    // }
  };

  removeLanguage = () => {
    let localParam = { ...this.state.operationalParameterDetails };
    let localLang = [...localParam.languageSpokenList];
    languages.push(localLang[this.state.selectedIndex]);
    languages.sort();
    console.log("localLang ", localLang);
    if (localLang.length === 1) {
        this.props.showSnackbar("You cannot remove all the languages.", 'error');
        return;
    }
    localLang.splice(this.state.selectedIndex, 1);
    localParam.languageSpokenList = localLang;

    let localSelectedObj = { ...this.state.selectedLanguage };
    localSelectedObj.language = languages[0];

    this.setState({
      addLanguageModalOpen: false,
      languageModalOpen: false,
      operationalParameterDetails: localParam,
      selectedLanguage: localSelectedObj
    });
  };

  handleLanguageClick = (language, index) => {
    if (this.state.editableMode) {
      this.setState({
        ...this.state,
        selectedLanguage: {
          language: language
        },
        addMode: false,
        selectedIndex: index,
        languageModalOpen: true
      });
    }
  };

  handlelanguageModalOpen = () => {
    this.setState({ languageModalOpen: true });
  };

  handlelanguageModalClose = () => {
    this.setState({ languageModalOpen: false });
  };

  handleaddLanguageModalOpen = () => {
    this.setState({ addLanguageModalOpen: true });
  };

  handleaddLanguageModalClose = () => {
    this.setState({ addLanguageModalOpen: false });
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12}>
            <Grid container>
              <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                <Typography component="p">
                  <span className={classes.pageHeader}>
                    {
                      this.props.data.literalsReducer.OperationalParams
                        .PageHeader
                    }
                  </span>
                  <span className={classes.PageDescription}>
                    {
                      this.props.data.literalsReducer.OperationalParams
                        .PageDescription
                    }
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper>
              <Grid container>
                <Grid item xs={12} sm={12} className={classes.rightPaper}>
                  <Grid item xs={12} sm={12} className={classes.descSection}>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12}>
                        A ride request will stay with every chauffeur for
                        <TextField
                          className={classes.textField2}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          name="rideRequestInterval"
                          value={
                            this.state.operationalParameterDetails
                              .rideRequestInterval
                          }
                          inputProps={{ className: "text-center" }}
                          onChange={this.handleChange}
                          error={this.state.errors.rideRequestInterval}
                          disabled={!this.state.editableMode}
                        />
                        mins (Ride request interval) before moving to the next
                        chauffeur . This is for a ride with pickup requested at
                        an interval of
                        <TextField
                          className={classes.textField2}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          name="pickupRequestInterval"
                          value={
                            this.state.operationalParameterDetails
                              .pickupRequestInterval
                          }
                          inputProps={{ className: "text-center" }}
                          onChange={this.handleChange}
                          error={this.state.errors.pickupRequestInterval}
                          disabled={!this.state.editableMode}
                        />{" "}
                        mins (Pickup request interval) from the time of request.
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <p className={classes.description}>Distance Unit</p>
                        <TextField
                          select
                          fullWidth
                          variant="outlined"
                          className={classes.textField}
                          onChange={this.handleChange}
                          name="distanceUnit"
                          value={
                            this.state.operationalParameterDetails.distanceUnit
                          }
                          SelectProps={{
                            native: true
                          }}
                        >
                          {distanceUnits.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <p className={classes.description}>
                          Max search radius for the chauffeur from the pickup
                          location (Miles)
                        </p>
                        <TextField
                          id="inviting-others"
                          label="Radius"
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          name="searchRadius"
                          value={
                            this.state.operationalParameterDetails.searchRadius
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.searchRadius}
                          disabled={!this.state.editableMode}
                        />
                        {this.state.errors.searchRadius ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p className={classes.description}>
                          Total No. of recommended chauffeurs
                        </p>
                        <TextField
                          id="inviting-others"
                          label="Recommended Chauffeurs"
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          name="recommendedDrivers"
                          value={
                            this.state.operationalParameterDetails
                              .recommendedDrivers
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.recommendedDrivers}
                          disabled={!this.state.editableMode}
                        />
                        {this.state.errors.recommendedDrivers ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p className={classes.description}>
                          Max time (days) before which a ride can be scheduled
                        </p>
                        <TextField
                          id="inviting-others"
                          label="Max time before ride schedule"
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          name="maxTimeBeforeScheduling"
                          value={
                            this.state.operationalParameterDetails
                              .maxTimeBeforeScheduling
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.maxTimeBeforeScheduling}
                          disabled={!this.state.editableMode}
                        />
                        {this.state.errors.maxTimeBeforeScheduling ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p className={classes.description}>
                          Min time gap to schedule a ride (mins)
                        </p>
                        <TextField
                          id="inviting-others"
                          label="Min time gap before schedule ride"
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          name="minTimeBeforeScheduling"
                          value={
                            this.state.operationalParameterDetails
                              .minTimeBeforeScheduling
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.minTimeBeforeScheduling}
                          disabled={!this.state.editableMode}
                        />
                        {this.state.errors.minTimeBeforeScheduling ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p className={classes.description}>
                          Reminder duration (mins) before the pickup time
                        </p>
                        <TextField
                          id="inviting-others"
                          label="Reminder duration before pickup time"
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          name="durationBeforePickupTime"
                          value={
                            this.state.operationalParameterDetails
                              .durationBeforePickupTime
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.durationBeforePickupTime}
                          disabled={!this.state.editableMode}
                        />
                        {this.state.errors.durationBeforePickupTime ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p className={classes.description}>
                          Interval (mins) within which the chauffeur needs to
                          respond after getting trip reminder
                        </p>
                        <TextField
                          id="inviting-others"
                          label="Response Interval"
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          name="responseInterval"
                          value={
                            this.state.operationalParameterDetails
                              .responseInterval
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.responseInterval}
                          disabled={!this.state.editableMode}
                        />
                        {this.state.errors.responseInterval ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p className={classes.description}>
                          Enroute time duration (mins) before the pick up time
                        </p>
                        <TextField
                          id="inviting-others"
                          label="Enroute time before pickup time"
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          name="timeBeforePickupTime"
                          value={
                            this.state.operationalParameterDetails
                              .timeBeforePickupTime
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.timeBeforePickupTime}
                          disabled={!this.state.editableMode}
                        />
                        {this.state.errors.timeBeforePickupTime ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p className={classes.description}>
                          Chauffeur waiting time (mins) after arriving at pickup
                          location
                        </p>
                        <TextField
                          id="inviting-others"
                          label="Chauffeur waiting time"
                          variant="outlined"
                          fullWidth
                          className={classes.textField}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          name="driverWaitingTimeAtPickup"
                          value={
                            this.state.operationalParameterDetails
                              .driverWaitingTimeAtPickup
                          }
                          onChange={this.handleChange}
                          error={this.state.errors.driverWaitingTimeAtPickup}
                          disabled={!this.state.editableMode}
                        />
                        {this.state.errors.driverWaitingTimeAtPickup ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <p className={classes.description}>
                          List of language spoken by chauffeurs
                        </p>
                        <div className={classes.languageSelect}>
                          {this.state.operationalParameterDetails
                            .languageSpokenList.length ? (
                            this.state.operationalParameterDetails.languageSpokenList.map(
                              (language, index) => {
                                return (
                                  <Chip
                                    key={index}
                                    label={language}
                                    onClick={() =>
                                      this.handleLanguageClick(language, index)
                                    }
                                    className={classes.chip}
                                  />
                                );
                              }
                            )
                          ) : (
                            <div
                              style={{
                                textAlign: "center",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                color: "#777",
                                padding: "10px",
                                fontSize: "12px"
                              }}
                            >
                              {
                                this.props.data.literalsReducer
                                  .RestaurantProfile.NoClosedDateMessage
                              }
                            </div>
                          )}
                          {this.state.editableMode ? (
                            <Button
                              size="small"
                              color="primary"
                              variant="contained"
                              onClick={this.handleaddLanguageModalOpen}
                              className={classes.activeGoButton}
                            >
                              Add More
                            </Button>
                          ) : null}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {this.state.editableMode ? (
                  <Grid item xs={12} sm={12}>
                    <div className={classes.actionContainer}>
                      <Button
                        size="large"
                        color="primary"
                        variant="outlined"
                        className={classNames(
                          classes.customMargin,
                          classes.activeGoButton
                        )}
                        onClick={this.saveHandler}
                      >
                        Save
                      </Button>
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.languageModalOpen}
          onClose={this.handlelanguageModalClose}
        >
          <div style={getModalStyle()} className={classes.modalPaper}>
            <Grid item xs={12} sm={12} className={classes.descSection}>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12}>
                  <h3 style={{ marginTop: "0" }}>Remove Language</h3>
                  <TextField
                    id="inviting-others"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    name="language"
                    value={this.state.selectedLanguage.language}
                    onChange={this.handleLanguageChange}
                    error={this.state.errors.language}
                    disabled
                  />
                  {this.state.errors.language ? (
                    <FormHelperText id="validation-error">
                      {
                        this.props.data.literalsReducer.ValidationErrors
                          .Required
                      }
                    </FormHelperText>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.modalAction}>
              <Button
                size="small"
                color="default"
                variant="contained"
                onClick={this.handlelanguageModalClose}
              >
                Cancel
              </Button>
              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={this.removeLanguage}
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.addLanguageModalOpen}
          onClose={this.handleaddLanguageModalClose}
        >
          <div style={getModalStyle()} className={classes.modalPaper}>
            <Grid item xs={12} sm={12} className={classes.descSection}>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12}>
                  <h3 style={{ marginTop: "0" }}>Language</h3>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    onChange={this.handleLanguageChange}
                    name="language"
                    value={this.state.selectedLanguage.language}
                    SelectProps={{
                      native: true
                    }}
                  >
                    {languages.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
                  {/* <TextField
                    id="inviting-others"
                    label="Add Language"
                    variant="outlined"
                    fullWidth
                    className={classes.textField}
                    name="language"
                    value={this.state.selectedLanguage.language}
                    onChange={this.handleLanguageChange}
                    error={this.state.errors.language}
                  />
                  {this.state.errors.language ? (
                    <FormHelperText id="validation-error">
                      {
                        this.props.data.literalsReducer.ValidationErrors
                          .Required
                      }
                    </FormHelperText>
                  ) : null} */}
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.modalAction}>
              <Button
                size="small"
                color="default"
                variant="contained"
                onClick={this.handleaddLanguageModalClose}
              >
                Cancel
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                className={classes.activeGoButton}
                onClick={this.saveLanguage}
              >
                Add
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    getOperationalParameter: () => dispatch(getOperationalParameter()),
    resetDetails: () => dispatch(resetDetails()),
    updateOperationalParameter: payload =>
      dispatch(updateOperationalParameter(payload)),
    showSnackbar: (message, variant) => dispatch(showSnackbar(message, variant))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...modalStyles(theme),
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(OperationalParams)
);
