// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  FETCH_DRIVERS,
  ADD_DRIVER,
  FETCH_DRIVER,
  DRIVER_CREATED_DONE,
  RESET_DRIVER_DETAILS,
  CHANGE_DRIVER_STATUS,
  DRIVER_STATUS_UPDATED_DONE,
  EDIT_DRIVER,
  DRIVER_UPDATED_DONE,
  RESET_DRIVER_PASSWORD,
  RESET_DRIVER_PASSWORD_DONE
} from "../constants/action-types";

export const getDriverList = searchObj => {
  return {
    type: FETCH_DRIVERS,
    hideToast: true,
    payload: {
      request: {
        url: `${apiURLs.driver}list`,
        method: "POST",
        params: {
          skip: searchObj.skip,
          limit: searchObj.limit
        },
        data: {
          sortConfig: searchObj.sortConfig,
          filters: searchObj.filters
        }
      }
    }
  };
};

export const addDriver = driverData => {
  return {
    type: ADD_DRIVER,
    payload: {
      request: {
        url: apiURLs.driver + "add",
        method: "POST",
        data: driverData
      }
    }
  };
};

export const fetchDriver = id => {

  return {
    type: FETCH_DRIVER,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.driver + id,
        method: "GET"
      }
    }
  };
};

export const changeDriverStatus = (id, driverData) => {
  return {
    type: CHANGE_DRIVER_STATUS,
    payload: {
      request: {
        url: apiURLs.driver + id + "/change-status",
        method: "PUT",
        data: driverData
      }
    }
  };
};

export const editDriver = (id, driverData) => {
  return {
    type: EDIT_DRIVER,
    payload: {
      request: {
        url: apiURLs.driver + id,
        method: "PUT",
        data: driverData
      }
    }
  };
};
export const driverUpdatedDone = () => {
  return {
    type: DRIVER_UPDATED_DONE
  };
};

export const driverCreatedDone = () => {
  return {
    type: DRIVER_CREATED_DONE
  };
};
export const statusUpdatedDone = () => {
  return {
    type: DRIVER_STATUS_UPDATED_DONE
  };
};

export const resetDetails = () => {
  return {
    type: RESET_DRIVER_DETAILS
  };
};

export const resetDriverPassword = (id) => {
  return {
    type: RESET_DRIVER_PASSWORD,
    payload: {
      request: {
        url: apiURLs.driver + id + "/reset-password",
        method: "PUT",
      }
    }
  };
};


export const resetDriverPasswordDone = () => {
  return {
    type: RESET_DRIVER_PASSWORD_DONE
  };
};


