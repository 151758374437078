import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../app/theme";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CashImage from "../../../assets/images/cash.png";
import styles from "./styles";
import classNames from "classnames";
import { Chart } from "react-google-charts";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  InlineDatePicker,
  DatePicker
} from "material-ui-pickers";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ToastStore } from "react-toasts";
import {
  getPaymentConfig,
  getCancelFeeConfig,
  editPaymentConfig,
  paymentUpdateDone,
  resetPayment,
  editCancelFeeConfig,
  cancelFeeUpdateDone,
  resetCancelFee,
  getGraphData
} from "../../../store/actions/revenue";
import { showSnackbar } from "../../../store/actions/snackbar";

const filterOptions = [
  {
    key: "1",
    text: "Yearly"
  },
  {
    key: "2",
    text: "Daily"
  },
  {
    key: "3",
    text: "Date Limit"
  }
];

const months = [
  {
    key: "01",
    text: "Jan"
  },
  {
    key: "02",
    text: "Feb"
  },
  {
    key: "03",
    text: "Mar"
  },
  {
    key: "04",
    text: "Apr"
  },
  {
    key: "05",
    text: "May"
  },
  {
    key: "06",
    text: "Jun"
  },
  {
    key: "07",
    text: "Jul"
  },
  {
    key: "08",
    text: "Aug"
  },
  {
    key: "09",
    text: "Sep"
  },
  {
    key: "10",
    text: "Oct"
  },
  {
    key: "11",
    text: "Nov"
  },
  {
    key: "12",
    text: "Dec"
  }
];

let years = ["2019"];

// import {
//   getCompanyProfile
// } from "../../../store/actions/company-profile";

class Revenue extends Component {
  state = {
    currency: "$",
    editableMode: false,
    filterOption: filterOptions[0].key,
    filterMonth: months[0].key,
    filterYear: years[0],
    filterData: {
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      endDate: new Date()
    },
    revenueData: {
      revenueByCash: 0
    },
    paymentData: {
      sharedToAdmin: 0,
      sharedToCharityBucket: 0,
      sharedToDriver: 0,
      sharedToUPCBucket: 0
    },
    cancellationData: {
      cancellationChargeBeforeMarkedEnRouted: 0,
      cancellationSharedToAdmin: 0,
      cancellationSharedToDriver: 0,
      cancellationChargeAfterMarkedEnRouted: 0
    },
    paymentDataEdit: {
      sharedToAdmin: 0,
      sharedToCharityBucket: 0,
      sharedToDriver: 0,
      sharedToUPCBucket: 0
    },

    cancellationDataEdit: {
      cancellationChargeBeforeMarkedEnRouted: 0,
      cancellationSharedToAdmin: 0,
      cancellationSharedToDriver: 0,
      cancellationChargeAfterMarkedEnRouted: 0
    },
    baseFareBreakDownData: {
      sharedToInvestor: 0,
      sharedToChaffeurCoordinator: 0,
      sharedToChaffeur: 0,
      sharedToUpcBucket: 0
    },
    baseFareBreakDownEditData: {
      sharedToInvestor: 0,
      sharedToChaffeurCoordinator: 0,
      sharedToChaffeur: 0,
      sharedToUpcBucket: 0
    },
    errors: {},
    loading: true,
    detailsPaymentFetched: false,
    detailsCancelFeeFetched: false,
    graphFetched: false,
    paymentEditable: false,
    cancellationEditable: false,
    baseFareBreakDownEditable: false,
    chartData: [
      ["Day", "Revenue($)"],
      ["Jul 1", 0],
      ["Jul 2", 6],
      ["Jul 3", 4],
      ["Jul 4", 0],
      ["Jul 5", 5],
      ["Jul 6", 0],
      ["Jul 7", 0]
    ]
  };

  paymentValidation = () => {
    let fields = { ...this.state.paymentData };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    for (let name in fields) {
      console.log("fields[name]", fields[name]);
      if (fields[name] === "") {
        errors[name] = true;
        hasErrors = true;
      }
    }

    this.setState({ errors: errors });
    return hasErrors;
  };

  baseFareBreakDownValidation = () => {
    let fields = { ...this.state.baseFareBreakDownData };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    let regexp = /^\d+(\.\d{1,1})?$/;



    for (let name in fields) {
      // console.log("fields[name]", fields[name]);
      if (fields[name] === "") {
        errors[name] = true;
        hasErrors = true;
      }

    }
    //  console.log(fields,'fields')
    if (
      // fields.sharedToChaffeur.trim().length &&
      !regexp.test(fields.sharedToChaffeur)

    ) {
      // console.log('in')
      errors["sharedToChaffeurInvalid"] = true;
      hasErrors = true;
    } else {
      errors["sharedToChaffeurInvalid"] = false;
    }


    if (
      // fields.sharedToChaffeurCoordinator.trim().length &&
      !/^\d+(\.\d{1,1})?$/.test(fields.sharedToChaffeurCoordinator)

    ) {
      // console.log('in')
      errors["sharedToChaffeurCoordinatorInvalid"] = true;
      hasErrors = true;
    } else {
      errors["sharedToChaffeurCoordinatorInvalid"] = false;
    }


    if (
      // fields.sharedToUpcBucket.trim().length &&
      !/^\d+(\.\d{1,1})?$/.test(fields.sharedToUpcBucket)

    ) {
      console.log('in')
      errors["sharedToUpcBucketInvalid"] = true;
      hasErrors = true;
    } else {
      errors["sharedToUpcBucketInvalid"] = false;
    }

    if (
      // fields.sharedToInvestor.trim().length &&
      !/^\d+(\.\d{1,1})?$/.test(fields.sharedToInvestor)

    ) {
      // console.log('in')
      errors["sharedToInvestorInvalid"] = true;
      hasErrors = true;
    } else {
      errors["sharedToInvestorInvalid"] = false;
    }

    // console.log(hasErrors,'hasErrors')

    this.setState({ errors: errors });
    return hasErrors;
  };

  cancellationValidation = () => {
    let fields = { ...this.state.cancellationData };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    for (let name in fields) {
      if (fields[name] === "") {
        errors[name] = true;
        hasErrors = true;
      }
    }

    this.setState({ errors: errors });
    return hasErrors;
  };

  handleStartDateChange = date => {
    let newState = { ...this.state };
    newState.filterData.startDate = date;
    this.setState(newState);
  };
  handleEndDateChange = date => {
    let newState = { ...this.state };
    newState.filterData.endDate = date;
    this.setState(newState);
  };

  handleFilterSelectChange = event => {
    this.setState({
      ...this.state,
      filterOption: event.target.value,
      graphFetched: false
    });
    if (event.target.value === "1") {
      this.props.getGraphData({
        startDate: new Date(2019, 0, 2),
        endDate: new Date()
      });
    }
  };

  handleYear = event => {
    this.setState({
      ...this.state,
      filterYear: event.target.value
    });
  };
  handleMonth = event => {
    this.setState({
      ...this.state,
      filterMonth: event.target.value
    });
  };

  filterWithDateRange = () => {
    let localFilterData = { ...this.state.filterData };
    console.log("date filter ", localFilterData);
    console.log("date filter--> ", this.state.filterData);
    if (localFilterData.startDate > localFilterData.endDate) {
      this.props.showSnackbar("End date must be more than start date", 'error');
    } else {
      this.setState({ ...this.state, graphFetched: false });
      this.props.getGraphData({
        startDate: localFilterData.startDate,
        endDate: localFilterData.endDate,
        daily: true
      });
    }
  };

  filterWithYearMonth = () => {
    console.log("this.state ", this.state);
    let localFilterData = {
      startDate: new Date(
        Number(this.state.filterYear),
        Number(this.state.filterMonth) - 1,
        2
      ),
      endDate: new Date(
        Number(this.state.filterYear),
        Number(this.state.filterMonth),
        1
      )
    };
    this.setState({ ...this.state, graphFetched: false });
    console.log("localFilterData ", localFilterData);
    this.props.getGraphData({
      startDate: localFilterData.startDate,
      endDate: localFilterData.endDate,
      daily: true
    });
  };

  componentDidMount() {
    let localStorageData = window.localStorage.upcAuthData;
    if (localStorageData && JSON.parse(localStorageData)) {
      if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
        JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
          each => {
            if (each.moduleName === "revenue") {
              if (each.role === 2) {
                this.setState({
                  ...this.state,
                  editableMode: true
                });
              }
            }
          }
        );
      } else {
        this.setState({
          ...this.state,
          editableMode: true
        });
      }
    }
    for (let item = 2020; item <= new Date().getFullYear(); item++) {
      years.push(item.toString());
    }

    this.props.getPaymentConfig();
    this.props.getCancelFeeConfig();
    this.props.getGraphData({
      startDate: new Date(2019, 0, 2),
      endDate: new Date()
    });
  }

  handleCancellationChange = () => { };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      // if success

      if (
        Object.keys(this.props.data.revenueReducer.paymentConfig).length &&
        !this.state.detailsPaymentFetched
      ) {
        // console.log(this.props.data.revenueReducer.paymentConfig,'this.props.data.revenueReducer.paymentConfig')
        let localPaymentData = { ...this.state.paymentData };
        let localBaseFareData = { ...this.state.baseFareBreakDownData }
        localPaymentData.sharedToAdmin = this.props.data.revenueReducer.paymentConfig.adminCommission;
        localPaymentData.sharedToCharityBucket = this.props.data.revenueReducer.paymentConfig.charityBucketPercentage;
        localPaymentData.sharedToDriver = this.props.data.revenueReducer.paymentConfig.driverPercentage;
        localPaymentData.sharedToUPCBucket = this.props.data.revenueReducer.paymentConfig.upcBucketPercentage;
        localPaymentData.businessTripPercentage = this.props.data.revenueReducer.paymentConfig.businessTripPercentage;

        localBaseFareData.sharedToChaffeur = this.props.data.revenueReducer.paymentConfig.driverPercentageFromBaseFare;
        localBaseFareData.sharedToChaffeurCoordinator = this.props.data.revenueReducer.paymentConfig.driverCoordinatorPercentageFromBaseFare;
        localBaseFareData.sharedToUpcBucket = this.props.data.revenueReducer.paymentConfig.upcBucketPercentageFromBaseFare;
        localBaseFareData.sharedToInvestor = this.props.data.revenueReducer.paymentConfig.investorPercentageFromBaseFare;

        this.setState({
          ...this.state,
          paymentDataEdit: { ...localPaymentData },
          paymentData: { ...localPaymentData },
          baseFareBreakDownEditData: { ...localBaseFareData },
          baseFareBreakDownData: { ...localBaseFareData },
          detailsPaymentFetched: true
        });
      }
      if (
        Object.keys(this.props.data.revenueReducer.cancelFeeConfig).length &&
        !this.state.detailsCancelFeeFetched
      ) {
        let localCancelFeeData = { ...this.state.cancellationData };
        localCancelFeeData.cancellationChargeBeforeMarkedEnRouted = this.props.data.revenueReducer.cancelFeeConfig.preEnRouteFee;
        localCancelFeeData.cancellationSharedToAdmin = this.props.data.revenueReducer.cancelFeeConfig.noShowSplitPercentage.upcPercentage;
        localCancelFeeData.cancellationSharedToDriver = this.props.data.revenueReducer.cancelFeeConfig.noShowSplitPercentage.driverPercentage;
        localCancelFeeData.cancellationChargeAfterMarkedEnRouted = this.props.data.revenueReducer.cancelFeeConfig.postEnRouteFee;

        this.setState({
          ...this.state,
          cancellationDataEdit: localCancelFeeData,
          cancellationData: localCancelFeeData,
          detailsCancelFeeFetched: true
        });
      }
      if (this.props.data.revenueReducer.paymentUpdated) {
        this.props.paymentUpdateDone();
      }
      if (this.props.data.revenueReducer.cancelFeeUpdated) {
        this.props.cancelFeeUpdateDone();
      }

      if (
        this.props.data.revenueReducer.revenueGraphData.length &&
        !this.state.graphFetched
      ) {
        let graphArray = [];
        let flag = false;
        let chartData = [];
        let revenueByCash = 0;
        // console.log(
        //   "graphData ",
        //   this.props.data.revenueReducer.revenueGraphData
        // );
        if (this.state.filterOption === "1") {
          graphArray = [];
          flag = false;
          chartData = [];
          this.props.data.revenueReducer.revenueGraphData.forEach(each => {
            revenueByCash = Number(revenueByCash) + Number(each.monthWiseTotal);
            if (!graphArray.length) {
              graphArray.push([
                each._id.year.toString(),
                Number(each.monthWiseTotal.toFixed(2))
              ]);
            } else {
              flag = false;
              graphArray.forEach(item => {
                if (item[0].toString() === each._id.year.toString()) {
                  flag = true;
                  item[1] = Number(
                    (Number(item[1]) + each.monthWiseTotal).toFixed(2)
                  );
                }
              });
              if (!flag) {
                graphArray.push([
                  each._id.year.toString(),
                  Number(each.monthWiseTotal.toFixed(2))
                ]);
              }
            }
          });
          chartData = [...graphArray];
          chartData.unshift(["Year", "Revenue($)"]);
          // console.log("graphArray ", graphArray);
          // console.log("chartData ", chartData);
          this.setState({
            ...this.state,
            chartData: chartData,
            graphFetched: true,
            revenueData: {
              revenueByCash: revenueByCash.toFixed(2)
            },
            loading: false
          });
        }
        if (
          this.state.filterOption === "3" ||
          this.state.filterOption === "2"
        ) {
          console.log("in 3");
          chartData = [["Date", "Revenue($)"]];
          this.props.data.revenueReducer.revenueGraphData.forEach(each => {
            revenueByCash = Number(revenueByCash) + Number(each.dayWiseTotal);
            if (each.dayWiseTotal) {
              chartData.push([each._id, Number(each.dayWiseTotal.toFixed(2))]);
            }
          });
          // console.log("chartData ", chartData);
          this.setState({
            ...this.state,
            chartData: chartData,
            graphFetched: true,
            revenueData: {
              revenueByCash: revenueByCash.toFixed(2)
            },
            loading: false
          });
        }
      }
    }
  }

  handleCancellationChange = event => {
    let newData = { ...this.state.cancellationData };
    let errors = { ...this.state.errors };
    errors[event.target.name] = false;
    newData[event.target.name] = event.target.value;
    if (event.target.name === "cancellationSharedToAdmin") {
      newData.cancellationSharedToDriver = 100 - Number(event.target.value);
    }
    this.setState({ ...this.state, cancellationData: newData, errors });
  };
  handleChange = event => {
    let newData = { ...this.state.paymentData };
    let errors = { ...this.state.errors };
    errors[event.target.name] = false;
    newData[event.target.name] = event.target.value;

    // if (event.target.name === "cancellationSharedToAdmin") {
    newData.sharedToAdmin =
      100 -
      (Number(newData.sharedToCharityBucket || 0) +
        Number(newData.sharedToDriver || 0) +
        Number(newData.sharedToUPCBucket || 0) +
        Number(newData.businessTripPercentage || 0));
    // }
    this.setState({ ...this.state, paymentData: newData, errors });
  };

  makePaymentEditable = () => {
    this.setState({ ...this.state, paymentEditable: true });
  };
  handleBaseFareChange = event => {
    let fields = { ...this.state.baseFareBreakDownData };
    let errors = { ...this.state.errors };
    errors[event.target.name] = false;
    fields[event.target.name] = event.target.value;

    // let hasErrors = false;
    // let add = Number(newData.sharedToChaffeur) +  Number(newData.sharedToChaffeurCoordinator) +Number(newData.sharedToUpcBucket) +  Number(newData.sharedToInvestor)


    // console.log(add,'add')
    // console.log(hasErrors,'hasErrors')
    this.setState({ ...this.state, baseFareBreakDownData: fields, errors });
    // return hasErrors;
  }
  makeBaseFareBreakDownEditable = () => {
    this.setState({ ...this.state, baseFareBreakDownEditable: true });
  };
  makeBaseFareBreakDownNonEditable = () => {
    let errors = { ...this.state.errors };
    for (let item in this.state.baseFareBreakDownData) {
      errors[item] = false;
    }
    if (errors.sharedToChaffeurInvalid) {
      errors.sharedToChaffeurInvalid = false;
    }
    if (errors.sharedToChaffeurCoordinatorInvalid) {
      errors.sharedToChaffeurCoordinatorInvalid = false;
    }
    if (errors.sharedToUpcBucketInvalid) {
      errors.sharedToUpcBucketInvalid = false;
    }
    if (errors.sharedToInvestorInvalid) {
      errors.sharedToInvestorInvalid = false;
    }

    let localBaseFareEditData = { ...this.state.baseFareBreakDownEditData };
    this.setState({
      ...this.state,
      baseFareBreakDownEditable: false,
      errors,
      baseFareBreakDownData: localBaseFareEditData
    });
  };

  saveBreakDownRelatedInfo = () => {
    // console.log(this.baseFareBreakDownValidation(),'this.baseFareBreakDownValidation()')
    let newData = { ...this.state.baseFareBreakDownData }

    if (!this.baseFareBreakDownValidation()) {
      if (Number(newData.sharedToChaffeur || 0) +
        Number(newData.sharedToChaffeurCoordinator || 0) +
        Number(newData.sharedToUpcBucket || 0) +
        Number(newData.sharedToInvestor || 0) !== 100) {
        this.props.showSnackbar("Breakdown share should be equal to 100%", 'error');
        return;
      } else {
        let errors = { ...this.state.errors };
        for (let item in this.state.baseFareBreakDownData) {
          errors[item] = false;
        }
        this.setState({
          ...this.state,
          baseFareBreakDownEditable: false,
          errors,
          // detailsPaymentFetched: false
        });
        let localBaseFareSaveData = { ...this.state.baseFareBreakDownData };
        let localPaySaveData = { ...this.state.paymentData };
        // console.log(
        //   "tta",
        //   Number(localBaseFareSaveData.sharedToInvestor) +
        //     Number(localBaseFareSaveData.sharedToChaffeur) +
        //     Number(localBaseFareSaveData.sharedToUPCBucket) +
        //     Number(localBaseFareSaveData.sharedToChaffeurCoordinator)
        // );
        if (Number(localBaseFareSaveData.sharedToInvestor) < 0) {
          this.props.showSnackbar("Investor share percentage should be positive", 'error');
          return;
        } else {
          let payloadPayment = {
            adminCommission: Number(localPaySaveData.sharedToAdmin),
            charityBucketPercentage: Number(
              localPaySaveData.sharedToCharityBucket
            ),
            upcBucketPercentage: Number(localPaySaveData.sharedToUPCBucket),
            businessTripPercentage: Number(localPaySaveData.businessTripPercentage),
            driverPercentage: Number(localPaySaveData.sharedToDriver),
            driverPercentageFromBaseFare: Number(localBaseFareSaveData.sharedToChaffeur),
            upcBucketPercentageFromBaseFare: Number(
              localBaseFareSaveData.sharedToUpcBucket
            ),
            driverCoordinatorPercentageFromBaseFare: Number(localBaseFareSaveData.sharedToChaffeurCoordinator),
            investorPercentageFromBaseFare: Number(localBaseFareSaveData.sharedToInvestor),
          };
          // console.log(payloadPayment,'payloadPayment')
          this.props.editPaymentConfig(payloadPayment);
        }

      }

    }
  }

  makeCancellationEditable = () => {
    this.setState({ ...this.state, cancellationEditable: true });
  };
  makePaymentNonEditable = () => {
    let errors = { ...this.state.errors };
    for (let item in this.state.paymentData) {
      errors[item] = false;
    }

    let localPaymentDataEdit = { ...this.state.paymentDataEdit };
    this.setState({
      ...this.state,
      paymentEditable: false,
      errors,
      paymentData: localPaymentDataEdit
    });
  };
  makeCancellationNonEditable = () => {
    let errors = { ...this.state.errors };
    for (let item in this.state.cancellationData) {
      errors[item] = false;
    }
    let localCancelDataEdit = { ...this.state.cancellationDataEdit };
    this.setState({
      ...this.state,
      cancellationEditable: false,
      errors,
      cancellationData: localCancelDataEdit
    });
  };
  savePaymentRelatedInfo = () => {
    if (!this.paymentValidation()) {
      let errors = { ...this.state.errors };
      for (let item in this.state.paymentData) {
        errors[item] = false;
      }
      this.setState({
        ...this.state,
        paymentEditable: false,
        errors,
        // detailsPaymentFetched: false
      });
      let localPaySaveData = { ...this.state.paymentData };
      let localBaseFareSaveData = { ...this.state.baseFareBreakDownData };
      console.log(
        "tta",
        Number(localPaySaveData.sharedToAdmin) +
        Number(localPaySaveData.sharedToCharityBucket) +
        Number(localPaySaveData.sharedToUPCBucket) +
        Number(localPaySaveData.sharedToDriver)
      );
      if (Number(localPaySaveData.sharedToAdmin) < 0) {
        this.props.showSnackbar("Admin share percentage should be positive", 'error');
        return;
      } else {
        let payloadPayment = {
          adminCommission: Number(localPaySaveData.sharedToAdmin),
          charityBucketPercentage: Number(
            localPaySaveData.sharedToCharityBucket
          ),
          upcBucketPercentage: Number(localPaySaveData.sharedToUPCBucket),
          driverPercentage: Number(localPaySaveData.sharedToDriver),
          businessTripPercentage: Number(localPaySaveData.businessTripPercentage),
          driverPercentageFromBaseFare: Number(localBaseFareSaveData.sharedToChaffeur),
          upcBucketPercentageFromBaseFare: Number(
            localBaseFareSaveData.sharedToUpcBucket
          ),
          driverCoordinatorPercentageFromBaseFare: Number(localBaseFareSaveData.sharedToChaffeurCoordinator),
          investorPercentageFromBaseFare: Number(localBaseFareSaveData.sharedToInvestor),
        };
        this.props.editPaymentConfig(payloadPayment);
      }
    }
  };
  saveCancellationRelatedInfo = () => {
    if (!this.cancellationValidation()) {
      let errors = { ...this.state.errors };
      for (let item in this.state.cancellationData) {
        errors[item] = false;
      }
      this.setState({
        ...this.state,
        cancellationEditable: false,
        errors,
        detailsCancelFeeFetched: false
      });
      let localCancelSaveData = { ...this.state.cancellationData };
      let payloadCancelData = {
        preEnRouteFee: Number(
          localCancelSaveData.cancellationChargeBeforeMarkedEnRouted
        ),
        postEnRouteFee: Number(
          localCancelSaveData.cancellationChargeAfterMarkedEnRouted
        ),
        noShowSplitPercentage: {
          driverPercentage: Number(
            localCancelSaveData.cancellationSharedToDriver
          ),
          upcPercentage: Number(localCancelSaveData.cancellationSharedToAdmin)
        }
      };
      this.props.editCancelFeeConfig(payloadCancelData);
    }
  };

  componentWillUnmount() {
    this.props.resetPayment();
    this.props.resetCancelFee();
  }

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Grid container spacing={24} style={{ paddingTop: "10px" }}>
            <Grid item xs={12} sm={12}>
              <Typography component="p">
                <span className={classes.pageHeader}>
                  {this.props.data.literalsReducer.Revenue.PageHeader}
                </span>
                <span className={classes.PageDescription}>
                  {this.props.data.literalsReducer.Revenue.PageDescription}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Paper>
                <Grid container>
                  <Grid item xs={12} sm={12} className={classes.rightPaper}>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <h3>Payment</h3>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.description}
                          style={{ marginTop: "0" }}
                        >
                          Percentage shared to chauffeur -
                          <TextField
                            disabled={
                              !this.state.paymentEditable
                            }
                            className={classes.textField}
                            onChange={this.handleChange}
                            value={this.state.paymentData.sharedToDriver}
                            name="sharedToDriver"
                            error={this.state.errors.sharedToDriver}
                            inputProps={{
                              minLength: 1,
                              maxLength: 2,
                              className: "text-center"
                            }}
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                          />{" "}
                          %
                        </div>
                        <div className={classes.description}>
                          Percentage for charity bucket -
                          <TextField
                            disabled={
                              !this.state.paymentEditable
                            }
                            className={classes.textField}
                            onChange={this.handleChange}
                            inputProps={{
                              minLength: 1,
                              maxLength: 2,
                              className: "text-center"
                            }}
                            value={this.state.paymentData.sharedToCharityBucket}
                            name="sharedToCharityBucket"
                            error={this.state.errors.sharedToCharityBucket}
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                          />{" "}
                          %
                        </div>
                        <div className={classes.description}>
                          Business trip percentage -
                          <TextField
                            disabled={
                              !this.state.paymentEditable
                            }
                            className={classes.textField}
                            onChange={this.handleChange}
                            value={this.state.paymentData.businessTripPercentage}
                            name="businessTripPercentage"
                            inputProps={{
                              minLength: 1,
                              maxLength: 2,
                              className: "text-center"
                            }}
                            error={this.state.errors.businessTripPercentage}
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                          />{" "}
                          %
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.description}
                          style={{ marginTop: "0" }}
                        >
                          Percentage for Ur PC bucket -
                          <TextField
                            disabled={
                              !this.state.paymentEditable
                            }
                            className={classes.textField}
                            onChange={this.handleChange}
                            value={this.state.paymentData.sharedToUPCBucket}
                            name="sharedToUPCBucket"
                            inputProps={{
                              minLength: 1,
                              maxLength: 2,
                              className: "text-center"
                            }}
                            error={this.state.errors.sharedToUPCBucket}
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                          />{" "}
                          %
                        </div>
                        <div className={classes.description}>
                          Admin commission -
                          <TextField
                            disabled={
                              !this.state.paymentEditable
                            }
                            className={classes.textField}
                            onChange={this.handleChange}
                            value={this.state.paymentData.sharedToAdmin}
                            name="sharedToAdmin"
                            inputProps={{
                              minLength: 1,
                              maxLength: 2,
                              className: "text-center"
                            }}
                            error={this.state.errors.sharedToAdmin}
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                          />{" "}
                          %
                        </div>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {this.state.editableMode ? (
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.actionContainer}>
                        {!this.state.paymentEditable ? (
                          <Button
                            size="large"
                            color="primary"
                            variant="outlined"
                            onClick={this.makePaymentEditable}
                            className={classes.activeGoButton}
                          >
                            Edit
                          </Button>
                        ) : (
                          <div>
                            <Button
                              size="large"
                              color="inherit"
                              variant="outlined"
                              onClick={this.makePaymentNonEditable}
                              className={classes.activeGoButton}
                              style={{
                                marginRight: "10px",
                                backgroundColor: "#e0e0e0",
                                borderColor: "#ccc",
                                color: "#000"
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="large"
                              color="primary"
                              variant="outlined"
                              onClick={this.savePaymentRelatedInfo}
                              className={classes.activeGoButton}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Paper>
                <Grid container>
                  <Grid item xs={12} sm={12} className={classes.rightPaper}>
                    <Grid container>
                      <Grid item xs={12} sm={12}>
                        <h3>Base Fare Breakdown</h3>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.description}
                          style={{ marginTop: "0" }}
                        >
                          Percentage for Chauffeur -
                          <TextField
                            disabled={
                              !this.state.baseFareBreakDownEditable
                            }
                            className={classes.textField}
                            onChange={this.handleBaseFareChange}
                            value={this.state.baseFareBreakDownData.sharedToChaffeur}
                            name="sharedToChaffeur"
                            error={this.state.errors.sharedToChaffeur || this.state.errors.sharedToChaffeurInvalid}
                            inputProps={{
                              minLength: 1,
                              maxLength: 4,
                              className: "text-center"
                            }}
                          // onInput={e => {
                          //   e.target.value = e.target.value.replace(
                          //     /[^0-9]/g,
                          //     ""
                          //   );
                          // }}
                          />{" "}
                          %
                        </div>
                        <div className={classes.description}>
                          Percentage for Chauffeur Coordinator -
                          <TextField
                            disabled={
                              !this.state.baseFareBreakDownEditable
                            }
                            className={classes.textField}
                            onChange={this.handleBaseFareChange}
                            inputProps={{
                              minLength: 1,
                              maxLength: 4,
                              className: "text-center"
                            }}
                            value={this.state.baseFareBreakDownData.sharedToChaffeurCoordinator}
                            name="sharedToChaffeurCoordinator"
                            error={this.state.errors.sharedToChaffeurCoordinator || this.state.errors.sharedToChaffeurCoordinatorInvalid}
                          // onInput={e => {
                          //   e.target.value = e.target.value.replace(
                          //     /[^0-9]/g,
                          //     ""
                          //   );
                          // }}
                          />{" "}
                          %
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.description}
                          style={{ marginTop: "0" }}
                        >
                          Percentage for Ur PC bucket -
                          <TextField
                            disabled={
                              !this.state.baseFareBreakDownEditable
                            }
                            className={classes.textField}
                            onChange={this.handleBaseFareChange}
                            value={this.state.baseFareBreakDownData.sharedToUpcBucket}
                            name="sharedToUpcBucket"
                            inputProps={{
                              minLength: 1,
                              maxLength: 4,
                              className: "text-center"
                            }}
                            error={this.state.errors.sharedToUpcBucket}
                          // onInput={e => {
                          //   e.target.value = e.target.value.replace(
                          //     /[^0-9]/g,
                          //     ""
                          //   );
                          // }}
                          />{" "}
                          %
                        </div>
                        <div className={classes.description}>
                          Percentage for Investor -
                          <TextField
                            disabled={!this.state.baseFareBreakDownEditable}
                            className={classes.textField}
                            onChange={this.handleBaseFareChange}
                            value={this.state.baseFareBreakDownData.sharedToInvestor}
                            name="sharedToInvestor"
                            inputProps={{
                              minLength: 1,
                              maxLength: 4,
                              className: "text-center"
                            }}
                            error={this.state.errors.sharedToInvestor || this.state.errors.sharedToInvestorInvalid}
                          // onInput={e => {
                          //   e.target.value = e.target.value.replace(
                          //     /[^0-9]/g,
                          //     ""
                          //   );
                          // }}
                          />{" "}
                          %
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {this.state.editableMode ? (
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.actionContainer}>
                        {!this.state.baseFareBreakDownEditable ? (
                          <Button
                            size="large"
                            color="primary"
                            variant="outlined"
                            onClick={this.makeBaseFareBreakDownEditable}
                            className={classes.activeGoButton}
                          >
                            Edit
                          </Button>
                        ) : (
                          <div>
                            <Button
                              size="large"
                              color="inherit"
                              variant="outlined"
                              onClick={this.makeBaseFareBreakDownNonEditable}
                              className={classes.activeGoButton}
                              style={{
                                marginRight: "10px",
                                backgroundColor: "#e0e0e0",
                                borderColor: "#ccc",
                                color: "#000"
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="large"
                              color="primary"
                              variant="outlined"
                              onClick={this.saveBreakDownRelatedInfo}
                              className={classes.activeGoButton}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Paper>
                <Grid container>
                  <Grid item xs={12} sm={12} className={classes.rightPaper}>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12}>
                        <h3 style={{ marginBottom: "0" }}>
                          Cancellation Charges
                        </h3>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.description}
                          style={{ marginTop: "0" }}
                        >
                          Cancellation charge ($) when rider cancels before the
                          chauffeur marks themselves ‘enroute’ -
                          <TextField
                            disabled={
                              !this.state.cancellationEditable &&
                              !this.state.editableMode
                            }
                            className={classes.textField}
                            onChange={this.handleCancellationChange}
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            inputProps={{ className: "text-center" }}
                            value={
                              this.state.cancellationData
                                .cancellationChargeBeforeMarkedEnRouted
                            }
                            name="cancellationChargeBeforeMarkedEnRouted"
                            error={
                              this.state.errors
                                .cancellationChargeBeforeMarkedEnRouted
                            }
                          />
                        </div>
                        <div className={classes.description}>
                          Percentage of the total cancellation charge to the
                          Admin when chauffeur marked no-show or if the rider
                          cancels the ride after chauffeur marked enroute -
                          <TextField
                            disabled={
                              !this.state.cancellationEditable &&
                              !this.state.editableMode
                            }
                            className={classes.textField}
                            onChange={this.handleCancellationChange}
                            inputProps={{
                              minLength: 1,
                              maxLength: 2,
                              className: "text-center"
                            }}
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            value={
                              this.state.cancellationData
                                .cancellationSharedToAdmin
                            }
                            name="cancellationSharedToAdmin"
                            error={this.state.errors.cancellationSharedToAdmin}
                          />{" "}
                          %
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div
                          className={classes.description}
                          style={{ marginTop: "0" }}
                        >
                          Cancellation charge ($) when If the rider cancels the
                          ride after the chauffeur had marked themselves
                          ‘enroute’ -
                          <TextField
                            disabled={
                              !this.state.cancellationEditable &&
                              !this.state.editableMode
                            }
                            className={classes.textField}
                            onChange={this.handleCancellationChange}
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            inputProps={{ className: "text-center" }}
                            value={
                              this.state.cancellationData
                                .cancellationChargeAfterMarkedEnRouted
                            }
                            name="cancellationChargeAfterMarkedEnRouted"
                            error={
                              this.state.errors
                                .cancellationChargeAfterMarkedEnRouted
                            }
                          />
                        </div>

                        <div className={classes.description}>
                          Percentage of the total cancellation charge to the
                          chauffeur when chauffeur marked no-show or if the
                          rider cancels the ride after chauffeur marked enroute
                          -
                          <TextField
                            disabled
                            className={classes.textField}
                            onChange={this.handleCancellationChange}
                            inputProps={{
                              minLength: 1,
                              maxLength: 2,
                              className: "text-center"
                            }}
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            value={
                              this.state.cancellationData
                                .cancellationSharedToDriver
                            }
                            name="cancellationSharedToDriver"
                            error={this.state.errors.cancellationSharedToDriver}
                          />{" "}
                          %
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {this.state.editableMode ? (
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.actionContainer}>
                        {!this.state.cancellationEditable ? (
                          <Button
                            size="large"
                            color="primary"
                            variant="outlined"
                            onClick={this.makeCancellationEditable}
                            className={classes.activeGoButton}
                          >
                            Edit
                          </Button>
                        ) : (
                          <div>
                            <Button
                              size="large"
                              color="inherit"
                              variant="outlined"
                              onClick={this.makeCancellationNonEditable}
                              className={classes.activeGoButton}
                              style={{
                                marginRight: "10px",
                                backgroundColor: "#e0e0e0",
                                borderColor: "#ccc",
                                color: "#000"
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="large"
                              color="primary"
                              variant="outlined"
                              onClick={this.saveCancellationRelatedInfo}
                              className={classes.activeGoButton}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
              <Typography component="p">
                <span className={classes.pageHeader}>Revenue Chart</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className="sales-filter-wrapper">
              <Grid container spacing={8}>
                <Grid item xs={12} sm={2}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      value={this.state.filterOption}
                      onChange={this.handleFilterSelectChange}
                      inputProps={{
                        className: classes.select
                      }}
                      input={
                        <OutlinedInput labelWidth={0} name="filterOption" />
                      }
                    >
                      {filterOptions.length
                        ? filterOptions.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.key}>
                              {option.text}
                            </MenuItem>
                          );
                        })
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                {this.state.filterOption === "2" ? (
                  <Grid item xs={12} sm={4}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        value={this.state.filterYear}
                        onChange={this.handleYear}
                        inputProps={{
                          className: classes.select
                        }}
                        input={
                          <OutlinedInput labelWidth={0} name="filterYear" />
                        }
                      >
                        {years.length
                          ? years.map((option, index) => {
                            return (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            );
                          })
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                {this.state.filterOption === "2" ? (
                  <Grid item xs={12} sm={4}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        value={this.state.filterMonth}
                        onChange={this.handleMonth}
                        inputProps={{
                          className: classes.select
                        }}
                        input={
                          <OutlinedInput labelWidth={0} name="filterMonth" />
                        }
                      >
                        {months.length
                          ? months.map((option, index) => {
                            return (
                              <MenuItem key={index} value={option.key}>
                                {option.text}
                              </MenuItem>
                            );
                          })
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                {this.state.filterOption === "2" ? (
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.filterWithYearMonth}
                      className={classNames(
                        classes.filterButton,
                        classes.activeGoButton
                      )}
                    >
                      {this.props.data.literalsReducer.Sales.FilterButtonText}
                    </Button>
                  </Grid>
                ) : null}
                {this.state.filterOption === "3" ? (
                  <Grid item xs={12} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className={classes.filterDateLabel}>
                        {this.props.data.literalsReducer.Sales.StartDate}
                      </div>
                      <InlineDatePicker
                        margin="normal"
                        keyboard
                        clearable
                        InputProps={{
                          disabled: true,
                          className: classes.input
                        }}
                        value={this.state.filterData.startDate}
                        name="startDate"
                        onChange={this.handleStartDateChange}
                        format={"Y-MM-dd"}
                        className={classes.dateTimeField}
                        variant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                ) : null}
                {this.state.filterOption === "3" ? (
                  <Grid item xs={12} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className={classes.filterDateLabel}>
                        {this.props.data.literalsReducer.Sales.EndDate}
                      </div>
                      <InlineDatePicker
                        margin="normal"
                        keyboard
                        clearable
                        InputProps={{
                          disabled: true,
                          className: classes.input
                        }}
                        value={this.state.filterData.endDate}
                        name="endDate"
                        onChange={this.handleEndDateChange}
                        format={"Y-MM-dd"}
                        className={classes.dateTimeField}
                        variant="outlined"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                ) : null}
                {this.state.filterOption === "3" ? (
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.filterWithDateRange}
                      className={classNames(
                        classes.filterButton,
                        classes.activeGoButton
                      )}
                    >
                      {this.props.data.literalsReducer.Sales.FilterButtonText}
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} style={{ padding: "25px" }}>
              <Grid container spacing={24}>
                <Grid item xs={12} sm={12} className={classes.chartWrapper}>
                  <div className={classes.chartHeading}>
                    <img
                      src={CashImage}
                      alt="cash"
                      className={classes.chartHeaderImage}
                    />
                    <span>
                      {
                        this.props.data.literalsReducer.Sales
                          .RevenueByCashHeading
                      }
                    </span>
                    <span className={classes.revenueAmount}>
                      <span className={classes.revenueCurrency}>
                        {this.state.currency}
                      </span>
                      {this.props.data.revenueReducer.revenueGraphData.length
                        ? this.state.revenueData.revenueByCash
                        : "0.00"}
                    </span>
                  </div>
                  {this.state.chartData.length > 1 &&
                    this.props.data.revenueReducer.revenueGraphData.length ? (
                    <Chart
                      width={"98%"}
                      height={"300px"}
                      chartType="Bar"
                      loader={
                        <div>
                          {
                            this.props.data.literalsReducer.Sales
                              .LoadingRevenueByCash
                          }
                        </div>
                      }
                      data={this.state.chartData}
                      options={{
                        colors: ["#EB5C00"],
                        chart: {},
                        legend: { position: "none" }
                      }}
                      rootProps={{ "data-testid": "2" }}
                    />
                  ) : !this.props.data.revenueReducer.isFetchingGraph ? (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: "#fff",
                        borderRadius: "10px"
                      }}
                    >
                      No revenue found for the date range selected!
                    </div>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "10px",
                        backgroundColor: "#fff",
                        borderRadius: "10px"
                      }}
                    >
                      {this.props.data.literalsReducer.Common.Loading}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    getPaymentConfig: () => dispatch(getPaymentConfig()),
    getCancelFeeConfig: () => dispatch(getCancelFeeConfig()),
    getGraphData: config => dispatch(getGraphData(config)),
    editPaymentConfig: payload => dispatch(editPaymentConfig(payload)),
    paymentUpdateDone: () => dispatch(paymentUpdateDone()),
    resetPayment: () => dispatch(resetPayment()),
    editCancelFeeConfig: payload => dispatch(editCancelFeeConfig(payload)),
    cancelFeeUpdateDone: () => dispatch(cancelFeeUpdateDone()),
    resetCancelFee: () => dispatch(resetCancelFee()),
    showSnackbar: (message, variant) => dispatch(showSnackbar(message, variant))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Revenue));
