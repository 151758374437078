import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import classNames from "classnames";
import PropTypes from 'prop-types';
import ScrollArea from "react-scrollbar";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../../app/theme";
import DemoUserImage from "../../../../assets/images/default-profile-pic.png";
import contentStyles from "../../../auth-layout/content.styles";
import styles from "./styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import StarRatings from "react-star-ratings";
import Modal from "@material-ui/core/Modal";
import {
  faStar, faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchTrip, resetDetails, searchDriver, assignDriver, driverAssignedDone } from "../../../../store/actions/assignDriver";
import CountryCodesConfig from "../../../../config/country-codes";
import moment from "moment";
library.add(faStar, faUpload);


function TabContainer({ children }) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  };
}
const modalStyles = theme => ({
  modalPaper: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    "& $profileImagePlaceholder": {
      margin: "0 auto 20px"
    },
    "& $profileImageUploaderContainer": {
      left: "0",
      right: "0",
      margin: "auto"
    }
  }
});

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const countries = CountryCodesConfig;
const searchTypes=[{
  label:"Name",
  value:1
}, {
    label: "Email",
    value: 2
  }, {
    label: "Phone No",
    value: 3
  }];

class AssignNewDriver extends Component {
  state = {
    single: null,
    labelWidth: 0,
    searchedDrivers:[],
    selectedDriverId:"",
    confirmationModalOpen:false,
    tripDetails:{
      tripReqBookingId:"",
      driverVehicleType:"",
      recommendedDrivers:[]
    },
    filterData:{
      searchField:"",
      countryCode: countries[0].code,
      searchType:searchTypes[0].value
    },
    assignedDriver:{},
    errors: {},
    haveImage: false,
    tabValue: 0
  };

  vehicleTypes = {
    1: "Sedan",
    2: "SUV"
  };

  driverResponses={
     1:"Request not sent",
     2:"Request pending",
     3:"Request accepted",
     4:"Request rejected",
     5:"Request timed out",
     6:"Request canceled"
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true
    });
    this.props.fetchTrip(this.props.match.params.tripId,"bookingRequest");
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.detailsFetched &&
      Object.keys(this.props.data.assignDriverReducer.tripDetails).length
    ) {
      let localTripData = { ...this.state.tripDetails };

      localTripData.driverVehicleType = this.props.data.assignDriverReducer.tripDetails.requestedNumberOfSeats < 5 ? "Sedan, SUV" :"SUV";
      
      localTripData.tripReqBookingId = this.props.data.assignDriverReducer.tripDetails.displayId || "N/A";

      localTripData.recommendedDrivers = this.props.data.assignDriverReducer.tripDetails.searchedDriversInfo.searchedDrivers;
      
      console.log('localTripData ', localTripData);

      this.setState({
        ...this.state,
        tripDetails: localTripData,
        detailsFetched: true,
        loading: false
      });
    }
    // console.log('this.props.data.assignDriverReducer.driverList ', this.props.data.assignDriverReducer.driverList);
    // if (this.props.data.assignDriverReducer.driverList.length && !this.state.driverSearched) {
    //   console.log('this.props.data.assignDriverReducer.driverList1 ', this.props.data.assignDriverReducer.driverList);
    //   this.setState({ ...this.state, driverSearched:true, searchedDrivers: this.props.data.assignDriverReducer.driverList});
    // }

    if (this.props.data.assignDriverReducer.driverAssigned) {
      this.props.driverAssignedDone();
      window.close();
    }
  }

  componentWillUnmount() {
    this.props.resetDetails();
  }

  openConfirmationModal = (driverId) => {
    this.setState({ confirmationModalOpen: true,selectedDriverId:driverId });
  };
  closeConfirmationModal = () => {
    this.setState({ confirmationModalOpen: false, selectedDriverId:"" });
  };
  assignDriver=()=>{
    console.log('state ', this.state, this.props.match.params.tripId);
    this.props.assignDriver(this.props.match.params.tripId,this.state.selectedDriverId);
  };

  filterChange = event => {
    let fields = { ...this.state.filterData };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;
    if (event.target.name ==="searchType") {
      fields.searchField="";
    }
    this.setState({ ...this.state, filterData: fields, errors });
  };

  handleToggleChange = name => {
    let localMenu = { ...this.state.menu };
    localMenu[name] = !localMenu[name];
    this.setState({ ...this.state, menu: localMenu });
  };

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }

  searchDriver=()=>{
    console.log('search ',this.state.filterData);
    let localFilterData={...this.state.filterData};
    this.setState({ ...this.state, driverSearched: false });
    let requestData={
      startTime: this.props.data.assignDriverReducer.tripDetails.pickupTime.requested,
      endTime: this.props.data.assignDriverReducer.tripDetails.dropTime.requested
    };
    if (Number(localFilterData.searchType)===1) {
      requestData.searchConfig = {
        name: localFilterData.searchField
      };
    } 
    else if(Number(localFilterData.searchType) === 2){
      requestData.searchConfig = {
        email: localFilterData.searchField
      };
    } else {
      requestData.searchConfig = {
        phone:{
        countryCode:localFilterData.countryCode,
        number:localFilterData.searchField
        }
      };
    }
    
    this.props.searchDriver(requestData, this.props.match.params.tripId);
  };

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        {!this.state.loading ? (
        <div>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={12}>
          <Grid container>
            <Grid item xs={12} sm={12} style={{ paddingTop: "10px" }}>
              <Typography component="p">
                <span className={classes.pageHeader}>
                  {
                    this.props.data.literalsReducer.AssignNewDriver.PageHeader
                  }
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
       <Grid container style={{marginTop: "15px"}}>
        <Grid item xs={12} sm={12}>
          <Paper>
            <Grid container spacing={24} className={classes.rightPaper}>
              <Grid item xs={12} sm={6} style={{borderRight: "1px solid #e1e1e1"}}>
                <h3>Booking Details</h3>
                <div className={classes.description}>
                  {this.props.data.literalsReducer.AssignNewDriver.BookingId} - 
                  <span>{this.state.tripDetails.tripReqBookingId}</span>
                </div>   
                <div className={classes.description}>
                  {this.props.data.literalsReducer.DriverDetails.CarType} - 
                  <span>{this.state.tripDetails.driverVehicleType}</span>
                </div>
                <Divider variant="middle" style={{"margin": "25px 0"}} />
                      <h3>Recommended Chauffeurs</h3>
                <List className={classNames(classes.root,classes.lists)}>
                        {this.state.tripDetails.recommendedDrivers.map(driver=>{
                          return <ListItem key={driver.driverId} alignItems="flex-start">
                            <div className={classes.profileImageWrap}>
                              <div className={classes.profileImagePlaceholder}>
                                {driver.driverInfo.personalInfo.profilePicture ?
                                  <img
                                    src={driver.driverInfo.personalInfo.profilePicture}
                                    alt="no-menu-image"
                                    style={{ width: "100%" }}
                                  /> : <img
                                    src={DemoUserImage}
                                    alt="no-menu-image"
                                    style={{ width: "100%" }}
                                  />}
                              </div>
                              <p>
                                <span>{driver.driverInfo.personalInfo.fullName}</span>
                                <span>{CountryCodesConfig.filter(item => {
                                  return item.code === driver.driverInfo.personalInfo.phone.countryCode;
                                })[0].dial_code + this.formatNumber(driver.driverInfo.personalInfo.phone.number)}</span>
                                <span>{this.vehicleTypes[driver.driverInfo.vehicleInfo.vehicleType]}</span>
                              </p>
                            </div>
                            <p className={classes.listRightPart}>
                              <span className={classes.inActiveStatus}>{this.driverResponses[driver.respondStatus]}</span>
                              {driver.respondStatus!==3?
                              <span>
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                    onClick={()=>this.openConfirmationModal(driver.driverId)}
                                >
                                  Assign
                                </Button>
                              </span> : null}
                            </p>
                          </ListItem>
                        })}
                  
                  <Divider variant="middle" component="li" className={classes.liDivider} />
                  
                </List>     
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                        <Grid container>
                    <Grid item xs={6}>
                            <h3>Search Other Chauffeurs</h3>
                    </Grid>
                    <Grid item xs={6} style={{textAlign:"right"}}>
                            <label style={{marginRight:"5px"}}>Search by:</label>
                          <TextField
                            id="select-car-type"
                            select
                            variant="outlined"
                            className={classes.textField}
                            value={this.state.filterData.searchType}
                            onChange={this.filterChange}
                            name="searchType"
                            SelectProps={{
                              native: true,
                              MenuProps: {
                                className: classes.menu
                              }
                            }}
                              style={{ verticalAlign: "middle", fontSize: "14px"}}
                          >
                            {searchTypes.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                      <Grid container>
                        {this.state.filterData.searchType === "3" ?
                         <Grid item xs={2}>
                          <TextField
                            id="select-car-type"
                            select
                            variant="outlined"
                            className={classes.textField}
                            value={this.state.filterData.countryCode}
                            onChange={this.filterChange}
                            name="countryCode"
                            SelectProps={{
                              native: true,
                              MenuProps: {
                                className: classes.menu
                              }
                            }}
                          >
                            {countries.filter(each => each.code === 'US').map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.code}
                              </option>
                            ))}
                          </TextField>
                        </Grid>: null}
                        {this.state.filterData.searchType === "3" ? 
                        <Grid item xs={8}>
                          <TextField
                            id="standard-search"
                                label="Search chauffeurs"
                            type="text"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            value={this.state.filterData.searchField}
                            onChange={this.filterChange}
                            name="searchField"
                                onInput={e => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                inputProps={{
                                  minLength: 10,
                                  maxLength: 10
                                }}
                            fullWidth
                            style={{ margin: 0 }}
                          />
                        </Grid> :
                         <Grid item xs={10}>
                            <TextField
                              id="standard-search"
                              label="Search chauffeurs"
                              type="text"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              value={this.state.filterData.searchField}
                              onChange={this.filterChange}
                              name="searchField"
                              fullWidth
                              style={{ margin: 0 }}
                            />
                          </Grid>}
                  
                  <Grid item xs={2} style={{textAlign:"center",paddingTop:"10px"}}>
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={this.searchDriver}
                          >
                            Search
                        </Button>
                  </Grid>
                </Grid>
                  </Grid>
               
                <List className={classNames(classes.root,classes.searchLists)}>
                        {this.props.data.assignDriverReducer.driverList.length ? 
                        this.props.data.assignDriverReducer.driverList.map(driver=>{
                          return <ListItem key={driver._id} alignItems="flex-start">
                            <div className={classes.profileImageWrap}>
                              <div className={classes.profileImagePlaceholder}>
                                {driver.personalInfo.profilePicture ?
                                  <img
                                    src={driver.personalInfo.profilePicture}
                                    alt="no-menu-image"
                                    style={{ width: "100%" }}
                                  /> : <img
                                    src={DemoUserImage}
                                    alt="no-menu-image"
                                    style={{ width: "100%" }}
                                  />}
                              </div>
                              <p>
                                <span>{driver.personalInfo.fullName}</span>
                                <span>{CountryCodesConfig.filter(item => {
                                  return item.code === driver.personalInfo.phone.countryCode;
                                })[0].dial_code + this.formatNumber(driver.personalInfo.phone.number)}</span>
                                <div
                                  className={classNames(
                                    classes.ratingShow,
                                    classes.description
                                  )}
                                >
                                  <StarRatings
                                    rating={driver.ratingData && driver.ratingData.total ?
                                      (driver.ratingData.total / driver.ratingData.count).toFixed(1) : 0}
                                    starRatedColor="#ffc000"
                                    numberOfStars={5}
                                    name="rating"
                                    starDimension="20px"
                                    starSpacing="2px"
                                  />
                                </div>
                              </p>
                            </div>
                            <p className={classes.listRightPart}>
                              <span>
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  onClick={() => this.openConfirmationModal(driver._id)}
                                >
                                  Assign
                                </Button>
                              </span>
                            </p>
                          </ListItem>
                        }):null}
                  
                 
                 
                  
                </List>  
              </Grid>
            </Grid>
          </Paper> 
        </Grid>
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.confirmationModalOpen}
                onClose={this.closeConfirmationModal}
              >
                <div style={getModalStyle()} className={classes.modalPaper}>
                  <Grid item xs={12} sm={12} className={classes.descSection}>
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12}>
                        <h2>Assign chauffeur</h2>
                        <div
                          className={classes.description}
                          style={{ marginBottom: "20px" }}
                        >
                          Are you sure you want to assign the chauffeur?
                      </div>
                        <div style={{ textAlign: "right" }}>
                          <Button
                            size="small"
                            color="default"
                            variant="contained"
                            className={classes.customMargin}
                            onClick={this.closeConfirmationModal}
                          >
                            Cancel
                        </Button>
                          <Button
                            style={{ marginLeft: "10px" }}
                            size="small"
                            color="primary"
                            variant="contained"
                            className={classNames(
                              classes.customMargin,
                              classes.activeGoButton
                            )}
                            onClick={this.assignDriver}
                          >
                            Yes
                        </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Modal>
      </Grid>
          </div>) : (
            <div
              style={{
                textAlign: "center",
                padding: "10px",
                backgroundColor: "#fff",
                borderRadius: "10px"
              }}
            >
              {this.props.data.literalsReducer.Common.Loading}
          </div>
          )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTrip: (id,type) => dispatch(fetchTrip(id,type)),
    searchDriver: (data,id) => dispatch(searchDriver(data,id)),
    assignDriver: (tripId,driverId) => dispatch(assignDriver(tripId,driverId)),
    resetDetails: () => dispatch(resetDetails()),
    driverAssignedDone: () => dispatch(driverAssignedDone())
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...modalStyles(theme),
      ...styles(theme),
      ...contentStyles(theme)
    }),
    { withTheme: true }
  )(AssignNewDriver)
);
