// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  GET_GLOBAL_CONFIG,
  UPDATE_GLOBAL_CONFIG,
  CONFIG_UPDATED_DONE,
  RESET_CONFIG_DETAILS
} from "../constants/action-types";

// //////////////////// Get Dashboard  Details // //////////////////
export const getGlobalConfig = () => {
  return {
    type: GET_GLOBAL_CONFIG,
    payload: {
      hideToast: true,
      request: {
        url: apiURLs.globalConfig,
        method: "GET"
      }
    },
    hideToast: true
  };
};
export const updateGlobalConfig = data => {
  return {
    type: UPDATE_GLOBAL_CONFIG,
    payload: {
      request: {
        url: apiURLs.globalConfig,
        method: "PUT",
        data: data
      }
    }
  };
};

export const configUpdatedDone = () => {
  return {
    type: CONFIG_UPDATED_DONE
  };
};

export const resetDetails = () => {
  return {
    type: RESET_CONFIG_DETAILS
  };
};
