import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
	palette: {
		primary: {
			'main': '#EB5C00'
		}
	}, 
	typography: {
		useNextVariants: true,
	}
});


export default theme;