// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  FETCH_ROLES,
  ADD_ROLE,
  FETCH_ROLE,
  ROLE_CREATED_DONE,
  RESET_ROLE_DETAILS,
  EDIT_ROLE,
  ROLE_UPDATED_DONE,
  DELETE_ROLE,
  ROLE_DELETE_DONE
} from "../constants/action-types";

export const getRoleList = searchObj => {
  return {
    type: FETCH_ROLES,
    hideToast: true,
    payload: {
      request: {
        url: `${apiURLs.role}list`,
        method: "POST",
        params: {
          skip: searchObj.skip,
          limit: searchObj.limit
        },
        data: {
          sortConfig: searchObj.sortConfig,
          filters: searchObj.filters
        }
      }
    }
  };
};

export const addRole = roleData => {
  return {
    type: ADD_ROLE,
    payload: {
      request: {
        url: apiURLs.role + "add",
        method: "POST",
        data: roleData
      }
    }
  };
};

export const fetchRole = id => {
  
  return {
    type: FETCH_ROLE,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.role + id,
        method: "GET"
      }
    }
  };
};
export const deleteRole = (id ) => {
  return {
    type: DELETE_ROLE,
    payload: {
      request: {
        url: apiURLs.role + id,
        method: "DELETE"
      }
    }
  };
};
export const roleDeleteDone = () => {
  return {
    type: ROLE_DELETE_DONE
  };
};



export const editRole = (id, roleData) => {
  return {
    type: EDIT_ROLE,
    payload: {
      request: {
        url: apiURLs.role + id,
        method: "PUT",
        data: roleData
      }
    }
  };
};
export const roleUpdatedDone = () => {
  return {
    type: ROLE_UPDATED_DONE
  };
};

export const roleCreatedDone = () => {
  return {
    type: ROLE_CREATED_DONE
  };
};

export const resetRole = () => {
  return {
    type: RESET_ROLE_DETAILS
  };
};

