import React, { Component } from "react";

import reactCSS from "reactcss";
import { connect } from "react-redux";

import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../app/theme";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import styles from "./styles";
import globalstyles from "../../../globalstyles";
import CustomTable from "../../../components/CustomTable";
import Button from "@material-ui/core/Button";
import ReactPaginate from "react-paginate";
import Drawer from "@material-ui/core/Drawer";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import AddDriver from "./add-driver";
import cancel from "../../../assets/images/cancel.png";
import { getOperationalParameter } from "../../../store/actions/operationalParameter";
import { getDriverList } from "../../../store/actions/driver";
import moment from "moment";
import CountryCodesConfig from "../../../config/country-codes";
import { DriverHeaderFormat } from "../../../config/header-format";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const carTypes = [
  {
    value: "-1",
    label: "All"
  },
  {
    value: "1",
    label: "Sedan"
  },
  {
    value: "2",
    label: "SUV"
  }
];
const statusList = [
  {
    value: "-1",
    label: "All"
  },
  {
    value: "1",
    label: "Active"
  },
  {
    value: "2",
    label: "Suspended"
  }
];

const countries = CountryCodesConfig;

const skip = 0;
const limit = 6;

class Drivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBoxFocused: false,
      searchKeyword: "",
      editableMode: false,
      languagePresent: [],
      detailsLanguageFetched: false,
      offset: 0,
      pendingStatus: 4,
      right: false,
      data: {
        total: 0,
        data: []
      },
      visibleColumns: [
        "Name",
        "Email",
        "Contact No.",
        "Status",
        "Total Trips Completed",
        "Ur PC Code"
      ],
      toastMessage: false,
      skip: skip,
      limit: limit,

      searchData: {},
      headerFormat: DriverHeaderFormat,
      displayColorPicker: false,
      color: {
        r: "241",
        g: "112",
        b: "19",
        a: "1"
      },
      errors: {},
      filterData: {
        name: "",
        email: "",
        contactNumber: "",
        vehicleRegistrationNo: "",
        displayId: "",
        upcCode: "",
        vehicleModel: "",
        vechicleMake: "",
        vehicleColor: "",
        city: "",
        state: "",
        vehicleType: carTypes[0].value,
        language: "All",
        countryCode: `${countries[0].code} (${countries[0].dial_code})`,
        accountStatus: statusList[0].value
      },
      selectedDate: new Date("2014-08-18T21:11:54")
    };
  }

  columnWidth = 100;
  minColumnLength = 5;

  statusList = {
    1: "Active",
    2: "Inactive",
    3: "Expired"
  };
  // vehicleType = {
  //   1: "SUV",
  //   2: "Sedan"
  // }

  redirect = data => {
    this.props.history.push("/web/auth/drivers/details/" + data._id);
  };

  handleColumnVisibilityChange = event => {
    let newState = { ...this.state };
    if (event.target.value.length > this.minColumnLength) {
      document.getElementById("custom_table").style.width =
        document.getElementById("custom_table").clientWidth +
        this.columnWidth +
        "px";
    } else {
      document.getElementById("custom_table").style.width = "1000px";
    }
    if (event.target.value.length > 1) {
      newState.visibleColumns = event.target.value;
    }
    this.setState(newState);
  };

  handleAvailabilityChange = index => event => {
    let localData = { ...this.state.data };
    let dataArray = [...localData.data];
    dataArray[index].availability = event.target.checked;
    localData.data = [...dataArray];
    this.setState({ ...this.state, data: localData });
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleSortClick = (option, header, index) => {
    let newState = {
      ...this.state
    };
    newState.headerFormat[header].option.forEach(element => {
      element.isActive = false;
    });
    newState.headerFormat[header].option[index].isActive = true;
    let localSortData = { ...this.state.sortData };
    if (header === "personalInfo.fullName") {
      localSortData.name = option.type;
    }
    newState.sortData = localSortData;
    this.setState(newState);
    this.props.getDriverList({
      skip: this.state.skip,
      limit: this.state.limit,
      sortConfig: localSortData,
      filters: this.getFilteredData()
    });
  };

  getFilteredData = () => {
    let localFilterData = {};

    for (let item in this.state.filterData) {
      if (this.state.filterData[item].trim().length) {
        if (
          item !== "contactNumber" &&
          item !== "countryCode" &&
          item !== "accountStatus" &&
          item !== "vehicleType" &&
          item !== "language"
        ) {
          localFilterData[item] = this.state.filterData[item];
        } else if (item === "accountStatus") {
          if (this.state.filterData[item] !== "-1") {
            localFilterData[item] = Number(this.state.filterData[item]);
          } else {
            delete localFilterData.accountStatus;
          }
        } else if (item === "vehicleType") {
          if (this.state.filterData[item] !== "-1") {
            localFilterData[item] = Number(this.state.filterData[item]);
          } else {
            delete localFilterData.vehicleType;
          }
        } else if (item === "language") {
          if (this.state.filterData[item] !== "All") {
            localFilterData[item] = this.state.filterData[item];
          } else {
            delete localFilterData.vehicleType;
          }
        } else if (this.state.filterData.contactNumber) {
          localFilterData.phone = {
            countryCode: this.state.filterData.countryCode.split(" (")[0],
            number: this.state.filterData.contactNumber
          };
        }
      }
    }
    // console.log("localFilterData ", localFilterData);
    return localFilterData;
  };

  handleChangePage = (event, page) => {
    let newState = {
      ...this.state,
      skip: page
    };
    this.setState(newState);
    this.fetchList(page * this.state.limit, this.state.limit);
  };

  handleChangeRowsPerPage = event => {
    let newState = {
      ...this.state,
      limit: event.target.value
    };
    this.setState(newState);
    this.fetchList(this.state.skip * this.state.limit, event.target.value);
  };

  addDriver = event => {
    event.preventDefault();
    this.props.history.push("/web/auth/drivers/add");
  };

  componentDidMount() {
    let localStorageData = window.localStorage.upcAuthData;
    if (localStorageData && JSON.parse(localStorageData)) {
      if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
        JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
          each => {
            if (each.moduleName === "driver") {
              if (each.role === 2) {
                this.setState({
                  ...this.state,
                  editableMode: true,
                  loading: true
                });
              } else {
                this.setState({
                  ...this.state,
                  loading: true
                });
              }
            }
          }
        );
      } else {
        this.setState({
          ...this.state,
          editableMode: true,
          loading: true
        });
      }
    }
    this.props.getOperationalParameter();
    this.props.getDriverList({
      skip: skip,
      limit: limit,
      sortConfig: {},
      filters: {}
    });
  }

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      // if success
      if (Object.keys(this.props.data.driverReducer.driverList).length) {
        let localData = { ...this.state.data };
        localData.total = this.props.data.driverReducer.driverList.total;
        localData.data = this.props.data.driverReducer.driverList.data;
        if (localData.data.length) {
          localData.data.forEach(each => {
            each.createdAt = moment(each.createdAt).format("MM-DD-YYYY");
            each.personalInfo.phoneNumber =
              CountryCodesConfig.filter(item => {
                return item.code === each.personalInfo.phone.countryCode;
              })[0].dial_code +
              this.formatNumber(each.personalInfo.phone.number);
            each.vehicleType = carTypes[each.vehicleInfo.vehicleType].label;
            let strLanguage = "";
            each.settings.languageSpoken.forEach((item, index) => {
              if (index === 0) {
                strLanguage += item;
              } else {
                strLanguage += "," + item;
              }
            });
            each.languages = strLanguage;
            each.vehicleColor = each.vehicleInfo.vehicleColor;
            // each.vehicleInfo= eachvehicleInfo
          });
        }

        this.setState({
          ...this.state,
          data: localData,
          loading: false
        });
      }

      if (
        Object.keys(
          this.props.data.operationalParameterData.operationalParameterDetails
        ).length &&
        !this.state.detailsLanguageFetched
      ) {
        let localParameter = [...this.state.languagePresent];
        localParameter.push("All");
        localParameter.push(
          ...this.props.data.operationalParameterData
            .operationalParameterDetails.languagesSpoken
        );

        console.log(localParameter);
        this.setState({
          ...this.state,
          languagePresent: localParameter,
          detailsLanguageFetched: true
        });
      }
    }
  }

  handleSearchChange = event => {
    this.setState({ ...this.state, searchKeyword: event.target.value });
  };

  componentWillUnmount() { }

  handlePageClick = data => {
    this.props.getDriverList({
      skip: data.selected * this.state.limit,
      limit: this.state.limit,
      sortConfig: { ...this.state.sortData },
      filters: this.getFilteredData()
    });
    this.setState({ ...this.state, skip: data.selected * this.state.limit });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      ...this.state,
      [side]: open
    });
  };

  handleColorPicker = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleColorPickerClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleColorChange = color => {
    this.setState({ color: color.rgb });
  };

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  driverFilterInfoValidation = () => {
    let fields = { ...this.state.filterData };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    if (
      fields.contactNumber.trim().length &&
      fields.contactNumber.trim().length < 10
    ) {
      errors.invalidContactNumber = true;
      hasErrors = true;
    }

    if (
      fields.email.trim().length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields.email)
    ) {
      errors["emailInvalid"] = true;
      hasErrors = true;
    } else {
      errors["emailInvalid"] = false;
    }

    console.log("hasErrors ", hasErrors);
    console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  resetFilter = () => {
    this.props.getDriverList({
      skip: skip,
      limit: limit,
      sortConfig: {},
      filters: {}
    });
    this.setState({
      ...this.state,
      right: false,
      filterData: {
        name: "",
        email: "",
        contactNumber: "",
        vehicleRegistrationNo: "",
        displayId: "",
        upcCode: "",
        vehicleModel: "",
        vechicleMake: "",
        vehicleType: "",
        language: "",
        countryCode: `${countries[0].code} (${countries[0].dial_code})`,
        accountStatus: statusList[0].value
      }
    });
  };

  filterChange = event => {
    let fields = { ...this.state.filterData };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    if (event.target.name === "contactNumber") {
      errors.invalidContactNumber = false;
    }

    if (event.target.name === "email") {
      errors.emailInvalid = false;
    }

    this.setState({ ...this.state, filterData: fields, errors });
  };

  filterHandler = () => {
    if (!this.driverFilterInfoValidation()) {
      let localFilterData = this.getFilteredData();
      this.props.getDriverList({
        skip: 0,
        limit: this.state.limit,
        sortConfig: { ...this.state.sortData },
        filters: localFilterData
      });
      this.setState({
        ...this.state,
        right: false
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { selectedDate } = this.state;

    const colorpickerStyles = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b
            }, ${this.state.color.a})`
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer"
        },
        popover: {
          position: "absolute",
          zIndex: "2"
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px"
        }
      }
    });

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={8} style={{ paddingTop: "10px" }}>
                  <Typography component="p">
                    <span className={classes.pageHeader}>
                      {this.props.data.literalsReducer.DriversList.PageHeader}
                    </span>
                    <span className={classes.PageDescription}>
                      {
                        this.props.data.literalsReducer.DriversList
                          .PageDescription
                      }
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.topButtons}>
                  {this.state.editableMode ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.addButton}
                      onClick={this.addDriver}
                    >
                      <AddIcon className={classes.leftIcon} />
                      Add Chauffeur
                    </Button>
                  ) : null}
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.addButton}
                    onClick={this.toggleDrawer("right", true)}
                  >
                    <FilterListIcon className={classes.leftIcon} />
                    Filter
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
              {Object.keys(this.state.data).length &&
                this.state.data.data.length &&
                !this.state.loading ? (
                <CustomTable
                  rows={this.state.data}
                  headerFormat={this.state.headerFormat}
                  sort={this.handleSortClick}
                  rowsPerPage={this.state.limit}
                  page={this.state.skip}
                  total={this.state.data.total}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  handleAvailabilityChange={this.handleAvailabilityChange}
                  visibleColumns={this.state.visibleColumns}
                  redirect={this.redirect}
                  handleColumnVisibilityChange={
                    this.handleColumnVisibilityChange
                  }
                  statusList={this.statusList}
                />
              ) : !this.state.loading ? (
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "#fff",
                    borderRadius: "10px"
                  }}
                >
                  No chauffeurs found!
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    backgroundColor: "#fff",
                    borderRadius: "10px"
                  }}
                >
                  {this.props.data.literalsReducer.Common.Loading}
                </div>
              )}
            </Grid>
            {Object.keys(this.state.data).length &&
              this.state.data.data.length &&
              this.state.data.total > this.state.limit ? (
              <Grid item xs={12} sm={12} style={{ paddingTop: 0 }}>
                <ReactPaginate
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.data.total / this.state.limit}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </Grid>
            ) : null}
            <Drawer
              anchor="right"
              open={this.state.right}
              PaperProps={{ className: classes.drawerWrapper }}
            >
              <div tabIndex={0} role="button">
                <Grid item xs={12} sm={12} className={classes.rightPaper}>
                  <Grid item xs={12} sm={12} md={12} align="right">
                    <IconButton
                      onClick={this.toggleDrawer("right", false)}
                      className={classes.cancel}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                      {/* <p className={classes.description}>Chauffeur Id</p>
                      <TextField
                        label="Id"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="displayId"
                        value={this.state.filterData.displayId}
                        onChange={this.filterChange}
                      /> */}
                      <p className={classes.description}>Name</p>
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="name"
                        value={this.state.filterData.name}
                        onChange={this.filterChange}
                      />
                      <p className={classes.description}>Email</p>
                      <TextField
                        type="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="email"
                        value={this.state.filterData.email}
                        onChange={this.filterChange}
                        error={this.state.errors.emailInvalid}
                      />
                      <p className={classes.description}>Ur PC Code</p>
                      <TextField
                        label="Ur PC Code"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="upcCode"
                        value={this.state.filterData.upcCode}
                        onChange={this.filterChange}
                      />
                      <p className={classes.description}>Contact No.</p>
                      <div className={classes.inlineField}>
                        <TextField
                          select
                          variant="outlined"
                          name="countryCode"
                          className={classes.textField}
                          value={this.state.filterData.countryCode}
                          onChange={this.filterChange}
                          SelectProps={{
                            native: true,
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                        >
                          {countries.filter(each => each.code === 'US').map((option, index) => (
                            <option
                              key={index}
                              value={`${option.code} (${option.dial_code})`}
                            >
                              {`${option.code} (${option.dial_code})`}
                            </option>
                          ))}
                        </TextField>
                        <TextField
                          id="car-maker"
                          label="Contact No."
                          variant="outlined"
                          className={classes.textField}
                          name="contactNumber"
                          value={this.state.filterData.contactNumber}
                          onChange={this.filterChange}
                          onInput={e => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          inputProps={{
                            minLength: 10,
                            maxLength: 10
                          }}
                          error={this.state.errors.invalidContactNumber}
                        />
                      </div>
                      <p className={classes.description}>Status</p>
                      <TextField
                        id="select-car-type"
                        select
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="accountStatus"
                        value={this.state.filterData.accountStatus}
                        onChange={this.filterChange}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                      >
                        {statusList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>

                      <p className={classes.description}>Associated Car Type</p>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        onChange={this.filterChange}
                        name="vehicleType"
                        value={this.state.filterData.vehicleType}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                      >
                        {carTypes.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                      <p className={classes.description}>
                        {this.props.data.literalsReducer.DriverDetails.CarRegNo}
                      </p>
                      <TextField
                        id="car-maker"
                        label={
                          this.props.data.literalsReducer.DriverDetails.CarRegNo
                        }
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="vehicleRegistrationNo"
                        value={this.state.filterData.vehicleRegistrationNo}
                        onChange={this.filterChange}
                      />
                      <p className={classes.description}>
                        {this.props.data.literalsReducer.DriverDetails.CarModel}
                      </p>
                      <TextField
                        id="car-maker"
                        label={
                          this.props.data.literalsReducer.DriverDetails.CarModel
                        }
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="vehicleModel"
                        value={this.state.filterData.vehicleModel}
                        onChange={this.filterChange}
                      />
                      <p className={classes.description}>
                        {this.props.data.literalsReducer.DriverDetails.Language}
                      </p>
                      <TextField
                        id="select-car-type"
                        select
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="language"
                        value={this.state.filterData.language}
                        onChange={this.filterChange}
                        SelectProps={{
                          native: true,
                          MenuProps: {
                            className: classes.menu
                          }
                        }}
                      >
                        {this.state.languagePresent.map((name, index) => (
                          <option key={index} value={name}>
                            {name}
                          </option>
                        ))}
                      </TextField>
                      <TextField
                        label="City"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="city"
                        value={this.state.filterData.city}
                        onChange={this.filterChange}
                      />
                      <TextField
                        label="State"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        name="state"
                        value={this.state.filterData.state}
                        onChange={this.filterChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <div className={classes.actionContainer}>
                        <Button
                          size="large"
                          color="default"
                          variant="contained"
                          onClick={this.resetFilter}
                        >
                          Reset
                        </Button>
                        <Button
                          size="large"
                          color="primary"
                          variant="contained"
                          className={classes.activeGoButton}
                          onClick={this.filterHandler}
                        >
                          Go
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Drawer>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    getOperationalParameter: () => dispatch(getOperationalParameter()),
    getDriverList: searchObject => dispatch(getDriverList(searchObject))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(Drivers)
);
