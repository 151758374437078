// API Config
import { apiURLs } from "../api-config/api.urls";
// Action Types Constant
import {
  FETCH_SETTLED_BILLS,
  FETCH_UNSETTLED_BILLS,
  FETCH_BILLING,
  RESET_BILLING_DETAILS,
  SETTLE_BILL,
  SETTLE_TRANSFER_BILL,
  EDIT_PAYMENT_BILL,
  BILL_UPDATED_DONE
} from "../constants/action-types";

export const getSettledBillList = searchObj => {
  return {
    type: FETCH_SETTLED_BILLS,
    hideToast: true,
    payload: {
      request: {
        url: `${apiURLs.billing}settled-list`,
        method: "POST",
        params: {
          skip: searchObj.skip,
          limit: searchObj.limit
        },
        data: {
          sortConfig: searchObj.sortConfig,
          filters: searchObj.filters
        }
      }
    }
  };
};

export const getUnSettledBillList = searchObj => {
  return {
    type: FETCH_UNSETTLED_BILLS,
    hideToast: true,
    payload: {
      request: {
        url: `${apiURLs.billing}unsettle-list`,
        method: "POST",
        params: {
          skip: searchObj.skip,
          limit: searchObj.limit
        },
        data: {
          sortConfig: searchObj.sortConfig,
          filters: searchObj.filters
        }
      }
    }
  };
};

export const fetchBill = id => {
  return {
    type: FETCH_BILLING,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.pastTrip + "/" + id,
        method: "GET"
      }
    }
  };
};

export const resetDetails = () => {
  return {
    type: RESET_BILLING_DETAILS
  };
};


export const settleBill = id => {
  return {
    type: SETTLE_BILL,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.billing + id + "/settle-payment",
        method: "POST"
      }
    }
  };
};

export const settleTransfer = id => {
  return {
    type: SETTLE_TRANSFER_BILL,
    hideToast: true,
    payload: {
      request: {
        url: apiURLs.billing + id + "/transfer-shares",
        method: "POST"
      }
    }
  };
};

export const editBill = (id, billData) => {
  return {
    type: EDIT_PAYMENT_BILL,
    payload: {
      request: {
        url: apiURLs.billing + id + "/update-payment-breakdown",
        method: "PUT",
        data: billData
      }
    }
  };
};

export const billUpdatedDone = () => {
  return {
    type: BILL_UPDATED_DONE
  };
};

