import { SNACKBAR_CLEAR, SNACKBAR_OPEN } from "../constants/action-types";

const initialState = {
  opem: false,
  mesage: null
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_OPEN:
      return {
        ...state,
        snackbarOpen: true,
        snackbarVariant: action.variant,
        snackbarMessage: action.message
      };
    case SNACKBAR_CLEAR:
      return {
        ...state,
        snackbarOpen: false,
      };
    default:
      return state;
  }
}

export default snackbarReducer;