export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const getTimeIntervalInMinute = (start, end) => {
    if (!start || !end) return;
    start = new Date(start);
    end = new Date(end);
    let interval = end.getTime() - start.getTime();
    return interval / (1000 * 60)
}

export const isInnerContentAvailable = (obj) => {
    return Object.values(obj).some(value => value)
}

export const sort = list => {
    return list.sort((a, b) => (a.personalInfo.firstName > b.personalInfo.firstName ? 1 : -1))
}

export const replace = (str, find, replaceWith) => {
    if(!str) return;
    return str.replace(new RegExp(find, 'g'), replaceWith);
}