import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../../app/theme";
import contentStyles from "../../../auth-layout/content.styles";
import styles from "./styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import globalstyles from "../../../../globalstyles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { getRoleList } from "../../../../store/actions/role";
import { addAdmin, adminCreatedDone } from "../../../../store/actions/admin";

library.add(faUpload);

class AddAdmin extends Component {
  state = {
    single: null,
    labelWidth: 0,
    detailsFetched: false,
    selectRoles: [],
    adminDetails: {
      firstName: "",
      lastName: "",
      email: "",
      isSuper: true,
      roleId: ""
    },
    roleTypes: [
      {
        value: "1",
        selected: true,
        text: "Super Access"
      },
      {
        value: "2",
        selected: false,
        text: "Others"
      }
    ],
    errors: {},
    haveImage: false
  };

  handleChange = event => {
    let fields = { ...this.state.adminDetails };
    let errors = { ...this.state.errors };
    fields[event.target.name] = event.target.value;
    errors[event.target.name] = false;

    if (event.target.name === "email") {
      errors.emailInvalid = false;
    }

    this.setState({ ...this.state, adminDetails: fields, errors });
  };

  adminInfoValidation = () => {
    let fields = { ...this.state.adminDetails };
    let errors = {
      ...this.state.errors
    };
    let hasErrors = false;

    if (!fields.firstName.trim()) {
      errors.firstName = true;
      hasErrors = true;
    }
    if (!fields.lastName.trim()) {
      errors.lastName = true;
      hasErrors = true;
    }

    if (!fields.email.trim()) {
      errors.email = true;
      hasErrors = true;
    }

    if (
      fields.email.trim().length &&
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fields.email)
    ) {
      errors["emailInvalid"] = true;
      hasErrors = true;
    } else {
      errors["emailInvalid"] = false;
    }

    console.log("hasErrors ", hasErrors);
    console.log("errors ", errors);

    this.setState({ errors: errors });
    return hasErrors;
  };

  componentDidMount() {
    this.props.getRoleList({
      skip: 0,
      limit: 0,
      sortConfig: {},
      filters: {}
    });
  }

  handleRoleSelectionChange = index => {
    let localRoleTypes = [...this.state.roleTypes];
    let localAdminDetails = { ...this.state.adminDetails };
    localRoleTypes.forEach((each, ind) => {
      if (index !== ind) {
        each.selected = false;
      }
    });
    localRoleTypes[index].selected = !localRoleTypes[index].selected;
    if (localRoleTypes[0].selected) {
      localAdminDetails.isSuper = localRoleTypes[0].selected;
    } else {
      localAdminDetails.isSuper = localRoleTypes[0].selected;
    }

    this.setState({
      ...this.state,
      roleTypes: localRoleTypes,
      adminDetails: localAdminDetails
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (ReactDOM.findDOMNode(this.InputLabelRef)) {
      this.setState({
        ...this.state,
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
      });
    }
    if (
      Object.keys(this.props.data.roleReducer.roleList).length &&
      !this.state.detailsFetched
    ) {
      let tmpRoles = this.props.data.roleReducer.roleList.data;
      let selectRoles = [];
      tmpRoles.forEach((item, index) => {
        selectRoles.push({
          value: item._id,
          label: item.name
        });
      });

      this.setState({
        ...this.state,
        selectRoles: selectRoles,
        detailsFetched: true
      });
    }

    if (this.props.data.adminReducer.adminCreated) {
      this.props.adminCreatedDone();
      this.props.history.push("/web/auth/admins");
    }
  }

  componentWillUnmount() {}

  saveHandler = () => {
    if (!this.adminInfoValidation()) {
      console.log("roletypes", this.state.adminDetails);
      let localAdmin = { ...this.state.adminDetails };
      let localRole = [...this.state.roleTypes];
      let payload = {};
      if (localRole[1].selected) {
        payload = {
          personalInfo: {
            firstName: localAdmin.firstName,
            lastName: localAdmin.lastName,
            email: localAdmin.email
          },
          roleInfo: {
            isSuperAdmin: localAdmin.isSuper,
            roleId: localAdmin.roleId
          }
        };
      } else {
        payload = {
          personalInfo: {
            firstName: localAdmin.firstName,
            lastName: localAdmin.lastName,
            email: localAdmin.email
          },
          roleInfo: {
            isSuperAdmin: localAdmin.isSuper
          }
        };
      }
      this.props.addAdmin(payload);
      //console.log("payload",payload)
    }
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                  <Typography component="p">
                    <span className={classes.pageHeader}>
                      {this.props.data.literalsReducer.Admins.PageHeader}
                    </span>
                    <span className={classes.PageDescription}>
                      {this.props.data.literalsReducer.AddAdmin.PageDescription}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.headerRightButton}
                    onClick={this.backToPrevPage}
                  >
                    <ArrowBackIosIcon className={classes.leftIcon} /> Back
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "15px" }}>
            <Grid item xs={12} sm={12}>
              <Paper>
                <Grid container className={classes.rightPaper}>
                  <Grid item xs={12} sm={6}>
                    <div className={classes.description}>
                      {this.props.data.literalsReducer.AddRider.Name}
                    </div>
                    <Grid container spacing={16}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="rider-first-name"
                          label={
                            this.props.data.literalsReducer.AddRider.FirstName
                          }
                          variant="outlined"
                          className={classNames(
                            classes.textField,
                            classes.halfField
                          )}
                          name="firstName"
                          value={this.state.adminDetails.firstName}
                          onChange={this.handleChange}
                          error={this.state.errors.firstName}
                        />
                        {this.state.errors.firstName ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          id="rider-last-name"
                          label={
                            this.props.data.literalsReducer.AddRider.LastName
                          }
                          variant="outlined"
                          className={classNames(
                            classes.textField,
                            classes.halfField
                          )}
                          name="lastName"
                          value={this.state.adminDetails.lastName}
                          onChange={this.handleChange}
                          error={this.state.errors.lastName}
                        />
                        {this.state.errors.lastName ? (
                          <FormHelperText id="validation-error">
                            {
                              this.props.data.literalsReducer.ValidationErrors
                                .Required
                            }
                          </FormHelperText>
                        ) : null}
                      </Grid>
                    </Grid>
                    <div className={classes.description}>
                      {this.props.data.literalsReducer.AddRider.Email}
                    </div>
                    <TextField
                      id="rider-email"
                      label={this.props.data.literalsReducer.AddRider.Email}
                      variant="outlined"
                      fullWidth
                      type="email"
                      className={classes.textField}
                      name="email"
                      onChange={this.handleChange}
                      value={this.state.adminDetails.email}
                      error={
                        this.state.errors.email ||
                        this.state.errors.emailInvalid
                      }
                    />
                    {this.state.errors.email ? (
                      <FormHelperText id="validation-error">
                        {
                          this.props.data.literalsReducer.ValidationErrors
                            .Required
                        }
                      </FormHelperText>
                    ) : null}
                    {!this.state.errors.email &&
                    this.state.errors.emailInvalid ? (
                      <FormHelperText id="validation-error">
                        {
                          this.props.data.literalsReducer.ValidationErrors
                            .InvalidEmail
                        }
                      </FormHelperText>
                    ) : null}
                    <div className={classes.description}>Roles</div>
                    <FormGroup className={classes.selectList}>
                      {this.state.roleTypes && this.state.roleTypes.length
                        ? this.state.roleTypes.map((type, index) => {
                            return (
                              <div key={index}>
                                <Checkbox
                                  checked={type.selected}
                                  onChange={() =>
                                    this.handleRoleSelectionChange(index)
                                  }
                                  value={type.value}
                                  color="primary"
                                />{" "}
                                <span style={{ marginRight: "10px" }}>
                                  {type.text}
                                </span>
                                {type.value === "2" ? (
                                  <TextField
                                    select
                                    variant="outlined"
                                    className={classes.textField}
                                    SelectProps={{
                                      native: true
                                    }}
                                    name="roleId"
                                    value={this.state.adminDetails.roleId}
                                    onChange={this.handleChange}
                                    disabled={!type.selected}
                                  >
                                    {this.state.selectRoles.map(option => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </TextField>
                                ) : null}
                              </div>
                            );
                          })
                        : null}
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.actionContainer}>
                      <Button
                        size="large"
                        color="default"
                        variant="contained"
                        className={classes.customMargin}
                        onClick={this.backToPrevPage}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        className={classNames(
                          classes.customMargin,
                          classes.activeGoButton
                        )}
                        onClick={this.saveHandler}
                      >
                        Add Admin
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    getRoleList: searchObject => dispatch(getRoleList(searchObject)),
    addAdmin: payload => dispatch(addAdmin(payload)),
    adminCreatedDone: () => dispatch(adminCreatedDone())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(AddAdmin)
);
