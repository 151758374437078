export const OngoingTripHeaderFormat = {
  displayId: {
    head: "Booking Id",
    key: "displayId",
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ],
    sort: true
  },

  "rider.fullName": {
    head: "Rider Name",
    key: ["rider", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "assignedDriverInfo.driver.displayId": {
    head: "Chauffeur Id",
    key: ["assignedDriverInfo", "driver", "displayId"],
    populate: true,
    sort: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "assignedDriverInfo.driver.fullName": {
    head: "Chauffeur Name",
    key: ["assignedDriverInfo", "driver", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },

  "assignedDriverInfo.driver.phoneNumber": {
    head: "Chauffeur Phone No.",
    key: ["assignedDriverInfo", "driver", "phoneNumber"],
    populate: true
  },

  "rider.phoneNumber": {
    head: "Rider Phone No.",
    key: ["rider", "phoneNumber"],
    populate: true
  },
  markedEnRouteTime: {
    head: "Driver Marked Enrouted",
    key: "markedEnRouteTime"
  },
  tripType: {
    head: "Trip Type",
    key: "tripType"
  },
  pickupTime: {
    head: "Pickup Time",
    key: "pickupTime"
  },
  "assignedDriverInfo.driver.email": {
    head: "Chauffeur Email",
    key: ["assignedDriverInfo", "driver", "email"],
    populate: true
  },
  "rider.email": {
    head: "Rider Email",
    key: ["rider", "email"],
    populate: true
  },
  tripStatus: {
    head: "Driver Status",
    key: "tripStatus",
    status: true
  }
};

export const PastTripHeaderFormat = {
  displayId: {
    head: "Booking Id",
    key: "displayId",
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ],
    sort: true
  },

  "rider.fullName": {
    head: "Rider Name",
    key: ["rider", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "assignedDriverInfo.driver.displayId": {
    head: "Chauffeur Id",
    key: ["assignedDriverInfo", "driver", "displayId"],
    populate: true,
    sort: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "assignedDriverInfo.driver.fullName": {
    head: "Chauffeur Name",
    key: ["assignedDriverInfo", "driver", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  vehicleType: {
    head: "Vehicle Type",
    key: "vehicleType"
  },
  tripType: {
    head: "Trip Type",
    key: "tripType"
  },
  tripStatus: {
    head: "Status",
    key: "tripStatus",
    status: true
  },
  "assignedDriverInfo.driver.email": {
    head: "Chauffeur Email",
    key: ["assignedDriverInfo", "driver", "email"],
    populate: true
  },
  "assignedDriverInfo.driver.phoneNumber": {
    head: "Driver Phone No.",
    key: ["assignedDriverInfo", "driver", "phoneNumber"],
    populate: true
  },
  "rider.email": {
    head: "Rider Email",
    key: ["rider", "email"],
    populate: true
  },
  "rider.phoneNumber": {
    head: "Rider Phone No.",
    key: ["rider", "phoneNumber"],
    populate: true
  },

  pickupTime: {
    head: "Pickup Time",
    key: "pickupTime"
  },
  paymentStatus: {
    head: "Payment Status",
    key: "paymentStatus"
  }
};

export const RiderHeaderFormat = {
  "personalInfo.fullName": {
    head: "Rider Name",
    key: ["personalInfo", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: 1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: -1,
        isActive: false
      }
    ]
  },
  "personalInfo.email": {
    head: "Email",
    key: ["personalInfo", "email"],
    populate: true
  },
  "personalInfo.phoneNumber": {
    head: "Contact No.",
    key: ["personalInfo", "phoneNumber"],
    populate: true
  },
  "upcBucketInfo.upcCode": {
    head: "Ur PC Code",
    key: ["upcBucketInfo", "upcCode"],
    populate: true
  },
  accountStatus: {
    head: "Status",
    key: "accountStatus",
    status: true,
    populate: false
  },
  createdAt: {
    head: "Joining Date",
    key: "createdAt"
  }
};

export const DriverHeaderFormat = {
  "tripInfo.completedTrips": {
    head: "Total Trips Completed",
    key: ["tripInfo", "completedTrips"],
    populate: true
  },
  "personalInfo.fullName": {
    head: "Name",
    key: ["personalInfo", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "personalInfo.email": {
    head: "Email",
    key: ["personalInfo", "email"],
    populate: true
  },
  "personalInfo.phoneNumber": {
    head: "Contact No.",
    key: ["personalInfo", "phoneNumber"],
    populate: true
  },
  "upcBucketInfo.upcCode": {
    head: "Ur PC Code",
    populate: true,
    key: ["upcBucketInfo", "upcCode"]
  },
  accountStatus: {
    head: "Status",
    key: "accountStatus",
    status: true,
    populate: false
  },

  vehicleType: {
    head: "Associated Car Type",
    key: "vehicleType"
  },
  "vehicleInfo.vehicleRegistrationNo": {
    head: "Car Reg No.",
    key: ["vehicleInfo", "vehicleRegistrationNo"],
    populate: true
  },
  "vehicleInfo.vehicleMake": {
    head: "Car Make",
    key: ["vehicleInfo", "vehicleMake"],
    populate: true
  },
  "vehicleInfo.vehicleModel": {
    head: "Car Model",
    key: ["vehicleInfo", "vehicleModel"],
    populate: true
  },
  vehicleColor: {
    head: "Car Color",
    key: "vehicleColor",
    color: true
  },
  languages: {
    head: "Languages",
    key: "languages"
  }
};

export const UPCDriverHeaderFormat = {
  "personalInfo.fullName": {
    head: "Chauffeur Name",
    key: ["personalInfo", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: 1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: -1,
        isActive: false
      }
    ]
  },
  "personalInfo.email": {
    head: "Email",
    key: ["personalInfo", "email"],
    populate: true
  },
  "upcBucketInfo.upcCode": {
    head: "Ur PC Code",
    key: ["upcBucketInfo", "upcCode"],
    populate: true
  },
  displayId: {
    head: "Chauffeur Id",
    key: "displayId"
  },
  "personalInfo.phoneNumber": {
    head: "Contact No.",
    key: ["personalInfo", "phoneNumber"],
    populate: true
  },
  noOfRiderReferred: {
    head: "No. of Riders Referred",
    key: "noOfRiderReferred"
  },
  upcMoneyMade: {
    head: "Ur PC Money Made",
    key: "upcMoneyMade"
  },
  upcMoneySpent: {
    head: "Ur PC Money Spent",
    key: "upcMoneySpent"
  }
};

export const UPCRiderHeaderFormat = {
  "personalInfo.fullName": {
    head: "Rider Name",
    key: ["personalInfo", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: 1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: -1,
        isActive: false
      }
    ]
  },
  "personalInfo.email": {
    head: "Email",
    key: ["personalInfo", "email"],
    populate: true
  },
  "upcBucketInfo.upcCode": {
    head: "Ur PC Code",
    key: ["upcBucketInfo", "upcCode"],
    populate: true
  },
  "personalInfo.phoneNumber": {
    head: "Contact No.",
    key: ["personalInfo", "phoneNumber"],
    populate: true
  },
  noOfRiderReferred: {
    head: "No. of Rider Referred",
    key: "noOfRiderReferred"
  },
  upcMoneyMade: {
    head: "Ur PC Money Made",
    key: "upcMoneyMade"
  },
  upcMoneySpent: {
    head: "Ur PC Money Spent",
    key: "upcMoneySpent"
  },
  upcCodeAttached: {
    head: "Ur PC Code Attached",
    key: "upcCodeAttached"
  }
};

export const OngoingTripAlertHeaderFormat = {
  "trip.displayId": {
    head: "Booking Id",
    key: ["trip", "displayId"],
    populate: true,
    sort: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "trip.rider.fullName": {
    head: "Rider Name",
    key: ["trip", "rider", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "trip.assignedDriverInfo.driver.displayId": {
    head: "Chauffeur Id",
    key: ["trip", "assignedDriverInfo", "driver", "displayId"],
    populate: true,
    sort: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "trip.assignedDriverInfo.driver.fullName": {
    head: "Chauffeur Name",
    key: ["trip", "assignedDriverInfo", "driver", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },

  "trip.assignedDriverInfo.driver.phoneNumber": {
    head: "Chauffeur Phone No.",
    key: ["trip", "assignedDriverInfo", "driver", "phoneNumber"],
    populate: true
  },

  "trip.rider.phoneNumber": {
    head: "Rider Phone No.",
    key: ["trip", "rider", "phoneNumber"],
    populate: true
  },
  tripStatus: {
    head: "Trip Status",
    key: "tripStatus"
  },
  alert: {
    head: "Alert",
    key: "alert"
  }
};

export const BookingRequestHeaderFormat = {
  displayId: {
    head: "Booking Id",
    key: "displayId",
    sort: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  driverAssigned: {
    head: "Chauffeur Assigned",
    key: "driverAssigned"
  },
  timeForMarkReady: {
    head: "Time For Chauffeur To Mark Ready",
    key: "timeForMarkReady",
    sort: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },

  "rider.fullName": {
    head: "Rider Name",
    key: ["rider", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "assignedDriverInfo.driver.fullName": {
    head: "Chauffeur Name",
    key: ["assignedDriverInfo", "driver", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "assignedDriverInfo.driver.phoneNumber": {
    head: "Chauffeur Phone No.",
    key: ["assignedDriverInfo", "driver", "phoneNumber"],
    populate: true
  },
  "rider.phoneNumber": {
    head: "Rider Phone No.",
    key: ["rider", "phoneNumber"],
    populate: true
  }
};

export const BookingAlertHeaderFormat = {
  "trip.displayId": {
    head: "Booking Id",
    key: ["trip", "displayId"],
    populate: true,
    sort: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },

  "trip.rider.fullName": {
    head: "Rider Name",
    key: ["trip", "rider", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },

  "trip.assignedDriverInfo.driver.fullName": {
    head: "Chauffeur Name",
    key: ["trip", "assignedDriverInfo", "driver", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },

  "trip.assignedDriverInfo.driver.phoneNumber": {
    head: "Chauffeur Phone No.",
    key: ["trip", "assignedDriverInfo", "driver", "phoneNumber"],
    populate: true
  },

  "trip.rider.phoneNumber": {
    head: "Rider Phone No.",
    key: ["trip", "rider", "phoneNumber"],
    populate: true
  },
  timeForStartTrip: {
    head: "Time For Start Trip",
    key: "timeForStartTrip"
  },
  alert: {
    head: "Alert",
    key: "alert"
  }
};

export const AdminHeaderFormat = {
  "personalInfo.fullName": {
    head: "Admin Name",
    key: ["name"],
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "personalInfo.email": {
    head: "Email",
    key: ["personalInfo", "email"],
    populate: true
  },
  adminSuper: {
    head: "Admin",
    key: "adminSuper"
  },
  roleName: {
    head: "Role Name",
    key: "roleName"
  }
};

export const RoleHeaderFormat = {
  roleName: {
    head: "Role Name",
    key: "roleName",
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  accessibilityModule: {
    head: "Accessibility Module",
    key: "accessibilityModule"
  }
};


export const BillingHeaderFormat = {
  displayId: {
    head: "Booking Id",
    key: "displayId",
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ],
    sort: true
  },

  "rider.fullName": {
    head: "Rider Name",
    key: ["rider", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "assignedDriverInfo.driver.displayId": {
    head: "Chauffeur Id",
    key: ["assignedDriverInfo", "driver", "displayId"],
    populate: true,
    sort: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  "assignedDriverInfo.driver.fullName": {
    head: "Chauffeur Name",
    key: ["assignedDriverInfo", "driver", "fullName"],
    populate: true,
    sort: true,
    isDarkColor: true,
    option: [
      {
        value: "alphabetical order",
        type: -1,
        isActive: false
      },
      {
        value: "anti alphabetical",
        type: 1,
        isActive: false
      }
    ]
  },
  vehicleType: {
    head: "Vehicle Type",
    key: "vehicleType"
  },
  tripType: {
    head: "Trip Type",
    key: "tripType"
  },
  tripStatus: {
    head: "Status",
    key: "tripStatus",
    status: true
  },
  "assignedDriverInfo.driver.email": {
    head: "Chauffeur Email",
    key: ["assignedDriverInfo", "driver", "email"],
    populate: true
  },
  "assignedDriverInfo.driver.phoneNumber": {
    head: "Driver Phone No.",
    key: ["assignedDriverInfo", "driver", "phoneNumber"],
    populate: true
  },
  "rider.email": {
    head: "Rider Email",
    key: ["rider", "email"],
    populate: true
  },
  "rider.phoneNumber": {
    head: "Rider Phone No.",
    key: ["rider", "phoneNumber"],
    populate: true
  },
  pickupLoaction: {
    head: "Pickup Location",
    key: "pickupLocation"
  },
  DropLocation: {
    head: "Drop Location",
    key: "dropLocation"
  },
  pickupTime: {
    head: "Pickup Time",
    key: "pickupTime"
  },
  paymentStatus: {
    head: "Payment Status",
    key: "paymentStatus"
  }
};
