import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Notification from "../assets/images/notification.png";
import moment from "moment";
import { connect } from "react-redux";
import { updateNotifications, seenNotificationDone, mqttNotificationDone } from "../store/actions/notifications";

import AlignItemsList from "./AlignItemsList";

const styles = theme => ({
  root: {
    display: "flex"
  },
  paper: {
    marginRight: theme.spacing.unit * 2
  },
  notificationBell: {
    marginLeft: "30px",
    marginTop: "10px"
  },
  badgeCount: {
    position: "absolute",
    fontSize: "14px",
    right: 0,
    top: 0,
    backgroundColor: "#EB5C00",
    color: "#fff",
    padding: "2px",
    borderRadius: "50%",
    width: "20px",
    height: "20px"
  },
  showMoreSpan: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'blue',
    marginTop: "10px",
    marginBottom: "px",
    marginLeft: '35%'
  }
});

class NotificationList extends Component {
  state = {
    anchorEl: null,
    open: false,
    placement: null,
    notificationDisplay: [],
    isDetailFetched: false,
    countUnread: 0,
    updateId: ''
  };

  handleClick = placement => event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: state.placement !== placement || !state.open,
      placement
    }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    if (event.target.id !== "showMoreID") {
      this.setState({ ...this.state, open: false, isDetailFetched: false });

    } else {
      this.setState({ ...this.state, isDetailFetched: false });

    }
    // console.log ("handle close ",event)
  };
  componentDidMount() {

  }
  componentWillUnmount() {
    this.setState({ ...this.state, open: false, isDetailFetched: false });
  }
  componentDidUpdate() {
    let tmpNotification = this.props.notifications

    if ((typeof tmpNotification !== 'undefined' && tmpNotification !== 'undefined' && !this.state.isDetailFetched) || this.props.data.notificationsReducer.mqttComing || (this.props.data.notificationsReducer.isUpdating && typeof tmpNotification !== 'undefined' && tmpNotification !== 'undefined')) {
      let arrNotificationNew = []
      let localArrNotification = [...this.state.notificationDisplay]

      tmpNotification.forEach((notification, i) => {

        let objNotifation = {}
        objNotifation.read = notification.seen
        switch (notification.inAppContent.type) {

          case "7":
            objNotifation.content = `A trip created with no Chauffeur assigned.Assign a chauffeur`;
            objNotifation.createdAt = moment(notification.sentTime).format("MM-DD-YYYY, h:mm a")
            objNotifation.to = `/web/auth/ongoing-trips/details/${notification.inAppContent.info._id}`
            objNotifation.id = notification._id
            break;

          case "6":
            objNotifation.content = `Chauffeur (${notification.inAppContent.info.assignedDriverInfo.driver.fullName}) could not find the rider at the pickup address.`;
            objNotifation.createdAt = moment(notification.sentTime).format("MM-DD-YYYY, h:mm a")
            objNotifation.to = `/web/auth/booking-request/details/${notification.inAppContent.info._id}`
            objNotifation.id = notification._id
            break;


          case "5":
            objNotifation.content = `Chauffeur (${notification.inAppContent.info.assignedDriverInfo.driver.fullName}) is opting additional charge.`;
            objNotifation.createdAt = moment(notification.sentTime).format("MM-DD-YYYY, h:mm a")
            objNotifation.to = `/web/auth/ongoing-trips/details/${notification.inAppContent.info._id}`
            objNotifation.id = notification._id
            break;

          case "4":
            objNotifation.content = `Chauffeur (${notification.inAppContent.info.assignedDriverInfo.driver.fullName}) has failed to mark himself enroute in time.`;
            objNotifation.createdAt = moment(notification.sentTime).format("MM-DD-YYYY, h:mm a")
            objNotifation.to = `/web/auth/booking-request/details/${notification.inAppContent.info._id}`
            objNotifation.id = notification._id
            break;

          case "3":
            objNotifation.content = `Chauffeur (${notification.inAppContent.info.assignedDriverInfo.driver.fullName}) has not marked ready.`;
            objNotifation.createdAt = moment(notification.sentTime).format("MM-DD-YYYY, h:mm a")
            objNotifation.to = `/web/auth/booking-request/details/${notification.inAppContent.info._id}`
            objNotifation.id = notification._id
            break;

          case "2":
            objNotifation.content = `Chauffeur (${notification.inAppContent.info.assignedDriverInfo.driver.fullName}) has cancelled a trip.Assign another chauffeur`;
            objNotifation.createdAt = moment(notification.sentTime).format("MM-DD-YYYY, h:mm a")
            objNotifation.to = `/web/auth/booking-request/details/${notification.inAppContent.info._id}`
            objNotifation.id = notification._id
            break;

          case "1":
            objNotifation.content = `Could not find any chauffeur .Assign a chauffeur`;
            objNotifation.createdAt = moment(notification.sentTime).format("MM-DD-YYYY, h:mm a")
            objNotifation.to = `/web/auth/booking-request/details/${notification.inAppContent.info._id}`
            objNotifation.id = notification._id
            break;

          default:
            break;
        }



        localArrNotification.push(objNotifation);
        localArrNotification.sort((first, second) => {
          return (new Date(first.createdAt) > new Date(second.createdAt)) ? -1 : 1;
        });
      });
      // console.log(localArrNotification,"######",arrNotificationNew)
      // localArrNotification.push(arrNotificationNew)
      this.setState({ ...this.state, notificationDisplay: localArrNotification, isDetailFetched: true })


    }

    if (this.props.data.notificationsReducer.isUpdating && typeof tmpNotification !== 'undefined' && tmpNotification !== 'undefined') {
      this.props.seenNotificationDone();

    }
  }

  listItemClick = (val) => {
    this.props.updateNotifications(val.id)
    let localCountUnRead = this.state.countUnread
    if (val.read) {
      localCountUnRead = localCountUnRead - 1
    }

    this.setState({ ...this.state, open: false, countUnread: localCountUnRead, updateId: val.id });
  }

  render() {
    const { classes } = this.props;
    const { anchorEl, open, placement } = this.state;
    return (
      <div className={classes.root}>
        <IconButton
          color="inherit"
          buttonRef={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick("bottom-end")}
          className={classes.notificationBell}
        >
          <img src={Notification} alt="notification" />
          <div className={classes.badgeCount}>
            {this.props.notificationCount < 99 ? this.props.notificationCount : "99+"}
          </div>
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>

                <ClickAwayListener onClickAway={this.handleClose}>

                  <AlignItemsList
                    listItemClick={this.listItemClick}
                    data={this.state.notificationDisplay}
                  />
                  {this.props.notificationCount > 10 ? <a id="showMoreID" className={classes.showMoreSpan} onClick={this.props.handleNotifictaion}>Show more</a> : null}
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    );
  }
}

NotificationList.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    updateNotifications: (id) => dispatch(updateNotifications(id)),
    seenNotificationDone: () => dispatch(seenNotificationDone()),
    mqttNotificationDone: () => dispatch(mqttNotificationDone())

  };
};


const mapStateToProps = state => {
  return { data: state };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NotificationList));
