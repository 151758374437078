import { combineReducers } from "redux";
import authReducer from "./authReducer";
import profileAPIData from "./profile";
import literalsReducer from "./literalsReducer";
import riderReducer from "./riderReducer";
import driverReducer from "./driverReducer";
import notificationsReducer from "./notificationsReducer";
import dashBoardAPIData from "./dashBoard";
import globalConfigData from "./globalConfig";
import rateChartData from "./rateChart";
import operationalParameterData from "./operationalParameter";
import zoneData from "./zone";
import roleReducer from "./roleReducer";
import adminReducer from "./adminReducer";
import upcRiderData from "./upcRider";
import upcDriverData from "./upcDriver";
import revenueReducer from "./revenueReducer";
import pastTripReducer from "./pastTripReducer";
import tripAlertReducer from "./tripAlert";
import bookingRequestReducer from "./bookingRequest";
import ongoingTripReducer from "./ongoingTripReducer";
import assignDriverReducer from "./assignDriverReducer";
import billingReducer from "./billingReducer";
import snackbarReducer from "./snackbarReducer";

import { LOGOUT_SUCCESS } from "../constants/action-types";

//all reducers should be listed here
const combinedReducer = combineReducers({
  authReducer,
  profileAPIData,
  riderReducer,
  literalsReducer,
  notificationsReducer,
  dashBoardAPIData,
  driverReducer,
  globalConfigData,
  rateChartData,
  operationalParameterData,
  zoneData,
  roleReducer,
  upcRiderData,
  adminReducer,
  upcDriverData,
  revenueReducer,
  pastTripReducer,
  tripAlertReducer,
  bookingRequestReducer,
  ongoingTripReducer,
  assignDriverReducer,
  billingReducer,
  snackbarReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    let literalsReducer = state.literalsReducer;
    state = {
      literalsReducer
    };
  }
  return combinedReducer(state, action);
};

export default rootReducer;
